import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import styled from 'styled-components/native'

import { EpisodeSummary } from '@lyrahealth-inc/shared-app-logic'
import {
  BodyText,
  BodyTextSize,
  colors,
  DataBoard,
  DataBoardItem,
  ExternalLinkIcon,
  Modal,
  Subhead,
  SubheadSize,
  TextButton,
  ThemeType,
  tID,
} from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './snapshot.module.scss'
import { actions as mixpanelActions, mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { episodeTerminationStatuses, measurementBasedCareUrls } from '../../common/constants/appConstants'
import { getClientClosedEpisodes } from '../../data/lyraTherapy/clientSelectors'
import { RootState } from '../../data/store'

const HeaderContainer = styled.View({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const StyledTextButton = styled(TextButton)<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['4px'],
}))

const DataBoardContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  paddingTop: theme.spacing['24px'],
}))

const ZebraTable = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['32px'],
  borderTopWidth: '3px',
  borderTopColor: colors.ui_oatmeal3,
  borderBottomWidth: '1px',
  borderBottomColor: colors.ui_oatmeal3,
}))

const Row = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  padding: theme.spacing['16px'],
}))

const RowHeader = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['4px'],
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

const TotalRow = styled(RowHeader)<{ theme: ThemeType }>(({ theme }) => ({
  padding: theme.spacing['16px'],
  backgroundColor: colors.white,
  borderTopWidth: '1px',
  borderTopColor: colors.ui_oatmeal3,
  marginBottom: theme.spacing['4px'],
}))

const TotalRowTitle = styled.View({
  flexDirection: 'row',
  justifyContent: 'start',
})

const TotalRowExceptionText = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  paddingLeft: theme.spacing['16px'],
  paddingTop: '3px',
}))

const FooterContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['16px'],
}))

const FooterTitle = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  paddingBottom: theme.spacing['8px'],
}))

export const ClosedEpisodesCountSnapshot: React.FC<ClosedEpisodesCountSnapshotProps> = ({ closedEpisodes }) => {
  const [isClosedEpisodesModalOpen, setIsClosedEpisodesModalOpen] = useState<boolean>(false)

  const closedMBCEpisodes = closedEpisodes
    ? Object.values(closedEpisodes).filter((completedEpisode) => completedEpisode?.clinical_success)
    : []

  const closedMBCEpisodesBreakdown = {
    completed: [],
    drop_out: [],
    care_transition: [],
    not_initiated: [],
    not_eligible: [],
    not_supported: [],
  }

  closedMBCEpisodes.forEach((closedEpisode) => {
    if (closedEpisode.episode_state in closedMBCEpisodesBreakdown) {
      closedMBCEpisodesBreakdown[closedEpisode.episode_state].push(closedEpisode)
    }
  })

  const renderClosedEpisodeByStatus = () => {
    return Object.entries(closedMBCEpisodesBreakdown).map(([terminationStatus, episodes], index) => {
      return (
        <Row
          testID={tID(`ClosedEpisodesCountSnapshot-details-${terminationStatus}`)}
          key={`ClosedEpisodesCountSnapshot-details-${terminationStatus}`}
          style={index % 2 ? null : { backgroundColor: colors.ui_oatmeal2 }}
        >
          <RowHeader>
            <BodyText
              size={BodyTextSize.DEFAULT}
              text={episodeTerminationStatuses.find((status) => status.value === terminationStatus)?.name}
            />
            <BodyText size={BodyTextSize.DEFAULT} text={episodes.length} />
          </RowHeader>
        </Row>
      )
    })
  }

  const viewMoreDetails = () => {
    track({
      event: mixpanelEvents.BUTTON_PRESS,
      action: mixpanelActions.VIEW_MBC_COUNT_DETAILS,
    })
    setIsClosedEpisodesModalOpen(true)
  }

  const viewHelpArticle = () => {
    track({
      event: mixpanelEvents.BUTTON_PRESS,
      action: mixpanelActions.VIEW_MBC_COUNT_HELP_ARTICLE,
    })
    window.open(measurementBasedCareUrls.countHelpArticle, '_blank')
  }

  return (
    <div>
      <div data-test-id='ClosedEpisodesCountSnapshot-card'>
        <HeaderContainer>
          <Subhead
            text={'Closed Episodes with Measurement-Based Care Improvement'}
            size={SubheadSize.MEDIUM}
            color={colors.ui_oatmeal6}
          />
          <StyledTextButton
            text='View details'
            testID={tID('ClosedEpisodesCountSnapshot-viewDetails')}
            onPress={viewMoreDetails}
          />
        </HeaderContainer>
        <DataBoardContainer>
          <DataBoard backgroundColor={colors.ui_oatmeal2} centered>
            <DataBoardItem
              testID={tID('ClosedEpisodesCountSnapshot-completed-mbc-count')}
              style={{ marginVertical: '64px' }}
              infoDataStyle={{ marginTop: '0px' }}
              value={closedMBCEpisodes.length}
            />
          </DataBoard>
        </DataBoardContainer>
      </div>
      <Modal
        visible={isClosedEpisodesModalOpen}
        onRequestClose={() => setIsClosedEpisodesModalOpen(false)}
        onCloseEnd={() => setIsClosedEpisodesModalOpen(false)}
        scrollable
        scrollableModalWidth='900px'
        scrollableModalHeight='auto'
        isHeaderTransparent
        modalContents={
          <>
            <Subhead text={'Closed Episodes with Measurement-Based Care Improvement'} size={SubheadSize.MEDIUM} />
            <ZebraTable testID={tID('ClosedEpisodesCountSnapshot-details-table')}>
              {renderClosedEpisodeByStatus()}
              <TotalRow testID={tID('ClosedEpisodesCountSnapshot-details-total')}>
                <TotalRowTitle>
                  <Subhead size={SubheadSize.SMALL} text={`Total`} />
                  <TotalRowExceptionText
                    color={colors.ui_oatmeal5}
                    size={BodyTextSize.CAPTION}
                    text={`* Does not include booster sessions`}
                  />
                </TotalRowTitle>
                <BodyText size={BodyTextSize.DEFAULT} text={closedMBCEpisodes.length} />
              </TotalRow>
            </ZebraTable>
            <FooterContainer>
              <FooterTitle
                size={BodyTextSize.DEFAULT}
                text={'How to calculate your measurement-based care compensation?'}
                color={colors.ui_oatmeal6}
              />
              <BodyText
                size={BodyTextSize.DEFAULT}
                text={
                  <FormattedMessage
                    defaultMessage='Multiply the total above by $90.00. This is your estimated Measurement-Based Care compensation. {helpArticleLink} Check the closed epsidoes client details to see which clients qualified for this compensation'
                    description='Button text shown to navigate to the user payment history'
                    values={{
                      helpArticleLink: (
                        <TextButton
                          isPrimary={true}
                          // @ts-expect-error TS(2322): Type '"inline-block"' is not assignable to type '"... Remove this comment to see the full error message
                          style={{ display: 'inline-block' }}
                          testID={tID('ClosedEpisodesCountSnapshot-modalHelpArticleButton')}
                          onPress={viewHelpArticle}
                          text='View exceptions'
                          rightIcon={<ExternalLinkIcon strokeColor={styles.x_primary_action} size={15} />}
                        />
                      ),
                    }}
                  />
                }
                color={colors.ui_oatmeal5}
              />
            </FooterContainer>
          </>
        }
      />
    </div>
  )
}

type ClosedEpisodesCountSnapshotProps = {
  closedEpisodes: {
    [key: string]: EpisodeSummary
  }
}

const mapStateToProps = (state: RootState) => ({
  closedEpisodes: getClientClosedEpisodes(state),
})

export default connect(mapStateToProps)(ClosedEpisodesCountSnapshot)
