import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'

import { ContentLayout } from '@lyrahealth-inc/ui-core'

import { ROLES } from '../common/constants/appConstants'
import { PRACTICES_DETAILS, PRACTICES_REGISTER } from '../common/constants/routingConstants'
import { hasRole } from '../common/utils/utils'
import { getAuthRoles } from '../data/auth/authSelectors'
import { RootState } from '../data/store'
import { SentryRoutes } from '../index'
// Routes
// dashboard
import PracticesDashboard from '../practices/dashboard/PracticesDashboard'
// individual practice pages
import PaymentsPracticeDetails from '../practices/individualPractice/paymentsAdmin/PaymentsPracticeDetails'
import ProvidersPracticeDetails from '../practices/individualPractice/providersAdmin/ProvidersPracticeDetails'
import RegisterPractice from '../practices/register/RegisterPractice'

type PracticesRoutesProps = {
  roles?: any // TODO: PropTypes.instanceOf(List)
}

class PracticesRoutes extends Component<PracticesRoutesProps> {
  _getDashboard() {
    if (hasRole(this.props.roles, ROLES.PRACTICES_ADMIN)) {
      return <h1>PRACTICES_DASHBOARD, for Practice Admin </h1>
    } else if (hasRole(this.props.roles, [ROLES.PROVIDERS_ADMIN, ROLES.PAYMENTS_ADMIN])) {
      return <PracticesDashboard />
    } else if (hasRole(this.props.roles, ROLES.INDIVIDUAL_PROVIDER)) {
      return <h1>PRACTICES_DASHBOARD, for Individual Provider </h1>
    } else {
      return <h1>PRACTICES_DASHBOARD, default</h1>
    }
  }

  _getDetails() {
    if (hasRole(this.props.roles, ROLES.PROVIDERS_ADMIN)) {
      return <ProvidersPracticeDetails />
    } else if (hasRole(this.props.roles, ROLES.PAYMENTS_ADMIN)) {
      return <PaymentsPracticeDetails />
    } else {
      return <h1>PRACTICE_DETAILS, default</h1>
    }
  }

  render() {
    return (
      <div>
        <ContentLayout>
          <div style={{ marginTop: '50px' }}>
            <SentryRoutes>
              <Route path='/' element={this._getDashboard()} />
              <Route path={`${PRACTICES_DETAILS.subroute}/*`} element={this._getDetails()} />
              <Route path={PRACTICES_REGISTER.subroute} element={<RegisterPractice />} />
            </SentryRoutes>
          </div>
        </ContentLayout>
      </div>
    )
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    roles: getAuthRoles(state),
  }
}

export default connect(mapStateToProps, null)(PracticesRoutes)
