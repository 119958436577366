import React, { FunctionComponent, useEffect, useState } from 'react'
// Disabling because getSize is required for component
// eslint-disable-next-line no-restricted-imports
import { Image } from 'react-native'

import styled from 'styled-components/native'

import { Image as ExpoImage } from '../image/Image'

const ImageContainer = styled(ExpoImage)<{ aspectRatio: number }>`
  width: 100%;
  height: undefined;
  aspect-ratio: ${({ aspectRatio }) => aspectRatio};
`

/**
 * Renders a full with image by calculating the aspect ratio of the image.
 */

export const FullWidthImage: FunctionComponent<FullWidthImageProps> = ({ url }) => {
  const [aspectRatio, setAspectRatio] = useState(1)
  useEffect(() => {
    Image.getSize(url, (width, height) => {
      setAspectRatio(width / height)
    })
  }, [url])
  return <ImageContainer aspectRatio={aspectRatio || 1} source={{ uri: url }} contentFit='contain' />
}

type FullWidthImageProps = {
  url: string
}
