import { createSelector } from 'reselect'

import { getLyraTherapyState } from './clientSelectors'

export const getLyraTherapyContents = createSelector(
  getLyraTherapyState,
  (lyraTherapyState) => lyraTherapyState?.contents,
)

export const getLyraTherapyContentsData = createSelector(getLyraTherapyContents, (contents) => contents?.data)

export const getLyraTherapyContentsPrograms = createSelector(getLyraTherapyContents, (contents) => contents?.programs)
