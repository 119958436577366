import React from 'react'
import CSSModules from 'react-css-modules'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'

import { cloneDeep } from 'lodash-es'
import PropTypes from 'prop-types'

import { BootstrapContainer, ChevronIcon, LtIcon } from '@lyrahealth-inc/ui-core'
import { toJS } from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './assignmentLibrary.module.scss'
import { programs as programMap } from '../../common/constants/appConstants'
import { CLIENT_HOME, CLIENTS_NEW_TRACK } from '../../common/constants/routingConstants'
import { getLyraTherapyContentsPrograms } from '../../data/lyraTherapy/contentSelectors'
import { RootState } from '../../data/store'
import MiniBackNav from '../miniBackNav/MiniBackNav'

export const TrackLibrary = ({ programs }: any) => {
  const navigate = useNavigate()
  const location = useLocation()

  const sessionCount = location?.state?.sessionCount
  const handleBackClicked = () => {
    navigate(CLIENT_HOME.route, { state: { sessionCount } })
  }

  const tracks = cloneDeep(
    programs.find((program: any) => program.name === programMap.SingleSessionCoaching)?.curricula.tracks,
  )
  delete tracks.default_SingleSessionCoaching

  const handleTrackClicked = (track: any) => {
    navigate(CLIENTS_NEW_TRACK.route, { state: { track, sessionCount } })
  }

  return (
    <BootstrapContainer col='col-md-10 col-md-offset-1'>
      <div styleName='container'>
        <MiniBackNav backFunc={handleBackClicked} subText='Client Activities' />
        <div style={{ width: '100%' }}>
          <h2 styleName='main-header'>Share care plan</h2>
          <div styleName='list-container'>
            {Object.values(tracks).map((track) => (
              // eslint-disable-next-line react/jsx-key, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              <div styleName='list-item-container' onClick={() => handleTrackClicked(track)}>
                <div styleName='name'>
                  <div styleName='icon'>
                    <LtIcon type={'track'} />
                  </div>
                  {(track as any).title}
                </div>
                <div styleName='session-info'>
                  <div styleName='session-count'>
                    {(track as any).curriculumSchedule.length} week{(track as any).curriculumSchedule.length > 1 && 's'}
                  </div>
                </div>
                <div>
                  <ChevronIcon isFilled fillColor={styles.x_soft_black} direction='right' />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </BootstrapContainer>
  )
}

TrackLibrary.propTypes = {
  programs: PropTypes.array,
}

const mapStateToProps = (state: RootState) => {
  return {
    programs: getLyraTherapyContentsPrograms(state),
  }
}

export default connect(mapStateToProps, null)(toJS(CSSModules(TrackLibrary, styles)))
