import React, { useCallback, useRef } from 'react'
import { useIntl } from 'react-intl'
import { connect, useSelector } from 'react-redux'
import { Navigate, useLocation, useNavigate } from 'react-router'

import { cloneDeep, isEmpty, isNil, merge, pick, reduce } from 'lodash-es'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import { css } from 'styled-components/native'

import { Content, CustomerInfo, Transfer, useFlags } from '@lyrahealth-inc/shared-app-logic'
import { CardMedium, ContentLayout, contentSchemaUtils, LoadingIndicator } from '@lyrahealth-inc/ui-core/lib'
import { FormBody, FormButtonParams, PrimaryButton, tID, toJS, useFetcher } from '@lyrahealth-inc/ui-core-crossplatform'

import attestationFormMetadata from './attestationFormMetadata'
import getApproachingSpecialityCareMetadata from './getApproachingSpecialityCareMetadata'
import getPreAuthSpecialityCareMetadata from './getPreAuthSpecialityCareMetadata'
import ICD10sField from './ICD10sField'
import ICD10sFormMetadata from './ICD10sFormMetadata'
import { addAlert, hideAlerts } from '../../../data/alertActions'
import { getAuthRoles } from '../../../data/auth/authSelectors'
import { getHealthPlanCurrentCharge, getHealthPlanICD10s } from '../../../data/healthPlan/healthPlanSelectors'
import { clearHealthPlanCharge, clearHPEligibility, getICD10s } from '../../../data/healthPlanAutoActions'
import { getPaymentsDataAllData } from '../../../data/payments/paymentsSelectors'
import { getRequestPaymentCustomers } from '../../../data/requestPayment/requestPaymentSelectors'
import { RootState } from '../../../data/store'
import { submitPaymentRequest } from '../../../requestPayment/data/requestPaymentActions'
import {
  bannerMessages,
  ICD10_PRIMARY_FIELD,
  ICD10_SECONDARY_FIELD,
  ROLES,
  specialityCareAttestationStatuses,
} from '../../constants/appConstants'
import {
  CLIENT_EMAIL,
  PRACTICES_DETAILS_HEALTHPLAN_PAYMENT_INFO,
  PRACTICES_DETAILS_NEW_PAYMENT,
  PROVIDERS_DETAILS_HEALTHPLAN_PAYMENT_INFO,
  PROVIDERS_DETAILS_NEW_PAYMENT,
  REQUEST_PAYMENT,
} from '../../constants/routingConstants'
import { convertToFormData, hasRole, prepopulateAttestation } from '../../utils/utils'

export const HealthPlanInfoAdmins: React.FC<HealthPlanInfoProps> = ({
  charge,
  availableCodes,
  customers = [],
  pastPayments,
  actions: { getICD10s, hideAlerts, addAlert, clearHealthPlanCharge, clearHPEligibility, submitPaymentRequest },
}) => {
  const intl = useIntl()
  const { isProgramLevelSessionLimitEnabled } = useFlags()
  const roles = useSelector(getAuthRoles)
  const [loading] = useFetcher([getICD10s, {}])
  const navigate = useNavigate()
  const location = useLocation()
  const hasClearedCharge = useRef(false)

  const goToNextScreen = useCallback(
    (
      chargeSubmitted,
      charge: Transfer & { session_tracking_status?: string; email_required?: boolean; data?: { company?: string } },
    ) => {
      if (charge?.email_required && !hasRole(roles, ROLES.PAYMENTS_ADMIN) && chargeSubmitted) {
        navigate(CLIENT_EMAIL.route, {
          state: {
            patient_id: charge?.patient_lyra_id,
            initialSession: charge?.session_tracking_status === 'session_limit_initial_session',
            sessionsLeft: parseInt(charge?.sessions_remaining, 10),
            firstName: charge?.first_name,
            lastName: charge?.last_name,
            customer: customers.find((customer: CustomerInfo) => customer.value === charge?.data?.company),
          },
        })
      } else if (
        hasRole(roles, ROLES.PAYMENTS_ADMIN) &&
        location.pathname === PRACTICES_DETAILS_HEALTHPLAN_PAYMENT_INFO.route
      ) {
        navigate(PRACTICES_DETAILS_NEW_PAYMENT.route, { replace: true, state: { prev_route: location.pathname } })
      } else if (
        hasRole(roles, ROLES.PAYMENTS_ADMIN) &&
        location.pathname === PROVIDERS_DETAILS_HEALTHPLAN_PAYMENT_INFO.route
      ) {
        navigate(PROVIDERS_DETAILS_NEW_PAYMENT.route, {
          replace: true,
          state: {
            provider_id: charge?.provider_lyra_id,
            prev_route: location.pathname,
          },
        })
      } else {
        navigate(REQUEST_PAYMENT.route, { replace: true, state: { prev_route: location.pathname } })
      }
    },
    [customers, location.pathname, navigate, roles],
  )

  const submitForm = useCallback(
    (values: { values?: Dict }) => {
      hideAlerts()
      const formValues = { ...values.values }
      if (Array.isArray(formValues[ICD10_PRIMARY_FIELD])) {
        formValues[ICD10_PRIMARY_FIELD] = formValues[ICD10_PRIMARY_FIELD].map((obj: any) => obj.code).join(',')
      }
      if (Array.isArray(formValues[ICD10_SECONDARY_FIELD])) {
        formValues[ICD10_SECONDARY_FIELD] = formValues[ICD10_SECONDARY_FIELD].map((obj: any) => obj.code).join(',')
      }
      const mergeChargeHPIData = {
        ...charge,
        ...formValues,
      }
      return submitPaymentRequest(convertToFormData(mergeChargeHPIData), false, isProgramLevelSessionLimitEnabled).then(
        (rpReturn: any) => {
          const chargeCopy = cloneDeep(charge)
          chargeCopy.session_tracking_status = rpReturn.data.session_tracking_status
          chargeCopy.sessions_remaining = rpReturn.data.data.sessions_remaining
          chargeCopy.email_required = rpReturn.data.email_required
          addAlert({
            show: true,
            contents: bannerMessages.SUBMIT_PAYMENT_SUCCESS,
            style: 'success',
            expires: false,
            autoDismissTimer: 10000,
          })
          hasClearedCharge.current = true
          clearHealthPlanCharge()
          clearHPEligibility()
          goToNextScreen(true, chargeCopy)
        },
        (error: any) => {
          const errorType = error.response.data.type
          if (errorType === 'duplicate_payment' || errorType === 'cancellation_policy') {
            goToNextScreen(false, charge)
          }
        },
      )
    },
    [
      addAlert,
      charge,
      clearHealthPlanCharge,
      clearHPEligibility,
      goToNextScreen,
      hideAlerts,
      submitPaymentRequest,
      isProgramLevelSessionLimitEnabled,
    ],
  )

  const getInitialICD10s = () => {
    if (!availableCodes) {
      return {}
    }
    const icd10s = pick(charge, [ICD10_PRIMARY_FIELD, ICD10_SECONDARY_FIELD])
    return reduce(
      icd10s,
      (res, val, key) => {
        if (isNil(val)) {
          return res
        }
        return {
          ...res,
          [key]: val.split(',').map((code: any) => availableCodes.all.find((codeObj: any) => codeObj.code === code)),
        }
      },
      {},
    )
  }

  const getAttended = () => pick(charge, 'attendance')

  const submitButton = useCallback(({ handleSubmit }: FormButtonParams) => {
    return <PrimaryButton text={'Submit'} onPress={handleSubmit} testID={tID('HealthPlanInfoAdmins-submit')} />
  }, [])

  if (isEmpty(charge)) {
    if (hasClearedCharge.current) {
      return null
    }
    return <Navigate to={REQUEST_PAYMENT.route} />
  }

  const { next_session_uses_health_plan, speciality_care_attestation_status } = charge

  let metadata: Content = {
    meta_data: { schema: { type: 'object', properties: {} }, uiSchema: { 'ui:order': [] } },
    content_type: 'form',
    group: 'exercise',
    name: 'healthPlanInfoMetadata',
    title: '',
  }

  if (next_session_uses_health_plan) {
    metadata = contentSchemaUtils.extendMetadata(metadata, ICD10sFormMetadata)
    ;(metadata.meta_data as any).initialValues = merge(getAttended(), getInitialICD10s())
  }
  let button
  const { APPROACHING_ATTESTATION, ATTESTATION_PRIOR_AUTHORIZATION, ATTESTATION_NEEDED } =
    specialityCareAttestationStatuses
  const { label: companyLabel } =
    customers.find(({ value }) =>
      value === (Array.isArray(charge?.company) && charge?.company[0].value) ? charge?.company[0].value : undefined,
    ) || {}
  switch (speciality_care_attestation_status) {
    case APPROACHING_ATTESTATION:
      metadata = contentSchemaUtils.extendMetadata(
        metadata,
        getApproachingSpecialityCareMetadata(companyLabel, `${charge?.first_name} ${charge?.last_name}`),
      )
      break
    case ATTESTATION_PRIOR_AUTHORIZATION:
      metadata = contentSchemaUtils.extendMetadata(
        metadata,
        getPreAuthSpecialityCareMetadata(
          companyLabel,
          charge?.first_name,
          `${charge?.first_name} ${charge?.last_name}`,
        ),
      )
      break
    case ATTESTATION_NEEDED:
      metadata = contentSchemaUtils.extendMetadata(metadata, attestationFormMetadata)
      ;(metadata.meta_data as any).initialValues = merge(
        getAttended(),
        getInitialICD10s(),
        prepopulateAttestation(pastPayments),
      )
      break
    default:
      break
  }

  return (
    <ContentLayout>
      <CardMedium backText={'Request Payment'} backFunc={() => goToNextScreen(false, charge)}>
        {loading || isEmpty(availableCodes) ? (
          <div style={{ textAlign: 'center' }}>
            <LoadingIndicator size={45} />
          </div>
        ) : (
          <FormBody
            intl={intl}
            schema={metadata?.meta_data?.schema}
            uiSchema={metadata?.meta_data?.uiSchema}
            customFields={{ icd10s: (props: any) => <ICD10sField {...props} availableCodes={availableCodes} /> }}
            name={metadata?.name}
            formButton={submitButton}
            saveForm={submitForm}
            withPageBreaks={false}
            formBodyCustomStyles={{
              submitButtonContainer: css`
                border-top-width: 0;
                padding: 0;
                width: 100%;
                box-shadow: none;
                border-bottom-left-radius: 16px;
                border-bottom-right-radius: 16px;
              `,
              submitButtonWrapper: css`
                max-width: none;
                margin-left: 40px;
                margin-bottom: 20px;
              `,
            }}
            initialValues={{
              ...(next_session_uses_health_plan && merge(getAttended(), getInitialICD10s())),
              ...(speciality_care_attestation_status === ATTESTATION_NEEDED && prepopulateAttestation(pastPayments)),
            }}
          >
            {button}
          </FormBody>
        )}
      </CardMedium>
    </ContentLayout>
  )
}

type HealthPlanInfoProps = {
  actions: any
  charge: any
  availableCodes: any
  customers: Array<any>
  pastPayments: Array<any>
}

const mapStateToProps = (state: RootState) => {
  return {
    charge: getHealthPlanCurrentCharge(state),
    availableCodes: getHealthPlanICD10s(state),
    customers: getRequestPaymentCustomers(state),
    pastPayments: getPaymentsDataAllData(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators(
    {
      clearHealthPlanCharge,
      clearHPEligibility,
      submitPaymentRequest,
      getICD10s,
      hideAlerts,
      addAlert,
    },
    dispatch,
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(toJS(HealthPlanInfoAdmins))
