import React from 'react'
import CSSModules from 'react-css-modules'

import { useFlags } from '@lyrahealth-inc/shared-app-logic'
import { CustomerInfo } from '@lyrahealth-inc/shared-app-logic/src/models/customer/Customer'

import styles from './requestPayment.module.scss'
import { sessionTracking } from '../common/constants/appConstants'

const FirstSessionModal = ({ firstName, lastName, sessionsLeft, customer }: FirstSessionModalProps) => {
  const { isProgramLevelSessionLimitEnabled: isProgramLevelSessionLimitEnabledLD } = useFlags()
  const isProgramLevelSessionLimitEnabled = isProgramLevelSessionLimitEnabledLD && customer?.sessions_limit_by_program
  return (
    <div styleName='modal-content'>
      <h2 styleName='main-header'>Thank you!</h2>
      <div>
        <p>
          Your payment was successfully submitted. As a reminder, your client,{' '}
          <span styleName='capitalize'>{firstName}</span> <span styleName='capitalize'>{lastName}</span> has session
          costs covered for a set number of sessions as part of their Lyra benefit.
        </p>
        {customer?.display_visits_per_issue_per_year ? (
          <>
            <p>
              Please note that <span styleName='capitalize'>{firstName}</span> has{' '}
              {customer.display_visits_per_issue_per_year} visits per issue per year. Issues determined between you and
              your client.
            </p>
            <p className='paragraph-small'>
              For any questions, please contact <a href='mailto:providers@lyrahealth.com'>providers@lyrahealth.com</a>
            </p>
          </>
        ) : (
          <>
            <p>
              Please note that <span styleName='capitalize'>{firstName}</span> has {sessionsLeft}{' '}
              {isProgramLevelSessionLimitEnabled && 'therapy '}sessions remaining.
            </p>
            <p className='paragraph-small'>
              We’ll let you both know when there are only {sessionTracking.WARNING_THRESHOLD}{' '}
              {isProgramLevelSessionLimitEnabled && 'therapy '}sessions left so you and {firstName} can start planning
              to complete treatment or discussing longer term care. For any questions, please contact{' '}
              <a href='mailto:providers@lyrahealth.com'>providers@lyrahealth.com</a>
            </p>
          </>
        )}
      </div>
    </div>
  )
}

type FirstSessionModalProps = {
  firstName: string
  lastName: string
  sessionsLeft: number
  customer?: CustomerInfo
}

export default CSSModules(FirstSessionModal, styles)
