import React from 'react'
import CSSModules from 'react-css-modules'
import { connect } from 'react-redux'
import { Navigate, Route } from 'react-router-dom'

import { fromJS } from 'immutable'
import * as _ from 'lodash-es'
import { bindActionCreators } from 'redux'

import { BootstrapContainer, NavButton } from '@lyrahealth-inc/ui-core'

import PracticeProviders from './providers/PracticeProviders'
import styles from './providersPracticeDetails.module.scss'
import TabFilters from '../../../common/components/tabFilters/TabFilters'
import {
  PRACTICES,
  PRACTICES_DETAILS_EDIT_INFO,
  PRACTICES_DETAILS_INFO,
  PRACTICES_DETAILS_PROVIDERS,
} from '../../../common/constants/routingConstants'
import { RootState } from '../../../data/store'
import { SentryRoutes } from '../../../index'
import withRouter from '../../../routing/withRouter'
import * as practiceDetailsActions from '../data/practiceDetailsActions'
// pages
import { getPracticeDetailsData } from '../data/practiceDetailsSelectors'
import DisplayPracticeInfo from '../info/DisplayPracticeInfo'
import EditPracticeInfo from '../info/EditPracticeInfo'

type ProvidersPracticeDetailsProps = {
  router?: any
  actions?: any
  practiceDetails?: any // TODO: PropTypes.instanceOf(Map)
}

class ProvidersPracticeDetails extends React.Component<ProvidersPracticeDetailsProps> {
  componentDidMount() {
    const { router, practiceDetails, actions } = this.props
    const practiceId = router.location.state && router.location.state.practiceId
    if (!practiceDetails) {
      if (practiceId) {
        return actions.getPractice(practiceId)
      }
      // we don't have the context for what practice we should fetch/display,
      // so punt them back to the practices list
      return router.navigate(PRACTICES.route, { replace: true })
    }
  }

  componentWillUnmount() {
    this.props.actions.clearPracticeDetailsStore()
  }

  _backToPractices = () => {
    this.props.router.navigate(PRACTICES.route)
  }

  _handleTabClick = (tab: any) => {
    switch (tab.toLowerCase()) {
      case 'providers':
        this.props.router.navigate(PRACTICES_DETAILS_PROVIDERS.route, { replace: true })
        break
      case 'info':
        this.props.router.navigate(PRACTICES_DETAILS_INFO.route, { replace: true })
        break
    }
  }

  _renderTabs = () => {
    let tab
    const pathname = this.props.router.location.pathname
    if (_.includes(pathname, 'info')) {
      tab = 'Info'
    } else if (_.includes(pathname, 'providers')) {
      tab = 'Providers'
    }

    // @ts-expect-error TS(2322): Type '(tab: any) => void' is not assignable to typ... Remove this comment to see the full error message
    return <TabFilters activeTab={tab} tabList={['INFO', 'PROVIDERS']} handleClick={this._handleTabClick} />
  }

  render() {
    if (!this.props.practiceDetails) {
      return false
    }

    const { practiceDetails } = this.props

    return (
      <BootstrapContainer>
        <NavButton
          // @ts-expect-error TS(2322): Type '{ className: string; styleType: string; text... Remove this comment to see the full error message
          className={styles['back-button']}
          styleType='back'
          text='Back to Practices'
          onClick={this._backToPractices}
        />
        <div styleName='overview'>
          <h2>{practiceDetails.practice_name}</h2>
          <p>{practiceDetails.email}</p>
        </div>
        {this._renderTabs()}
        <SentryRoutes>
          <Route
            path={PRACTICES_DETAILS_EDIT_INFO.subroute}
            // @ts-expect-error TS(2322): Type '{ initialValues: any; }' is not assignable t... Remove this comment to see the full error message
            element={<EditPracticeInfo initialValues={fromJS(practiceDetails)} />}
          />
          <Route path={PRACTICES_DETAILS_INFO.subroute} element={<DisplayPracticeInfo data={practiceDetails} />} />
          <Route
            path={PRACTICES_DETAILS_PROVIDERS.subroute}
            element={<PracticeProviders practiceID={practiceDetails.id} />}
          />
          <Route path='*' element={<Navigate to={PRACTICES_DETAILS_INFO.route} />} />
        </SentryRoutes>
      </BootstrapContainer>
    )
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    practiceDetails: getPracticeDetailsData(state),
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators({ ...practiceDetailsActions }, dispatch),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CSSModules(ProvidersPracticeDetails, styles)))
