import { addMilliseconds, parseISO } from 'date-fns'
import { RRule, Weekday } from 'rrule'

import {
  Appointment,
  EventExtendedProps,
  getTimezoneOffset,
  isOOOEvent,
  LyraEventExtendedPropsNoTZ,
  ProviderCalendarEvent,
} from '@lyrahealth-inc/shared-app-logic'

import { employmentTypes } from '../common/constants/appConstants'

export function mapEventToBookableEvent(
  event: { startTime: string; endTime: string },
  timeZone: string,
  recurring: boolean = true,
) {
  let recurrence = undefined
  if (recurring) {
    const weekday = addMilliseconds(parseISO(event.startTime), getTimezoneOffset(timeZone, event.startTime)).getUTCDay()
    recurrence = [
      new RRule({
        freq: RRule.WEEKLY,
        byweekday: [new Weekday(weekday === 0 ? 6 : weekday - 1)],
      }).toString(),
    ]
  }

  return {
    title: 'Available',
    start: event.startTime,
    end: event.endTime,
    timezone: timeZone,
    event_type: recurring ? 'bookable_recurring' : 'bookable',
    recurrence,
  }
}

export function mapExtendedProps(
  event: ProviderCalendarEvent,
  appointments: { [key: number]: Appointment },
): EventExtendedProps {
  const eventType = event.extended_properties?.private?.lyraEventType
  const hasConferenceData = event.has_conference_data
  const isOOO = isOOOEvent(event.title)

  const untypedTz = event.extended_properties?.private?.clientTimeZone
  const clientTimeZone = untypedTz && typeof untypedTz === 'string' ? untypedTz : null

  const untypedAppointmentId = event.extended_properties?.private?.appointmentId
  const appointmentId =
    untypedAppointmentId && typeof untypedAppointmentId === 'string' ? parseInt(untypedAppointmentId) : null

  if (!eventType || typeof eventType !== 'string') {
    return {
      lyraEventType: 'generic',
      description: event.description,
      externalId: event.external_id,
      calendarConfigurationId: event.provider_calendar_configuration_id,
      hasConferenceData,
      clientTimeZone,
      lyraAppointmentId: appointmentId,
      isOOOEvent: isOOO,
    }
  }

  if (eventType === 'bookable_recurring' || eventType === 'bookable') {
    return {
      lyraEventType: eventType,
      externalId: event.external_id,
    }
  }

  if (eventType === 'session' && appointmentId && appointmentId in appointments) {
    const appointment = appointments[appointmentId]
    const clientTimeZone = event.extended_properties?.private?.clientTimeZone
    return {
      clientTimeZone: clientTimeZone && typeof clientTimeZone === 'string' ? clientTimeZone : null,
      ...getSessionExtendedProperties(appointment),
    }
  }

  return {
    lyraEventType: 'generic',
    description: event.description,
    externalId: event.external_id,
    calendarConfigurationId: event.provider_calendar_configuration_id,
    hasConferenceData,
    clientTimeZone,
    lyraAppointmentId: appointmentId,
    isOOOEvent: isOOO,
  }
}

export const getSessionExtendedProperties = (appointment: Appointment): LyraEventExtendedPropsNoTZ => {
  return {
    lyraEventType: 'session',
    lyraProgramId: appointment.programId,
    appointmentClass: appointment.sessionNumber === 1 ? 'initial' : 'recurring',
    lyraAppointmentId: appointment.appointmentId,
    clientFirstName: appointment.userInfo?.firstName,
    clientLastName: appointment.userInfo?.lastName,
    clientPhoneNumber: appointment.userInfo?.phone,
    appointmentSessionNumber: appointment.sessionNumber,
    appointmentMeetingFormat: appointment.meetingFormat,
    clientId: appointment.userInfo?.lyraId ?? null,
    isNoShow: appointment.appointmentStatus === 'missed',
  }
}

export const getBookableTarget = (employmentStatus?: string): number => {
  switch (employmentStatus) {
    case employmentTypes.FULL_TIME:
      return 30
    case employmentTypes.PART_TIME:
      return 19
    case employmentTypes.REDUCED_FULL_TIME:
      return 24
    case employmentTypes.CLINICAL_QUALITY_COORDINATOR:
      return 15
    case employmentTypes.FELLOW_SUPERVISOR:
      return 24
    case employmentTypes.TEST:
      return 3
    default:
      return 30
  }
}

export const getWorkingHours = (employmentStatus?: string): number => {
  switch (employmentStatus) {
    case employmentTypes.FULL_TIME:
      return 40
    case employmentTypes.PART_TIME:
      return 24
    case employmentTypes.FULL_TIME_REDUCED:
    case employmentTypes.REDUCED_FULL_TIME:
      return 32
    case employmentTypes.CLINICAL_QUALITY_CONSULTANTS:
    case employmentTypes.CLINICAL_QUALITY_COORDINATOR:
      return 20
    case employmentTypes.FELLOW_SUPERVISOR:
      return 32
    case employmentTypes.TEST:
      return 5
    default:
      return 40
  }
}

export const getBookableMaxSlots = (employmentStatus?: string): number => {
  switch (employmentStatus) {
    case employmentTypes.FULL_TIME:
      return 36
    case employmentTypes.PART_TIME:
      return 21
    case employmentTypes.REDUCED_FULL_TIME:
      return 29
    case employmentTypes.CLINICAL_QUALITY_COORDINATOR:
      return 18
    case employmentTypes.FELLOW_SUPERVISOR:
      return 29
    case employmentTypes.TEST:
      return 5
    default:
      return 36
  }
}

type SlotsToUpdate = {
  slotsToAdd: { startTime: string; endTime: string }[]
  slotsToRemove: { startTime: string; endTime: string; id: string }[]
}

const getLocalTimeString = (time: string, timeZone: string) => {
  const eventStart = parseISO(time)
  return eventStart.toLocaleDateString('en-US', { weekday: 'long', hour: '2-digit', minute: '2-digit', timeZone })
}
export const getSlotsToUpdate = (
  newSlots: { startTime: string; endTime: string }[],
  oldSlots: { startTime: string; endTime: string; id: string }[],
  timeZone: string,
): SlotsToUpdate => {
  const slotsToRemove = oldSlots.filter(
    (oldSlot) =>
      !newSlots.find(
        (newSlot) =>
          getLocalTimeString(newSlot.startTime, timeZone) === getLocalTimeString(oldSlot.startTime, timeZone) &&
          getLocalTimeString(newSlot.endTime, timeZone) === getLocalTimeString(oldSlot.endTime, timeZone),
      ),
  )

  const slotsToAdd = newSlots.filter(
    (newSlot) =>
      !oldSlots.find(
        (oldSlot) =>
          getLocalTimeString(newSlot.startTime, timeZone) === getLocalTimeString(oldSlot.startTime, timeZone) &&
          getLocalTimeString(newSlot.endTime, timeZone) === getLocalTimeString(oldSlot.endTime, timeZone),
      ),
  )
  return {
    slotsToAdd,
    slotsToRemove,
  }
}
