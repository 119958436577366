import React from 'react'

import { useTheme } from 'styled-components/native'

import {
  BodyText,
  BodyTextSize,
  Link,
  PrimaryButton,
  Subhead,
  SubheadSize,
  tID,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { actions, mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import {
  BodyTextContainer,
  ButtonContainer,
  HeaderContainer,
  SchedulingWalkthroughComponentProps,
  WalkthroughContentContainer,
} from '../constants'

export const SchedulingWalkthroughOUS: React.FC<SchedulingWalkthroughComponentProps> = ({ connectCalendar }) => {
  const { colors } = useTheme()

  const internationalInstructionsLink = () => {
    track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.SCHEDULING_INTERNATIONAL_WALKTHROUGH_HOW_TO_CONNECT })
    window.open(
      'https://icas-provider-support.zendesk.com/hc/en-us/articles/33280044864275-How-to-Set-up-and-Edit-Your-Calendar-Availability',
      '_blank',
    )
  }

  return (
    <WalkthroughContentContainer>
      <HeaderContainer>
        <Subhead
          level='2'
          size={SubheadSize.LARGE}
          textAlign='center'
          text='Let’s connect Lyra to your Google Calendar'
          color={colors.textPrimary}
        />
      </HeaderContainer>
      <BodyTextContainer>
        <BodyText
          color={colors.textSecondary}
          textAlign='center'
          text='We’ll walk you through the calendar integration process step-by-step. This requires managing your calendar settings and will take around 15 minutes.'
        />
      </BodyTextContainer>
      <BodyTextContainer>
        <BodyText
          color={colors.textSecondary}
          textAlign='center'
          text={
            <>
              First, review{' '}
              <Link text='these instructions' onPress={internationalInstructionsLink} size={BodyTextSize.DEFAULT} /> to
              set up your availability and access live support if needed.
            </>
          }
        />
      </BodyTextContainer>
      <ButtonContainer>
        <PrimaryButton text='Continue' onPress={connectCalendar} testID={tID('CompleteWalkthrough-button')} />
      </ButtonContainer>
    </WalkthroughContentContainer>
  )
}
