import React, { FunctionComponent, MutableRefObject } from 'react'

import { isNil, noop } from 'lodash-es'
import styled from 'styled-components/native'

import { Contentmetadata } from '@lyrahealth-inc/shared-app-logic'

import { ThemedStatusBar } from '../../atoms/themedStatusBar/ThemedStatusBar'
import { ActivityHeader } from '../../organisms/activityHeader/ActivityHeader'
import { InfoSheetBody } from '../../organisms/infoSheetBody/InfoSheetBody'
import { Flex1View } from '../../templates/content/CommonViews'
import { ThemeType, tID } from '../../utils'

/**
 * A wrapper for FormBody that controls pagination and renders the activity header
 */
export interface InfoSheetProps {
  metaData: Contentmetadata
  title: string
  instructions: string | null
  onLinkClicked: (url: string) => void
  exit?: () => void
  onView?: () => void
  a11yFocusedElementOnLoad?: MutableRefObject<any>
  defaultFlex?: boolean
}

const Container = styled(Flex1View)<{ theme: ThemeType }>(({ theme: { colors } }) => ({
  backgroundColor: colors.backgroundPrimary,
}))

export const InfoSheet: FunctionComponent<InfoSheetProps> = ({
  metaData = {},
  title = '',
  instructions,
  onLinkClicked,
  exit,
  onView = noop,
  a11yFocusedElementOnLoad,
  defaultFlex = false,
}) => {
  const { content, links, imageUrl, selectedMedication, title: selectedMedTitle } = metaData ?? {}
  const headerTitle = isNil(selectedMedTitle) || selectedMedTitle === '' ? title : ''
  const infoInstructions =
    selectedMedication?.instructions === undefined ? instructions : selectedMedication?.instructions
  return (
    <Container testID={tID('InfoSheet')}>
      <ActivityHeader title={headerTitle} onClosePress={exit} a11yFocusedElementOnLoad={a11yFocusedElementOnLoad} />
      <Flex1View defaultFlex={defaultFlex} testID={tID('InfoSheet-body-container')}>
        <InfoSheetBody
          instructions={infoInstructions}
          content={content}
          links={links}
          imageURL={imageUrl}
          onView={onView}
          onLinkClicked={onLinkClicked}
        />
      </Flex1View>
      <ThemedStatusBar defaultStyle='light' />
    </Container>
  )
}
