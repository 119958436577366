import React, { Component } from 'react'
import CSSModules from 'react-css-modules'
import { connect } from 'react-redux'

import { format, parseISO } from 'date-fns'
import numeral from 'numeral'
import { bindActionCreators } from 'redux'

import {
  BaseInput,
  BaseModal,
  BootstrapContainer,
  ContentLayout,
  dateUtils,
  FilterButton,
  LoadingIndicator,
  NotificationBanner,
  SecondaryButton,
  TextButton,
} from '@lyrahealth-inc/ui-core'

import * as paymentsDashboardActions from './data/paymentsDashboardActions'
import styles from './paymentsDashboard.module.scss'
import DownloadCSVButton from '../../common/components/downloadCSV/DownloadCSVButton'
import ExcludePaymentForm from '../../common/components/forms/excludePaymentModal/ExcludePaymentForm'
import RejectPaymentForm from '../../common/components/forms/rejectPaymentModal/RejectPaymentForm'
import ReversePaymentForm from '../../common/components/forms/reversePaymentModal/ReversePaymentForm'
import PaymentCard from '../../common/components/paymentCard/PaymentCard'
import PaymentDetailsModal from '../../common/components/paymentDetailsModal/PaymentDetailsModal'
import {
  CHECKBOX_GROUP_ELIGIBILITY_STATUS_FILTER,
  CHECKBOX_GROUP_INVOICE_TYPE_FILTER,
  CHECKBOX_GROUP_PAYMENT_STATUS_FILTER,
  CHECKBOX_GROUP_SESSION_TYPE_FILTER,
  INVOICE_NUMBER_PER_PAGE,
  paymentExclusionReasons,
  paymentRejectionReasons,
  paymentReversalReasons,
  ROLES,
} from '../../common/constants/appConstants'
import { GET_PAYMENTS_OF_TYPE } from '../../common/constants/reduxConstants'
import { hasErrorForAction, hasRole } from '../../common/utils/utils'
import { getAlertsState } from '../../data/alertsSelectors'
import { getAuthRoles } from '../../data/auth/authSelectors'
import { getPaymentsBalances, getPaymentsData } from '../../data/payments/paymentsSelectors'
import { RootState } from '../../data/store'
import * as paymentsDataActions from '../data/paymentsDataActions'

type PaymentsDashboardProps = {
  actions?: any
  paymentsData?: any // TODO: PropTypes.instanceOf(Map)
  balances?: any // TODO: PropTypes.instanceOf(Map)
  alerts?: any // TODO: PropTypes.instanceOf(List)
  roles?: any // TODO: PropTypes.instanceOf(List)
}

type PaymentsDashboardState = any

class PaymentsDashboard extends Component<PaymentsDashboardProps, PaymentsDashboardState> {
  constructor(props: PaymentsDashboardProps) {
    super(props)

    this.state = {
      invoiceTypeFilter: '',
      sessionTypeFilter: '',
      paymentStatusTypeFilter: 'submitted', // default to submitted when first loading page
      eligibilityStatusTypeFilter: '',
      clientLyraIdQuery: '',
      providerLyraIdQuery: '',
      loadingMore: false,
      reversePayment: false,
      rejectPayment: false,
      excludePayment: false,
      viewPayment: false,
      modalData: {},
      noMoreForType: {
        all: false,
      },
    }
  }

  componentDidMount() {
    if (!this.props.paymentsData?.all) {
      this.props.actions.getPayments({ status: 'submitted', page: '0', limit: INVOICE_NUMBER_PER_PAGE })
    }
    this.props.actions.getPaymentsBalances()
  }

  componentWillUnmount() {
    if (this.props.paymentsData) {
      const updatedPayments = (this.props.paymentsData.all?.data ?? []).filter((payment: any) => {
        return payment.hasUpdatedTo
      })

      if (updatedPayments.length > 0) {
        this.props.actions.clearUpdatedCharges(updatedPayments)
      }
    }
  }

  onCSVDownloadClick = ($$values: any) => {
    const startDate = dateUtils.getDisplayDate($$values.get('start_date'), 'YYYY-MM-DD')
    const endDate = dateUtils.getDisplayDate($$values.get('end_date'), 'YYYY-MM-DD')
    return this.props.actions.downloadPayments($$values.get('payment_status'), startDate, endDate)
  }

  renderClearUpdatedButton = () => {
    const updatedPayments = (this.props.paymentsData.all?.data ?? []).filter((payment: any) => {
      return payment.hasUpdatedTo
    })

    if (updatedPayments.length === 0) return
    return (
      <SecondaryButton
        styleName='approve-reject-btn'
        onClick={() => {
          this.props.actions.clearUpdatedCharges(updatedPayments)
        }}
      >
        Clear approved/rejected
      </SecondaryButton>
    )
  }

  onViewClick = (data: any) => {
    this.setState({
      reversePayment: false,
      rejectPayment: false,
      excludePayment: false,
      viewPayment: true,
      modalData: data,
    })
  }

  onReverseClick = (chargeData: any) => {
    this.setState({
      reversePayment: true,
      rejectPayment: false,
      excludePayment: false,
      viewPayment: false,
      modalData: chargeData,
    })
  }

  onRejectClick = (chargeData: any) => {
    this.setState({
      rejectPayment: true,
      reversePayment: false,
      excludePayment: false,
      viewPayment: false,
      modalData: chargeData,
    })
  }

  onExcludeClick = (chargeData: any) => {
    this.setState({
      rejectPayment: false,
      reversePayment: false,
      excludePayment: true,
      viewPayment: false,
      modalData: chargeData,
    })
  }

  clearFilters = () => {
    this.setState({
      invoiceTypeFilter: '',
      sessionTypeFilter: '',
      paymentStatusTypeFilter: '',
      eligibilityStatusTypeFilter: '',
      clientLyraIdQuery: '',
      providerLyraIdQuery: '',
    })
  }

  saveFilter = (filterType: any, filterValue: any) => {
    this.setState({
      [`${filterType}`]: filterValue,
    })
  }

  getFilteredPayments = () => {
    const {
      invoiceTypeFilter,
      sessionTypeFilter,
      paymentStatusTypeFilter,
      eligibilityStatusTypeFilter,
      clientLyraIdQuery,
      providerLyraIdQuery,
    } = this.state
    const isHealthPlan = invoiceTypeFilter ? invoiceTypeFilter === 'HP' : undefined
    this.props.actions
      .getPayments({
        status: paymentStatusTypeFilter,
        page: '0',
        limit: INVOICE_NUMBER_PER_PAGE,
        providerId: providerLyraIdQuery,
        isHealthPlan,
        patientId: clientLyraIdQuery,
        sessionType: sessionTypeFilter,
        eligibilityCode: eligibilityStatusTypeFilter,
      })
      .then((successObj: any) => {
        const noMores = this.state.noMoreForType
        noMores.all = successObj.data.length < INVOICE_NUMBER_PER_PAGE

        this.setState({
          noMoreForType: noMores,
        })
      })
  }

  // If value is empty or valid v4 uuid return true
  isValidLyraId = (value: any) =>
    !value || /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(value)

  renderPaymentsList = (type: any) => {
    if (!this.props.paymentsData || !this.props.paymentsData[type]) {
      if (hasErrorForAction(this.props.alerts, GET_PAYMENTS_OF_TYPE)) {
        return false // custom workflow or display could render here
      } else {
        this.props.actions.getPayments({ status: 'submitted', page: '0', limit: INVOICE_NUMBER_PER_PAGE })
        return (
          <div className={styles['loading-container']}>
            <LoadingIndicator size={45} />
          </div>
        )
      }
    }

    const paymentsList = this.props.paymentsData[type]?.data
    const errorBanner = this.props.paymentsData[type]?.eligibilityError ? (
      <NotificationBanner
        // @ts-expect-error TS(2769): No overload matches this call.
        level='danger'
        className={styles['error-banner']}
        notifications={['Eligibility Service Error']}
      />
    ) : (
      []
    )

    if (paymentsList.length === 0) {
      return <div className={styles['loading-container']}>No payments found.</div>
    }

    return (
      <div>
        {errorBanner}
        {paymentsList.map((payment: any, i: any) => {
          const status = payment.status
          const key = `$$paymentsData_${status}_${i}`

          const conflictingDates: any = []
          const showConflicts = ['submitted', 'pending', 'approved'].includes(status)
          if (
            showConflicts &&
            payment.is_too_frequent_with_charge_ids !== null &&
            payment.is_too_frequent_with_charge_ids.length !== 0 &&
            Array.isArray(payment.is_too_frequent_with_charge_ids)
          ) {
            payment.is_too_frequent_with_charge_ids.map((charge: any) =>
              conflictingDates.push(format(parseISO(charge.date_of_service), 'MM/dd/yyyy')),
            )
          }

          return (
            // @ts-expect-error TS(2741): FIXME! Property 'type' is missing...
            <PaymentCard
              readonly={hasRole(this.props.roles, ROLES.VIEW_ONLY)}
              data={payment}
              conflicts={conflictingDates}
              key={key}
              viewClick={this.onViewClick}
              reverseClick={this.onReverseClick}
              rejectClick={this.onRejectClick}
              excludeClick={this.onExcludeClick}
            />
          )
        })}
      </div>
    )
  }

  loadMore = () => {
    const currentPage = this.props.paymentsData?.all?.page
    const nextPage = parseInt(currentPage, 10) + 1

    this.setState({
      loadingMore: true,
    })

    const {
      invoiceTypeFilter,
      sessionTypeFilter,
      paymentStatusTypeFilter,
      eligibilityStatusTypeFilter,
      clientLyraIdQuery,
      providerLyraIdQuery,
    } = this.state
    const isHealthPlan = invoiceTypeFilter ? invoiceTypeFilter === 'HP' : undefined

    this.props.actions
      .getPayments({
        status: paymentStatusTypeFilter,
        page: nextPage,
        limit: INVOICE_NUMBER_PER_PAGE,
        providerId: providerLyraIdQuery,
        isHealthPlan,
        patientId: clientLyraIdQuery,
        sessionType: sessionTypeFilter,
        eligibilityCode: eligibilityStatusTypeFilter,
      })
      .then(
        (successObj: any) => {
          const noMores = this.state.noMoreForType
          noMores.all = successObj.data.length < INVOICE_NUMBER_PER_PAGE

          this.setState({
            loadingMore: false,
            noMoreForType: noMores,
          })
        },
        () => {
          this.setState({
            loadingMore: false,
          })
        },
      )
  }

  submitReversePayment = (values: any) => {
    const {
      actions: { updateChargeStatus },
    } = this.props
    const { modalData } = this.state
    let reversalDescription = values.get('description')
    if (reversalDescription === 'other') {
      reversalDescription = values.get('description_text_area')
    } else {
      reversalDescription = paymentReversalReasons[reversalDescription]
    }
    return updateChargeStatus(
      modalData.chargeId,
      modalData.payload,
      modalData.is_too_frequent,
      reversalDescription,
    ).then(() => this.setState({ reversePayment: false }))
  }

  submitRejectPayment = (values: any) => {
    const {
      actions: { updateChargeStatus },
    } = this.props
    const { modalData } = this.state
    let rejectionDescription
    if (values.get('description') === 'other') {
      rejectionDescription = values.get('description_text_area')
    } else {
      rejectionDescription = paymentRejectionReasons[values.get('description')]
    }
    return updateChargeStatus(
      modalData.chargeId,
      modalData.payload,
      modalData.is_too_frequent,
      rejectionDescription,
    ).then(() => this.setState({ rejectPayment: false }))
  }

  submitExcludePayment = (values: any) => {
    const {
      actions: { updateChargeStatus },
    } = this.props
    const { modalData } = this.state
    let exclusionDescription
    if (values.get('description') === 'other') {
      exclusionDescription = values.get('description_text_area')
    } else {
      exclusionDescription = paymentExclusionReasons[values.get('description')]
    }
    return updateChargeStatus(
      modalData.chargeId,
      modalData.payload,
      modalData.is_too_frequent,
      exclusionDescription,
    ).then(() => this.setState({ excludePayment: false }))
  }

  render() {
    if (!this.props.paymentsData) {
      if (hasErrorForAction(this.props.alerts, GET_PAYMENTS_OF_TYPE)) {
        return false // custom workflow or display could render here
      } else {
        return (
          <ContentLayout>
            <BootstrapContainer>
              <div styleName='loading-container'>
                <LoadingIndicator size={45} />
              </div>
            </BootstrapContainer>
          </ContentLayout>
        )
      }
    }

    const { rejectPayment, reversePayment, excludePayment, viewPayment, modalData } = this.state

    // @ts-expect-error TS(2322): Type '{ type: string; viewData: any; }' is not ass... Remove this comment to see the full error message
    const ModalData = <PaymentDetailsModal type='provider' viewData={viewPayment ? modalData : {}} />

    // Populate initial values for RejectPaymentForm if there are any
    let initialRejectionValues
    const prevRejectionComment = rejectPayment && modalData ? modalData.admin_note : undefined
    if (prevRejectionComment) {
      if (Object.values(paymentRejectionReasons).includes(prevRejectionComment)) {
        const rejectionReason = Object.keys(paymentRejectionReasons).find(
          (key) => paymentRejectionReasons[key] === prevRejectionComment,
        )
        initialRejectionValues = {
          description: rejectionReason,
        }
      } else {
        initialRejectionValues = {
          description: 'other',
          description_text_area: prevRejectionComment,
        }
      }
    }

    let initialReversalValues
    const prevReversalComment = reversePayment && modalData ? modalData.admin_note : undefined
    if (prevReversalComment) {
      if (Object.values(paymentReversalReasons).includes(prevReversalComment)) {
        const reversalReason = Object.keys(paymentReversalReasons).find(
          (key) => paymentReversalReasons[key] === prevReversalComment,
        )
        initialReversalValues = {
          description: reversalReason,
        }
      } else {
        initialReversalValues = {
          description: 'other',
          description_text_area: prevReversalComment,
        }
      }
    }

    // Populate initial values for ExcludePaymentForm if there are any
    let initialExclusionValues
    const prevExclusionComment = excludePayment && modalData ? modalData.admin_note : undefined
    if (prevExclusionComment) {
      if (Object.values(paymentExclusionReasons).includes(prevExclusionComment)) {
        const exclusionReason = Object.keys(paymentExclusionReasons).find(
          (key) => paymentExclusionReasons[key] === prevExclusionComment,
        )
        initialExclusionValues = {
          description: exclusionReason,
        }
      } else {
        initialExclusionValues = {
          description: 'other',
          description_text_area: prevExclusionComment,
        }
      }
    }

    const ReversePaymentModal = (
      <ReversePaymentForm
        // @ts-expect-error TS(2322): Type '{ submitFunction: (values: any) => any; show... Remove this comment to see the full error message
        submitFunction={this.submitReversePayment}
        showReverseText
        initialValues={initialReversalValues}
      />
    )

    const RejectPaymentModal = (
      <RejectPaymentForm
        // @ts-expect-error TS(2322): Type '{ submitFunction: (values: any) => any; show... Remove this comment to see the full error message
        submitFunction={this.submitRejectPayment}
        showRejectText
        initialValues={initialRejectionValues}
      />
    )

    const ExcludePaymentModal = (
      <ExcludePaymentForm
        // @ts-expect-error TS(2322): Type '{ submitFunction: (values: any) => any; show... Remove this comment to see the full error message
        submitFunction={this.submitExcludePayment}
        showExcludeText
        initialValues={initialExclusionValues}
      />
    )

    let modalToShow
    if (reversePayment) {
      modalToShow = (
        <BaseModal
          isOpen={reversePayment}
          body={ReversePaymentModal}
          closeModal={() => this.setState({ reversePayment: false })}
        />
      )
    } else if (rejectPayment) {
      modalToShow = (
        <BaseModal
          isOpen={rejectPayment}
          body={RejectPaymentModal}
          closeModal={() => this.setState({ rejectPayment: false })}
        />
      )
    } else if (excludePayment) {
      modalToShow = (
        <BaseModal
          isOpen={excludePayment}
          body={ExcludePaymentModal}
          closeModal={() => this.setState({ excludePayment: false })}
        />
      )
    } else {
      modalToShow = (
        <BaseModal isOpen={viewPayment} body={ModalData} closeModal={() => this.setState({ viewPayment: false })} />
      )
    }

    return (
      <BootstrapContainer>
        <div styleName='top-container'>
          {this.props.balances ? (
            <div styleName='balances-left'>
              <div>
                <div styleName='description'>Available Balance</div>
                <h3 styleName='value'>{numeral(this.props.balances.available_balance / 100).format('$0,0[.]00')}</h3>
              </div>
              <div>
                <div styleName='description'>Pending Payments</div>
                <h3 styleName='value'>{numeral(this.props.balances.pending_balance / 100).format('$0,0[.]00')}</h3>
              </div>
            </div>
          ) : (
            <div styleName='balances-left'>&nbsp;</div>
          )}
          <div styleName='top-buttons-container'>
            {this.renderClearUpdatedButton()}
            {/* @ts-expect-error TS(2322): Type '($$values: any) => any' is not assignable to... Remove this comment to see the full error message */}
            <DownloadCSVButton downloadClick={this.onCSVDownloadClick} />
          </div>
        </div>
        <div styleName={'filter-buttons'}>
          <FilterButton
            filterName={'Invoice Type'}
            // @ts-expect-error TS(2322): Type '{ filterKey: string; data: { id: string; tex... Remove this comment to see the full error message
            filterOptions={CHECKBOX_GROUP_INVOICE_TYPE_FILTER}
            filterArray={[this.state.invoiceTypeFilter]}
            // @ts-expect-error TS(2322): Type '(array: any) => void' is not assignable to t... Remove this comment to see the full error message
            saveOnClick={(array: any) => this.saveFilter('invoiceTypeFilter', array[0])}
          />
          <FilterButton
            filterName={'Session Type'}
            // @ts-expect-error TS(2322): Type '{ filterKey: string; data: { id: string; tex... Remove this comment to see the full error message
            filterOptions={CHECKBOX_GROUP_SESSION_TYPE_FILTER}
            filterArray={[this.state.sessionTypeFilter]}
            // @ts-expect-error TS(2322): Type '(array: any) => void' is not assignable to t... Remove this comment to see the full error message
            saveOnClick={(array: any) => this.saveFilter('sessionTypeFilter', array[0])}
          />
          <FilterButton
            filterName={'Payment Status'}
            // @ts-expect-error TS(2322): Type '{ filterKey: string; data: { id: string; tex... Remove this comment to see the full error message
            filterOptions={CHECKBOX_GROUP_PAYMENT_STATUS_FILTER}
            filterArray={[this.state.paymentStatusTypeFilter]}
            // @ts-expect-error TS(2322): Type '(array: any) => void' is not assignable to t... Remove this comment to see the full error message
            saveOnClick={(array: any) => this.saveFilter('paymentStatusTypeFilter', array[0])}
          />
          <FilterButton
            filterName={'Eligibility Status'}
            // @ts-expect-error TS(2322): Type '{ filterKey: string; data: { id: string; tex... Remove this comment to see the full error message
            filterOptions={CHECKBOX_GROUP_ELIGIBILITY_STATUS_FILTER}
            filterArray={[this.state.eligibilityStatusTypeFilter]}
            // @ts-expect-error TS(2322): Type '(array: any) => void' is not assignable to t... Remove this comment to see the full error message
            saveOnClick={(array: any) => this.saveFilter('eligibilityStatusTypeFilter', array[0])}
          />
          <TextButton
            text='Clear filters'
            onClick={this.clearFilters}
            disabled={
              !this.state.invoiceTypeFilter &&
              !this.state.sessionTypeFilter &&
              !this.state.paymentStatusTypeFilter &&
              !this.state.eligibilityStatusTypeFilter &&
              !this.state.clientLyraIdQuery &&
              !this.state.providerLyraIdQuery
            }
            isSmall
          />
        </div>
        <div styleName={'lyra-id-query'}>
          {/* @ts-expect-error TS(2739): Type '{ children: Element; touched: true; style: {... Remove this comment to see the full error message */}
          <BaseInput touched style={{ marginRight: '15px' }}>
            <input
              type='text'
              value={this.state.clientLyraIdQuery}
              onChange={(e) => this.setState({ clientLyraIdQuery: e.target.value })}
              placeholder='Client Lyra ID Query'
              data-test-id='PaymentsDashboard-clientLyraIdQueryInput'
            />
          </BaseInput>
          {/* @ts-expect-error TS(2739): Type '{ children: Element; touched: true; }' is mi... Remove this comment to see the full error message */}
          <BaseInput touched>
            <input
              type='text'
              value={this.state.providerLyraIdQuery}
              onChange={(e) => this.setState({ providerLyraIdQuery: e.target.value })}
              placeholder='Provider Lyra ID Query'
              data-test-id='PaymentsDashboard-providerLyraIdQueryInput'
            />
          </BaseInput>
          <TextButton
            text='Query'
            disabled={
              !(this.isValidLyraId(this.state.clientLyraIdQuery) && this.isValidLyraId(this.state.providerLyraIdQuery))
            }
            onClick={this.getFilteredPayments}
            isSmall
            data-test-id='PaymentsDashboard-QueryButton'
          />
        </div>
        {this.renderPaymentsList('all')}
        {this.state.noMoreForType.all ? (
          <div styleName='end-of-list-spacer' />
        ) : (
          <div styleName='load-more'>
            {this.state.loadingMore ? (
              <LoadingIndicator size={30} />
            ) : this.props.paymentsData && this.props.paymentsData.all ? (
              <SecondaryButton onClick={this.loadMore}>Load More</SecondaryButton>
            ) : (
              []
            )}
          </div>
        )}
        {modalToShow}
      </BootstrapContainer>
    )
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    paymentsData: getPaymentsData(state),
    balances: getPaymentsBalances(state),
    alerts: getAlertsState(state),
    roles: getAuthRoles(state),
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators({ ...paymentsDashboardActions, ...paymentsDataActions }, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CSSModules(PaymentsDashboard, styles, { allowMultiple: true }))
