import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'

import styled from 'styled-components/native'

import { BodyText } from '../../atoms/bodyText/BodyText'
import { Avatar, avatarAltTextGeneric, AvatarDetails } from '../../atoms/icons/Avatar'
import { Subhead } from '../../atoms/subhead/Subhead'
import { BodyTextSize, SubheadSize } from '../../styles/typeStyles'
import { ThemeType, tID } from '../../utils'

const Container = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  alignItems: 'center',
  marginBottom: theme.spacing['12px'],
}))

const IntroTextContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['8px'],
}))

const AvatarContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['16px'],
}))

const ProviderNameContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  alignItems: 'center',
  textAlign: 'center',
  marginTop: theme.spacing['4px'],
}))

export const CareOnboardingHeader: FunctionComponent<CareOnboardingHeaderProps> = ({
  activityTitle,
  avatarDetails,
  clientFirstName,
}) => {
  const { formatMessage } = useIntl()
  const { displayName } = (avatarDetails as { src: string; displayName: string } as AvatarDetails) ?? {
    displayName: '',
  }
  return (
    <Container testID={tID('CareOnboardingHeader-container')}>
      <Subhead
        size={SubheadSize.MEDIUM}
        textAlign={'center'}
        text={formatMessage(
          {
            defaultMessage: 'Looking forward to our first session, {clientFirstName}.',
            description: 'Title on care onboarding form to welcome client',
          },
          {
            clientFirstName: clientFirstName,
          },
        )}
        testID={tID('CareOnboardingHeader-title')}
      />
      <IntroTextContainer>
        <BodyText
          text={formatMessage(
            {
              defaultMessage: 'Complete your {activityTitle} form so we make the most of our time together.',
              description: 'Content on care onboarding form ask client to complete form',
            },
            {
              activityTitle: activityTitle?.toLowerCase(),
            },
          )}
          textAlign={'center'}
          testID={tID('CareOnboardingHeader-introTextContainer')}
        />
      </IntroTextContainer>

      {avatarDetails && (
        <>
          <AvatarContainer testID={tID('CareOnboardingHeader-avatarContainer')}>
            <Avatar
              details={avatarDetails as { src: string; displayName: string } as AvatarDetails}
              accessibilityLabel={formatMessage(avatarAltTextGeneric, { name: displayName })}
              size={72}
            />
          </AvatarContainer>
          <ProviderNameContainer>
            <BodyText size={BodyTextSize.SMALL} text={displayName} testID={tID('CareOnboardingHeader-providerName')} />
          </ProviderNameContainer>
        </>
      )}
    </Container>
  )
}

type CareOnboardingHeaderProps = {
  activityTitle?: string
  avatarDetails?: AvatarDetails
  clientFirstName?: string
}
