import React from 'react'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components/native'

import { NavBar, ThemeType, tID } from '@lyrahealth-inc/ui-core-crossplatform'

import { actions, mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { SCHEDULING } from '../../common/constants/routingConstants'

const FixedContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  position: 'sticky',
  top: '0px',
  left: '0px',
  right: '0px',
  zIndex: 100,
  background: theme.colors.backgroundSecondary,
}))

export const SchedulingWalkthroughNavBar: React.FC = () => {
  const navigate = useNavigate()

  return (
    <>
      <FixedContainer testID={tID('SchedulingWalkthroughNavBar')}>
        <NavBar
          onBack={() => {
            track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.SCHEDULING_WALKTHROUGH_BACK })
            navigate(SCHEDULING.route)
          }}
          onExit={() => {
            track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.SCHEDULING_WALKTHROUGH_EXIT })
            navigate(SCHEDULING.route)
          }}
          isTransparent={true}
        />
      </FixedContainer>
    </>
  )
}
