import React, { FunctionComponent, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { cloneDeep, get, remove as lodashRemove, noop } from 'lodash-es'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import styled from 'styled-components/native'

import {
  addOrdinalSuffix,
  assignmentOnlyHasProviderDraft,
  Curriculum,
  EpisodeContents,
} from '@lyrahealth-inc/shared-app-logic'
import { Assignment, Content } from '@lyrahealth-inc/shared-app-logic/src'
import {
  PrimaryButton,
  SelectField,
  Subhead,
  SubheadSize,
  TertiaryButton,
  ThemeType,
  tID,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { CLIENT_HOME } from '../../common/constants/routingConstants'
import { addAlert } from '../../data/alertActions'
import { getAuthUser } from '../../data/auth/authSelectors'
import {
  getClientDetailsData,
  getClientSelectedEpisodeId,
  getSelectedEpisodeCurriculum,
} from '../../data/lyraTherapy/clientSelectors'
import { getLyraTherapyContentsData } from '../../data/lyraTherapy/contentSelectors'
import { RootState } from '../../data/store'
import { patchAssignment, setAssignment, unsetAssignment } from '../assignments/data/assignmentsAutoActions'
import { updateCurriculum } from '../episodes/data/episodesAutoActions'

type ActivityActionsModalProps = ConnectedProps<typeof connector> & {
  data?: {
    actionName: 'copy' | 'move' | 'unassign'
    assignment: Assignment | EpisodeContents
    sessionCount: number
  }
  actions?: {
    patchAssignment: any
    setAssignment: any
    unsetAssignment: any
    updateCurriculum: any
    addAlert: any
  }
  closeModal?: () => void
  currentSession?: number
}

const HeaderContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: spacing['32px'],
}))

const ButtonsContainer = styled.View({
  flexDirection: 'row',
  justifyContent: 'flex-end',
})

const PrimaryButtonContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  marginLeft: spacing['16px'],
}))

const ActivityActionsModal: FunctionComponent<ActivityActionsModalProps> = ({
  data,
  actions,
  closeModal = noop,
  contents,
  curriculum,
  currentSession,
  episodeId,
  clientDetails,
}) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const user = useSelector(getAuthUser)
  const [selectSessionValue, setSelectSessionValue] = useState<number>(
    (data && currentSession && data?.sessionCount < currentSession ? currentSession : data && data?.sessionCount + 1) ||
      0,
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const selectedIsAssignment = useMemo(
    () => data?.sessionCount === currentSession,
    [data?.sessionCount, currentSession],
  )

  const addToAssignments = () => {
    const assignmentName = data?.assignment.name || (data?.assignment as Assignment).content.name
    const assignmentTitle = data?.assignment.title || (data?.assignment as Assignment).content?.title
    const assignmentInstructions =
      data?.assignment.instructions || (data?.assignment as Assignment).content?.instructions
    const selectedContent = contents.find((content: Content) => content.name === assignmentName)
    const metadata = get(data?.assignment, 'content_meta_data') || selectedContent?.meta_data
    if (!metadata) {
      closeModal()
      return actions.addAlert({
        show: true,
        contents: `Unable to move assignment. No metadata exists for ${assignmentTitle}`,
        style: 'danger',
        expires: true,
      })
    }
    actions
      .setAssignment({
        provider_id: user?.id,
        patient_id: clientDetails?.id,
        content_id: selectedContent?.id,
        instructions: assignmentInstructions,
        content_meta_data: metadata,
        session_period: currentSession,
      })
      .then(() => closeModal())
  }

  const addRemoveCurriculum = (add: boolean, remove: boolean, shouldPreserveAssignmentId?: boolean) => {
    const selectedCurriculum = curriculum.findIndex(
      (sessionObject: Curriculum) => sessionObject.session === data?.sessionCount,
    )
    const targetCurriculum = curriculum.findIndex(
      (sessionObject: Curriculum) => sessionObject.session === Number(selectSessionValue),
    )
    const newCurriculum = cloneDeep(curriculum)
    if (remove) {
      // If this is a future session and an Assignment entity, remove it from the Curriculum
      if (data?.assignment?.id && currentSession && data?.sessionCount > currentSession) {
        newCurriculum[selectedCurriculum].contents = newCurriculum[selectedCurriculum]?.contents?.filter(
          (content: EpisodeContents) => content.id != data?.assignment?.id,
        )
      } else {
        lodashRemove(newCurriculum[selectedCurriculum]?.contents, data?.assignment as any)
      }
    }

    let sessionObject
    /* if the clicked item is a past or current session, 'data.assignment' is an actual Assignment object,
     * so it needs to be converted to a curriculum object */
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    if (data.sessionCount <= currentSession) {
      const group = get(data?.assignment, 'content.group')

      sessionObject = {
        content_type: get(data?.assignment, 'content.content_type'),
        group,
        name: get(data?.assignment, 'content.name'),
        title: get(data?.assignment, 'content.title'),
        instructions: get(data?.assignment, 'instructions'),
        content_meta_data: get(data?.assignment, 'content_meta_data'),
        id:
          shouldPreserveAssignmentId &&
          group === 'exercise' &&
          assignmentOnlyHasProviderDraft(data?.assignment as Assignment)
            ? get(data?.assignment, 'id')
            : null,
        content_id: get(data?.assignment, 'content_id'),
      }
    } else {
      sessionObject = data?.assignment
    }
    if (add) newCurriculum[targetCurriculum]?.contents.push(sessionObject as any)
    return actions.updateCurriculum({ id: episodeId, curriculum: newCurriculum }).then(() => closeModal())
  }

  const onUnassignClick = () => {
    setIsLoading(true)
    // If the Activity is an Assignment entity, we want to unset the assignment
    if (selectedIsAssignment || (data?.assignment as Assignment).id) {
      actions.unsetAssignment({ id: data?.assignment.id }).then(() => {
        closeModal()
        navigate(CLIENT_HOME.route)
      })

      // If the Activity is from a future session, we also want to remove it from the Curriculum
      if (currentSession && data && data.sessionCount > currentSession) {
        addRemoveCurriculum(false, true)
      }
    } else {
      addRemoveCurriculum(false, true)
    }
  }

  const onCopyClick = () => {
    setIsLoading(true)
    if (selectedIsAssignment) {
      addRemoveCurriculum(true, false)
    } else {
      if (selectSessionValue === currentSession) {
        addToAssignments()
      } else {
        addRemoveCurriculum(true, false)
      }
    }
  }

  const onMoveClick = () => {
    setIsLoading(true)

    if (selectedIsAssignment) {
      const group = (data?.assignment as Assignment).content?.group
      // Move the assignment from the current Session to a future Session
      const shouldPreserveAssignmentId = true
      addRemoveCurriculum(true, false, shouldPreserveAssignmentId)

      if (group === 'exercise' && assignmentOnlyHasProviderDraft(data?.assignment as Assignment)) {
        // Update the session_period for the Assignment being moved if it's an Exercise with responses
        actions
          .patchAssignment(data?.assignment.id, { session_period: selectSessionValue })
          .then(() => navigate(CLIENT_HOME.route))
      } else {
        actions.unsetAssignment({ id: data?.assignment.id }).then(() => navigate(CLIENT_HOME.route))
      }
    } else {
      if (selectSessionValue === currentSession) {
        // Move the assignment from a future session to the current Session
        addToAssignments()
        addRemoveCurriculum(false, true)
      } else {
        // Move the assignment from a future Session to a different future Session
        addRemoveCurriculum(true, true)
      }
    }
  }

  const onActionClick = () => {
    if (data?.actionName === 'unassign') {
      onUnassignClick()
    } else if (data?.actionName === 'move') {
      onMoveClick()
    } else if (data?.actionName === 'copy') {
      onCopyClick()
    }
  }

  const getTranslatedActionText = () => {
    if (data?.actionName === 'unassign') {
      return <FormattedMessage defaultMessage='Unassign' description='Button to remove assignment from this session' />
    } else if (data?.actionName === 'move') {
      return <FormattedMessage defaultMessage='Move' description='Button to move assignment from this session' />
    } else if (data?.actionName === 'copy') {
      return <FormattedMessage defaultMessage='Copy' description='Button to copy assignment from this session' />
    } else return ''
  }

  const getTranslatedHeaderText = () => {
    const assignmentTitle = data?.assignment.title || (data?.assignment as Assignment).content?.title
    const countWithOrdinalSuffix = addOrdinalSuffix(data?.sessionCount || 0)
    const clientFirstName = clientDetails?.first_name

    if (data?.actionName === 'unassign') {
      return formatMessage(
        {
          defaultMessage:
            "Are you sure you want to unassign {assignmentTitle} from {clientFirstName}'s {countWithOrdinalSuffix} session?",
          description:
            "Title asking if the activity should be unassigned from this session, using the client's first name and session number",
        },
        { assignmentTitle, clientFirstName, countWithOrdinalSuffix },
      )
    } else if (data?.actionName === 'move') {
      return formatMessage(
        {
          defaultMessage: 'Move {assignmentTitle} to a new session',
          description: 'Title asking if the activity should be moved to the new session',
        },
        { assignmentTitle },
      )
    } else if (data?.actionName === 'copy') {
      return formatMessage(
        {
          defaultMessage: 'Copy {assignmentTitle} to a new session',
          description: 'Title asking if the activity should be copied to the new session',
        },
        { assignmentTitle },
      )
    } else return ''
  }

  if (!data) return <></>
  return (
    <>
      <HeaderContainer>
        <Subhead size={SubheadSize.MEDIUM} text={getTranslatedHeaderText()} />
      </HeaderContainer>
      {(data.actionName === 'copy' || data.actionName === 'move') && (
        <SelectField
          value={selectSessionValue}
          onChange={(e: number) => setSelectSessionValue(e)}
          options={curriculum.reduce(
            (
              result: Array<{
                label: string
                value: string | number
              }>,
              curr: Curriculum,
            ) => {
              if (data.sessionCount !== curr.session) {
                result.push({
                  label: `Session ${curr.session}`,
                  value: curr.session,
                })
              }
              return result
            },
            [],
          )}
          disablePlaceholder
        />
      )}
      <ButtonsContainer>
        <TertiaryButton
          onPress={closeModal}
          text={<FormattedMessage defaultMessage='Cancel' description='Cancel button to go back to session page' />}
        ></TertiaryButton>
        <PrimaryButtonContainer>
          <PrimaryButton
            onPress={onActionClick}
            testID={
              data.actionName === 'unassign'
                ? tID('ActivityActionsModal-unassign')
                : tID('ActivityActionsModal-copy-move')
            }
            loading={isLoading}
            text={getTranslatedActionText()}
          ></PrimaryButton>
        </PrimaryButtonContainer>
      </ButtonsContainer>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    clientDetails: getClientDetailsData(state),
    curriculum: getSelectedEpisodeCurriculum(state) || [],
    contents: getLyraTherapyContentsData(state),
    episodeId: getClientSelectedEpisodeId(state) || '',
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    actions: bindActionCreators(
      { patchAssignment, setAssignment, unsetAssignment, updateCurriculum, addAlert },
      dispatch,
    ),
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(ActivityActionsModal)
