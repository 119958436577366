import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from 'react'
import { NativeSyntheticEvent, TextInputChangeEventData } from 'react-native'

import { cloneDeep, get, has, mapValues, merge, set } from 'lodash-es'

import { Contentmetadata } from '@lyrahealth-inc/shared-app-logic'
import { InputField, PrimaryButton, SecondaryButton, tID } from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './formSchemaBuilder.module.scss'

type FormSchemaBuilderProps = {
  constraints: any
  metadata: Contentmetadata
  name: string
  title: string
}

const FormSchemaBuilder: ForwardRefRenderFunction<FormSchemaBuilderRef, FormSchemaBuilderProps> = (
  { constraints, metadata, name, title },
  ref,
) => {
  const getCustomFields = () => {
    let customFields = {}
    const fieldsSchema = metadata?.schema?.properties || {}
    const fieldsUiSchema = metadata?.uiSchema || {}
    Object.entries(fieldsSchema).forEach(([fieldName, schema]) => {
      if ((schema as any).custom) {
        customFields = set(customFields, fieldName, { schema, uiSchema: fieldsUiSchema[fieldName] })
      }
    })
    return customFields
  }
  const [addingCustomField, setAddingCustomField] = useState<boolean>(false)
  const [customFields, setCustomFields] = useState(getCustomFields())
  const [customFieldTitle, setCustomFieldTitle] = useState<string>('')

  useImperativeHandle(ref, () => ({
    configure() {
      return configure()
    },
  }))

  const configure = () => {
    let metadataClone = cloneDeep(metadata)

    // Get the existing fieldsMetaData
    let fieldsMetaData = get(metadataClone, ['schema', 'properties']) || {}

    // Get initial custom field names from the initial customFields
    const initialCustomFields = getCustomFields()
    const initialCustomFieldNames = Object.keys(initialCustomFields)

    // Get current custom field names from the updated customFields
    const currentCustomFieldNames = Object.keys(customFields)

    // Calculate custom fields to remove (those that were in initialCustomFields but are no longer in customFields)
    const customFieldsToRemove = initialCustomFieldNames.filter(
      (fieldName) => !currentCustomFieldNames.includes(fieldName),
    )

    // Remove the custom fields that have been deleted from fieldsMetaData
    customFieldsToRemove.forEach((fieldName) => {
      delete fieldsMetaData[fieldName]
    })

    // Merge in the current customFields into fieldsMetaData (add or update custom fields)
    fieldsMetaData = merge(
      fieldsMetaData,
      mapValues(customFields, (customField: any) => get(customField, 'schema')),
    )

    // Update the metadata with the new fieldsMetaData
    metadata = set(metadataClone, ['schema', 'properties'], fieldsMetaData)

    // Make sure every field has a default value. Integers should be 0.
    metadataClone = set(
      metadataClone,
      'initialValues',
      mapValues(fieldsMetaData, (fieldMeta: any) => (get(fieldMeta, 'type') === 'integer' ? 0 : undefined)),
    )
    // We want to be absolutely sure we're maintaining the original metadata by going through these series of gets and sets.
    // Maintain the original UI widgets as well as append any customFields.
    // Else if there is no predefined metadata uiSchema, we will default to an empty map
    // in order to have the ability to use mergeDeep with custom fields.
    metadataClone = set(
      metadataClone,
      'uiSchema',
      merge(
        get(metadata, 'uiSchema', {}),
        mapValues(customFields, (customField: any) => get(customField, 'uiSchema')),
      ),
    )

    // Now we ensure we display the original fields as well as the custom fields
    metadataClone = set(
      metadataClone,
      'report.fieldsToDisplay',
      merge(get(metadataClone, 'report.fieldsToDisplay', []), Object.keys(customFields)),
    )

    return metadataClone
  }

  // TODO: Break out this method into it's own component requirements for
  // additional custom field types are added
  // The input just sets the label of the custom field and does not need to be attached to the parent form
  // The field creation is handled by createSliderField
  const renderSliderFieldConfig = () => {
    return (
      <div className={styles['add-custom-field-row']}>
        <div style={{ marginTop: '10px', flexGrow: 2, marginRight: '10px' }}>
          <InputField
            name={`goal${Object.keys(customFields).length}`}
            onChange={(e: NativeSyntheticEvent<TextInputChangeEventData>) => setCustomFieldTitle(e.nativeEvent.text)}
          />
        </div>
        <PrimaryButton
          onPress={createSliderField}
          disabled={!customFieldTitle}
          testID={tID('FormSchemaBuilder-save-field')}
          text='Save'
          style={{ marginTop: 10 }}
        />
      </div>
    )
  }

  const getFieldName = () => {
    const contentName = name
    let customName
    let i = 0
    while (!customName) {
      customName = has(customFields, `${contentName}Custom${i}`) ? null : `${contentName}Custom${i}`
      i++
    }
    return customName
  }

  const createSliderField = () => {
    const customField = {}
    const fieldName = getFieldName()
    const { minValue, maxValue, minLabel, maxLabel, step } = constraints.sliderField
    ;(customField as any).schema = {
      custom: true,
      maximum: maxValue,
      minimum: minValue,
      step: step,
      minLabel,
      maxLabel,
      name: fieldName,
      title: customFieldTitle,
      type: 'integer',
    }
    ;(customField as any).uiSchema = {
      'ui:widget': 'range',
    }

    const newCustomFields = set(customFields, fieldName, customField)

    setCustomFields(newCustomFields)
    setAddingCustomField(false)
    setCustomFieldTitle('')
  }

  const removeField = (field: any) => {
    const fieldName = get(field, 'schema.name')

    const newCustomFields = { ...customFields }

    // Remove the custom field from component state
    delete newCustomFields[fieldName]

    setCustomFields(newCustomFields)
  }

  return (
    <div className={styles.content}>
      <h2>{`Configure ${title}:`}</h2>
      <ol>
        {Object.values(customFields).map((field: any, index: number) => {
          return (
            <li key={field.schema.name}>
              {`${index + 1}. ${field.schema.title}`}
              <SecondaryButton
                isSmall
                onPress={() => removeField(field)}
                style={{ marginLeft: 20 }}
                testID={tID(`FormSchemaBuilder-delete-${field.schema.name}`)}
                text='Delete'
              />
            </li>
          )
        })}
      </ol>
      {addingCustomField ? renderSliderFieldConfig() : ''}
      <PrimaryButton
        disabled={addingCustomField}
        onPress={() => setAddingCustomField(true)}
        style={{ marginTop: 20 }}
        testID={tID('FormSchemaBuilder-add-field')}
        text='+ Add'
      />
    </div>
  )
}

export type FormSchemaBuilderRef = {
  configure: () => void
}

export default forwardRef(FormSchemaBuilder)
