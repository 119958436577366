import React from 'react'
import CSSModules from 'react-css-modules'
import { connect } from 'react-redux'

import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form/immutable'

import { BaseModal, CardMedium, dateUtils } from '@lyrahealth-inc/ui-core'

import * as registerPracticeActions from './data/registerPracticeActions'
import { getRegisterPracticeShowModal } from './data/registerPracticeSelectors'
import styles from './registerPractice.module.scss'
import PracticeInfoForm from '../../common/components/forms/practiceInfo/PracticeInfoForm'
import practiceInfoValidate from '../../common/components/forms/practiceInfo/practiceInfoValidate'
import { bannerMessages } from '../../common/constants/appConstants'
import TermsOfUse from '../../common/content/TermsOfUse'
import { getErrorText } from '../../common/utils/utils'
import * as alertActions from '../../data/alertActions'
import { getAuthPracticeId, getAuthPracticeName, getAuthUserEmail } from '../../data/auth/authSelectors'
import * as authActions from '../../data/authActions'
import { RootState } from '../../data/store'
import withRouter from '../../routing/withRouter'

type RegisterPracticeProps = {
  handleSubmit?: (...args: any[]) => any
  submitting?: boolean
  showModal?: boolean
  actions?: any
  router?: any
}

class RegisterPractice extends React.Component<RegisterPracticeProps> {
  _toggleTOUModal = (show: any) => {
    this.props.actions.toggleRegisterPracticeModal(show)
  }

  _submitForm = (values: any) => {
    this.props.actions.hideAlerts()

    const formData = new FormData()
    const vals = values.toJS()
    Object.keys(vals).forEach((key) => {
      let val = vals[key]
      if (key === 'date_of_birth') {
        val = dateUtils.getDisplayDate(val, 'YYYY-MM-DD')
      }
      if (['identification_file_front', 'identification_file_back'].includes(key)) {
        val = val[0]
      }
      formData.append(key, val)
    })

    return this.props.actions.submitRegisterPractice(formData).then(
      () => {
        // request successful
        this.props.actions.registerSuccessful()
        this.props.router.navigate('/')
        this.props.actions.addAlert({
          show: true,
          contents: bannerMessages.REGISTER_PRACTICE_SUCCESS,
          style: 'success',
          expires: true,
          autoDismissTimer: 10000,
        })
      },
      (error: any) => {
        const errorText = error.response.data.message || getErrorText(error.response.status)
        this.props.actions.addAlert({
          show: true,
          contents: errorText,
          style: 'danger',
          expires: true,
        })
      },
    )
  }

  render() {
    const { handleSubmit, submitting, showModal } = this.props

    return (
      <CardMedium>
        <h2 styleName='title'>Register Practice</h2>

        <PracticeInfoForm
          handleSubmit={handleSubmit}
          submitFunction={this._submitForm}
          submitting={submitting}
          termsOfUseHandler={() => this._toggleTOUModal(true)}
          primaryButtonText='Register'
        />

        <BaseModal
          // @ts-expect-error TS(2322): Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
          isOpen={showModal}
          body={<TermsOfUse />}
          closeModal={() => this._toggleTOUModal(false)}
          size='large'
        />
      </CardMedium>
    )
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    showModal: getRegisterPracticeShowModal(state),
    initialValues: {
      id: getAuthPracticeId(state),
      name: getAuthPracticeName(state),
      email: getAuthUserEmail(state),
      payment_preference: '2',
    },
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators({ ...registerPracticeActions, ...alertActions, ...authActions }, dispatch),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    reduxForm({
      form: 'PracticeInfoForm',
      validate: practiceInfoValidate,
      touchOnBlur: false,
      // @ts-expect-error TS(2345): Argument of type 'typeof RegisterPractice' is not ... Remove this comment to see the full error message
    })(CSSModules(RegisterPractice, styles)),
  ),
)
