import React, { FunctionComponent, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { format, utcToZonedTime } from 'date-fns-tz'
import { fromJS, Map } from 'immutable'
import { isEmpty } from 'lodash-es'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import {
  Appointment,
  AvailabilitiesResponse,
  ClientObject,
  DAILY_END_TIME,
  DAILY_START_TIME,
  getCountryDisplayName,
  NINETY_DAYS_TO_MS,
  ProviderProgramTaxonomy,
  SLICE_DURATION_MINUTES,
} from '@lyrahealth-inc/shared-app-logic'
import { appointmentUtils, BootstrapContainer, GlobeIcon, LoadingIndicator, NavButton } from '@lyrahealth-inc/ui-core'
import {
  colors,
  ExpandedCalendarBooking,
  Headline,
  HeadlineSize,
  toJS,
  useFetcher,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { APPOINTMENT_OPERATIONS } from '../../common/constants/appConstants'
import {
  CLIENTS_NEW_SESSION,
  CLIENTS_NEW_SESSION_CONFIRMATION,
  CLIENTS_RESCHEDULE_SESSION,
  CLIENTS_RESCHEDULE_SESSION_CONFIRMATION,
} from '../../common/constants/routingConstants'
import { getAuthRoles, getAuthUserId } from '../../data/auth/authSelectors'
import {
  getClientAppointmentDetails,
  getClientAppointmentsAvailabilityV2,
  getClientAppointmentsData,
  getClientDetailsData,
  getClientSelectedEpisode,
} from '../../data/lyraTherapy/clientSelectors'
import { getLyraTherapyContentsPrograms } from '../../data/lyraTherapy/contentSelectors'
import { RootState } from '../../data/store'
import { getProviderModalities } from '../../providers/data/providerSelectors'
import { getProviderAvailabilityV2, setAppointment } from '../clients/clientDetails/data/appointmentsAutoActions'

const NavButtonContainer = styled.div({
  marginBottom: '24px',
})

const InnerBootstrapContainer = styled.div({
  marginTop: '50px',
  marginBottom: '50px',
})

const HeadlineContainer = styled.div({
  marginBottom: '24px',
})

const InternationalBanner = styled.div({
  backgroundColor: colors.primary_teal1,
  padding: '16px 0 16px 16px',
  marginBottom: '16px',
  display: 'flex',
  alignItems: 'center',

  svg: {
    marginRight: '8px',
  },
})

const SessionSelectionContainer: FunctionComponent<SessionSelectionContainerProps> = ({
  clientDetails,
  programTaxonomy,
  operation,
  availabilities,
  appointments,
  appointment,
  providerModalities,
  actions: { getProviderAvailabilityV2, setAppointment },
}) => {
  const providerId: string | undefined = useSelector(getAuthUserId)
  const intl = useIntl()
  const navigate = useNavigate()

  const timeZone = Intl.DateTimeFormat()?.resolvedOptions()?.timeZone ?? 'America/Los_Angeles'

  const handleSelectedAppointment = (selectedAppointmentTime: string): any => {
    const appointmentMap = fromJS(appointment)
    const appointmentsList = fromJS(appointments)

    const date = utcToZonedTime(selectedAppointmentTime, timeZone)
    const time = format(date, 'HH:mm:ss', { timeZone })
    const dateStr = format(date, 'yyyy-MM-dd', { timeZone })

    const selectedAppointmentFormatted = {
      startDate: dateStr,
      startTime: time,
      timeZone,
    }

    switch (operation) {
      case APPOINTMENT_OPERATIONS.NEW:
        return appointmentUtils.createAppointmentPayload({
          appointment: appointmentMap.merge(selectedAppointmentFormatted),
          providerID: providerId as unknown as null,
          providerModalities: providerModalities as unknown as null,
          userID: clientDetails?.id,
          referenceAppointment: appointmentsList.get(-1, Map()),
        })
      case APPOINTMENT_OPERATIONS.RESCHEDULE:
        return appointmentMap.merge(selectedAppointmentFormatted).toJS()
    }
  }

  const [isLoading] = useFetcher([
    getProviderAvailabilityV2,
    {
      providerId: providerId,
      params: {
        slice_duration_minutes: SLICE_DURATION_MINUTES,
        start_datetime: new Date().toISOString(),
        end_datetime: new Date(Date.now() + NINETY_DAYS_TO_MS).toISOString(),
        daily_start_time: DAILY_START_TIME,
        daily_end_time: DAILY_END_TIME,
        clientele: programTaxonomy?.clientele ?? '',
        treatment: programTaxonomy?.treatment ?? '',
        partner: programTaxonomy?.partner ?? '',
        offering: programTaxonomy?.offering ?? '',
        appointment_class: appointments.some((a) => a.appointmentStatus === 'completed') ? 'recurring' : 'initial',
        lead_time_hours: 0,
        appointment_duration_minutes: appointment?.appointmentDuration,
      },
    },
  ])

  const goBack = () => {
    switch (operation) {
      case APPOINTMENT_OPERATIONS.NEW:
        navigate(CLIENTS_NEW_SESSION.route)
        break
      case APPOINTMENT_OPERATIONS.RESCHEDULE:
        navigate(CLIENTS_RESCHEDULE_SESSION.route)
        break
    }
  }

  const headlineText = useMemo(() => {
    switch (operation) {
      case APPOINTMENT_OPERATIONS.NEW:
        return 'Create new'
      case APPOINTMENT_OPERATIONS.RESCHEDULE:
        return 'Reschedule'
    }
  }, [operation])

  const onTimeSelection = (dateTime: string) => {
    const appointment = handleSelectedAppointment(dateTime)
    if (appointment) {
      setAppointment({ appointment: appointment })
      switch (operation) {
        case APPOINTMENT_OPERATIONS.NEW:
          navigate(CLIENTS_NEW_SESSION_CONFIRMATION.route)
          break
        case APPOINTMENT_OPERATIONS.RESCHEDULE:
          navigate(CLIENTS_RESCHEDULE_SESSION_CONFIRMATION.route)
          break
      }
    }
  }
  const banner =
    !isEmpty(clientDetails?.country) && clientDetails?.country !== 'US' ? (
      <InternationalBanner data-test-id='SessionSelectionContainer-international-banner'>
        <GlobeIcon />{' '}
        {`Note that the session time is in your timezone, but that the client is located in ${getCountryDisplayName(
          clientDetails?.country ?? '',
        )}, ${clientDetails?.time_zone}.`}
      </InternationalBanner>
    ) : null
  return (
    <BootstrapContainer col='col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1'>
      <InnerBootstrapContainer>
        <NavButtonContainer>
          <NavButton text='Define session details' styleType='back' onClick={goBack} />
        </NavButtonContainer>
        <HeadlineContainer>
          <Headline
            text={`${headlineText} session with ${clientDetails?.full_name}`}
            size={HeadlineSize.SMALL}
            color={colors.charcoal6}
          />
        </HeadlineContainer>
        {isLoading && availabilities == null && (
          <div styleName='loading-container'>
            <LoadingIndicator size={45} />
          </div>
        )}
        {availabilities && (
          <ExpandedCalendarBooking
            timeZone={timeZone}
            availabilities={availabilities}
            onSelected={onTimeSelection}
            intl={intl}
            responsive={true}
            banner={banner}
            sliceDurationMinutes={SLICE_DURATION_MINUTES}
          />
        )}
      </InnerBootstrapContainer>
    </BootstrapContainer>
  )
}

export type SessionSelectionContainerProps = ConnectedProps<typeof connector> & {
  operation: APPOINTMENT_OPERATIONS
}

type StateProps = {
  clientDetails: ClientObject | null
  programTaxonomy: ProviderProgramTaxonomy | undefined
  availabilities: AvailabilitiesResponse | undefined
  appointments: Array<Appointment>
  appointment: Appointment | undefined
  providerModalities: Array<string>
}

const mapStateToProps = (state: RootState): StateProps => {
  const programs = getLyraTherapyContentsPrograms(state)
  const selectedEpisode = getClientSelectedEpisode(state)
  const roles: string[] = getAuthRoles(state) ?? []
  const programForEpisode = programs.find((p) => p.id === selectedEpisode?.program_id)

  const allAppointments = getClientAppointmentsData(state)
  const appointments = allAppointments.filter((apt) => apt.episodeId === selectedEpisode?.id)

  return {
    clientDetails: getClientDetailsData(state),
    programTaxonomy: programForEpisode?.program_taxonomy.find((p) => p.role != null && roles.includes(p.role)),
    availabilities: getClientAppointmentsAvailabilityV2(state),
    providerModalities: getProviderModalities(state),
    appointments,
    appointment: getClientAppointmentDetails(state),
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      getProviderAvailabilityV2,
      setAppointment,
    },
    dispatch,
  ),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(toJS(SessionSelectionContainer))
