import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../../data/store'

export const getClientsState = (state: RootState) => state?.clients

export const getClientsData = createSelector(getClientsState, (clientsState) => clientsState.data)

export const getClientsDataClientList = createSelector(getClientsData, (data) => data.clientList)

export const getClientsDataSessionData = createSelector(getClientsData, (data) => data.sessionData)
