import React, { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'

import * as _ from 'lodash-es'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'

import { ClientObject, CustomerInfo, ImmutableTypedMap, useFlags } from '@lyrahealth-inc/shared-app-logic'
import { AwaitingResultsIcon, BootstrapContainer, DataTable, LoadingIndicator } from '@lyrahealth-inc/ui-core'
import { toJS } from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './clientsDashboard.module.scss'
import { getDashboardTableColumns } from './DashboardTableCols'
import AvailabilityCard from '../../common/components/availabilityCard/AvailabilityCard'
import { ROLES } from '../../common/constants/appConstants'
import { GET_CLIENTS } from '../../common/constants/reduxConstants'
import { CLIENTS_DETAILS, OUTCOMES_CONSENT } from '../../common/constants/routingConstants'
import { hasErrorForAction, hasRole } from '../../common/utils/utils'
import { getAlertsState } from '../../data/alertsSelectors'
import { Alert } from '../../data/alertTypes'
import { getAuthRoles, getAuthUserId, getAuthUserOutcomesAgreed } from '../../data/auth/authSelectors'
import { getRequestPaymentCustomers } from '../../data/requestPayment/requestPaymentSelectors'
import { RootState } from '../../data/store'
import { getProviderCapacity } from '../../providers/data/providersDataActions'
import { getProvider } from '../../providers/individualProvider/data/providerDetailsActions'
import { getClients } from '../data/clientsDataActions'
import { getClientsDataClientList } from '../data/clientsDataSelectors'
import { clientSelected } from '../individualClient/data/clientDetailsAutoActions'

interface DashboardClientObject extends ClientObject {
  display_visits_per_issue_per_year?: number | undefined
}

export const ClientsDashboard: React.FC<ClientsDashboardProps> = ({
  actions: { getClients, getProviderCapacity, clientSelected, getProvider },
  customers,
}) => {
  const { isProgramLevelSessionLimitEnabled } = useFlags()
  const outcomesAgreed = useSelector(getAuthUserOutcomesAgreed)
  const userId = useSelector(getAuthUserId)
  const userRoles: string[] = useSelector(getAuthRoles)
  const alerts: Alert[] = useSelector(getAlertsState)
  const clients = useSelector(getClientsDataClientList) ?? []
  const navigate = useNavigate()
  useEffect(() => {
    if (outcomesAgreed) {
      getClients()
    }
    if (userId) {
      getProvider(userId)
      getProviderCapacity({ id: userId })
    }
  }, [outcomesAgreed, userId, getClients, getProviderCapacity, getProvider])

  const renderClientList = () => {
    // create the list of clients to be shown in the DataTable
    let filteredClient = clients
    //add display_visits_per_issue_per_year for each client if filteredClient array exists
    if (Array.isArray(filteredClient)) {
      filteredClient = filteredClient.map((item: DashboardClientObject) => {
        return {
          ...item,
          display_visits_per_issue_per_year: customers.find((customer) => customer.value === item.employer)
            ?.display_visits_per_issue_per_year,
        }
      })
    }

    if (_.isEmpty(filteredClient)) {
      return (
        <div className={styles['awaiting-results-container']}>
          <AwaitingResultsIcon />
          <h2>Awaiting Client Results</h2>
          <p>
            Once client outcomes are submitted,
            <br />
            you can see the results right here.
          </p>
          <a
            href='https://provider-support.lyrahealth.com/hc/en-us/articles/115012608808'
            target='_blank'
            rel='noreferrer'
          >
            Learn more
          </a>
        </div>
      )
    }
    return (
      <div className={styles['clients-dashboard-container']}>
        <DataTable
          data={clients as unknown as Dict[]}
          className={styles['clients-table']}
          striped={false}
          highlight={false}
          resizable={false}
          defaultSorted={[
            {
              id: 'date_submitted',
              desc: true,
            },
          ]}
          destinationSelector='id'
          // @ts-expect-error TS(2322): Type '(state: RootState, rowInfo: any) => void' is not a... Remove this comment to see the full error message
          rowClickHandler={rowClickHandler}
          // @ts-expect-error TS(2322): Type '({ Header: string; accessor: string; Cell: (... Remove this comment to see the full error message
          columns={getDashboardTableColumns(isProgramLevelSessionLimitEnabled)}
        />
      </div>
    )
  }

  const rowClickHandler = (state: RootState, rowInfo: any) => {
    clientSelected(rowInfo.original)
    navigate(CLIENTS_DETAILS.route)
  }

  if (hasRole(userRoles, ROLES.ICAS_PROVIDER)) {
    return (
      <BootstrapContainer col='col-md-10 col-md-offset-1' style={{ overflow: 'visible' }}>
        {/* @ts-expect-error TS(2741): Property 'isMinified' is missing in type '{}' but ... Remove this comment to see the full error message */}
        <AvailabilityCard />
      </BootstrapContainer>
    )
  }
  if (!outcomesAgreed) {
    return <Navigate to={OUTCOMES_CONSENT.route} />
  }
  if (!clients && !hasErrorForAction(alerts, GET_CLIENTS)) {
    return (
      <div style={{ marginTop: '50px', textAlign: 'center' }}>
        <LoadingIndicator color={styles.x_success} size={45} />
      </div>
    )
  }
  return (
    <BootstrapContainer col='col-md-10 col-md-offset-1' style={{ overflow: 'visible' }}>
      {/* @ts-expect-error TS(2741): Property 'isMinified' is missing in type '{}' but ... Remove this comment to see the full error message */}
      <AvailabilityCard />
      <div className={styles['title-block']}>
        <h1 data-test-id='ClientsDashboard-client-list'>Clients</h1>
        <p>
          Your client list displays clients with Outcomes <b>or</b> billing activity within the last 60 days. You can
          resend Outcomes to eligible clients* within their record below. Clients without activity in the last 60 days
          will reappear if there is new activity. <br></br>
          <br></br>
          <i>
            *Outcomes are automatically sent to all adult clients on a monthly basis (minors and clients participating
            in family therapy are not eligible to receive Outcomes). For more information on our Automated Outcomes
            process, please visit our{' '}
            <a
              href='https://provider-support.lyrahealth.com/hc/en-us/articles/115012608808-Automated-Outcomes-Collection-Process-and-FAQ'
              target='_blank'
              rel='noreferrer'
            >
              Help Center
            </a>
            .
          </i>
        </p>
      </div>
      {renderClientList()}
    </BootstrapContainer>
  )
}

type ClientsDashboardProps = {
  actions: {
    getClients: () => void
    getProvider: (id: string) => void
    getProviderCapacity: ({ id }: { id: string }) => void
    clientSelected: (info: any) => void
  }
  customers: CustomerInfo[]
}

const mapStateToProps = (state: RootState) => {
  const formState = (state.form as unknown as ImmutableTypedMap<typeof state.form>).toJS() as typeof state.form
  return {
    clientFilter: formState.filterTableForm?.values?.isActive,
    customers: getRequestPaymentCustomers(state) || [],
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): any => {
  return {
    actions: bindActionCreators({ clientSelected, getClients, getProviderCapacity, getProvider }, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(toJS(ClientsDashboard))
