import React from 'react'
import { connect, useSelector } from 'react-redux'
import { useNavigate, useOutletContext } from 'react-router'

import { addMinutes } from 'date-fns'
import { format } from 'date-fns-tz'
import { get, isEmpty, pick, reduce } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import {
  Appointment,
  AppointmentSummary,
  Episode,
  getDateFromAppointment,
  getProgramNameFromId,
  NoteStatusStates,
  noteSummaryStatusText,
  NoteTypes,
  useFlags,
} from '@lyrahealth-inc/shared-app-logic'
import {
  LoadingIndicator,
  NotesList,
  PrimaryButton,
  Subhead,
  SubheadSize,
  ThemeType,
  tID,
  useFetcher,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { getAppointmentSummaries, getNotes } from './data/clientNotesAutoActions'
import { prepopulationFieldsMap } from './data/NotesPrepopulationMap'
import { actions, mixpanelEvents, sessionNoteTypes } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { programConfig, programNameTitleMap } from '../../common/constants/appConstants'
import { CLIENT_NEW_NOTE, CLIENT_NOTE } from '../../common/constants/routingConstants'
import { getAuthUserId } from '../../data/auth/authSelectors'
import {
  getClientAppointmentsData,
  getClientAssignmentsData,
  getClientDataId,
  getClientEpisodeProgramConfig,
  getClientEpisodesData,
  getClientNotesAppointmentSummaries,
  getClientSelectedEpisode,
} from '../../data/lyraTherapy/clientSelectors'
import { getLyraTherapyContentsData } from '../../data/lyraTherapy/contentSelectors'

const notesProgramMap = {
  MedicationManagement: {
    INITIAL_NOTE: 'bcmInitialNote',
    SESSION_NOTE: 'bcmSessionNote',
    GENERAL_NOTE: 'bcmGeneralNote',
    REFERRAL_NOTE: 'baseReferralNote',
    OLD_INITIAL_NOTE: 'medsInitialNote',
    OLD_SESSION_NOTE: 'medsSessionNote',
    OLD_GENERAL_NOTE: 'medsGeneralNote',
    OLD_REFERRAL_NOTE: 'medsReferralNote',
  },
  BlendedCareTherapy: {
    INITIAL_NOTE: 'bctInitialNote',
    SESSION_NOTE: 'bctSessionNote',
    GENERAL_NOTE: 'bctGeneralNote',
    REFERRAL_NOTE: 'baseReferralNote',
    OLD_INITIAL_NOTE: 'initialNote',
    OLD_SESSION_NOTE: 'sessionNote',
    OLD_GENERAL_NOTE: 'generalNote',
    OLD_REFERRAL_NOTE: 'referralNote',
  },
  Coaching: {
    INITIAL_NOTE: 'bccInitialNote',
    SESSION_NOTE: 'bccSessionNote',
    GENERAL_NOTE: 'baseGeneralNote',
    REFERRAL_NOTE: 'baseReferralNote',
    OLD_INITIAL_NOTE: 'coachingInitialNote',
    OLD_SESSION_NOTE: 'coachingSessionNote',
    OLD_GENERAL_NOTE: 'coachingGeneralNote',
    OLD_REFERRAL_NOTE: 'coachingReferralNote',
  },
  SingleSessionCoaching: {
    INITIAL_NOTE: 'sscInitialNote',
    SESSION_NOTE: 'sscSessionNote',
    GENERAL_NOTE: 'baseGeneralNote',
    REFERRAL_NOTE: 'baseReferralNote',
    OLD_INITIAL_NOTE: 'singleSessionCoachingInitialNote',
    OLD_SESSION_NOTE: 'singleSessionCoachingSessionNote',
    OLD_GENERAL_NOTE: 'singleSessionCoachingGeneralNote',
    OLD_REFERRAL_NOTE: 'singleSessionCoachingReferralNote',
  },
  AlcoholUseDisorderTherapy: {
    INITIAL_NOTE: 'audInitialNote',
    SESSION_NOTE: 'audSessionNote',
    GENERAL_NOTE: 'audGeneralNote',
    REFERRAL_NOTE: 'baseReferralNote',
  },
  ClinicalLeaveEvaluation: {
    INITIAL_NOTE: 'cleInitialNote',
    SESSION_NOTE: 'cleSessionNote',
    GENERAL_NOTE: 'cleGeneralNote',
    REFERRAL_NOTE: 'baseReferralNote',
  },
  TeensTherapy: {
    INITIAL_NOTE: 'lcttInitialNote',
    SESSION_NOTE: 'lcttSessionNote',
    GENERAL_NOTE: 'lcttGeneralNote',
    REFERRAL_NOTE: 'baseReferralNote',
  },
}

type ClientNotesProps = {
  getNotes: ({ episode_id }: { episode_id: string }) => void
  getAppointmentSummaries: ({
    providerId,
    patientId,
    statusOnly,
  }: {
    providerId: string
    patientId: string
    statusOnly: boolean
  }) => void
}

const NotesSectionContainer = styled.View<{ theme: ThemeType }>(({ theme: { breakpoints, spacing } }) => ({
  margin: 'auto',
  marginTop: spacing['24px'],
  marginBottom: spacing['48px'],
  width: breakpoints.isMinWidthTablet ? '750px' : '100%',
}))

const SubheaderContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  marginBottom: spacing['24px'],
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

const NotesSubhead = styled(Subhead)<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  marginTop: spacing['8px'],
}))

const ClientNotes: React.FC<ClientNotesProps> = ({ getAppointmentSummaries, getNotes }) => {
  const { enableAINotesFeatures, showAINotesFeatures } = useFlags()

  const appointmentSummaries: AppointmentSummary[] = useSelector(getClientNotesAppointmentSummaries) ?? []
  const assignments = useSelector(getClientAssignmentsData)
  const clientId = useSelector(getClientDataId)
  const contents = useSelector(getLyraTherapyContentsData)
  const currentEpisode: Episode | undefined = useSelector(getClientSelectedEpisode)
  const episodes: Episode[] = useSelector(getClientEpisodesData)
  const clientEpisodeProgramConfig = useSelector(getClientEpisodeProgramConfig)
  const sessions = useSelector(getClientAppointmentsData).filter((session: Appointment) =>
    ['rescheduled', 'newAppt', 'concluded', 'completed'].includes(session.appointmentStatus),
  )
  const userId = useSelector(getAuthUserId)

  const { episode } = useOutletContext<{ episode: Episode | null }>()
  const [loading, notes = [], fetched] = useFetcher(
    [getNotes, { episode_id: episode?.id }],
    [userId, clientId, episode?.id],
  )
  useFetcher(
    [
      getAppointmentSummaries,
      { providerId: userId, patientId: clientId, episodeId: episode?.id, statusOnly: true },
      !!enableAINotesFeatures,
    ],
    [userId, clientId, episode?.id],
  )
  const navigate = useNavigate()
  const { colors } = useTheme()

  if (loading || !fetched) return <LoadingIndicator size={45} />
  const goToNewNote = (note: any) => {
    if ([NoteTypes.INITIAL, NoteTypes.SESSION].includes(note.note_type)) {
      track({
        event: mixpanelEvents.BUTTON_PRESS,
        action: actions.CLIENT_NOTES_TAB_OPEN_NOTE,
        details: {
          ...(note.aiSummaryStatus && { summaryStatus: noteSummaryStatusText[note.aiSummaryStatus] }),
          noteType: sessionNoteTypes[note.note_type],
          ...(note.appointment_id && { appointmentId: note.appointment_id?.toString() }),
        },
      })
    }
    navigate(`${CLIENT_NEW_NOTE.route}`, { state: { note } })
  }
  const goToNote = (note: any) => {
    track({
      event: mixpanelEvents.BUTTON_PRESS,
      action: actions.CLIENT_NOTES_TAB_OPEN_NOTE,
      details: {
        ...(note.aiSummaryStatus && { summaryStatus: noteSummaryStatusText[note.aiSummaryStatus] }),
        noteType: sessionNoteTypes[note.note_type],
        ...(note.appointment_id && { appointmentId: note.appointment_id?.toString() }),
      },
    })
    navigate(`${CLIENT_NOTE.route}?id=${note.id}`, { state: { note } })
  }

  const episodeSessions = sessions.filter((s: any) => s.episodeId === episode?.id)

  const populateInitialValues = (formResponses = [], name: any) => {
    const prefillableField = (originWidget: any, destinationWidget: any) => {
      if (originWidget === destinationWidget || destinationWidget === 'text' || destinationWidget === 'textarea') {
        return true
      } else {
        return false
      }
    }
    const formUiSchema = contents.find((n: any) => n.name === name)?.meta_data?.uiSchema || {}
    if (isEmpty(formUiSchema)) return
    let initialValues = {}
    formResponses.forEach(({ contentName, data, responseUiSchema = {} }) => {
      const fieldsMap = (prepopulationFieldsMap[name] || {})[contentName]
      if (!fieldsMap) {
        return
      }
      let valuesToPopulate = pick(data, Object.keys(fieldsMap))
      // Use the fieldsMap to swap the field keys to the form we are prepopulating
      valuesToPopulate = reduce(
        valuesToPopulate,
        function (res, val, key) {
          let keyValuePairs = {}
          // check to see if fieldsMap[key] is an array in case of a 1:many prepopulation map
          if (Array.isArray(fieldsMap[key]))
            keyValuePairs = fieldsMap[key].reduce((obj: any, targetFieldName: any) => {
              if (
                key in responseUiSchema &&
                targetFieldName in formUiSchema &&
                // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                prefillableField(responseUiSchema[key]['ui:widget'], formUiSchema[targetFieldName]['ui:widget'])
              ) {
                return { ...obj, [targetFieldName]: val }
              } else {
                return obj
              }
            }, {})
          else {
            if (
              key in responseUiSchema &&
              fieldsMap[key] in formUiSchema &&
              // @ts-expect-error TS(2532): Object is possibly 'undefined'.
              prefillableField(responseUiSchema[key]['ui:widget'], formUiSchema[fieldsMap[key]]['ui:widget'])
            ) {
              keyValuePairs = { [fieldsMap[key]]: val }
            }
          }
          return { ...res, ...keyValuePairs }
        },
        {},
      )
      initialValues = { ...initialValues, ...valuesToPopulate }
    })
    return initialValues
  }

  const getInitialValues = (name = '', sessionNumber: any) => {
    let formResponses: any = []

    const clientIntake = assignments.find((assignment: any) => {
      return (
        [
          clientEpisodeProgramConfig?.clientIntake,
          clientEpisodeProgramConfig?.oldClientIntake,
          clientEpisodeProgramConfig?.clientIntakeNonUS,
        ].includes(assignment.content.name) && assignment.episode_id === episode?.id
      )
    })
    if (clientIntake) {
      const clientIntakeResponse = get(clientIntake, 'assignment_responses.0.response')
      formResponses = [
        {
          contentName: clientIntake.content.name,
          data: clientIntakeResponse,
          responseUiSchema: clientIntake.content_meta_data.uiSchema,
        },
      ]
    }

    const previousApptId = (episodeSessions[sessionNumber - 1] || {}).appointmentId
    const previousNote = notes.find((note: any) => note.appointment_id === previousApptId)
    if (previousNote) {
      const previousNoteResponse = (previousNote as any).body || {}
      formResponses = [
        ...formResponses,
        {
          contentName: (previousNote as any).content_meta_data.name,
          data: previousNoteResponse,
          responseUiSchema: (previousNote as any).content_meta_data.meta_data.uiSchema,
        },
      ]
    }
    return populateInitialValues(formResponses, name)
  }

  if (!episode) {
    return null
  }

  const {
    INITIAL_NOTE,
    SESSION_NOTE,
    GENERAL_NOTE,
    REFERRAL_NOTE,
    OLD_INITIAL_NOTE,
    OLD_SESSION_NOTE,
    OLD_GENERAL_NOTE,
    OLD_REFERRAL_NOTE,
  } = notesProgramMap[episode.program_name]
  const canAddOrEditNotes = episodes.some((epi: any) => epi.provider_patient_id === episode.provider_patient_id)
  let sessionNotes
  if (clientEpisodeProgramConfig?.program === episode.program_name && canAddOrEditNotes) {
    sessionNotes = episodeSessions.map((session: any, i: any) => {
      const appointmentStart = getDateFromAppointment(session)
      const appointmentEnd = addMinutes(appointmentStart, session.appointmentDuration)
      const isFlankingMeridiem = format(appointmentStart, 'a') !== format(appointmentEnd, 'a')
      const appointmentTimeslot =
        format(appointmentStart, isFlankingMeridiem ? `EEE, MMM d h:mmaaaaa'm'` : 'EEE, MMM d h:mm') +
        ' - ' +
        format(appointmentEnd, `h:mmaaaaa'm' z`)
      const note = notes.find((note: any) => note.appointment_id === session.appointmentId) || {}
      const name = i === 0 ? INITIAL_NOTE : SESSION_NOTE
      const programName = getProgramNameFromId(episode?.program_id) ?? ''
      const aiSummaryStatus =
        programConfig[programName]?.aiNotesEnabled &&
        appointmentSummaries?.find(
          (summary: AppointmentSummary) => summary.appointment_id === session.appointmentId.toString(),
        )?.status
      let body = (note as any).body
      if (isEmpty(note)) {
        const initialValues = getInitialValues(name, i)
        if (!isEmpty(initialValues)) {
          body = initialValues
        } else {
          const oldName = i === 0 ? OLD_INITIAL_NOTE : OLD_SESSION_NOTE
          const oldInitialValues = getInitialValues(oldName, i)
          body = !isEmpty(oldInitialValues) ? oldInitialValues : undefined
        }
      }
      return {
        title: `Session ${i + 1} Note`,
        appointment_id: session.appointmentId,
        content_meta_data: contents.find(
          (n: any) => n.name === name || n.name === OLD_INITIAL_NOTE || n.name === OLD_SESSION_NOTE,
        ),
        note_type: i === 0 ? NoteTypes.INITIAL : NoteTypes.SESSION,
        sessionDate: session.startDate,
        sessionNumber: session.sessionNumber,
        appointmentTimeslot,
        authorName: get(note, 'body.authorName'),
        ...(showAINotesFeatures && { aiSummaryStatus }),
        ...note,
        body,
      }
    })
  } else {
    sessionNotes = notes
      .filter(
        (note: any) =>
          [NoteTypes.INITIAL, NoteTypes.SESSION].includes(note.note_type) && note.status === NoteStatusStates.COMPLETED,
      )
      .map((note: any) => ({
        title: note.note_type === NoteTypes.SESSION ? 'Session note' : 'Initial Note',
        ...note,
      }))
  }

  const generalNotes = notes
    .filter((note: any) => note.note_type === NoteTypes.GENERAL)
    .map((note: any) => ({
      ...note,
      title: note.body.subject,
    }))
  const generalNoteMetadata = contents.find((n: any) => n.name === GENERAL_NOTE || n.name === OLD_GENERAL_NOTE)

  const mostRecentSessionNote = notes
    ? notes
        .filter((note: any) => note.status === NoteStatusStates.COMPLETED)
        .sort((a: any, b: any) => (b.update_date > a.update_date ? 1 : -1))[0]
    : {}

  let sessionNoteResponses: any = []
  if (mostRecentSessionNote) {
    const mostRecentSessionNoteResponse = (mostRecentSessionNote as any).body || {}
    sessionNoteResponses = [
      {
        contentName: (mostRecentSessionNote as any).content_meta_data.name,
        data: mostRecentSessionNoteResponse,
        responseUiSchema: (mostRecentSessionNote as any).content_meta_data.meta_data.uiSchema,
      },
    ]
  }
  const generalNotePrepopulation = populateInitialValues(sessionNoteResponses, GENERAL_NOTE)

  const newGeneralNote = {
    title: generalNoteMetadata?.title,
    content_meta_data: generalNoteMetadata,
    note_type: NoteTypes.GENERAL,
    body: generalNotePrepopulation,
  }

  const referralNotes = notes
    .filter((note: any) => note.note_type === NoteTypes.REFERRAL)
    .map((note: any) => ({
      ...note,
      title: 'Care Referral Form',
    }))
  const referralNoteMetadata = contents.find((n: any) => n.name === REFERRAL_NOTE || n.name === OLD_REFERRAL_NOTE)
  const newReferralNote = {
    title: referralNoteMetadata?.title,
    content_meta_data: referralNoteMetadata,
    note_type: NoteTypes.REFERRAL,
  }
  return (
    <>
      <NotesSectionContainer testID={tID('ClientNotes-sessionNotesContainer')}>
        <SubheaderContainer>
          <NotesSubhead
            text={`${programNameTitleMap[episode.program_name]} Session Notes`}
            size={SubheadSize.LARGE}
            color={colors.textPrimary}
          />
        </SubheaderContainer>
        <NotesList
          notes={sessionNotes}
          onAddNoteClick={canAddOrEditNotes ? goToNewNote : null}
          onFinishNoteClick={canAddOrEditNotes ? goToNote : null}
          onViewNoteClick={goToNote}
        />
      </NotesSectionContainer>
      <NotesSectionContainer testID={tID('ClientNotes-generalNotesContainer')}>
        <SubheaderContainer>
          <NotesSubhead
            text={`${programNameTitleMap[episode.program_name]} General Notes`}
            size={SubheadSize.LARGE}
            color={colors.textPrimary}
          />
          {currentEpisode?.id === episode.id && (
            <PrimaryButton
              testID={tID('ClientNotes-AddGeneralNote')}
              onPress={() => goToNewNote(newGeneralNote)}
              text='Add General Note'
            />
          )}
        </SubheaderContainer>

        <NotesList
          notes={generalNotes}
          onViewNoteClick={goToNote}
          onFinishNoteClick={currentEpisode?.id === episode.id ? goToNote : null}
          showNoteTitle={true}
        />
      </NotesSectionContainer>
      <NotesSectionContainer testID={tID('ClientNotes-referralNotesContainer')}>
        <SubheaderContainer>
          <NotesSubhead text='Care Referral Form' size={SubheadSize.LARGE} color={colors.textPrimary} />
          {currentEpisode?.id === episode.id && (
            <PrimaryButton
              testID={tID('ClientNotes-AddReferralNote')}
              onPress={() => goToNewNote(newReferralNote)}
              text='Refer to Other Care'
            />
          )}
        </SubheaderContainer>
        <NotesList
          notes={referralNotes}
          onViewNoteClick={goToNote}
          onFinishNoteClick={currentEpisode?.id === episode.id ? goToNote : null}
          emptyListText='No form yet'
        />
      </NotesSectionContainer>
    </>
  )
}

export default connect(null, { getAppointmentSummaries, getNotes })(ClientNotes)
