import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../../data/store'

export const getCalendarState = (state: RootState) => state?.calendar

export const getCalendarRequiresAuthorization = createSelector(
  getCalendarState,
  (calendarState) => calendarState?.requiresAuthorization,
)

export const getCalendarAvailabilitySlots = createSelector(
  getCalendarState,
  (calendarState) => calendarState?.availabilitySlots,
)

export const getCalendarConfiguration = createSelector(
  getCalendarState,
  (calendarState) => calendarState?.calendars?.[0] ?? null,
)

export const getCalendarGoogleEvents = createSelector(getCalendarState, (calendarState) => calendarState?.googleEvents)

export const getCalendarProvider = createSelector(getCalendarState, (calendarState) => calendarState?.provider)

export const getCalendarShowCalendarLiveModal = createSelector(
  getCalendarState,
  (calendarState) => calendarState?.showCalendarLiveModal,
)
