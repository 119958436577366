import React from 'react'

import { ProgramCoverageBreakdown } from './BenefitsEligibility'
import styles from './clientProfile.module.scss'
import { healthPlanStates, programs } from '../../../../common/constants/appConstants'

const HealthPlanStatus: React.FC<HealthPlanStatusProps> = ({
  program,
  currentProgramCoverageBreakdown,
  status,
  hasEAPSessions,
}) => {
  const { READY, NOT_READY, NOT_ELIGIBLE, HEALTH_PLAN_PAYMENT_MISSING } = healthPlanStates
  const { considerMeds, stressManagement, considerLyraTherapy } = currentProgramCoverageBreakdown
  switch (program) {
    case programs.MedicationManagement:
      if (!considerMeds?.eapSupported) {
        switch (status) {
          case READY:
            return (
              <>
                Your client{' '}
                <span className={styles.success} data-test-id='HealthPlanStatus-meds-bhb-ready'>
                  is ready
                </span>{' '}
                to use their health plan for their sessions.
              </>
            )
          case NOT_ELIGIBLE:
            return (
              <>
                Your client’s health plan{' '}
                <span className={styles.attention} data-test-id='HealthPlanStatus-meds-bhb-not-eligible'>
                  does not cover Lyra
                </span>
                . Ask your client to contact the Care Navigator Team at{' '}
                <a href='mailto:care@lyrahealth.com'>care@lyrahealth.com</a>.
              </>
            )
          case HEALTH_PLAN_PAYMENT_MISSING:
            return (
              <>
                Your client has entered their health plan information but is missing their payment card for copayments.{' '}
                <span className={styles.warning}>
                  Please have your client update their payment card for their health plan section to complete their
                  profile.
                </span>{' '}
              </>
            )
          case NOT_READY:
            return (
              <>
                Your client
                <span className={styles.warning} data-test-id='HealthPlanStatus-meds-bhb-not-ready'>
                  needs to enter both their health plan and payment information in their profile
                </span>
                to determine coverage for their sessions.
              </>
            )
        }
      }
      break
    // falls through to the default cases if meds is supported through EAP
    case programs.Coaching:
    case programs.SingleSessionCoaching:
      if (
        stressManagement?.bhbSupported &&
        stressManagement?.eapSupported &&
        !stressManagement?.specialtyBhbSupported
      ) {
        switch (status) {
          case READY:
            return (
              <>
                If you and your client intend to continue care past the covered EAP sessions, your client{' '}
                <span className={styles.success} data-test-id='HealthPlanStatus-coaching-bhb-eap-ready'>
                  is ready to use their eligible health plan insurance to pay for sessions.
                </span>
              </>
            )
          case NOT_ELIGIBLE:
            return (
              <>
                Your client&apos;s <span className={styles.attention}>health insurance does not cover Lyra</span> and
                care should be discontinued{' '}
                <span className={styles.bold} data-test-id='HealthPlanStatus-coaching-bhb-eap-not-eligible'>
                  past the covered EAP sessions.
                </span>
              </>
            )
          case HEALTH_PLAN_PAYMENT_MISSING:
            return (
              <>
                Your client has entered their health plan information but is missing their payment card for copayments.{' '}
                <span className={styles.warning}>
                  Please have your client update their payment card for their health plan section to complete their
                  profile.
                </span>{' '}
              </>
            )
          case NOT_READY:
            return (
              <>
                If you and your client intend to continue care past the covered EAP sessions, your client{' '}
                <span className={styles.warning} data-test-id='HealthPlanStatus-coaching-bhb-eap-not-ready'>
                  needs to add their health insurance and payment information
                </span>{' '}
                to their profile.
              </>
            )
        }
      } else if (
        stressManagement?.bhbSupported &&
        !stressManagement?.eapSupported &&
        !stressManagement?.specialtyBhbSupported
      ) {
        switch (status) {
          case READY:
            return (
              <>
                Your client{' '}
                <span className={styles.success} data-test-id='HealthPlanStatus-coaching-bhb-ready'>
                  is ready to use their eligible health insurance to pay for sessions.
                </span>
              </>
            )
          case NOT_ELIGIBLE:
            return (
              <>
                Your client&apos;s{' '}
                <span className={styles.attention} data-test-id='HealthPlanStatus-coaching-bhb-not-eligible'>
                  health insurance does not cover Lyra
                </span>{' '}
                and care should be discontinued.
              </>
            )
          case HEALTH_PLAN_PAYMENT_MISSING:
            return (
              <>
                Your client has entered their health plan information but is missing their payment card for copayments.{' '}
                <span className={styles.warning}>
                  Please have your client update their payment card for their health plan section to complete their
                  profile.
                </span>{' '}
              </>
            )
          case NOT_READY:
            return (
              <>
                Your client{' '}
                <span className={styles.warning} data-test-id='HealthPlanStatus-coaching-bhb-not-ready'>
                  needs to add their health insurance and payment information
                </span>{' '}
                to their profile to continue care.
              </>
            )
        }
      } else {
        return <>Your client does not have coverage through an eligible health plan for this program.</>
      }
      break
    case programs.BlendedCareTherapy:
      if (
        considerLyraTherapy?.bhbSupported &&
        considerLyraTherapy?.eapSupported &&
        !considerLyraTherapy?.specialtyBhbSupported
      ) {
        switch (status) {
          case READY:
            return (
              <>
                If you and your client intend to continue care past the covered EAP sessions, your client{' '}
                <span className={styles.success} data-test-id='HealthPlanStatus-therapy-bhb-eap-ready'>
                  {' '}
                  is ready to use their eligible health plan insurance to pay for sessions.
                </span>
              </>
            )
          case NOT_ELIGIBLE:
            return (
              <>
                Your client&apos;s{' '}
                <span className={styles.attention} data-test-id='HealthPlanStatus-therapy-bhb-eap-not-eligible'>
                  health insurance does not cover Lyra
                </span>{' '}
                and care should be discontinued <span className={styles.bold}>past the covered EAP sessions.</span>
              </>
            )
          case HEALTH_PLAN_PAYMENT_MISSING:
            return (
              <>
                Your client has entered their health plan information but is missing their payment card for copayments.{' '}
                <span className={styles.warning}>
                  Please have your client update their payment card for their health plan section to complete their
                  profile.
                </span>{' '}
              </>
            )
          case NOT_READY:
            return (
              <>
                If you and your client intend to continue care past the covered EAP sessions, your client{' '}
                <span className={styles.warning} data-test-id='HealthPlanStatus-therapy-bhb-eap-not-ready'>
                  needs to add their health insurance and payment information
                </span>{' '}
                to their profile.
              </>
            )
        }
      } else if (
        !considerLyraTherapy?.bhbSupported &&
        considerLyraTherapy?.eapSupported &&
        considerLyraTherapy?.specialtyBhbSupported
      ) {
        switch (status) {
          case READY:
            return (
              <>
                If you and your client intend to continue care past the covered EAP sessions, your client must have
                active substance abuse, be at risk for hospitalization, or have treatment resistant depression. Your
                client{' '}
                <span className={styles.success} data-test-id='HealthPlanStatus-therapy-eap-specialty-ready'>
                  is ready to use their eligible health plan insurance to pay for sessions.
                </span>
              </>
            )
          case NOT_ELIGIBLE:
            return (
              <>
                Your client&apos;s{' '}
                <span className={styles.attention} data-test-id='HealthPlanStatus-therapy-eap-specialty-not-eligible'>
                  health insurance does not cover Lyra
                </span>{' '}
                and care should be discontinued <span className={styles.bold}>past the covered EAP sessions.</span>
              </>
            )
          case HEALTH_PLAN_PAYMENT_MISSING:
            return (
              <>
                Your client has entered their health plan information but is missing their payment card for copayments.{' '}
                <span className={styles.warning}>
                  Please have your client update their payment card for their health plan section to complete their
                  profile.
                </span>{' '}
              </>
            )
          case NOT_READY:
            return (
              <>
                If you and your client intend to continue care past the covered EAP sessions, your client must have
                active substance abuse, be at risk for hospitalization, or have treatment resistant depression. Your
                client{' '}
                <span className={styles.warning} data-test-id='HealthPlanStatus-therapy-eap-specialty-not-ready'>
                  needs to add their health insurance and payment information
                </span>{' '}
                to their profile.
              </>
            )
        }
      } else if (
        considerLyraTherapy?.bhbSupported &&
        !considerLyraTherapy?.eapSupported &&
        !considerLyraTherapy?.specialtyBhbSupported
      ) {
        switch (status) {
          case READY:
            return (
              <>
                Your client{' '}
                <span className={styles.success} data-test-id='HealthPlanStatus-therapy-bhb-ready'>
                  is ready to use their eligible health plan insurance to pay for sessions.
                </span>
              </>
            )
          case NOT_ELIGIBLE:
            return (
              <>
                Your client&apos;s{' '}
                <span className={styles.attention} data-test-id='HealthPlanStatus-therapy-bhb-not-eligible'>
                  health insurance does not cover Lyra
                </span>
                . Ask your client to contact the Care Navigator Team at{' '}
                <a href='mailto:care@lyrahealth.com'>care@lyrahealth.com</a>.
              </>
            )
          case HEALTH_PLAN_PAYMENT_MISSING:
            return (
              <>
                Your client has entered their health plan information but is missing their payment card for copayments.{' '}
                <span className={styles.warning}>
                  Please have your client update their payment card for their health plan section to complete their
                  profile.
                </span>{' '}
              </>
            )
          case NOT_READY:
            return (
              <>
                Your client{' '}
                <span className={styles.warning} data-test-id='HealthPlanStatus-therapy-bhb-not-ready'>
                  needs to add their health insurance and payment information
                </span>{' '}
                to their profile to start care.
              </>
            )
        }
      } else if (
        considerLyraTherapy?.bhbSupported &&
        !considerLyraTherapy?.eapSupported &&
        considerLyraTherapy?.specialtyBhbSupported
      ) {
        switch (status) {
          case READY:
            return (
              <>
                Your client{' '}
                <span className={styles.success} data-test-id='HealthPlanStatus-therapy-bhb-specialty-ready'>
                  is ready to use their eligible health insurance to pay for sessions.
                </span>{' '}
                If your client has active substance abuse, is at risk for hospitalization, or has treatment resistant
                depression, you may continue past the recommended 16 sessions.
              </>
            )
          case NOT_ELIGIBLE:
            return (
              <>
                Your client&apos;s{' '}
                <span className={styles.attention} data-test-id='HealthPlanStatus-therapy-bhb-specialty-not-eligible'>
                  health insurance does not cover Lyra
                </span>{' '}
                and care should be discontinued.
              </>
            )
          case HEALTH_PLAN_PAYMENT_MISSING:
            return (
              <>
                Your client has entered their health plan information but is missing their payment card for copayments.{' '}
                <span className={styles.warning}>
                  Please have your client update their payment card for their health plan section to complete their
                  profile.
                </span>{' '}
              </>
            )
          case NOT_READY:
            return (
              <>
                Your client{' '}
                <span className={styles.warning} data-test-id='HealthPlanStatus-therapy-bhb-specialty-not-ready'>
                  needs to add their health insurance and payment information
                </span>{' '}
                to their profile to start care. If your client has active substance abuse, is at risk for
                hospitalization, or has treatment resistant depression, you may continue past the recommended 16
                sessions.
              </>
            )
        }
      }
  }

  // default cases
  switch (status) {
    case READY:
      return (
        <>
          Your client{' '}
          <span className={styles.bold} data-test-id='HealthPlanStatus-default-ready'>
            can use Lyra
          </span>{' '}
          with their health plan
          {hasEAPSessions && <> past the covered EAP sessions</>}.
        </>
      )
    case NOT_READY:
      return (
        <>
          If you and your client intend to continue{hasEAPSessions && <> past the covered EAP sessions</>}, your client{' '}
          <span className={styles.attention} data-test-id='HealthPlanStatus-default-not-ready'>
            needs to enter their health plan information
          </span>{' '}
          to determine coverage.
        </>
      )
    case HEALTH_PLAN_PAYMENT_MISSING:
      return (
        <>
          If you and your client intend to continue{hasEAPSessions && <> past the covered EAP sessions</>}, your client{' '}
          <span className={styles.attention} data-test-id='HealthPlanStatus-default-not-ready'>
            needs to enter their health plan payment information
          </span>{' '}
          to determine coverage.
        </>
      )
    case NOT_ELIGIBLE:
    default:
      return (
        <>
          Your client’s health plan{' '}
          <span className={styles.bold} data-test-id='HealthPlanStatus-default-not-eligible'>
            does not cover Lyra
          </span>
          {hasEAPSessions && <> past the covered EAP sessions</>}.
        </>
      )
  }
}

type HealthPlanStatusProps = {
  program?: string
  currentProgramCoverageBreakdown: ProgramCoverageBreakdown
  status: string
  hasEAPSessions: boolean
}

export default HealthPlanStatus
