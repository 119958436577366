import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import classNames from 'classnames'
import { format, parseISO } from 'date-fns'
import { includes, isEqual, isInteger, isNumber } from 'lodash-es'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'

import {
  Assignment,
  Episode,
  Outcome,
  ProviderAdminProviderInfo,
  QuestionResponse,
  ResendOutcomeResponse,
} from '@lyrahealth-inc/shared-app-logic'
import {
  BaseModal,
  DropdownButton,
  LoadingIndicator,
  LyraPopover,
  OutcomeChart,
  outcomeConstants,
  OutcomeResponseDetails,
  outcomeUtils,
  SecondaryButton,
  TextButton,
  TextButtonAlign,
} from '@lyrahealth-inc/ui-core'
import { tID, toJS } from '@lyrahealth-inc/ui-core-crossplatform'

import * as assessmentPopovers from './AssessmentPopovers'
import styles from './clientDetails.module.scss'
import { track, trackClicks } from '../../../../mixpanel/mixpanelTracking'
import { programNameTitleMap, SUPERVISOR_ROLES } from '../../common/constants/appConstants'
import { hasRole } from '../../common/utils/utils'
import { addAlert } from '../../data/alertActions'
import { RootState } from '../../data/store'
import { getProviderDetailsData } from '../../providers/data/providerSelectors'
import { resendOutcomeEmail } from '../data/clientsDataActions'

const ClientOutcomes: React.FC<ClientOutcomesProps> = ({
  clientFirstName,
  clientFullName,
  consentStatus,
  consentSubmitted,
  isRegistered,
  learnMore,
  outcomes,
  patient_lyra_id,
  providerDetailsData,
  reminder,
  title,
  assignmentsWithSelfReflection,
  selectedProgressEpisode,
  // @ts-expect-error TS(2339): Property 'addAlert' does not exist on type '{ rese... Remove this comment to see the full error message
  actions: { addAlert, resendOutcomeEmail },
}) => {
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState<Response | null>(null)
  const [selectedOutcome, setSelectedOutcome] = useState(outcomes ? outcomes[0] : [])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    trackClicks('ClientOutcomes')
  }, [])

  useEffect(() => {
    setSelectedOutcome(outcomes ? outcomes[0] : [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProgressEpisode])

  if (!outcomes) return <></>

  const showAnswersModal = (modalData: Response) => {
    setShowModal(true)
    setModalData(modalData)
  }

  const onViewButtonClick = (assessmentType: string) => {
    const outcome = selectedOutcome
    const answers = (outcome as any).response_details
    const modalData = {
      clientName: clientFullName ?? '',
      title: undefined,
      dateSubmitted: format(parseISO((outcome as any).response_date), 'MM/dd/yyyy'),
      assessmentType: assessmentType,
      score: (outcome as any).scores[assessmentType],
      answers: answers.filter((a: QuestionResponse) => includes(a.groups, assessmentType)),
    }
    showAnswersModal(modalData)
  }

  const resendOutcomeClickHandler = () => {
    setLoading(true)
    resendOutcomeEmail(patient_lyra_id)
      .then(
        (resendOutcomeResponse: ResendOutcomeResponse) => {
          return addAlert({
            show: true,
            contents: resendOutcomeResponse.message,
            style: resendOutcomeResponse.status === 'error' ? 'danger' : 'success',
            expires: true,
          })
        },
        () => {},
      )
      .finally(() => {
        setLoading(false)
      })
  }

  const renderOutcomeResultsEntry = (assessmentName: string, assessmentTitle: string = assessmentName) => {
    const describeScore = outcomeUtils.convertOutcomeScoreToDescription
    const scores = (selectedOutcome as any)?.scores
    return (
      <div className={styles.row} data-test-id={`ClientOutcomes-results-${assessmentName}`}>
        <div>
          <h3>
            {assessmentTitle}{' '}
            <TextButton
              data-test-id={`ClientOutcomes-viewAnswers-${assessmentName.toLowerCase()}`}
              onClick={() => {
                onViewButtonClick(assessmentName)
              }}
              customClass={styles['view-answers-button']}
              align={TextButtonAlign.LEFT}
              text='view answers'
            />
          </h3>
        </div>
        <div>
          <h3 data-test-id={`client-${assessmentName}-score`}>{scores[assessmentName]}</h3>
        </div>
        <div>
          <h3 data-test-id={`client-${assessmentName}-score-description`}>
            {describeScore(assessmentName, scores[assessmentName])}
          </h3>
        </div>
      </div>
    )
  }

  const renderOutcomeResults = () => {
    const describeScore = outcomeUtils.convertOutcomeScoreToDescription
    const scores = (selectedOutcome as Outcome)?.scores
    let assignmentForSelectedOutcome

    const programName = (selectedOutcome as Outcome)?.program_name?.toLowerCase().includes('coaching')
      ? 'Coaching'
      : 'Therapy'
    const providerType = programName === 'Therapy' ? 'therapist' : 'coach'
    if (selectedOutcome && 'assignment_id' in selectedOutcome) {
      assignmentForSelectedOutcome = assignmentsWithSelfReflection?.find(
        (assignment) => assignment.id === selectedOutcome?.assignment_id,
      )
    }
    const selfReflectionText = assignmentForSelectedOutcome?.assignment_responses?.[0]?.response?.self_reflection
    // Hide Therapeutic Alliance scores about other Providers unless the Provider is a Supervisor.
    const shouldProviderViewAllianceScores =
      (selectedOutcome as Outcome)?.provider_lyra_id === providerDetailsData?.id ||
      hasRole(providerDetailsData?.roles, SUPERVISOR_ROLES)

    if (scores) {
      return (
        <div className={styles['client-details-table']} data-test-id='ClientOutcomes-resultsContainer'>
          <div className={classNames(styles.row, styles.header)}>
            <div>
              <h4>Assessment</h4>
            </div>
            <div>
              <h4>Score</h4>
            </div>
            <div>
              <h4>Description</h4>
            </div>
          </div>
          {isNumber(scores.CGSQ) && renderOutcomeResultsEntry('CGSQ', 'CGSQ')}
          {isInteger(scores['PARENTAL-STRESS']) && renderOutcomeResultsEntry('PARENTAL-STRESS', 'PARENTAL STRESS')}
          {isNumber(scores['PARENTING-TASK']) && renderOutcomeResultsEntry('PARENTING-TASK', 'PARENTING TASK')}
          {isInteger(scores['RCADS-ANXIETY']) && renderOutcomeResultsEntry('RCADS-ANXIETY', 'RCADS: Anxiety')}
          {isInteger(scores['RCADS-DEPRESSION']) && renderOutcomeResultsEntry('RCADS-DEPRESSION', 'RCADS: Depression')}
          {isInteger(scores['RCADS-TOTAL']) && renderOutcomeResultsEntry('RCADS-TOTAL', 'RCADS: Total')}
          {isInteger(scores.TFM) && renderOutcomeResultsEntry('TFM')}
          {isInteger(scores.BDD) && renderOutcomeResultsEntry('BDD')}
          {isInteger(scores.PACS) && renderOutcomeResultsEntry('PACS')}
          {isInteger(scores.WSAS) && renderOutcomeResultsEntry('WSAS')}
          {isInteger(scores.PCL5) && renderOutcomeResultsEntry('PCL5', 'PCL5')}
          {isInteger(scores.CHRT) && renderOutcomeResultsEntry('CHRT')}
          {isInteger(scores.GAD) && renderOutcomeResultsEntry('GAD', 'GAD7')}
          {isInteger(scores.PHQ) ? (
            <div className={styles.row} data-test-id='ClientOutcomes-results-PHQ'>
              <div>
                <h3>
                  PHQ9{' '}
                  <TextButton
                    data-test-id='ClientOutcomes-viewAnswers-phq9'
                    onClick={() => {
                      onViewButtonClick('PHQ')
                    }}
                    customClass={styles['view-answers-button']}
                    align={TextButtonAlign.LEFT}
                    text='view answers'
                  />
                </h3>
                <p>
                  Life Impairment Question{' '}
                  <LyraPopover content={assessmentPopovers.phq} iconId='life-impairment-question-popover' />
                </p>
                {scores['PHQ-SELF-HARM'] ? (
                  <p className={styles['self-harm']}>
                    Thoughts of self harm{' '}
                    <LyraPopover content={assessmentPopovers.selfHarm} iconId='thoughts-self-harm-popover' />
                    <br />
                    and/or suicide
                  </p>
                ) : (
                  ''
                )}
              </div>
              <div>
                <h3 data-test-id={'client-phq-score'}>{scores.PHQ}</h3>
              </div>
              <div>
                <h3 data-test-id={'client-phq-score-description'}>{describeScore('PHQ', scores.PHQ)}</h3>
                <p>{describeScore('PHQ-LIFE', scores['PHQ-LIFE'])}</p>
                {scores['PHQ-SELF-HARM'] ? (
                  <p className={styles['self-harm']}>{describeScore('PHQ-SELF-HARM', scores['PHQ-SELF-HARM'])}</p>
                ) : (
                  ''
                )}
              </div>
            </div>
          ) : null}
          {isInteger(scores.PSS) && renderOutcomeResultsEntry('PSS')}
          {isInteger(scores['WELL-BEING']) && renderOutcomeResultsEntry('WELL-BEING', 'Well Being')}
          {(isInteger(scores['SATISFACTION-GOALS']) ||
            isInteger(scores['SATISFACTION-RELATIONSHIP']) ||
            isInteger(scores['SATISFACTION-THERAPY'])) &&
          shouldProviderViewAllianceScores ? (
            <div className={styles.row} data-test-id='ClientOutcomes-results-alliance'>
              <div>
                <h3 className={styles['alliance-header']}>
                  Alliance Questions
                  <LyraPopover
                    content={assessmentPopovers.alliance(programName, providerType)}
                    iconId='alliance-questions-popover'
                  />
                </h3>
                <p>{programName} Approach Satisfaction</p>
                <p>Relationship Satisfaction</p>
                <p>{programName} Content Satisfaction</p>
              </div>
              <div>
                <h3 className={styles['alliance-header']}>&nbsp;</h3>
              </div>
              <div>
                <h3 className={styles['alliance-header']}>&nbsp;</h3>
                <p>{describeScore('SATISFACTION', scores['SATISFACTION-GOALS'])}</p>
                <p>{describeScore('SATISFACTION', scores['SATISFACTION-RELATIONSHIP'])}</p>
                <p>{describeScore('SATISFACTION', scores['SATISFACTION-THERAPY'])}</p>
              </div>
            </div>
          ) : null}
          {selfReflectionText ? (
            <div className={styles.row} data-test-id='ClientOutcomes-selfReflectionContainer'>
              <div>
                <h3 className={styles['client-self-reflection-header']}>Member Self Reflection</h3>
                <div className={styles['client-self-reflection-content']}>{selfReflectionText}</div>
              </div>
            </div>
          ) : null}
        </div>
      )
    } else {
      return <></>
    }
  }

  const renderReminder = () => {
    let shouldRemind = false

    let latestOutcomeDate = '0' // Use a string to match response_date type
    for (const outcome of outcomes) {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      if (outcome.send_at > latestOutcomeDate) {
        // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        latestOutcomeDate = outcome.send_at
      }
    }
    const requestedOutcomes = outcomes.filter((outcome: Outcome) => !outcome.response_date)
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    requestedOutcomes.sort((a: Outcome, b: Outcome) => b.send_at.localeCompare(a.send_at))

    const shouldDisplayConsentReminder = !isRegistered && !consentSubmitted
    const shouldDisplayOutcomeReminder =
      requestedOutcomes.length !== 0 && latestOutcomeDate === requestedOutcomes[0].send_at
    const shouldDisplayOptOutMessage =
      (isRegistered && consentStatus === false) || (consentStatus === false && consentSubmitted)
    if (shouldDisplayConsentReminder || shouldDisplayOutcomeReminder || shouldDisplayOptOutMessage) {
      shouldRemind = true
    }

    const getReminderMessage = () => {
      const reminderMessage = {
        header: <></>,
        content: <></>,
      }
      let contentString

      if (shouldDisplayOptOutMessage) {
        contentString = `We will not request Outcomes from this client. If he/she is open to submitting Outcomes, please ask him/her to send an email to care@lyrahealth.com.`
        reminderMessage.header = (
          <p data-test-id='ClientOutcomes-Reminder' className={`test-reminder-message' ${styles['no-data-header']}`}>
            <span className={styles['client-first-name']}>{clientFirstName}</span> has opted out of outcomes
          </p>
        )
        reminderMessage.content = <p>{contentString}</p>
      } else if (shouldDisplayConsentReminder || shouldDisplayOutcomeReminder) {
        if (shouldDisplayConsentReminder) {
          contentString = `hasn't submitted the consent we sent by email`
        } else if (shouldDisplayOutcomeReminder) {
          contentString = `hasn't submitted the latest Outcomes we sent by email on ${format(
            parseISO(latestOutcomeDate),
            'MM/dd/yyyy',
          )}`
        }
        reminderMessage.header = (
          <p data-test-id='ClientOutcomes-Reminder' className={`test-reminder-message' ${styles['no-data-header']}`}>
            Please remind <span className={styles['client-first-name']}>{clientFirstName}</span> to submit{' '}
            {shouldDisplayConsentReminder ? 'consent' : 'outcome'}
          </p>
        )
        reminderMessage.content = (
          <p>
            <span className={styles['client-first-name']}>{clientFirstName} </span>
            {contentString}
          </p>
        )
      }
      return reminderMessage
    }

    const reminderMessage = getReminderMessage()
    if (!shouldRemind) return false
    return (
      <div data-test-id='ClientOutcomes-Reminder' className={styles['no-data']}>
        {reminderMessage.header}
        {reminderMessage.content}
      </div>
    )
  }

  const renderOutcomeDropdown = () => {
    if (!outcomes) return <></>
    const submittedOutcomes = outcomes.filter((outcome: Outcome) => outcome.response_date).reverse()
    const hasMultipleTypesOfOutcomes = new Set(outcomes.map((element) => element.program_name)).size > 1
    const selectOptions = submittedOutcomes.map((outcome, idx) => {
      const { program_name: programName, response_date } = outcome
      // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
      const program = programNameTitleMap[programName] ? programNameTitleMap[programName] : ''
      return {
        text: `#${idx + 1} ${format(parseISO(response_date), 'MM/dd/yy')}${
          hasMultipleTypesOfOutcomes ? ' ' + program : ''
        }`,
        id: idx.toString(),
        outcome,
        selectHandler: () => setSelectedOutcome(outcome),
      }
    })
    if (submittedOutcomes.length === 0) return false
    const currentSelectedOutcome = selectOptions.find(({ outcome }) => isEqual(outcome, selectedOutcome))
    // @ts-expect-error TS(2790): The operand of a 'delete' operator must be optiona... Remove this comment to see the full error message
    selectOptions.forEach((selectOption) => delete selectOption.outcome)
    return (
      <div data-test-id='ClientOutcomes-dropdown'>
        <h4 className={styles['dropdown-label']}>Date submitted</h4>
        <div id='select-outcome-dropdown' className={styles['select-outcome-dropdown']}>
          <DropdownButton id='selectOutcome' pullLeft dropdownItems={selectOptions}>
            {currentSelectedOutcome?.text}
          </DropdownButton>
        </div>
      </div>
    )
  }

  const renderOutcomesCharts = () => {
    const submittedOutcomes = outcomes.filter((outcome) => outcome.response_date)
    if (submittedOutcomes.length === 0) return false

    return (
      <div data-test-id='ClientOutcomes-charts'>
        <h2 className={styles['history-title']}>Outcomes History</h2>
        <div className={styles['charts-container']}>
          {outcomeConstants.outcomeChartConstants.map((assessment: any) => {
            return (
              <OutcomeChart
                key={assessment.name}
                assessmentType={assessment.name}
                outcomesData={submittedOutcomes}
                answerModalAction={showAnswersModal}
                clientName={clientFullName ?? ''}
                track={track}
              />
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <div className={styles['select-outcome-container']}>
      {title ? <h2 className={styles['outcome-header']}>{title}</h2> : ''}
      {learnMore && (
        <>
          <p data-testid={tID('ClientDetails-resend-instruction')}>
            Do not resend unless it has been more than 4 weeks.
          </p>
          <div data-test-id='ClientDetails-resend'>
            {loading ? (
              <LoadingIndicator size={30} />
            ) : (
              <SecondaryButton onClick={resendOutcomeClickHandler} disabled={loading}>
                Resend {isRegistered || consentStatus ? 'Outcome' : 'Consent'}
              </SecondaryButton>
            )}
          </div>
          <a
            id='outcomes-learn-more'
            className={styles['learn-more']}
            href='https://provider-support.lyrahealth.com/hc/en-us/articles/115012608808'
            target='_blank'
            rel='noreferrer'
          >
            Learn more
          </a>
        </>
      )}
      {reminder && renderReminder()}
      {renderOutcomeDropdown()}
      {renderOutcomeResults()}
      {renderOutcomesCharts()}
      <BaseModal
        isOpen={showModal}
        closeModal={() => setShowModal(false)}
        // @ts-expect-error TS(2322): Type 'Response | null' is not assignable to type '... Remove this comment to see the full error message
        body={<OutcomeResponseDetails viewData={modalData} />}
      />
    </div>
  )
}

type Response = {
  clientName: string
  title: string | undefined
  dateSubmitted: string
  assessmentType: string
  score: number | string | null | undefined
  answers: QuestionResponse[] | undefined
}

type ClientOutcomesProps = {
  clientFirstName?: string
  clientFullName?: string
  consentStatus?: boolean
  consentSubmitted?: boolean
  isRegistered?: boolean
  learnMore?: boolean
  outcomes?: Outcome[] | undefined
  assignmentsWithSelfReflection?: Assignment[] | undefined
  patient_lyra_id?: string
  providerDetailsData?: ProviderAdminProviderInfo
  reminder?: boolean
  title?: string
  selectedProgressEpisode?: Episode
  actions?: {
    resendOutcomeEmail: (clientID: any) => any
    addAlert: (alertObj: any) => any
  }
}

const mapStateToProps = (state: RootState): any => {
  return {
    providerDetailsData: getProviderDetailsData(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    dispatch,
    actions: bindActionCreators({ resendOutcomeEmail, addAlert }, dispatch),
  }
}

export default connect<object, object, ClientOutcomesProps>(mapStateToProps, mapDispatchToProps)(toJS(ClientOutcomes))
