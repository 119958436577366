import React, { FunctionComponent, MutableRefObject, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { AccessibilityInfo, Keyboard, View } from 'react-native'

import BottomSheetGorhom from '@gorhom/bottom-sheet'
import { isEqual, isNil, noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import {
  ActivityResponseData,
  Assignment,
  ContentAttributes,
  Contentmetadata,
  countConditionalPages,
  CountryValues,
  getLastPageFromResponse,
  useGetLinkedAssignmentsPages,
  useGetSchemaTotalPages,
  useIsMounted,
  usePrevious,
  useThrottledCallback,
  useWindowSize,
} from '@lyrahealth-inc/shared-app-logic'

import { PrimaryButton, ThemedStatusBar } from '../../atoms'
import { AvatarDetails } from '../../atoms/icons/Avatar'
import { IS_WEB } from '../../constants'
import { AppContext } from '../../context'
import {
  ACTIVITY_EXIT_MODAL_CONTENT,
  ActivityExitModalContent,
} from '../../molecules/activityExitModalContent/ActivityExitModalContent'
import { BottomSheet } from '../../molecules/bottomSheet/BottomSheet'
import { CareOnboardingHeader } from '../../molecules/careOnboardingHeader/CareOnboardingHeader'
import { InputAccessory } from '../../molecules/inputAccessory/InputAccessory'
import { ActivityHeader } from '../../organisms/activityHeader/ActivityHeader'
import {
  FormBody,
  FormBodyCustomStyles,
  FormButtonParams,
  FormChangeProperties,
} from '../../organisms/formBody/FormBody'
import { colors } from '../../styles'
import { Flex1View } from '../../templates/content/CommonViews'
import { tID } from '../../utils'
import { ThemeType } from '../../utils/themes/ThemeProvider'

import type { UICoreFormContext } from '../../organisms/formBody/types'

export interface ActivityEntryProps {
  a11yFocusedElementOnLoad?: MutableRefObject<any>
  activityIntroductionProviderInfo?: Dict
  avatarDetails?: AvatarDetails
  clientFirstName?: string
  contentMetadata?: Contentmetadata
  contentAttributes?: ContentAttributes
  context?: UICoreFormContext
  customerCountryList?: CountryValues[]
  deleteDraft: (percentProgress: number) => void
  disableAutoSave?: boolean
  disableIsRequiredFormValidation?: boolean
  editIndex?: number
  exit: (values?: Dict) => void
  focused: boolean
  instructions?: string | null
  isProviderDraft?: boolean
  isProviderPreview?: boolean
  linkedAssignments?: Assignment[]
  locale?: string
  name?: string
  newEntry?: boolean
  onInit?: (percentProgress: number) => void
  onPopOverPress?: (link: string) => void
  onSavePress?: (percentProgress: number) => void
  preRequisiteAssignment?: Assignment
  response?: Dict
  responseDate?: string
  saveForm: (values: Dict, percentProgress?: number) => void
  setAssessmentMaxPage?: (maxPage: number) => void
  showAllPages?: boolean
  title?: string
  useCustomizedActivityIntroduction?: boolean
  userCountryIsoCode?: string
  videoSessionOpen?: boolean
  withBottomSheetModal?: boolean
  withPageBreaks?: boolean
  sentryLogMissingUISchemaKeys?: (keys: string) => void
}

/**
 * A wrapper for FormBody that controls pagination and renders the activity header
 */

const ActivityEntryContent = styled(Flex1View)<{ backgroundColor: string; videoSessionOpen: boolean }>(
  ({ backgroundColor, videoSessionOpen }) => ({
    backgroundColor,
    // Fixed positioning required on web to display correctly in Safari on mobile and maintain
    // footer at bottom of screen while loading.
    ...(IS_WEB && {
      position: 'fixed',
      top: videoSessionOpen ? '90px' : '0',
      left: '0',
      right: '0',
      bottom: '0',
    }),
  }),
)

const CTAContainer = styled.View<{ theme: ThemeType; videoSessionOpen: boolean }>(
  ({
    theme: {
      spacing,
      breakpoints: { isMinWidthTablet },
    },
    videoSessionOpen,
  }) => ({
    marginTop: spacing['16px'],
    marginBottom: isMinWidthTablet ? spacing['16px'] : spacing['32px'],
    ...(IS_WEB &&
      videoSessionOpen && {
        paddingBottom: '90px',
        borderTopColor: colors.ui_oatmeal3,
      }),
  }),
)

export const ActivityEntry: FunctionComponent<ActivityEntryProps> = ({
  a11yFocusedElementOnLoad,
  activityIntroductionProviderInfo,
  avatarDetails,
  clientFirstName,
  contentMetadata = {},
  contentAttributes = {},
  context,
  customerCountryList,
  deleteDraft,
  disableAutoSave = false,
  disableIsRequiredFormValidation,
  editIndex,
  exit,
  focused,
  instructions,
  isProviderDraft = false,
  isProviderPreview = false,
  linkedAssignments = [],
  locale,
  name,
  newEntry,
  onInit = noop,
  onPopOverPress,
  onSavePress = noop,
  preRequisiteAssignment,
  response,
  responseDate,
  saveForm,
  setAssessmentMaxPage,
  showAllPages,
  title = '',
  useCustomizedActivityIntroduction = false,
  userCountryIsoCode,
  videoSessionOpen = false,
  withBottomSheetModal = true,
  withPageBreaks = true,
  sentryLogMissingUISchemaKeys,
}) => {
  const { colors } = useTheme() as ThemeType
  const { isCareOnboardingPhase2Enabled } = useContext(AppContext)
  const [isAtTopOfPage, setIsAtTopOfPage] = useState(true)
  const [initialValues, setInitialValues] = useState<ActivityResponseData | Dict | null>(null)
  const [lastPage, setLastPage] = useState(0)
  const [showBottomsheet, setShowBottomsheet] = useState(false)
  const initialized = useRef(false)
  const previousInitialized = usePrevious(initialized.current)
  const formValues = useRef<Dict | null>(null)
  const [throttleDelay, setThrottleDelay] = useState<number | null>(5000)
  const isMounted = useIsMounted()
  const bottomSheetRef = useRef<BottomSheetGorhom>(null)
  const closeBottomSheet = useCallback(
    (exitActivity?: boolean) => {
      bottomSheetRef?.current?.close()
      if (exitActivity) {
        exit()
      }
    },
    [exit],
  )
  const { schema, uiSchema, initialValues: initialMetaDataValues, withConditionalLastPages } = contentMetadata ?? {}
  const inputAccessoryViewID = 'ActivityEntry'
  const [headerHeight, setHeaderHeight] = useState(0)
  const [footerHeight, setFooterHeight] = useState(0)
  const [, windowHeight] = useWindowSize()
  const backgroundColor = colors.backgroundSecondary

  const {
    bypassReview = false,
    customActivityIntroduction,
    customErrorMessage,
    hideInstructionsIfHasPrereq,
    hideProgressBar,
    initialPageIfHasPrereq = 0,
    linkedAssignmentTitle,
    singlePageContent = false,
  } = contentAttributes

  const activityTitle = linkedAssignmentTitle ?? title
  const hasPostLinkedAssignment =
    linkedAssignments.findIndex((linkedAssignment) => linkedAssignment.content.name === name) !==
    linkedAssignments.length - 1

  // Cancel throttle when screen is not in focus
  useEffect(() => {
    const delay = focused ? 5000 : null
    setThrottleDelay(delay)
  }, [focused])

  // calculate the total number of pages in the schema
  const schemaTotalPages = useGetSchemaTotalPages({ schema, uiSchema })
  const { totalPages: linkedAssignmentsTotalPages, currentPage: linkedAssignmentsCurrentPage } =
    useGetLinkedAssignmentsPages({
      selectedAssignmentName: name,
      linkedAssignments,
      isCareOnboardingPhase2Enabled,
    })

  const introOffset = 1
  const lastPageOffset = 2
  const [totalPages, setTotalPages] = useState<number>(schemaTotalPages)
  const previousAnswers = useRef<ActivityResponseData | Dict | null>(initialValues)
  const [currentPage, setCurrentPage] = useState<number>(
    showAllPages
      ? totalPages
      : preRequisiteAssignment
      ? Math.max(getLastPageFromResponse({ response, schema }), initialPageIfHasPrereq)
      : 0,
  )
  const [showErrorBanner, setShowErrorBanner] = useState<boolean>(false)

  const percentProgress =
    ((singlePageContent ? totalPages : currentPage) + introOffset + linkedAssignmentsCurrentPage) /
    (totalPages + lastPageOffset + linkedAssignmentsTotalPages)
  const previousPercentProgress = usePrevious(percentProgress)
  const isLastPage = currentPage === totalPages || singlePageContent
  const intl = useIntl()
  const { formatMessage } = intl
  const bypassReviewButtonText = hasPostLinkedAssignment
    ? formatMessage({
        defaultMessage: 'Submit and Continue',
        description: 'button to proceed to submit activity and continue to next activity',
      })
    : formatMessage({
        defaultMessage: 'Submit',
        description: 'button to submit activity and continue to next activity',
      })

  let InstructionsSection

  if (!isNil(hideInstructionsIfHasPrereq && preRequisiteAssignment)) {
    InstructionsSection = <></>
  } else if (customActivityIntroduction && customActivityIntroduction === 'CareOnboardingHeader') {
    InstructionsSection = (
      <CareOnboardingHeader
        clientFirstName={clientFirstName}
        avatarDetails={avatarDetails}
        activityTitle={activityTitle}
      />
    )
  }

  // on mount set initial values equal to response for existing responses
  useEffect(() => {
    if (isNil(initialValues)) {
      if (!newEntry && !isNil(response)) {
        setInitialValues(response)
        if (schema) {
          const lastPageFromResponse = getLastPageFromResponse({ response, schema })
          setLastPage(lastPageFromResponse)
          if (!showAllPages && lastPageFromResponse) {
            setCurrentPage(
              Math.max(
                getLastPageFromResponse({ response, schema }),
                preRequisiteAssignment ? initialPageIfHasPrereq : 0,
              ),
            )
          }
        }
      } else {
        setInitialValues(initialMetaDataValues || {})
      }
      initialized.current = true
    }
  }, [
    initialMetaDataValues,
    initialPageIfHasPrereq,
    initialValues,
    lastPage,
    newEntry,
    response,
    schema,
    showAllPages,
    schemaTotalPages,
    preRequisiteAssignment,
  ])

  useEffect(() => {
    updateTotalPage(initialValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues])

  useEffect(() => {
    // We make sure 'on Init' is called only once
    if (initialized.current && initialized.current !== previousInitialized) {
      onInit(percentProgress)
    }
  }, [initialized, previousInitialized, onInit, percentProgress])

  const handleNextPress = useCallback(
    (setTouched: FormButtonParams['setTouched']) => {
      const errors = setTouched()
      if (errors.length === 0) {
        // incrementPage
        !isLastPage && setCurrentPage(currentPage + 1)
        setShowErrorBanner(false)
      } else {
        setShowErrorBanner(true)
      }
      AccessibilityInfo.announceForAccessibility(`Complete ${errors.length} required items to continue`)
    },
    [currentPage, isLastPage],
  )

  const FormButton = useCallback(
    ({ handleSubmit, setTouched, loading }: FormButtonParams) => {
      if (isLastPage) {
        return (
          <CTAContainer videoSessionOpen={videoSessionOpen}>
            <PrimaryButton
              aria-label={
                loading
                  ? formatMessage({ defaultMessage: 'Loading', description: 'Button for loading state' })
                  : isProviderDraft
                  ? formatMessage({
                      defaultMessage: 'Save as draft',
                      description: 'Button for Provider to save a draft',
                    })
                  : bypassReview
                  ? bypassReviewButtonText
                  : formatMessage({ defaultMessage: 'Review', description: 'Button to start review' })
              }
              text={
                loading ? (
                  <FormattedMessage defaultMessage='Loading...' description='Button for loading state' />
                ) : isProviderDraft ? (
                  <FormattedMessage defaultMessage='Save as draft' description='Button for Provider to save a draft' />
                ) : bypassReview ? (
                  bypassReviewButtonText
                ) : (
                  <FormattedMessage defaultMessage='Review' description='Button to start review' />
                )
              }
              onPress={() => {
                handleNextPress(setTouched)
                handleSubmit()
              }}
              testID={tID('form-reviewButton')}
              accessibilityLabel={
                loading
                  ? formatMessage({
                      defaultMessage: 'Loading...',
                      description: 'button text loading',
                    })
                  : isProviderDraft
                  ? formatMessage({
                      defaultMessage: 'Save as draft',
                      description: 'Button for Provider to save a draft',
                    })
                  : bypassReview
                  ? bypassReviewButtonText
                  : formatMessage({
                      defaultMessage: 'Review',
                      description: 'button text review',
                    })
              }
              fullWidth
            />
          </CTAContainer>
        )
      } else {
        return (
          <CTAContainer videoSessionOpen={videoSessionOpen}>
            <PrimaryButton
              text={<FormattedMessage defaultMessage='Next' description='Button to go to next screen' />}
              onPress={() => handleNextPress(setTouched)}
              testID={tID('form-nextButton')}
              accessibilityLabel={intl.formatMessage({
                defaultMessage: 'Next',
                description: 'button text next',
              })}
              fullWidth
            />
          </CTAContainer>
        )
      }
    },
    [
      bypassReview,
      intl,
      bypassReviewButtonText,
      handleNextPress,
      isLastPage,
      formatMessage,
      isProviderDraft,
      videoSessionOpen,
    ],
  )

  const updateTotalPage = (answers: ActivityResponseData | Dict | null) => {
    if (answers && schema) {
      // Will show how much conditional pages should be reduced on total page
      const result = countConditionalPages(schema, answers)

      const shouldUpdateTotalPages = !isEqual(result, previousAnswers.current)

      // Get new total page count
      const adjustedTotalPages = schemaTotalPages - result.totalCount

      if (shouldUpdateTotalPages) {
        setTotalPages(adjustedTotalPages)
        previousAnswers.current = result

        // If the user was on page 8 but totalPages is only 5, set the current page the value of totalPages
        if (currentPage > adjustedTotalPages) {
          setCurrentPage(adjustedTotalPages)
        }

        // This is so that we can pass it to Activity Review
        setAssessmentMaxPage && setAssessmentMaxPage(adjustedTotalPages)
      }
    }
  }

  // on close press open bottom sheet if it's a new entry
  const handleClosePress = () => {
    const formValuesEmpty = isNil(formValues.current)
    Keyboard.dismiss()
    // Setting thottle delay to 0 cancels the callback
    setThrottleDelay(null)
    if (newEntry && !formValuesEmpty && withBottomSheetModal) {
      setShowBottomsheet(true)
    } else {
      if (!formValuesEmpty && !isProviderDraft) {
        saveForm({ values: formValues.current })
      }
      exit({ values: !formValuesEmpty ? formValues.current : initialValues })
    }
  }

  const handleSavePress = useCallback(() => {
    if (previousPercentProgress !== percentProgress) {
      onSavePress(percentProgress)
    }
    saveForm({ values: formValues.current })
    closeBottomSheet(true)
  }, [closeBottomSheet, onSavePress, percentProgress, previousPercentProgress, saveForm])

  const handleDeletePress = useCallback(() => {
    deleteDraft(percentProgress)
    closeBottomSheet(true)
  }, [closeBottomSheet, deleteDraft, percentProgress])

  const throttledHandleFormChange = useThrottledCallback((values) => {
    if (isMounted()) {
      saveForm({ values })
      if (previousPercentProgress !== percentProgress) {
        onSavePress(percentProgress)
      }
    }
  }, throttleDelay)

  const handleFormChange = ({ values }: FormChangeProperties) => {
    if (!disableAutoSave) {
      throttledHandleFormChange(values)
      withConditionalLastPages && updateTotalPage(values)
    }
    formValues.current = values
  }

  const getBottomSheetModal = useCallback(() => {
    if (withBottomSheetModal && showBottomsheet) {
      return (
        <BottomSheet
          ref={bottomSheetRef}
          snapPoints={[316]}
          onCloseEnd={() => {
            setShowBottomsheet(false)
          }}
        >
          <ActivityExitModalContent
            exitModalContentType={ACTIVITY_EXIT_MODAL_CONTENT.CLOSE_NEW_DRAFT}
            onPrimaryButtonPress={handleSavePress}
            onRequestClose={() => closeBottomSheet(false)}
            onTertiaryButtonPress={handleDeletePress}
          />
        </BottomSheet>
      )
    }
    return <View />
  }, [withBottomSheetModal, showBottomsheet, handleSavePress, handleDeletePress, closeBottomSheet])

  const calculateScrollFieldHeight = useCallback(() => {
    return windowHeight - (headerHeight + footerHeight)
  }, [footerHeight, headerHeight, windowHeight])

  return (
    <ActivityEntryContent
      testID={tID('ActivityEntry')}
      backgroundColor={backgroundColor}
      videoSessionOpen={videoSessionOpen}
    >
      <ActivityHeader
        a11yFocusedElementOnLoad={a11yFocusedElementOnLoad}
        title={activityTitle}
        percentProgress={isProviderPreview || hideProgressBar ? undefined : percentProgress}
        onClosePress={handleClosePress}
        status='draft'
        date={newEntry ? null : responseDate}
        setHeaderHeight={IS_WEB ? setHeaderHeight : noop}
        progressBarAccessibilityLiveRegion='assertive'
        isAtTopOfPage={isAtTopOfPage}
        isProviderPreview={isProviderPreview}
      />
      <Flex1View testID={tID('form-container')}>
        {schema && !isNil(initialValues) && (
          <FormBody
            intl={intl}
            schema={schema}
            // Since the FormBody is consuming Contentmetadata and Metadata,
            // we cast this here since we know it is Contentmetadata
            uiSchema={uiSchema}
            instructions={instructions}
            InstructionsSection={InstructionsSection}
            currentPage={currentPage}
            totalPages={totalPages}
            singlePageContent={singlePageContent}
            initialScrollIndex={editIndex ?? lastPage}
            name={name}
            saveForm={saveForm}
            formButton={isProviderPreview ? undefined : FormButton}
            onFormChange={handleFormChange}
            initialValues={initialValues}
            inputAccessoryViewID={inputAccessoryViewID}
            showErrorBanner={showErrorBanner}
            withPageBreaks={withPageBreaks}
            setFooterHeight={IS_WEB ? setFooterHeight : noop}
            // Only calculate the scroll field height on web, doing this on mobile will cause slow performance
            scrollListHeight={IS_WEB ? calculateScrollFieldHeight() : undefined}
            locale={locale}
            customerCountryList={customerCountryList}
            userCountryIsoCode={userCountryIsoCode}
            formContext={context}
            onPopOverPress={onPopOverPress}
            useCustomizedActivityIntroduction={useCustomizedActivityIntroduction}
            activityIntroductionProviderInfo={activityIntroductionProviderInfo}
            hasNewLookAndFeel
            backgroundColor={backgroundColor}
            setIsAtTopOfPage={setIsAtTopOfPage}
            disableIsRequiredFormValidation={disableIsRequiredFormValidation}
            customErrorMessage={customErrorMessage ? formatMessage(customErrorMessage) : undefined}
            sentryLogMissingUISchemaKeys={sentryLogMissingUISchemaKeys}
            formBodyCustomStyles={
              {
                ...(bypassReview && { submitButtonWrapper: { maxWidth: 'fit-content' } }),
              } as unknown as FormBodyCustomStyles
            }
          />
        )}
      </Flex1View>
      {getBottomSheetModal()}
      <InputAccessory nativeID={inputAccessoryViewID} />
      <ThemedStatusBar defaultStyle='dark' />
    </ActivityEntryContent>
  )
}
