import React, { useState } from 'react'
import { connect } from 'react-redux'

import styled from 'styled-components/native'

import { Appointment, EpisodeBaseline, EpisodeStates, EpisodeSummary } from '@lyrahealth-inc/shared-app-logic'
import {
  BodyText,
  BodyTextSize,
  colors,
  DataBoard,
  DataBoardItem,
  ExternalLinkIcon,
  InfoIcon,
  Modal,
  Subhead,
  SubheadSize,
  TextButton,
  ThemeType,
  tID,
  Tooltip,
} from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './snapshot.module.scss'
import { actions as mixpanelActions, mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { appointmentStatuses, measurementBasedCareUrls } from '../../common/constants/appConstants'
import { getClientClosedEpisodes } from '../../data/lyraTherapy/clientSelectors'
import { RootState } from '../../data/store'
import { getLTVideoAppointments } from '../data/ltVideoSelectors'

const HeaderContainer = styled.View({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const HeaderTextContainer = styled.View({
  flexDirection: 'column',
})

const StyledTextButton = styled(TextButton)<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['4px'],
}))

const DataBoardContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  paddingTop: theme.spacing['24px'],
}))

const Row = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  padding: theme.spacing['16px'],
}))

const RowText = styled.View({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
})

const ZebraTable = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['32px'],
  borderTopWidth: '3px',
  borderTopColor: colors.ui_oatmeal3,
  borderBottomWidth: '1px',
  borderBottomColor: colors.ui_oatmeal3,
}))

const ViewHelpArticleTextButton = styled(TextButton)<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['16px'],
}))

export const ClosedEpisodesPercentageSnapshot: React.FC<ClosedEpisodesPercentageSnapshotProps> = ({
  appointments,
  closedEpisodes,
}) => {
  const [isClosedEpisodesModalOpen, setIsClosedEpisodesModalOpen] = useState<boolean>(false)

  const completedEpisodes = closedEpisodes
    ? Object.entries(closedEpisodes).filter(
        ([, closedEpisode]) =>
          (closedEpisode.episode_state === EpisodeStates.COMPLETED ||
            closedEpisode.episode_state === EpisodeStates.DROP_OUT) &&
          (closedEpisode.clinical_success === true || closedEpisode.clinical_success === false),
      )
    : []
  const completedMBCEpisodes = completedEpisodes.filter(([, completedEpisode]) => completedEpisode?.clinical_success)
  const completedMBCEpisodePercentage = completedEpisodes.length
    ? Math.round((completedMBCEpisodes.length / completedEpisodes.length) * 1000) / 10
    : '0'

  const mildCompletedEpisodes = completedEpisodes.filter(
    ([, completedEpisode]: [string, EpisodeSummary]) => completedEpisode?.baseline === EpisodeBaseline.MILD_MINIMAL,
  )
  const mildCompletedMBCEpisodes = mildCompletedEpisodes
    .filter(([, completedEpisode]) => completedEpisode?.clinical_success)
    .map(([episodeId]) => episodeId)
  const mildCompletedMBCPercentage = Object.values(mildCompletedEpisodes).length
    ? Math.round((mildCompletedMBCEpisodes.length / Object.values(mildCompletedEpisodes).length) * 1000) / 10
    : '0'
  const averageMildMBCEpisodeLength = mildCompletedMBCEpisodes.length
    ? Math.round(
        (appointments.filter(
          (appointment) =>
            appointment.appointmentStatus === appointmentStatuses.completed &&
            appointment.episodeId &&
            mildCompletedMBCEpisodes.includes(appointment.episodeId),
        ).length /
          mildCompletedMBCEpisodes.length) *
          10,
      ) /
        10 +
      ' sessions'
    : 'N/A'

  const moderateCompletedEpisodes = completedEpisodes.filter(
    ([, completedEpisode]: [string, EpisodeSummary]) => completedEpisode?.baseline === EpisodeBaseline.MODERATE_SEVERE,
  )
  const moderateCompletedMBCEpisodes = moderateCompletedEpisodes
    .filter(([, completedEpisode]) => completedEpisode?.clinical_success)
    .map(([episodeId]) => episodeId)
  const moderateCompletedMBCPercentage = Object.values(moderateCompletedEpisodes).length
    ? Math.round((moderateCompletedMBCEpisodes.length / Object.values(moderateCompletedEpisodes).length) * 1000) / 10
    : '0'
  const averageModerateMBCEpisodeLength = moderateCompletedMBCEpisodes.length
    ? Math.round(
        (appointments.filter(
          (appointment) =>
            appointment.appointmentStatus === appointmentStatuses.completed &&
            appointment.episodeId &&
            moderateCompletedMBCEpisodes.includes(appointment.episodeId),
        ).length /
          moderateCompletedMBCEpisodes.length) *
          10,
      ) /
        10 +
      ' sessions'
    : 'N/A'

  const viewMoreDetails = () => {
    track({
      event: mixpanelEvents.BUTTON_PRESS,
      action: mixpanelActions.VIEW_MBC_PERCENTAGE_DETAILS,
    })
    setIsClosedEpisodesModalOpen(true)
  }

  const viewHelpArticle = () => {
    track({
      event: mixpanelEvents.BUTTON_PRESS,
      action: mixpanelActions.VIEW_MBC_PERCENTAGE_HELP_ARTICLE,
    })
    window.open(measurementBasedCareUrls.percentageHelpArticle, '_blank')
  }

  return (
    <div>
      <div data-test-id='ClosedEpisodesPercentageSnapshot-card'>
        <HeaderContainer>
          <HeaderTextContainer>
            <Subhead text={'Clinical Success Rate'} size={SubheadSize.MEDIUM} color={colors.ui_oatmeal6} />
            <BodyText size={BodyTextSize.LARGE} text={`Goal: 80% of clients`} color={colors.ui_oatmeal5} />
          </HeaderTextContainer>
          <StyledTextButton
            text='View details'
            testID={tID('ClosedEpisodesPercentageSnapshot-viewDetails')}
            onPress={viewMoreDetails}
          />
        </HeaderContainer>
        <DataBoardContainer>
          <DataBoard backgroundColor={colors.ui_oatmeal2} centered>
            <DataBoardItem
              testID={tID('ClosedEpisodesPercentageSnapshot-completed-mbc-percentage')}
              style={{ marginVertical: '64px' }}
              infoDataStyle={{ marginTop: '0px' }}
              value={`${completedMBCEpisodePercentage}%`}
              tooltip={
                <Tooltip hoverEnabled placement='right' content='Completed and drop out episodes only'>
                  <InfoIcon size={24} />
                </Tooltip>
              }
            />
          </DataBoard>
        </DataBoardContainer>
      </div>
      <Modal
        visible={isClosedEpisodesModalOpen}
        onRequestClose={() => setIsClosedEpisodesModalOpen(false)}
        onCloseEnd={() => setIsClosedEpisodesModalOpen(false)}
        scrollable
        scrollableModalWidth='600px'
        scrollableModalHeight='auto'
        isHeaderTransparent
        modalContents={
          <>
            <HeaderTextContainer>
              <Subhead
                testID={tID('ClosedEpisodesPercentageSnapshot-modal-title')}
                text={'Clinical Success Rate'}
                size={SubheadSize.MEDIUM}
                color={colors.ui_oatmeal6}
              />
              <BodyText
                testID={tID('ClosedEpisodesPercentageSnapshot-modal-title')}
                size={BodyTextSize.LARGE}
                text={`Percentage of clients you graduated that demonstrated measurement-based care improvement`}
                color={colors.ui_oatmeal5}
              />
            </HeaderTextContainer>
            <ZebraTable testID={tID('ClosedEpisodesPercentageSnapshot-details-table')}>
              <Row
                testID={tID(`ClosedEpisodesPercentageSnapshot-details-mild`)}
                style={{ backgroundColor: colors.ui_oatmeal2 }}
              >
                <RowText testID={tID('ClosedEpisodesPercentageSnapshot-details-mild-percentage')}>
                  <BodyText size={BodyTextSize.DEFAULT} text={'Mild/Minimal'} color={colors.ui_oatmeal6} />
                  <BodyText
                    size={BodyTextSize.DEFAULT}
                    text={`${mildCompletedMBCPercentage}%`}
                    color={colors.ui_oatmeal6}
                  />
                </RowText>
                <RowText testID={tID('ClosedEpisodesPercentageSnapshot-details-mild-duration')}>
                  <BodyText size={BodyTextSize.DEFAULT} text={'Average Episode Duration'} color={colors.ui_oatmeal5} />
                  <BodyText
                    size={BodyTextSize.DEFAULT}
                    text={`${averageMildMBCEpisodeLength}`}
                    color={colors.ui_oatmeal5}
                  />
                </RowText>
              </Row>
              <Row testID={tID(`ClosedEpisodesPercentageSnapshot-details-moderate`)}>
                <RowText testID={tID('ClosedEpisodesPercentageSnapshot-details-moderate-percentage')}>
                  <BodyText size={BodyTextSize.DEFAULT} text={'Moderate/Severe'} color={colors.ui_oatmeal6} />
                  <BodyText
                    size={BodyTextSize.DEFAULT}
                    text={`${moderateCompletedMBCPercentage}%`}
                    color={colors.ui_oatmeal6}
                  />
                </RowText>
                <RowText testID={tID('ClosedEpisodesPercentageSnapshot-details-moderate-duration')}>
                  <BodyText size={BodyTextSize.DEFAULT} text={'Average Episode Duration'} color={colors.ui_oatmeal5} />
                  <BodyText
                    size={BodyTextSize.DEFAULT}
                    text={`${averageModerateMBCEpisodeLength}`}
                    color={colors.ui_oatmeal5}
                  />
                </RowText>
              </Row>
            </ZebraTable>
            <ViewHelpArticleTextButton
              // @ts-expect-error TS(2322): Type '{ isPrimary: boolean; onPress: () => void; t... Remove this comment to see the full error message
              isPrimary={true}
              testID={tID('ClosedEpisodesPercentageSnapshot-modalHelpArticleButton')}
              onPress={viewHelpArticle}
              text='View help article'
              rightIcon={<ExternalLinkIcon strokeColor={styles.x_primary_action} size={15} />}
            />
          </>
        }
      />
    </div>
  )
}

type ClosedEpisodesPercentageSnapshotProps = {
  closedEpisodes?: {
    [key: string]: EpisodeSummary
  }
  appointments: Appointment[]
}

const mapStateToProps = (state: RootState) => ({
  appointments: getLTVideoAppointments(state),
  closedEpisodes: getClientClosedEpisodes(state),
})

export default connect(mapStateToProps)(ClosedEpisodesPercentageSnapshot)
