import React, { useState } from 'react'
import CSSModules from 'react-css-modules'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'

import { isEmpty, sortBy } from 'lodash-es'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'

import { assignmentHasCompletedResponse } from '@lyrahealth-inc/shared-app-logic'
import { AssignmentsList, AssignmentTagType, BootstrapContainer, TypeAheadField } from '@lyrahealth-inc/ui-core'
import { toJS, useFetcher } from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './assignmentLibrary.module.scss'
import ContentFilter from './ContentFilter'
import { programNameTitleMap, ROLES } from '../../common/constants/appConstants'
import { CLIENT_HOME, CLIENTS_NEW_ASSIGNMENT } from '../../common/constants/routingConstants'
import { hasRole } from '../../common/utils/utils'
import {
  getClientAssignmentsData,
  getClientEpisodesData,
  getClientSelectedEpisode,
} from '../../data/lyraTherapy/clientSelectors'
import { getLyraTherapyContentsData } from '../../data/lyraTherapy/contentSelectors'
import { RootState } from '../../data/store'
import { getProviderDetailsData } from '../../providers/data/providerSelectors'
import { getContentCategoriesTagsByProgramId } from '../data/ltContentsAutoActions'
import MiniBackNav from '../miniBackNav/MiniBackNav'

const mapContentTypetoDisplayNames = {
  exercise: { displayName: 'Exercise', tabName: 'Exercises' },
  lesson: { displayName: 'Lesson', tabName: 'Lessons' },
  infosheet: { displayName: 'Guide', tabName: 'Guides' },
}

/* list out the exercises, lessons, and infosheets for the providers to select from and add to the assignments/curriculum */
export const ContentLibrary = ({
  contents,
  assignments = [],
  selectedEpisode = {},
  episodes = [],
  actions: { getContentCategoriesTagsByProgramId },
  providerDetailsData = {},
}: any) => {
  const navigate = useNavigate()
  const location = useLocation()

  const sessionCount = location?.state?.sessionCount ?? undefined
  const [activeTab, setActiveTab] = useState('Exercises')

  const handleContentClicked = (content: any) => {
    navigate(CLIENTS_NEW_ASSIGNMENT.route, { state: { content, sessionCount } })
  }
  const handleBackClicked = () => {
    navigate(CLIENT_HOME.route, { state: { sessionCount } })
  }

  const exercises = sortBy(
    contents.filter((content: any) => content.group === 'exercise'),
    'title',
  )
  const lessons = sortBy(
    contents.filter((content: any) => content.group === 'lesson'),
    'title',
  )
  const infosheets = sortBy(
    contents.filter((content: any) => content.group === 'infosheet'),
    'title',
  )

  const allActivities = exercises.concat(lessons, infosheets)
  const [filteredActivities, setFilteredActivities] = useState(allActivities)

  const [, categoryTags = [], fetchedCategoryTags] = useFetcher(
    [[getContentCategoriesTagsByProgramId, { programId: selectedEpisode?.program_id }]],
    [selectedEpisode?.program_id],
  )
  const sortFilteredActivitiesAs = (type: any) => {
    return sortBy(
      filteredActivities.filter((content) => content.group === type),
      'title',
    )
  }
  const handleFilteredActivities = (input: any) => {
    if (isEmpty(input)) {
      setFilteredActivities(allActivities)
    } else {
      setFilteredActivities(input)
      setActiveTab(mapContentTypetoDisplayNames[input?.[0].group].tabName)
    }
  }

  const assignmentSessionTags = {}
  const currentEpisodeAssignments = assignments.filter(
    (assignment: any) => assignment.episode_id === selectedEpisode.id,
  )
  contents.forEach((content: any) => {
    const lastAssignedCurrentEpisode = sortBy(currentEpisodeAssignments, 'session_period')
      .reverse()
      .find((assignment) => assignment.content.id === content.id)
    const otherEpisodesAssignments = assignments.filter(
      (assignment: any) => assignment.content.id === content.id && assignment.episode_id !== selectedEpisode.id,
    )
    const otherAssignedEpisodes = episodes
      .filter((episode: any) =>
        otherEpisodesAssignments.map((assignment: any) => assignment.episode_id).includes(episode.id),
      )
      .sort((a: any, b: any) => (a.start_date > b.start_date ? -1 : 1))
    const lastAssignedOtherEpisodes =
      // In rare occurances(bugs) of episode permission issues, sometimes not all episodes are available to the FE, see https://lyrahealth.atlassian.net/browse/LCDELIVERY-4988
      !isEmpty(otherAssignedEpisodes) &&
      otherEpisodesAssignments.find((assignment: any) => assignment.episode_id === otherAssignedEpisodes[0].id)

    if (lastAssignedCurrentEpisode) {
      assignmentSessionTags[content.id] = [
        { type: AssignmentTagType.default, text: `Last assigned session ${lastAssignedCurrentEpisode.session_period}` },
      ]
      if (assignmentHasCompletedResponse(lastAssignedCurrentEpisode)) {
        assignmentSessionTags[content.id].push({ type: AssignmentTagType.success, text: 'Client completed' })
      }
    } else if (otherAssignedEpisodes.length) {
      assignmentSessionTags[content.id] = [
        {
          type: AssignmentTagType.default,
          text: `Assigned in previous ${
            otherAssignedEpisodes[0]?.program_name in programNameTitleMap &&
            otherAssignedEpisodes[0]?.program_name !== selectedEpisode.program_name
              ? `${programNameTitleMap[otherAssignedEpisodes[0]?.program_name]} `
              : ''
          }episode`,
        },
      ]
      if (assignmentHasCompletedResponse(lastAssignedOtherEpisodes)) {
        assignmentSessionTags[content.id].push({ type: AssignmentTagType.success, text: 'Client completed' })
      }
    }
  })
  const handleKeyDown = (event: { key: string }, tabToActivate: string) => {
    if (event.key === 'Enter' || event.key === 'Space') {
      setActiveTab(tabToActivate)
    }
  }
  // For now setting the lint rule to disable as moving h3 to interactive element will need
  // additional work and design input
  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  const renderFilter = () => {
    return (
      <div styleName='filter-container'>
        <h3
          onClick={() => setActiveTab('Exercises')}
          onKeyDown={(event) => handleKeyDown(event, 'Exercises')}
          styleName={activeTab === 'Exercises' ? 'active' : ''}
          data-test-id='ContentLibrary-exercisesFilter'
        >
          Exercises
        </h3>
        <h3
          onClick={() => setActiveTab('Lessons')}
          onKeyDown={(event) => handleKeyDown(event, 'Lessons')}
          styleName={activeTab === 'Lessons' ? 'active' : ''}
          data-test-id='ContentLibrary-lessonsFilter'
        >
          Lessons
        </h3>
        <h3
          onClick={() => setActiveTab('Guides')}
          onKeyDown={(event) => handleKeyDown(event, 'Guides')}
          styleName={activeTab === 'Guides' ? 'active' : ''}
          data-test-id='ContentLibrary-guidesFilter'
        >
          Guides
        </h3>
      </div>
    )
  }
  /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
  return (
    <BootstrapContainer col='col-md-10 col-md-offset-1'>
      <div styleName='container'>
        <MiniBackNav backFunc={handleBackClicked} subText={`Activities for Session ${sessionCount}`} />

        <div style={{ width: '100%' }}>
          <h2 styleName='main-header'>Share new content</h2>
          <div>
            <div>
              {/* @ts-expect-error TS(2739): Type '{ placeholder: string; emptyLabel: string; m... Remove this comment to see the full error message */}
              <TypeAheadField
                id='ContentLibrary-typeahead'
                placeholder='Search for content...'
                emptyLabel='No activities found.'
                multiSelect={false}
                options={filteredActivities}
                labelKey={(content: any) =>
                  `${content.title} (${mapContentTypetoDisplayNames[content.group].displayName}) ${
                    isEmpty(content.tags) ? '' : `(${content.tags})`
                  }`
                }
                className={styles['typeahead-field']}
                input={{
                  onChange: handleFilteredActivities,
                  onBlur: handleFilteredActivities,
                }}
              />
            </div>
            {hasRole(providerDetailsData?.roles, [
              ROLES.LT_THERAPIST,
              ROLES.LT_FELLOW_THERAPIST,
              ROLES.LT_COACH,
              ROLES.LT_COACH_SUPERVISOR,
            ]) &&
              fetchedCategoryTags && (
                <ContentFilter
                  allActivities={allActivities}
                  setFilteredActivities={setFilteredActivities}
                  categoryTags={categoryTags}
                  isProviderCoach={hasRole(providerDetailsData?.roles, [ROLES.LT_COACH, ROLES.LT_COACH_SUPERVISOR])}
                />
              )}
            <div data-test-id='ContentLibrary-filter'>{renderFilter()}</div>
            {activeTab === 'Exercises' && (
              <AssignmentsList
                assignments={sortFilteredActivitiesAs('exercise')}
                itemClickHandler={handleContentClicked}
                noDataText='No Exercises available'
                // @ts-expect-error TS(2769): No overload matches this call.
                assignmentTags={assignmentSessionTags}
              />
            )}
            {activeTab === 'Lessons' && (
              <AssignmentsList
                assignments={sortFilteredActivitiesAs('lesson')}
                itemClickHandler={handleContentClicked}
                noDataText='No Lessons available'
                // @ts-expect-error TS(2769): No overload matches this call.
                assignmentTags={assignmentSessionTags}
              />
            )}
            {activeTab === 'Guides' && (
              <AssignmentsList
                assignments={sortFilteredActivitiesAs('infosheet')}
                itemClickHandler={handleContentClicked}
                noDataText='No Guides available'
                // @ts-expect-error TS(2769): No overload matches this call.
                assignmentTags={assignmentSessionTags}
              />
            )}
          </div>
        </div>
      </div>
    </BootstrapContainer>
  )
}

ContentLibrary.propTypes = {
  contents: PropTypes.array,
  assignments: PropTypes.array,
}

const mapStateToProps = (state: RootState) => {
  return {
    contents: getLyraTherapyContentsData(state),
    episodes: getClientEpisodesData(state),
    selectedEpisode: getClientSelectedEpisode(state),
    assignments: getClientAssignmentsData(state),
    providerDetailsData: getProviderDetailsData(state),
  }
}
const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      getContentCategoriesTagsByProgramId,
    },
    dispatch,
  ),
})
export default connect(mapStateToProps, mapDispatchToProps)(toJS(CSSModules(ContentLibrary, styles)))
