import React, { useState } from 'react'
import { connect, useSelector } from 'react-redux'

import { AnyAction, bindActionCreators, Dispatch } from 'redux'

import { Address, useFlags } from '@lyrahealth-inc/shared-app-logic'
import { LoadingIndicator, PrimaryButton, toJS } from '@lyrahealth-inc/ui-core-crossplatform'
import { ButtonSize } from '@lyrahealth-inc/ui-core-crossplatform/src/atoms/baseButton/BaseButton'

import { getAuthConfig, getAuthInPersonPreference } from '../../data/auth/authSelectors'
import { RootState } from '../../data/store'
import { updateInPersonDesireStatus } from '../../providers/data/providersDataActions'
import { getProviderDetailsAddresses } from '../../providers/data/providerSelectors'

export const InPersonToggle: React.FC<InPersonToggleProps> = ({
  showInPersonSuccess,
  onPressAfterUpdate,
  providerAddresses,
  providerId,
  testID,
  size = ButtonSize.DEFAULT,
  isMyInfoPage = false,
  customStyles,
  actions: { updateInPersonDesireStatus },
}) => {
  const inPersonPreference = useSelector(getAuthInPersonPreference) ?? false
  const config = useSelector(getAuthConfig)
  const [updatingInPersonDesireStatus, setUpdatingInPersonDesireStatus] = useState(false)

  const { isInPersonPreferenceEnabled } = useFlags()
  return (
    <>
      {config?.showInPersonPrompt && !inPersonPreference && !!providerAddresses && isInPersonPreferenceEnabled && (
        <>
          <div className={customStyles['sub-section']}>
            {!isMyInfoPage && <h3 className={customStyles['sub-heading']}>Are you willing to offer in-person care?</h3>}
            <p className={customStyles.subtext}>
              You are unavailable for in-person care in our system. In-Person care may increase your Lyra referrals.
            </p>
          </div>
          <div className={customStyles['in-person-container']}>
            <PrimaryButton
              fullWidth={true}
              text={'Turn on in-person'}
              onPress={() => {
                setUpdatingInPersonDesireStatus(true)
                updateInPersonDesireStatus({
                  id: providerId,
                  data: { in_person_preference: true },
                }).then(() => {
                  setUpdatingInPersonDesireStatus(false)
                  onPressAfterUpdate()
                })
              }}
              testID={testID}
              size={size}
            />
            {updatingInPersonDesireStatus && <LoadingIndicator size={20} topPadding={0} />}
          </div>
        </>
      )}
      {config?.showInPersonPrompt &&
        (showInPersonSuccess || (isMyInfoPage && inPersonPreference)) &&
        isInPersonPreferenceEnabled && (
          <div className={customStyles['sub-section']}>
            {!isMyInfoPage ? (
              <h3 className={customStyles['sub-heading']}>Thank you for offering in-person care!</h3>
            ) : (
              <p className={customStyles['sub-heading']}>Yes, you are listed as available for in-person care.</p>
            )}
          </div>
        )}
    </>
  )
}

type InPersonToggleProps = {
  showInPersonSuccess: boolean
  onPressAfterUpdate: () => void // Action to perform after successfully updating in person pref
  providerAddresses?: Address[]
  providerId: string
  testID: string
  size?: ButtonSize
  isMyInfoPage?: boolean
  customStyles: { [key: string]: string }
  actions: {
    updateInPersonDesireStatus: ({ id, data }: { id: string; data: { in_person_preference: boolean } }) => Promise<any>
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    providerAddresses: getProviderDetailsAddresses(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    actions: bindActionCreators(
      {
        updateInPersonDesireStatus,
      },
      dispatch,
    ),
  }
}

export default connect<object, object, any>(mapStateToProps, mapDispatchToProps)(toJS(InPersonToggle))
