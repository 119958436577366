import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { ViewStyle } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { BodyText } from '../../atoms/bodyText/BodyText'
import { HeaderText } from '../../atoms/headerText/HeaderText'
import { CloseIcon } from '../../atoms/icons/CloseIcon'
import { PressableOpacity } from '../../atoms/pressableOpacity/PressableOpacity'
import { SecondaryButton } from '../../atoms/secondaryButton/SecondaryButton'
import { TertiaryButton } from '../../atoms/tertiaryButton/TertiaryButton'
import { BodyTextSize } from '../../styles/typeStyles'
import { ThemeType, tID } from '../../utils'

type AlertPopUpButton = {
  text: React.ReactNode
  onPress: () => void
  icon?: React.ReactElement
  testID?: string
  loading?: boolean
}
export type AlertPopUpProps = {
  headerText: React.ReactNode
  onClose: () => void
  secondaryButton?: AlertPopUpButton
  tertiaryButton?: AlertPopUpButton
  text: React.ReactNode
  textSize?: BodyTextSize
  style?: ViewStyle
}

const AlertPopUpContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: theme.colors.backgroundWarning,
  borderRadius: theme.spacing['16px'],
  color: theme.colors.borderWarning,
  display: 'flex',
  padding: theme.spacing['16px'],
  maxWidth: '400px',
  boxShadow: `0 2px 10px ${theme.colors.shadowLow}`,
}))

const CloseIconContainer = styled(PressableOpacity)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing['12px'],
  right: theme.spacing['12px'],
  zIndex: 1,
}))

const HeaderTextContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['8px'],
}))

const ButtonsContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  alignItems: 'center',
  flexDirection: 'row',
  marginTop: theme.spacing['32px'],
  alignSelf: 'flex-end',
}))

export const AlertPopUp: FunctionComponent<AlertPopUpProps> = ({
  headerText,
  onClose,
  secondaryButton,
  tertiaryButton,
  text,
  textSize = BodyTextSize.SMALL,
  style,
}) => {
  const { colors } = useTheme()

  const handleCloseModal = () => {
    onClose()
  }

  const { formatMessage } = useIntl()

  return (
    <AlertPopUpContainer testID={tID('AlertPopUp')} style={style}>
      <CloseIconContainer
        onPress={handleCloseModal}
        testID={tID('AlertPopUp-close')}
        accessibilityLabel={formatMessage({
          defaultMessage: 'Close Alert',
          description: 'This button closes the alert pop up',
        })}
      >
        <CloseIcon size={12} />
      </CloseIconContainer>
      <HeaderTextContainer>
        <HeaderText text={headerText} color={colors.borderWarning} />
      </HeaderTextContainer>
      <BodyText text={text} size={textSize} color={colors.borderWarning} />
      {secondaryButton || tertiaryButton ? (
        <ButtonsContainer>
          {tertiaryButton && (
            <TertiaryButton
              customTextColor={colors.borderWarning}
              fullWidth={true}
              onPress={tertiaryButton.onPress}
              testID={tertiaryButton.testID}
              text={tertiaryButton.text}
              style={{ backgroundColor: colors.backgroundWarning }}
              loading={tertiaryButton.loading}
            />
          )}
          {secondaryButton && (
            <SecondaryButton
              customTextColor={colors.borderWarning}
              fullWidth={true}
              onPress={secondaryButton.onPress}
              iconColor={colors.borderWarning}
              rightIcon={secondaryButton.icon}
              testID={secondaryButton.testID}
              text={secondaryButton.text}
              style={{ borderColor: colors.borderWarning, backgroundColor: colors.backgroundWarning }}
              loading={secondaryButton.loading}
            />
          )}
        </ButtonsContainer>
      ) : null}
    </AlertPopUpContainer>
  )
}
