import React from 'react'
import { connect, useSelector } from 'react-redux'
import { Outlet } from 'react-router'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components/native'

import { ThemeType } from '@lyrahealth-inc/ui-core-crossplatform'

import { SchedulingWalkthroughDA } from './SchedulingWalkthroughDA'
import { SchedulingWalkthroughNavBar } from './SchedulingWalkthroughNavBar'
import { SchedulingWalkthroughOUS } from './SchedulingWalkthroughOUS'
import { actions, mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { SCHEDULING } from '../../common/constants/routingConstants'
import { getAuthConfig } from '../../data/auth/authSelectors'
import { getSchedulingAuthUrl } from '../data/schedulingSelectors'

const BackgroundContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: theme.colors.backgroundSecondary,
}))

export type SchedulingWalkthroughProps = {
  schedulingAuthUrl: string
}

const SchedulingWalkthrough: React.FC<SchedulingWalkthroughProps> = ({ schedulingAuthUrl }) => {
  const navigate = useNavigate()
  const config = useSelector(getAuthConfig)

  const connectGoogleCalendar = () => {
    track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.SCHEDULING_WALKTHROUGH_GOOGLE_CONNECTION })
    // This is to allow us to mock the redirect to google, this will shortcut to the callback url route with mock code.
    if (schedulingAuthUrl.startsWith(SCHEDULING.route)) {
      navigate(schedulingAuthUrl)
    } else {
      window.open(schedulingAuthUrl, '_self')
    }
  }

  return (
    <BackgroundContainer>
      <SchedulingWalkthroughNavBar />
      {config?.internationalSmartSchedulingEnabled ? (
        <SchedulingWalkthroughOUS connectCalendar={connectGoogleCalendar} />
      ) : (
        <SchedulingWalkthroughDA connectCalendar={connectGoogleCalendar} />
      )}
      <Outlet />
    </BackgroundContainer>
  )
}

const mapStateToProps = ($$state: any) => {
  return {
    schedulingAuthUrl: getSchedulingAuthUrl($$state),
  }
}

export default connect(mapStateToProps, null)(SchedulingWalkthrough)
