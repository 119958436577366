import React, { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle } from 'react'
import { Field } from 'react-final-form'

import { cloneDeep, get, isEmpty, merge, orderBy } from 'lodash-es'

import { InputFieldRFF } from '@lyrahealth-inc/ui-core-crossplatform'
import { TypeAheadRFF } from '@lyrahealth-inc/ui-core-crossplatform/src/atoms/formElements/typeAhead/TypeAhead'

import styles from './configureMedsInfoSheet.module.scss'
const medNameValidation = (medName: any) =>
  !medName || medName.length === 0 ? 'You must select a medication for this Info Sheet' : undefined

const validateMedsFrequency = (instructions: any) => {
  if (instructions && instructions.includes('[frequency- MD to update]')) {
    return 'Please replace [frequency- MD to update] in instructions'
  } else {
    return undefined
  }
}

type ConfigureMedsInfoSheetProps = {
  formValues: any
  metadata: any
  name?: string
  initialize: (formValues: any) => void
  restart: (formValues: any) => void
  form: any
}

const ConfigureMedsInfoSheet: ForwardRefRenderFunction<ConfigureMedsInfoSheetRef, ConfigureMedsInfoSheetProps> = (
  { formValues, metadata, name = 'medsInfoSheetConfig', initialize, restart, form },
  ref,
) => {
  useImperativeHandle(ref, () => ({
    configure(values) {
      return configure(values)
    },
  }))

  // Subscribe to changes in the medication field so that the instructions field can be updated
  const unsubscribeFromMedicationField = form.subscribe(
    (formState: any) => {
      const { modified, values } = formState
      if (modified.medication && values.medication && replaceInstructions) {
        replaceInstructions(values.medication)
      }
    },
    { values: true, modified: true },
  )

  useEffect(() => {
    initialize(formValues)

    return () => {
      unsubscribeFromMedicationField()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const configure = (values: Dict) => {
    let selectedMedication = {}
    const metadataClone = cloneDeep(metadata)
    // Check the 'medsInfoSheetConfig' values to be sure the user selected a med
    // Check that the Typeahead returned an array of length 1, meaning the user has selected a single medication
    if (get(values, name) && get(values, [name, 'medication']).length === 1) {
      const infoSheetConfig: any = get(values, name)
      const selectedMedId = infoSheetConfig.medication[0].id

      // Get all the metadata for the selectedMedication by Id
      selectedMedication = cloneDeep(
        metadataClone.medicationOptions.find((medOption: any) => medOption.id === selectedMedId),
      )
      ;(selectedMedication as any).instructions = get(values, `${name}.instructions`)
    }

    metadataClone.selectedMedication = selectedMedication
    metadataClone.title = isEmpty(selectedMedication) ? '' : `${(selectedMedication as any).label} Information`
    return metadataClone
  }

  const replaceInstructions = (selectedMedName: string) => {
    const selectedMed = get(metadata, 'medicationOptions', []).find((med: any) => med.label === selectedMedName)

    let updatedInstructions = get(selectedMed, 'instructions') || metadata.defaultInstructions
    // Populate <<<Medication>>> label field in the instructions
    if (get(selectedMed, 'label')) {
      updatedInstructions = updatedInstructions.replace(/<<<Medication>>>/g, selectedMed.label)
    }
    // Populate <<<Medication Highlights>>> field in the instructions
    if (get(selectedMed, 'highlights')) {
      updatedInstructions = updatedInstructions.replace(/<<<Medication Highlights>>>/g, selectedMed.highlights)
    }

    restart(
      merge(formValues, {
        [name]: {
          instructions: updatedInstructions,
          medication: [selectedMed],
        },
        medication: selectedMedName,
      }),
    )
  }

  const defaultSelectedMedication = get(metadata, ['selectedMedication', 'label']) ? [metadata.selectedMedication] : []
  const medicationOptions = orderBy(
    get(metadata, 'medicationOptions', []),
    [(med: { label: string }) => med.label.toLowerCase()],
    'asc',
  ).map((options: { label: any }) => options.label)

  return (
    <div className={styles.container}>
      <Field
        name='medication'
        label='Medication'
        placeholder='Select a medication'
        component={TypeAheadRFF}
        defaultSelected={defaultSelectedMedication}
        validate={medNameValidation}
        options={medicationOptions}
        multiSelect={false}
        fixedDropdownMenuPosition
      />

      {get(formValues, `${name}.medication.length`) > 0 && (
        <Field
          name={`${name}.instructions`}
          label='Instructions'
          component={InputFieldRFF}
          validate={validateMedsFrequency}
          multiline
          numberOfLines={10}
        />
      )}
    </div>
  )
}

export type ConfigureMedsInfoSheetRef = {
  configure: (values: Dict) => any
}

export default forwardRef(ConfigureMedsInfoSheet)
