import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import styled from 'styled-components/native'

import { AlertPopUp, LeaveSiteIcon, tID } from '@lyrahealth-inc/ui-core-crossplatform'

export type CapacityAlertPopUpProps = {
  handleOpenCalendar: () => void
  handleLearnMore: () => void
  onClose: () => void
  alertText: string
}

const Alert = styled(AlertPopUp)(({ theme }) => ({
  bottom: theme.spacing['48px'],
  left: theme.spacing['32px'],
  position: 'fixed',
  zIndex: 9,
}))

export const CapacityAlertPopUp: FunctionComponent<CapacityAlertPopUpProps> = ({
  handleOpenCalendar,
  handleLearnMore,
  onClose,
  alertText,
}) => {
  return (
    <Alert
      headerText='Low availability'
      secondaryButton={{
        text: (
          <FormattedMessage
            defaultMessage='Open calendar'
            description='This button opens a new tab and takes the user to their calendar page'
          />
        ),
        onPress: handleOpenCalendar,
        icon: <></>,
        testID: tID('AlertPopUp-openCalendarButton'),
      }}
      tertiaryButton={{
        text: (
          <FormattedMessage
            defaultMessage='Learn more'
            description='This button is a call to action for the user to go to a different page to learn more about creating more calendar availabilities'
          />
        ),
        onPress: handleLearnMore,
        icon: <LeaveSiteIcon />,
        testID: tID('AlertPopUp-learnMoreButton'),
      }}
      onClose={onClose}
      text={alertText}
    />
  )
}
