import React, { FunctionComponent } from 'react'

import { useTheme } from 'styled-components/native'

import { CalendarAlert } from '@lyrahealth-inc/shared-app-logic'

import { AlertIconStroke, Headline, IconBadge, PressableOpacity } from '../../atoms'
import { Corners } from '../../atoms/iconBadge/IconBadge'
import { HeadlineSize } from '../../styles'
import { ThemeType } from '../../utils'

export type ProviderCalendarMetricBadgeProps = {
  alert: CalendarAlert
  onPress?: () => void
  testID?: string
}

export const ProviderCalendarMetricBadge: FunctionComponent<ProviderCalendarMetricBadgeProps> = ({
  alert,
  onPress,
  testID,
}) => {
  const theme = useTheme() as ThemeType
  return (
    <PressableOpacity onPress={onPress} testID={testID}>
      <IconBadge
        frontIcon={<AlertIconStroke size={16} fillColor={theme.colors.textError} />}
        text={<Headline text={alert.badge} size={HeadlineSize.EYEBROW} color={theme.colors.textWarning} />}
        color={theme.colors.backgroundWarning}
        cornerType={Corners.CURVED}
      />
    </PressableOpacity>
  )
}
