import React, { Component } from 'react'
import CSSModules from 'react-css-modules'
import { connect } from 'react-redux'

import { isNil } from 'lodash-es'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form/immutable'

import { BootstrapContainer, ContentLayout } from '@lyrahealth-inc/ui-core'

import styles from './clientEmail.module.scss'
import ThankYou from './ThankYou'
import ClientEmailForm from '../../common/components/forms/clientEmail/ClientEmailForm'
import clientEmailValidate from '../../common/components/forms/clientEmail/clientEmailValidate'
import { REQUEST_PAYMENT } from '../../common/constants/routingConstants'
import { getErrorText } from '../../common/utils/utils'
import * as alertActions from '../../data/alertActions'
import * as providersDataActions from '../../providers/data/providersDataActions'
import withRouter from '../../routing/withRouter'

type ClientEmailProps = {
  handleSubmit?: (...args: any[]) => any
  submitting?: boolean
  actions?: any
  router?: any
}

class ClientEmail extends Component<ClientEmailProps> {
  _submitForm = (values: any) => {
    this.props.actions.hideAlerts()
    const {
      actions: { submitClientEmail, submitClientEmailForHealthPlan },
    } = this.props
    const formData = new FormData()
    const vals = values.toJS()
    Object.keys(vals).forEach((key) => {
      formData.append(key, vals[key])
    })

    const emailPromises = [submitClientEmail(this.props.router.location.state.patient_id, formData)]
    if (this.props.router.location.state.isHealthPlanEligible) {
      formData.append('company', this.props.router.location.state.company)
      emailPromises.push(submitClientEmailForHealthPlan(this.props.router.location.state.patient_id, formData))
    }
    return Promise.all(emailPromises).then(
      () => {
        this._continue(true)
      },
      (error) => {
        const errorText = error.response.data.message || getErrorText(error.response.status)
        this.props.actions.addAlert({
          show: true,
          contents: errorText,
          style: 'danger',
          expires: true,
        })
      },
    )
  }

  _continue = (submitted: any) => {
    if (submitted) {
      this.props.actions.addAlert({
        show: true,
        contents: "Thank you! Your client's email was successfully submitted",
        style: 'success',
        expires: false,
        autoDismissTimer: 4000,
      })
    }
    this.props.router.navigate(REQUEST_PAYMENT.route, { state: { fromSubmissionIntermediate: true } })
  }

  render() {
    const { handleSubmit, submitting, router } = this.props

    if (isNil(router.location.state)) {
      return router.navigate(REQUEST_PAYMENT.route)
    }
    return (
      <ContentLayout>
        <BootstrapContainer>
          <div styleName='card'>
            <ClientEmailForm
              thankyou={
                <ThankYou
                  initialSession={router.location.state.initialSession}
                  sessionsLeft={router.location.state.sessionsLeft}
                  firstName={router.location.state.firstName}
                  lastName={router.location.state.lastName}
                  customer={router.location.state.customer}
                />
              }
              handleSubmit={handleSubmit}
              submitFunction={this._submitForm}
              submitting={submitting}
              continueFunction={this._continue}
              firstName={router.location.state.firstName}
              lastName={router.location.state.lastName}
            />
          </div>
        </BootstrapContainer>
      </ContentLayout>
    )
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators({ ...providersDataActions, ...alertActions }, dispatch),
  }
}

export default withRouter(
  connect(
    null,
    mapDispatchToProps,
  )(
    reduxForm({
      form: 'clientEmailForm',
      validate: clientEmailValidate,
      touchOnBlur: false,
      // @ts-expect-error TS(2345): Argument of type 'typeof ClientEmail' is not assig... Remove this comment to see the full error message
    })(CSSModules(ClientEmail, styles)),
  ),
)
