import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { isEmpty, sumBy } from 'lodash-es'

import { ClientListClientObject, ClientObject, useFlags } from '@lyrahealth-inc/shared-app-logic'
import { Dashlet, Label, RefreshButton } from '@lyrahealth-inc/ui-core'
import { toJS, useFetcher } from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './activitiesDashlet.module.scss'
import { CLIENT_HOME } from '../../../common/constants/routingConstants'
import { getClientFullName } from '../../../common/utils/utils'
import { getClientsDataCounts } from '../../../data/lyraTherapy/clientSelectors'
import { RootState } from '../../../data/store'
import { selectLtClient } from '../../clients/clientDetails/data/ltClientDetailsAutoActions'
import { getClientsCounts, getLtClientsV2 } from '../../clients/data/ltClientsAutoActions'

const ActivitiesDashlet: React.FC<ActivitiesDashletProps> = ({
  clients,
  clientsCounts,
  activeClientsFetched,
  providerId,
  getClientsCounts,
  getLtClientsV2,
  selectLtClient,
}) => {
  const navigate = useNavigate()
  const countsEndPointExcludedContentGroups = ['lesson', 'infosheet']
  const unreadActivitiesCount = !isEmpty(clientsCounts)
    ? sumBy(Object.values(clientsCounts).flat(), (count: Count) => count.unread_responses)
    : 0

  const [isRefreshingClients, setIsRefreshingClients] = useState(false)

  const [isLoadingCounts, , hasFetchedCounts] = useFetcher(
    [getClientsCounts, { provider_ids: [providerId], exclude_content_groups: countsEndPointExcludedContentGroups }],
    [providerId],
  )

  const { isPreferredNameEnabled } = useFlags()

  const renderClientListItem = (client: ClientListClientObject) => {
    const unreadAssignmentsCount = clientsCounts[client.id][0].unread_responses
    // PROVIDER-2435: we are temporarily disabling the overdue flag on this dashlet
    // const assignmentsOverdue = clientsCounts[client.id][0].overdue_responses > 0

    const selectClient = () => {
      selectLtClient(client)
      navigate(CLIENT_HOME.route)
    }

    return (
      <div
        className={styles['dashlet-item-container']}
        data-test-id={`ActivitiesDashlet-activities-item-${client.first_name}`}
        key={client.id}
      >
        <button className={styles['dashlet-item-content']} onClick={selectClient}>
          <div>
            <div className={styles.clientName}>{getClientFullName(client, isPreferredNameEnabled)}</div>
          </div>
          <div className={styles.right}>
            {/* {assignmentsOverdue && <Label textColor={styles.x_red6} backgroundColor={styles.x_red1} text='overdue' />} */}
            <Label
              textColor={styles.x_white}
              backgroundColor={styles.x_alert}
              borderRadius='6px'
              padding='0 7px'
              text={unreadAssignmentsCount}
            />
          </div>
        </button>
      </div>
    )
  }

  return (
    <div className={styles['dashlet-container']} data-test-id='ActivitiesDashlet-container'>
      <Dashlet
        title={`Unreviewed activities${
          !activeClientsFetched || !hasFetchedCounts ? '' : ` (${unreadActivitiesCount})`
        }`}
        buttons={
          <RefreshButton
            size={'20'}
            isLoading={isRefreshingClients}
            data-test-id='ActivitiesDashlet-refreshButton'
            onClick={() => {
              setIsRefreshingClients(true)
              Promise.all([
                getLtClientsV2({ providerId, status: 'active' }),
                getLtClientsV2({ providerId, status: 'inactive' }),
                getClientsCounts({
                  provider_ids: [providerId],
                  exclude_content_groups: countsEndPointExcludedContentGroups,
                }),
              ]).then(() => {
                setIsRefreshingClients(false)
              })
            }}
          />
        }
        isLoading={!activeClientsFetched || isLoadingCounts || (!hasFetchedCounts && isEmpty(clientsCounts))}
      >
        {unreadActivitiesCount === 0 ? (
          <div className={styles['empty-dashlet']}>No unreviewed activities</div>
        ) : Array.isArray(clients) ? (
          clients
            ?.filter((client: any) => client.id in clientsCounts && clientsCounts[client.id][0].unread_responses > 0)
            ?.map((client: any) => renderClientListItem(client))
        ) : (
          <></>
        )}
      </Dashlet>
    </div>
  )
}

type ActivitiesDashletProps = {
  clients: ClientObject[]
  clientsCounts: {
    [key: string]: Count[]
  }
  activeClientsFetched: boolean
  providerId: string
  getClientsCounts: ({
    provider_ids: [providerId],
  }: {
    provider_ids: [string]
    exclude_content_groups: Array<string>
  }) => void
  getLtClientsV2: ({ providerId, status }: { providerId: string; status: string }) => Promise<object>
  selectLtClient: (client: ClientListClientObject) => void
}

type Count = {
  overdue_responses: number
  patient_id: string
  provider_id: string
  unread_messages: number
  unread_responses: number
}

const mapStateToProps = (state: RootState) => ({
  clientsCounts: getClientsDataCounts(state),
})

// @ts-expect-error TS(2345): Argument of type '(wrappedComponentProps: Activiti... Remove this comment to see the full error message
export default connect(mapStateToProps, { selectLtClient, getLtClientsV2, getClientsCounts })(toJS(ActivitiesDashlet))
