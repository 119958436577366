import React, { FunctionComponent, useState } from 'react'
import { connect, ConnectedProps, useSelector } from 'react-redux'

import { cloneDeep, noop } from 'lodash-es'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'

import { Assignment, AssignmentStatuses, EpisodeContents, ExerciseDraftPayload } from '@lyrahealth-inc/shared-app-logic'
import {
  ACTIVITY_EXIT_MODAL_CONTENT,
  ActivityEntry,
  ActivityExitModalContent,
  Modal,
} from '@lyrahealth-inc/ui-core-crossplatform'

import {
  clearAssignmentResponse,
  setAssignment,
  setAssignmentDraft,
  setAssignmentDraftResponse,
  setAssignmentDraftResponseToAssignment,
  setAssignmentResponse,
  updateAssignmentResponse,
} from './data/assignmentsAutoActions'
import { getAuthUser } from '../../data/auth/authSelectors'
import {
  getClientCurrentSessionCount,
  getClientDetailsData,
  getClientSelectedEpisodeId,
  getSelectedEpisodeCurriculum,
} from '../../data/lyraTherapy/clientSelectors'
import { RootState } from '../../data/store'
import { getLTVideoSessionOpen } from '../data/ltVideoSelectors'
import { updateCurriculum } from '../episodes/data/episodesAutoActions'

type ProviderExerciseDraftProps = ConnectedProps<typeof connector> & {
  actions: any
  assignmentId?: string
  content: any
  contentId?: string
  editMode?: boolean
  instructions: string
  name?: string
  onExit: () => void
  response?: Dict
  responseId?: string
  sessionCount?: number
  title?: string
  videoSessionOpen: boolean
}

const ProviderExerciseDraft: FunctionComponent<ProviderExerciseDraftProps> = ({
  actions: {
    clearAssignmentResponse,
    setAssignment,
    setAssignmentDraft,
    setAssignmentDraftResponse,
    setAssignmentDraftResponseToAssignment,
    setAssignmentResponse,
    updateAssignmentResponse,
    updateCurriculum,
  },
  assignmentId,
  clientDetails,
  content,
  contentId,
  currentSessionCount,
  curriculum,
  editMode,
  episodeId,
  instructions,
  name,
  onExit,
  response,
  responseId,
  sessionCount = 0,
  title,
  videoSessionOpen,
}) => {
  const user = useSelector(getAuthUser)
  const [exitModalOpen, setExitModalOpen] = useState<boolean>(false)
  const [exitModalContent, setExitModalContent] = useState<React.ReactElement | null>(null)

  const saveExerciseDraft = (formValues: Dict) => {
    // It's possible to be in edit mode without a Provider draft so we want to set a new response if that is the case
    const isNewDraft = !response
    const data = {
      assignment_id: assignmentId || null,
      id: responseId || null,
      provider_started: true,
      response: formValues,
      status: AssignmentStatuses.provider_draft,
    }

    if (editMode && assignmentId) {
      if (!isNewDraft) {
        updateAssignmentResponse(data)
      } else {
        // Create a new draft when in 'edit' mode eg. The entry point was from clicking edit in the
        // dropdown for the assignment
        setAssignmentResponse(data)
      }
      setAssignmentDraftResponse(data)
    } else if (!editMode && assignmentId) {
      // Exercise belongs to a Session so create the new draft
      setAssignmentResponse(data)
    } else {
      // Draft is for an Exercise that has not been added to a Session yet
      addDraftToAssignedExercise(data)
    }
    onExit()
  }

  const addDraftToAssignedExercise = (draft: ExerciseDraftPayload) => {
    let providerDraftResponse: ExerciseDraftPayload

    return setAssignmentDraft(draft).then((draftResponse: ExerciseDraftPayload) => {
      // Store the draft response in a local var in case we need to pass it to setAssignmentDraftResponseToAssignment()
      providerDraftResponse = draftResponse

      // Put this response in state so that we can add it to the Assignment if the
      // assignment is saved to the current Session
      setAssignmentDraftResponse(draftResponse)

      // If the draft is for an Exercise in a future Session and we entered via 'Start a draft',
      // the Exercise already exists in the Curriculum, but there is no Assignment entity.
      // If the entrypoint for creating the draft is either 'Edit' or '+Add activity' we bypass this step because the
      // Assignment entity is created and the draft is attached after the user clicks on the
      // Add Activity / Update Activity button in the ConfigAssignmentContainer
      if (sessionCount > currentSessionCount && !editMode) {
        const data = {
          provider_id: user?.id,
          patient_id: clientDetails?.id,
          content_id: contentId,
          content_meta_data: content,
          instructions: instructions,
          session_period: sessionCount,
        }

        return setAssignment(data).then((assignment: Assignment) => {
          setAssignmentDraftResponseToAssignment(
            Object.assign(cloneDeep(providerDraftResponse), {
              assignment_id: assignment.id,
            }),
          )
            .then(() => {
              const curriculumCopy = cloneDeep(curriculum)
              const sessionToUpdate = curriculumCopy.findIndex(
                (sessionObject) => sessionObject.session === sessionCount,
              )

              // Update the Curriculum with the Exercise id as the Exercise is now an Assignment entity
              const contentIndex = curriculumCopy[sessionToUpdate].contents.findIndex(
                (content: EpisodeContents) => content.name === name,
              )
              curriculumCopy[sessionToUpdate].contents[contentIndex] = {
                name: name as string,
                title: title as string,
                group: 'exercise',
                content_type: 'form',
                instructions: instructions,
                content_meta_data: content,
                id: assignment.id,
              }

              return updateCurriculum({ id: episodeId, curriculum: curriculumCopy })
            })
            .then(() => {
              clearAssignmentResponse()
              onExit()
            })
        })
      } else {
        return
      }
    })
  }

  const onDraftExit = (formValues: Dict) => {
    setExitModalOpen(true)
    setExitModalContent(
      <ActivityExitModalContent
        exitModalContentType={ACTIVITY_EXIT_MODAL_CONTENT.CLOSE_NEW_PROVIDER_STARTED_DRAFT}
        onPrimaryButtonPress={() => {
          saveExerciseDraft(formValues.values as Dict)
        }}
        onRequestClose={() => {
          onExit()
        }}
        onTertiaryButtonPress={() => {
          onExit()
        }}
      />,
    )
  }

  return (
    <>
      <ActivityEntry
        contentMetadata={content}
        deleteDraft={noop}
        disableAutoSave
        exit={(values) => onDraftExit(values as Dict)}
        focused={false}
        instructions={instructions}
        name={name}
        saveForm={({ values }) => {
          saveExerciseDraft(values as Dict)
        }}
        showAllPages
        title={title}
        withPageBreaks={false}
        isProviderPreview={false}
        isProviderDraft
        videoSessionOpen={videoSessionOpen}
        response={response}
        disableIsRequiredFormValidation
      />

      <Modal
        modalContents={exitModalContent}
        onRequestClose={() => setExitModalOpen(false)}
        onCloseEnd={() => {}}
        visible={exitModalOpen}
      />
    </>
  )
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    actions: bindActionCreators(
      {
        clearAssignmentResponse,
        setAssignment,
        setAssignmentDraft,
        setAssignmentDraftResponse,
        setAssignmentDraftResponseToAssignment,
        setAssignmentResponse,
        updateAssignmentResponse,
        updateCurriculum,
      },
      dispatch,
    ),
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    clientDetails: getClientDetailsData(state),
    currentSessionCount: getClientCurrentSessionCount(state) || 0,
    curriculum: getSelectedEpisodeCurriculum(state) || [],
    episodeId: getClientSelectedEpisodeId(state) || '',
    videoSessionOpen: getLTVideoSessionOpen(state),
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(ProviderExerciseDraft)
