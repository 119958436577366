import React, { useEffect, useMemo } from 'react'
import { connect, useSelector } from 'react-redux'

import * as Sentry from '@sentry/react'
import { LDContext, LDMultiKindContext } from 'launchdarkly-js-client-sdk'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'

import { launchDarklyUserDeviceUUID, LD_SHARED_CONTEXT_KEYS } from '@lyrahealth-inc/shared-app-logic'
import { useLDClient } from '@lyrahealth-inc/shared-app-logic/src/features/flags/launchdarkly-client-sdk.web'
import { toJS, useFetcher } from '@lyrahealth-inc/ui-core-crossplatform'

import {
  getAuthConfig,
  getAuthIsLoggedIn,
  getAuthRoles,
  getAuthSupervisorRoles,
  getAuthUserId,
} from './data/auth/authSelectors'
import { getClientDataCountry } from './data/lyraTherapy/clientSelectors'
import { getRequestPaymentCustomersByCountry } from './data/requestPayment/requestPaymentSelectors'
import {
  getLtClientsV2,
  setInitialActiveClientsFetched,
  setInitialInActiveClientsFetched,
} from './lyraTherapy/clients/data/ltClientsAutoActions'
import { getProviderCapacity } from './providers/data/providersDataActions'
import { getCustomers, getCustomersByCountry } from './requestPayment/data/requestPaymentActions'

export const AppContainer: React.FC<AppContainerProps> = ({
  children,
  actions: {
    getCustomers,
    getLtClientsV2,
    getProviderCapacity,
    setInitialActiveClientsFetched,
    setInitialInActiveClientsFetched,
    getCustomersByCountry,
  },
}) => {
  const isLoggedIn: boolean = useSelector(getAuthIsLoggedIn)
  const userId = useSelector(getAuthUserId)
  const launchDarklyUserId = !userId ? LD_SHARED_CONTEXT_KEYS.LAUNCH_DARKLY_USER_UUID : userId

  const config = useSelector(getAuthConfig)
  const roles = useSelector(getAuthRoles)
  const supervisorRoles = useSelector(getAuthSupervisorRoles)
  const country = useSelector(getClientDataCountry)
  const customersByCountry = useSelector(getRequestPaymentCustomersByCountry)

  const deviceContext: LDContext = useMemo(
    () => ({
      kind: 'device',
      /**
       * This device context is anonymous
       * The key is omitted, and the SDK will automatically generate one
       */
      ...(launchDarklyUserDeviceUUID && { key: launchDarklyUserDeviceUUID }),
    }),
    [],
  )
  const userContext: LDContext = useMemo(
    () => ({
      key: launchDarklyUserId,
      kind: 'user',
      roles,
      supervisorRoles,
    }),
    [roles, supervisorRoles, launchDarklyUserId],
  )

  useFetcher(
    [
      [getCustomers, {}, isLoggedIn],
      [getProviderCapacity, { id: userId }, !!config?.bcPlatform],
    ],
    [isLoggedIn, userId],
  )
  useFetcher(
    [[getCustomersByCountry, { country }, isLoggedIn && country && !(country in customersByCountry)]],
    [isLoggedIn, country, customersByCountry],
  )
  useFetcher([[getLtClientsV2, { providerId: userId, status: 'active' }, !!config?.bcPlatform]], [userId], {
    onFinally: () => {
      setInitialActiveClientsFetched()
    },
  })
  useFetcher([[getLtClientsV2, { providerId: userId, status: 'inactive' }, !!config?.bcPlatform]], [userId], {
    onFinally: () => {
      setInitialInActiveClientsFetched()
    },
  })

  useEffect(() => {
    if (userId) {
      Sentry.setUser({ id: userId })
    }
  }, [userId])

  const client = useLDClient()
  useEffect(() => {
    if (!client) {
      return
    }
    const multiContext: LDMultiKindContext = {
      kind: 'multi',
      user: userContext,
      device: deviceContext,
    }
    console.debug(
      `Vendor - Context flags available for user: ${userContext.key}, device: ${launchDarklyUserDeviceUUID}`,
    )

    client.identify(multiContext)
  }, [roles, supervisorRoles, userId, client, userContext, deviceContext])
  return <>{children}</>
}

type AppContainerProps = {
  actions: any
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators(
    {
      getCustomers,
      getLtClientsV2,
      getProviderCapacity,
      setInitialActiveClientsFetched,
      setInitialInActiveClientsFetched,
      getCustomersByCountry,
    },
    dispatch,
  ),
})

export default connect(null, mapDispatchToProps)(toJS(AppContainer))
