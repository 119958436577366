import { createSelector } from '@reduxjs/toolkit'

import { getLyraTherapyState } from '../../data/lyraTherapy/clientSelectors'

export const getLTVideo = createSelector(getLyraTherapyState, (lyraTherapyState) => lyraTherapyState?.video)

export const getLTVideoSessionOpen = createSelector(getLTVideo, (videoState) => videoState?.sessionOpen)

export const getLTVideoAppointments = createSelector(getLTVideo, (videoState) => videoState?.appointments)

export const getLTVideoSettings = createSelector(getLTVideo, (videoState) => videoState?.settings)

export const getLTVideoSessionInfo = createSelector(getLTVideo, (videoState) => videoState?.sessionInfo)
