import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../../data/store'

export const getPaymentHistoryState = (state: RootState) => state?.paymentHistory

export const getPaymentHistoryShowModal = createSelector(
  getPaymentHistoryState,
  (paymentHistoryState) => paymentHistoryState?.showModal,
)

export const getPaymentHistoryModalData = createSelector(
  getPaymentHistoryState,
  (paymentHistoryState) => paymentHistoryState?.modalData,
)

export const getPaymentHistoryHistoryData = createSelector(
  getPaymentHistoryState,
  (paymentHistoryState) => paymentHistoryState?.historyData,
)
