import { ClientObject } from '@lyrahealth-inc/shared-app-logic'

import { sessionTracking } from '../constants/appConstants'

type SessionLimitWarningArgs = {
  client: Partial<ClientObject>
  isProgramLevelSessionLimitEnabled?: boolean
}

// @ts-expect-error TS(7030): Not all code paths return a value.
const sessionLimitWarning = ({
  client: {
    open_bhb_session_count: openBhbSessionCount,
    open_bhb_session_limit: openBhbSessionLimit,
    sessions_remaining: sessionsRemaining,
    sessions_limit: sessionsLimit,
    sessions_limit_by_program: sessionsLimitByProgram,
    sessions_used_by_program: sessionsUsedByProgram,
  },
  isProgramLevelSessionLimitEnabled = false,
}: SessionLimitWarningArgs) => {
  const warningThreshold = sessionTracking.WARNING_THRESHOLD
  if (
    isProgramLevelSessionLimitEnabled &&
    sessionsLimitByProgram &&
    sessionsLimitByProgram.therapy &&
    sessionsUsedByProgram
  ) {
    sessionsRemaining = sessionsLimitByProgram.therapy - (sessionsUsedByProgram.therapy ?? 0)
  }
  const showEAPWarning =
    typeof sessionsLimit === 'number' &&
    sessionsLimit > 0 &&
    typeof sessionsRemaining === 'number' &&
    sessionsRemaining <= warningThreshold
  const showHPIWarning =
    openBhbSessionCount &&
    openBhbSessionLimit &&
    openBhbSessionCount > 0 &&
    openBhbSessionLimit > 0 &&
    openBhbSessionLimit - openBhbSessionCount <= warningThreshold

  if (showEAPWarning || showHPIWarning) {
    return `Less than ${warningThreshold} sessions remaining.`
  }
}

export default sessionLimitWarning
