import React, { useCallback } from 'react'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { isWithinInterval } from 'date-fns'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import styled from 'styled-components'

import { historicUtilization, targetUtilization, utilizationQuarters } from '@lyrahealth-inc/shared-app-logic'
import {
  CaseloadManagementDetailedWeeklyView,
  LoadingIndicator,
  useFetcher,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { actions as mixpanelActions, mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { employmentTypes, ROLES } from '../../common/constants/appConstants'
import { BC_PERFORMANCE } from '../../common/constants/routingConstants'
import { getYearMonthDayLocaleByDate, hasRole } from '../../common/utils/utils'
import { getAuthEmploymentStatus, getAuthRoles, getAuthUserId } from '../../data/auth/authSelectors'
import {
  getCurrentQuarterCMDDetailedWeeklyView,
  getProviderHistoricUtilization,
  getProviderTargetUtilization,
  getProviderUtilizationChargesQuarter,
  getRequestedCaseloadManagementQuarter,
} from '../../data/lyraTherapy/clientSelectors'
import {
  getHistoricCharges,
  getHistoricUtilization,
  getUtilizationCharges,
} from '../clients/clientDetails/data/appointmentsAutoActions'
const Container = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

type CaseloadManagementDetailedWeeklyViewContainerProps = ConnectedProps<typeof connector>

const CaseloadManagementDetailedWeeklyViewContainer: React.FunctionComponent<
  CaseloadManagementDetailedWeeklyViewContainerProps
> = ({ actions: { getHistoricUtilization, getHistoricCharges } }) => {
  const employmentStatus = useSelector(getAuthEmploymentStatus)
  const userId = useSelector(getAuthUserId)
  const userRoles: string[] = useSelector(getAuthRoles)
  const historicUtilization: historicUtilization | undefined = useSelector(getProviderHistoricUtilization)
  const historicChargesQuarter = useSelector(getProviderUtilizationChargesQuarter)
  const requestedQuarter: utilizationQuarters | undefined = useSelector(getRequestedCaseloadManagementQuarter)
  const currentQuarter: string | undefined = useSelector(getCurrentQuarterCMDDetailedWeeklyView)
  const targetUtilization: targetUtilization | undefined = useSelector(getProviderTargetUtilization)
  const navigate = useNavigate()

  const onBackPressed = useCallback(() => {
    track({
      event: mixpanelEvents.BUTTON_PRESS,
      action: mixpanelActions.BACK,
    })
    navigate(BC_PERFORMANCE.route)
  }, [navigate])

  const requestedQuarterName = requestedQuarter ? Object.keys(requestedQuarter)[0] : ''
  const todayDate = new Date()
  const targetDate =
    !requestedQuarter ||
    isWithinInterval(todayDate, {
      start: new Date(`${requestedQuarter[requestedQuarterName][0]}T00:00`),
      end: new Date(`${requestedQuarter[requestedQuarterName][1]}T00:00`),
    })
      ? todayDate
      : new Date(`${requestedQuarter[requestedQuarterName][1]}T00:00`)
  const [loadingHistoricUtilization, , fetchedHistoricUtilization] = useFetcher(
    [
      [getHistoricUtilization, { id: userId, date: getYearMonthDayLocaleByDate(targetDate) }],
      [
        getHistoricCharges,
        { id: userId, date: getYearMonthDayLocaleByDate(targetDate), sessionType: 'lyra_therapy_teens_parent_session' },
      ],
    ],
    [userId],
  )

  const displayPTO =
    !hasRole(userRoles, [ROLES.LT_COACH, ROLES.LT_SS_COACH]) ||
    (!!employmentStatus && [employmentTypes.FULL_TIME, employmentTypes.PART_TIME].includes(employmentStatus))

  const displayTarget = hasRole(userRoles, [ROLES.LT_COACH, ROLES.LT_SS_COACH])
  return (
    <Container>
      {loadingHistoricUtilization || !fetchedHistoricUtilization || !historicUtilization ? (
        <LoadingIndicator size={45} />
      ) : (
        <CaseloadManagementDetailedWeeklyView
          historicUtilization={historicUtilization}
          targetUtilization={targetUtilization}
          onBackPressed={onBackPressed}
          displayPTO={displayPTO}
          historicQuarter={requestedQuarterName}
          currentQuarter={currentQuarter}
          displayTarget={displayTarget}
          isTeensTherapist={hasRole(userRoles, [ROLES.LT_TEENS_THERAPIST, ROLES.LT_TEENS_THERAPIST_SUPERVISOR])}
          historicCharges={historicChargesQuarter}
        />
      )}
    </Container>
  )
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators({ getHistoricCharges, getHistoricUtilization, getUtilizationCharges }, dispatch),
})
const connector = connect(null, mapDispatchToProps)
export default connector(CaseloadManagementDetailedWeeklyViewContainer)
