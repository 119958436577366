import React, { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'

import { isEmpty } from 'lodash-es'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'

import { LoadingIndicator } from '@lyrahealth-inc/ui-core'

import styles from './downloadPaymentHistory.module.scss'
import DownloadPaymentHistoryCard from './downloadPaymentHistoryCard/downloadPaymentHistoryCard'
import { hasErrorForAction } from '../../../common/utils/utils'
import { getAlertsState } from '../../../data/alertsSelectors'
import { Alert } from '../../../data/alertTypes'
import { getAuthUserId } from '../../../data/auth/authSelectors'
import { RootState } from '../../../data/store'
import { getProviderPaymentHistoryDownload } from '../../../providers/data/providerSelectors'
import { getProviderCSV } from '../../../providers/individualProvider/data/providerDetailsActions'
import { GET_PROVIDER_PAYMENT_HISTORY_CSV } from '../../constants/reduxConstants'

export const DownloadPaymentHistory: React.FC<DownloadPaymentHistoryProps> = ({
  providerCSV,
  actions: { getProviderCSV },
}) => {
  const providerID = useSelector(getAuthUserId)
  const alerts: Alert[] = useSelector(getAlertsState)
  useEffect(() => {
    if (!providerID) {
      return
    }
    getProviderCSV(providerID)
  }, [getProviderCSV, providerID])

  const renderDownloadCard = () => {
    if (isEmpty(providerCSV.data)) {
      return <div>No CSV found.</div>
    } else {
      return <DownloadPaymentHistoryCard paymentHistoryFile={providerCSV} />
    }
  }

  return (
    <div className={styles['page-container']}>
      <h1 className={styles.title}>Downloads</h1>
      {!providerCSV && !hasErrorForAction(alerts, GET_PROVIDER_PAYMENT_HISTORY_CSV) ? (
        <LoadingIndicator size={45} />
      ) : (
        renderDownloadCard()
      )}
    </div>
  )
}

type DownloadPaymentHistoryProps = {
  providerCSV: PaymentHistoryFile
  actions: {
    getProviderCSV: (providerLyraId: string) => Dispatch<AnyAction>
  }
}

type PaymentHistoryFile = {
  file_name: string
  data: string
}

const mapStateToProps = (state: RootState) => {
  return {
    providerCSV: getProviderPaymentHistoryDownload(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    actions: bindActionCreators({ getProviderCSV }, dispatch),
  }
}

// @ts-expect-error TS(2345): Argument of type 'FC<DownloadPaymentHistoryProps>'... Remove this comment to see the full error message
export default connect(mapStateToProps, mapDispatchToProps)(DownloadPaymentHistory)
