import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../../../../data/store'

const getPlatformEngagementState = (state: RootState) => state?.platformEngagement

export const getCurrentPlatformEngagementMetrics = createSelector(getPlatformEngagementState, (state) => state?.current)
export const getPreviousPlatformEngagementMetrics = createSelector(
  getPlatformEngagementState,
  (state) => state?.previous,
)
