import React from 'react'
import { useSelector } from 'react-redux'

import { ROLES } from '../common/constants/appConstants'
import { hasRole } from '../common/utils/utils'
import { getAuthRoles } from '../data/auth/authSelectors'
import RequestPayment from '../requestPayment/RequestPayment'
import RequestPaymentNew from '../requestPayment/RequestPaymentAdmins'

export const RequestPaymentRoutes: React.FC = () => {
  const roles: string[] = useSelector(getAuthRoles)
  if (hasRole(roles, [ROLES.PRACTICES_ADMIN])) {
    // @ts-expect-error TS(2740): Type '{}' is missing the following properties from... Remove this comment to see the full error message
    return <RequestPaymentNew />
  }

  // @ts-expect-error TS(2740): Type '{}' is missing the following properties from... Remove this comment to see the full error message
  return <RequestPayment />
}

export default RequestPaymentRoutes
