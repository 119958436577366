import React, { FunctionComponent } from 'react'
import { View } from 'react-native'

import LottieView from 'lottie-react-native'
import styled from 'styled-components/native'

import calendarConfetti from '../../assets/lottie/calendar_confetti.json'
import { tID } from '../../utils'
import { ProviderCalendarCheckmarkIllustration } from '../illustrations'

const Container = styled.View({
  position: 'relative',
})
export interface CalendarCheckmarkLottieProps {
  width?: number
}

const ASPECT_RATIO = 160 / 164
const CONFETTI_RATIO = 4
export const CalendarCheckmarkLottie: FunctionComponent<CalendarCheckmarkLottieProps> = ({ width = 164 }) => {
  const height = width * ASPECT_RATIO
  const confettiSize = width * CONFETTI_RATIO
  const leftOffset = confettiSize / 2 - width / 2
  const topOffset = confettiSize / 2 - height / 2
  return (
    <Container testID={tID('CalendarCheckmarkLottie')}>
      <View style={{ position: 'absolute', zIndex: 1, alignItems: 'center', left: -leftOffset, top: -topOffset }}>
        <LottieView autoPlay source={calendarConfetti} style={{ width: confettiSize }} />
      </View>
      <ProviderCalendarCheckmarkIllustration width={width} height={height} />
    </Container>
  )
}
