import axios from 'axios'
import { isNil } from 'lodash-es'
import { AnyAction, Dispatch } from 'redux'

import { CALENDAR_SERVICE_BASE_URL } from '@lyrahealth-inc/shared-app-logic'

import {
  CANCEL_APPOINTMENT,
  CLEAR_LT_APPOINTMENTS,
  CLEAR_SELECTED_APPOINTMENT,
  CREATE_APPOINTMENT,
  CREATE_REPEATING_APPOINTMENT,
  GET_APPOINTMENT_UTILIZATION,
  GET_HISTORIC_UTILIZATION,
  GET_LT_APPOINTMENTS,
  GET_LT_APPOINTMENTS_FOR_PATIENT,
  GET_PROVIDER_AVAILABILITY_V2,
  GET_TARGET_UTILIZATION,
  GET_UTILIZATION_CHARGES,
  GET_UTILIZATION_CHARGES_QUARTER,
  GET_UTILIZATION_QUARTERS,
  SET_APPOINTMENT,
  SET_CURRENT_CASELOAD_MANAGEMENT_DASHBOARD_QUARTER,
  SET_CURRENT_CMD_DETAILED_WEEKLY_VIEW_QUARTER,
  SET_CURRENT_SESSION_COUNT,
  UPDATE_LT_APPOINTMENT,
} from '../../../../common/constants/reduxConstants'
import store from '../../../../data/store'

export const getLTAppointments = ({ id, episodes, status, type, timing, offset, cancelToken }: any) => ({
  action: GET_LT_APPOINTMENTS,
  request: {
    method: 'get',
    url: `/v1/${id}/appointments`,
    params: { episodes, status, type, timing, offset },
    cancelToken,
  },
})

export const getLTAppointmentsForPatient = ({
  provider_id,
  patient_id,
  episodes,
  status,
  type,
  timing,
  offset,
  cancelToken,
}: any) => ({
  action: GET_LT_APPOINTMENTS_FOR_PATIENT,
  request: {
    method: 'get',
    url: `/v1/${provider_id}/${patient_id}/appointments`,
    params: { episodes, status, type, timing, offset },
    cancelToken,
  },
})

export const updateAppointment = ({ providerID, ...data }: any) => ({
  action: UPDATE_LT_APPOINTMENT,
  request: {
    method: 'PUT',
    url: `/v1/${providerID}/appointments`,
    data,
  },
})

type AvailabilityV2Request = {
  providerId: string
  params: {
    slice_duration_minutes: number
    start_datetime: string
    end_datetime: string
    daily_start_time: string
    daily_end_time: string
    clientele: string
    treatment: string
    offering: string
    partner: string
    appointment_class: 'initial' | 'recurring'
    lead_time_hours: string
    appointment_duration_minutes?: number
  }
}

export const getProviderAvailabilityV2 =
  ({ providerId, params }: AvailabilityV2Request) =>
  async (dispatch: Dispatch<AnyAction>) => {
    const { data: availability } = await axios.post(
      `${CALENDAR_SERVICE_BASE_URL}/api/v1/provider/availability`,
      { provider_ids: [providerId] },
      {
        headers: { Authorization: `Bearer ${store.getState().auth?.apiToken}` },
        params,
      },
    )

    return dispatch({
      type: GET_PROVIDER_AVAILABILITY_V2,
      data: availability,
    })
  }

export const createAppointment = ({ providerID, ...data }: any) => ({
  action: CREATE_APPOINTMENT,
  request: {
    method: 'POST',
    url: `/v1/${providerID}/appointments`,
    data,
  },
})

export const createRepeatingAppointment = ({ providerID, ...data }: any) => ({
  action: CREATE_REPEATING_APPOINTMENT,
  request: {
    method: 'POST',
    url: `/v1/${providerID}/appointments-recurring`,
    data,
  },
})

export const cancelAppointment = ({ providerID, appointmentID, changeReason }: any) => ({
  action: CANCEL_APPOINTMENT,
  request: {
    method: 'DELETE',
    url: `/v1/${providerID}/appointments/${appointmentID}`,
    params: { changeReason },
  },
})

export const clearSelectedAppointment = () => ({
  type: CLEAR_SELECTED_APPOINTMENT,
})

export const setAppointment = (data: any) => ({
  type: SET_APPOINTMENT,
  data,
})

export const clearLTAppointments = () => ({
  type: CLEAR_LT_APPOINTMENTS,
})

export const setCurrentSessionCount = ({ episodeId }: any) => ({ type: SET_CURRENT_SESSION_COUNT, episodeId })

export const setCurrentQuarterCMD = ({ quarter }: any) => ({
  type: SET_CURRENT_CASELOAD_MANAGEMENT_DASHBOARD_QUARTER,
  quarter,
})

export const setCurrentQuarterCMDDetailedWeeklyView = (quarter: any) => ({
  type: SET_CURRENT_CMD_DETAILED_WEEKLY_VIEW_QUARTER,
  quarter,
})

export const getAppointmentUtilization = ({ id, date }: any) => ({
  action: GET_APPOINTMENT_UTILIZATION,
  request: {
    method: 'get',
    url: `/v1/providers/${id}/utilization/appointments`,
    params: { date },
  },
})

export const getTargetUtilization = ({ id, date, includeFinalWeek = false }: any) => ({
  action: GET_TARGET_UTILIZATION,
  request: {
    method: 'get',
    url: `/v1/providers/${id}/utilization/targets`,
    params: { date, include_final_week: includeFinalWeek },
  },
})

export const getHistoricUtilization = ({ id, date, bookableView }: any) => {
  const paramsPayload = !isNil(bookableView) ? { date, bookable_view: bookableView } : { date }
  return {
    action: GET_HISTORIC_UTILIZATION,
    request: {
      method: 'get',
      url: `/v1/providers/${id}/utilization/historic/week`,
      params: paramsPayload,
    },
  }
}

export const getUtilizationCharges = ({
  id,
  date,
  sessionType,
}: {
  id: string
  date: string
  sessionType: string
}) => ({
  action: GET_UTILIZATION_CHARGES,
  request: {
    method: 'get',
    url: `/v1/providers/${id}/utilization/charges`,
    params: { date, session_type: sessionType },
  },
})

export const getHistoricCharges = ({ id, date, sessionType }: { id: string; date: string; sessionType: string }) => ({
  action: GET_UTILIZATION_CHARGES_QUARTER,
  request: {
    method: 'get',
    url: `/v1/providers/${id}/utilization/charges/historic/week`,
    params: { date, session_type: sessionType },
  },
})

export const getUtilizationQuarters = ({ date }: { date: string }) => ({
  action: GET_UTILIZATION_QUARTERS,
  request: {
    method: 'get',
    url: `/v1/utilization/quarters?date=${date}`,
  },
})
