import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import moment from 'moment-timezone'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'

import { Appointment, ClientObject, useFlags } from '@lyrahealth-inc/shared-app-logic'
import { ChevronIcon, Dashlet, dateUtils, Label, RefreshButton } from '@lyrahealth-inc/ui-core'
import { toJS } from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './unclosedSessionsDashlet.module.scss'
import { appointmentStatuses } from '../../../common/constants/appConstants'
import { CLIENT_HOME } from '../../../common/constants/routingConstants'
import { getClientFullName } from '../../../common/utils/utils'
import { RootState } from '../../../data/store'
import { getLTAppointments } from '../../clients/clientDetails/data/appointmentsAutoActions'
import { selectLtClient } from '../../clients/clientDetails/data/ltClientDetailsAutoActions'
import { getLTVideoAppointments } from '../../data/ltVideoSelectors'

export const UnclosedSessionsDashlet: React.FC<UnclosedSessionsDashletProps> = ({
  providerId,
  appointments,
  clients,
  isLoading,
  actions: { selectLtClient, getLTAppointments },
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { isPreferredNameEnabled } = useFlags()

  const [isRefreshingSessions, setIsRefreshingSessions] = useState(false)
  const unclosedAppointments = appointments.filter((appt: any) => {
    const clientForAppointment =
      Array.isArray(clients) && clients?.find((client: ClientObject) => client.id === appt?.userId)
    return (
      moment(dateUtils.getAppointmentDateTimeObject(appt)).isBefore(moment()) &&
      ![appointmentStatuses.completed, appointmentStatuses.missed, appointmentStatuses.canceled].includes(
        appt.appointmentStatus,
      ) &&
      clientForAppointment
    )
  })

  const renderSessionListItem = (appt: Appointment) => {
    const appointmentTime = moment.tz(`${appt?.startDate} ${appt?.startTime}`, appt.timeZone).toDate()
    const clientForAppointment = Array.isArray(clients) && clients?.find((client: any) => client.id === appt?.userId)
    if (!clientForAppointment) {
      // In the weird case that a client cannot be found, don't show a list item
      return
    }
    return (
      <div className={styles['dashlet-item-container']} key={`${clientForAppointment.first_name}${appt.startDate}`}>
        <button
          className={styles['dashlet-item-content']}
          onClick={() => {
            selectLtClient(clientForAppointment)
            navigate(CLIENT_HOME.route)
          }}
          data-test-id={`UnclosedSessions-list-item-${clientForAppointment.first_name}-${appt.startDate}`}
        >
          <div className={styles.left}>
            <div className={styles.appointmentTime}>
              <div className={styles.date}>
                {intl.formatDate(appointmentTime, { year: 'numeric', month: '2-digit', day: '2-digit' })}
              </div>
              <div className={styles.subtext}>
                {intl.formatDate(appointmentTime, { hour: '2-digit', minute: '2-digit' })}
              </div>
            </div>
            <div className={styles.appointmentInfo}>
              <div>{getClientFullName(clientForAppointment, isPreferredNameEnabled)}</div>
              <div className={styles.subtext}>{`Session ${appt.sessionNumber}`}</div>
            </div>
          </div>
          <div className={styles.right}>
            {moment(appointmentTime).add(24, 'hours').isBefore(moment()) && (
              <Label textColor={styles.x_red6} backgroundColor={styles.x_red1} text='overdue' />
            )}
            <ChevronIcon isFilled fillColor={styles.x_soft_black} direction='right' />
          </div>
        </button>
      </div>
    )
  }

  return (
    <div className={styles['dashlet-container']} data-test-id='UnclosedSessionDashlet-content'>
      <Dashlet
        title={`Unclosed sessions${isLoading ? '' : ` (${unclosedAppointments.length})`}`}
        buttons={
          <RefreshButton
            size={'20'}
            isLoading={isRefreshingSessions}
            onClick={() => {
              setIsRefreshingSessions(true)
              getLTAppointments({ id: providerId }).then(() => {
                setIsRefreshingSessions(false)
              })
            }}
          />
        }
        isLoading={isLoading}
      >
        {unclosedAppointments.length === 0 ? (
          <div className={styles['empty-dashlet']}>No sessions to close</div>
        ) : (
          unclosedAppointments.map(renderSessionListItem)
        )}
      </Dashlet>
    </div>
  )
}

type UnclosedSessionsDashletProps = {
  actions: {
    selectLtClient: (client: ClientObject) => void
    getLTAppointments: ({ id }: { id: string }) => Promise<object>
  }
  providerId: string
  appointments: Appointment[]
  clients: ClientObject[]
  isLoading: boolean
}

const mapStateToProps = (state: RootState): any => ({
  appointments: getLTVideoAppointments(state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators({ selectLtClient, getLTAppointments }, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(toJS(UnclosedSessionsDashlet))
