import React, { FormEvent } from 'react'
import CSSModules from 'react-css-modules'
import { connect, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { subYears } from 'date-fns'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import { Field, formValueSelector, reduxForm } from 'redux-form/immutable'

import { ClientSessionData } from '@lyrahealth-inc/shared-app-logic'
import {
  BootstrapContainer,
  CardMedium,
  DataTable,
  InputField,
  PrimaryButton,
  SelectField,
} from '@lyrahealth-inc/ui-core'

import styles from './sessionLookup.module.scss'
import { getClientSession } from '../../../clients/data/clientsDataActions'
import { getClientsDataSessionData } from '../../../clients/data/clientsDataSelectors'
import { RootState } from '../../../data/store'
import { programString, ProgramStringKey } from '../../constants/appConstants'
import { PROVIDERS_DETAILS_PAYMENT_HISTORY } from '../../constants/routingConstants'
import validation from '../../utils/validation'
import { ClientSessionLimitInfo } from '../client/ClientSessionLimitInfo'

const tableColumns = [
  {
    Header: 'Provider Name',
    accessor: 'providerName',
  },
  {
    Header: 'Program',
    accessor: 'program',
  },
  {
    Header: 'Session Type',
    accessor: 'sessionType',
  },
  {
    Header: 'Visit Date',
    accessor: 'visitDate',
  },
  {
    Header: 'Attendance',
    accessor: 'attendance',
  },
  {
    Header: 'Specialty Care',
    accessor: 'specialtyCare',
  },
  {
    Header: 'Company',
    accessor: 'displayedCompany',
  },
]

export const SessionLookup: React.FC<SessionLookupProps> = ({
  lyraIds,
  searchYear,
  submitting,
  actions: { getClientSession },
}) => {
  const clientData: ClientSessionData[] = useSelector(getClientsDataSessionData) ?? []
  const navigate = useNavigate()
  const _rowClickHandler = (_: any, rowInfo: { original: { providerId: string } }): void => {
    navigate(PROVIDERS_DETAILS_PAYMENT_HISTORY.route, { state: { provider_id: rowInfo.original.providerId } })
  }

  const clientSessionDisplay = (clientData: ClientSessionData[]) => {
    return clientData.map((ele: ClientSessionData) => {
      return (
        <DataTable
          key={ele.id}
          pageSize={ele.charges_for_session_lookup.length}
          className={styles.card}
          destinationSelector='providerId'
          // @ts-expect-error TS(2322): Type '(_: any, rowInfo: {    original: {        pr... Remove this comment to see the full error message
          rowClickHandler={_rowClickHandler}
          data={ele.charges_for_session_lookup}
          columns={tableColumns}
        />
      )
    })
  }

  const displaySessionUsageByCompany = (program: ProgramStringKey, sessionInfo: any, isMinor: boolean = false) => {
    const companySessionList = Object.keys(sessionInfo).map((customerName, index) => {
      const sessionLimit = sessionInfo[customerName].session_limit
      const sessionUsed = sessionInfo[customerName].benefit_year_usage
      const displayVisitsPerIssuePerYear = sessionInfo[customerName].display_visits_per_issue_per_year
      const benefitResetDate = sessionInfo[customerName].benefit_reset_date
      const sessionInfoByProgram = sessionInfo[customerName].session_info_by_program
      let sessionCountNode: string | React.ReactNode = (
        <ClientSessionLimitInfo
          program={program}
          sessionUsed={sessionUsed}
          sessionLimit={sessionLimit}
          displayVisitsPerIssuePerYear={displayVisitsPerIssuePerYear}
          usedSessionsByProgram={sessionInfoByProgram?.benefit_year_usage}
          EAPSessionLimitByProgram={sessionInfoByProgram?.session_limit}
          benefitResetDate={benefitResetDate}
          isMinor={isMinor}
        />
      )
      if (!sessionCountNode) {
        if (sessionUsed) {
          sessionCountNode = `${sessionUsed} sessions used.`
        } else {
          sessionCountNode = '-'
        }
      }
      return (
        <div key={index}>
          <b> {customerName}</b>: {sessionCountNode}
        </div>
      )
    })

    return (
      <span styleName='resultLine'>
        <div>
          <p>
            <b>{programString[program]} Sessions: </b>
            {companySessionList}
          </p>
        </div>
      </span>
    )
  }

  const getResultDisplay = (clientData: ClientSessionData[], searchYear: number) => {
    // Will use the selected year or default to the current year if one is not present for any reason
    const selectedYear = searchYear ? searchYear : new Date().getFullYear()
    return clientData.map((client: ClientSessionData, index: number) => {
      return (
        <div key={index}>
          <span styleName='sessionHeader'>
            <p>
              <b>Client Info for Current Benefit Year</b>
            </p>
          </span>
          <span styleName='resultLine'>
            <div>
              <p>
                <b>Client ID: </b>
                {client.id}
              </p>
              <p>
                <b>HealthPlan Supported: </b>
                {Object.prototype.hasOwnProperty.call(client, 'health_plan_status') ? 'True' : 'False'}
              </p>
              <p>
                <b>HealthPlan Eligibility: </b>
                {client.health_plan_status || '-'}
              </p>
            </div>
            <div>
              <p>
                <b>Company: </b>
                {client.displayed_employer}
              </p>
              <p>
                <b>LyraCode Enabled: </b>
                {client.lyra_code_enabled ? 'True' : 'False'}
              </p>
              <p>
                <b>LyraCode: </b>
                {client.friendly_lyra_code || '-'}
              </p>
            </div>
            <span>
              <div>
                <p>
                  <b>HealthPlan Error Status: </b>
                  {client.health_plan_info || '-'}
                </p>
              </div>
            </span>
          </span>
          {displaySessionUsageByCompany('eap', client.eap_session_info, client.is_minor)}
          {displaySessionUsageByCompany('hp', client.health_plan_session_info)}
          <span styleName='sessionHeader'>
            <p>
              <b>Sessions for Calendar Year {selectedYear}</b>
            </p>
          </span>
          {clientSessionDisplay(clientData)}
        </div>
      )
    })
  }

  const submitFunction = (e: FormEvent) => {
    e.preventDefault()
    return getClientSession(lyraIds.trim().split('\n'), searchYear)
  }

  //This allows us to search for the last 5 years of sessions.
  const searchYearList = Array.from({ length: 5 }, (_, i) => subYears(new Date(), i).getFullYear())

  return (
    <BootstrapContainer col='col-md-16'>
      <CardMedium>
        <h2>Client Session Counts and Limits</h2>
        <form styleName='idForm' onSubmit={(e) => submitFunction(e)}>
          <div className='row'>
            <div className='col-xs-12 col-sm-6'>
              <Field name='lyraIds' type='text' component={InputField} placeholder='Lyra Id' disabled={submitting} />
            </div>
            <div className='col-xs-12 col-sm-6'>
              <Field name='searchYear' component={SelectField} placeholder='Year' disabled={submitting}>
                <option value='' disabled>
                  Select Year
                </option>
                {searchYearList.map((year) => {
                  return (
                    <option key={`searchYear-${year}`} value={year}>
                      {year}
                    </option>
                  )
                })}
              </Field>
            </div>
          </div>
          <PrimaryButton isLoading={submitting} id='submit' type='submit'>
            Submit
          </PrimaryButton>
        </form>
        {getResultDisplay(clientData, searchYear)}
      </CardMedium>
    </BootstrapContainer>
  )
}

type SessionLookupProps = {
  lyraIds: string
  searchYear: number
  submitting: boolean
  actions: { getClientSession: (lyraIds: string[], searchYear: number) => Promise<void> }
}

const mapStateToProps = (state: RootState) => {
  const selector = formValueSelector('clientSession')
  return {
    lyraIds: selector(state, 'lyraIds'),
    searchYear: selector(state, 'searchYear'),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    actions: bindActionCreators({ getClientSession }, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'clientSession',
    validate: ($$values) =>
      validation.requiredFields($$values, [
        { fieldName: 'lyraIds', errorText: 'Lyra Id' },
        { fieldName: 'searchYear', errorText: 'Year' },
      ]),
    touchOnBlur: true,
    // @ts-expect-error TS(2345): Argument of type 'FC<SessionLookupProps>' is not a... Remove this comment to see the full error message
  })(CSSModules(SessionLookup, styles)),
)
