import React from 'react'
import { useIntl } from 'react-intl'
import { GestureResponderEvent } from 'react-native'

import { get, isEmpty, uniqueId } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { Assignment, AssignmentGroup, EpisodeContents, Provider } from '@lyrahealth-inc/shared-app-logic'

import { AssignmentsListItem } from './AssignmentsListItem'
import { AssignmentsNone } from './AssignmentsNone'
import { Subhead, TextButton } from '../../atoms'
import { SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'

export interface AssignmentsListProps {
  userRole: string
  addActivityHandler?: () => void | null
  providers: Provider[]
  sessionData?: { sessionCount: number }
  itemActions?: Array<ItemAction>
  assignments: Assignment[]
  futureEpisodes?: (Assignment | EpisodeContents)[]
  noDataText?: string
  itemClickHandler?: (val: any) => void
  title?: string
  assignmentTags?: {
    id: string
  }
  clientFirstName?: string
  combineLinkedAssignments?: boolean
  simplifyStatus?: boolean
}

export interface ItemAction {
  name: string
  text: string
  selectHandler: (
    e?: GestureResponderEvent,
    name?: string,
    assignment?: Assignment | EpisodeContents,
    sessionCount?: number,
  ) => void
  shouldShow: (assignment?: Assignment | EpisodeContents, sessionCount?: number) => void
}

const AddActivityContainer = styled.View<{
  isMobileSized: boolean
}>(({ isMobileSized }) => ({
  height: '50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: isMobileSized ? '0px 17px' : '0px 23px',
}))

const ItemsListContainer = styled.View<{ theme: ThemeType }>(({ theme: { colors } }) => ({
  margin: '0 0 20px 0',
  padding: 0,
  borderRadius: '12px',
  border: `1px solid ${colors.borderDefault}`,
  backgroundColor: colors.backgroundPrimary,
}))

const SubheadText = styled(Subhead)({
  marginBottom: '8px',
  display: 'block',
})

export const AssignmentsList: React.FC<AssignmentsListProps> = ({
  addActivityHandler,
  assignments,
  futureEpisodes,
  title,
  noDataText,
  providers = [],
  clientFirstName,
  combineLinkedAssignments = false,
  simplifyStatus = false,
  ...props
}) => {
  const intl = useIntl()
  const { breakpoints } = useTheme()

  const renderList = (
    assignments: Assignment[] | undefined,
    futureEpisodes: (Assignment | EpisodeContents)[] | undefined,
  ) => {
    const { itemClickHandler, userRole, itemActions, sessionData, assignmentTags } = props
    return (
      <>
        {assignments &&
          assignments.map((assignment, idx) => (
            <AssignmentsListItem
              key={uniqueId('assignmentListItem')}
              assignment={assignment}
              assignments={assignments}
              provider={providers.find((provider) => provider.provider.lyra_id === assignment.provider_id)}
              multiProvider={providers.length > 1}
              itemClickHandler={itemClickHandler}
              userRole={userRole}
              itemActions={itemActions}
              sessionCount={get(sessionData, 'sessionCount')}
              assignmentTags={assignmentTags?.[assignment.id]}
              isFirstItem={idx === 0}
              isLastItem={idx === assignments.length - 1 && !addActivityHandler}
              clientFirstName={clientFirstName}
              largeText
              combineLinkedAssignments={combineLinkedAssignments}
              simplifyStatus={simplifyStatus}
            />
          ))}
        {futureEpisodes &&
          futureEpisodes.map((episode, idx) => (
            <AssignmentsListItem
              key={uniqueId('assignmentListItem')}
              futureEpisode={episode}
              itemClickHandler={itemClickHandler}
              userRole={userRole}
              itemActions={itemActions}
              sessionCount={get(sessionData, 'sessionCount')}
              isFirstItem={idx === 0}
              isLastItem={idx === futureEpisodes.length - 1 && !addActivityHandler}
              clientFirstName={clientFirstName}
              largeText
              combineLinkedAssignments={combineLinkedAssignments}
              simplifyStatus={simplifyStatus}
            />
          ))}
      </>
    )
  }

  let activities, assessments, lessons, exercises, infoSheets
  if (!isEmpty(assignments)) {
    lessons = assignments.filter((a: Assignment) => {
      const group = a.group || get(a, 'content.group')
      return group === AssignmentGroup.LESSON
    })
    assessments = assignments.filter((a: Assignment) => {
      const group = a.group || get(a, 'content.group')
      return group === AssignmentGroup.ASSESSMENT
    })
    exercises = assignments.filter((a: Assignment) => {
      const group = a.group || get(a, 'content.group')
      return group === AssignmentGroup.EXERCISE
    })
    infoSheets = assignments.filter((a: Assignment) => {
      const group = a.group || get(a, 'content.group')
      return group === AssignmentGroup.INFO_SHEET
    })
    // Order activities: lessons > worksheets > infosheets > assessments
    activities = lessons.concat(exercises, infoSheets, assessments)
  }

  return (
    <>
      <SubheadText text={title} size={SubheadSize.MEDIUM} />
      <ItemsListContainer>
        {isEmpty(activities) && isEmpty(futureEpisodes) ? (
          <AssignmentsNone addActivityHandler={addActivityHandler} noDataText={noDataText} />
        ) : (
          <>
            {renderList(activities, futureEpisodes)}
            {addActivityHandler ? (
              <AddActivityContainer isMobileSized={breakpoints.isMobileSized}>
                <TextButton
                  textAlign='left'
                  testID={tID('AssignmentList-addActivityTextButton')}
                  onPress={addActivityHandler}
                  text={intl.formatMessage({
                    defaultMessage: '+ Add Activity',
                    description:
                      'Button to add a new activity. The plus sign at the beginning signifies adding something new.',
                  })}
                />
              </AddActivityContainer>
            ) : (
              ''
            )}
          </>
        )}
      </ItemsListContainer>
    </>
  )
}
