import React, { useCallback, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect, ConnectedProps, useSelector } from 'react-redux'

import { isWithinInterval } from 'date-fns'
import styled from 'styled-components/native'

import {
  appointmentUtilization,
  getStartOfNextQuarterByWeeks,
  historicUtilization,
  targetUtilization,
  useFlags,
  UtilizationCharges,
  utilizationQuarters,
} from '@lyrahealth-inc/shared-app-logic'
import {
  BodyText,
  BodyTextSize,
  colors,
  DashboardUpdateInfo,
  DropdownButton,
  ExternalLinkIcon,
  InlineInfoBanner,
  LoadingIndicator,
  LoadingSkeleton,
  Subhead,
  SubheadSize,
  TextButton,
  ThemeType,
  tID,
  useFetcher,
} from '@lyrahealth-inc/ui-core-crossplatform'

import CapacityHeader from './CapacityHeader'
import DetailedWeeklyViewContainer from './DetailedWeeklyViewContainer'
import QuarterlyProgressChart from './QuarterlyProgressChart'
import QuarterlySnapshot from './QuarterlySnapshot'
import WeeklySnapshot from './WeeklySnapshot'
import { actions as mixpanelActions, mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import AvailabilityCard from '../../common/components/availabilityCard/AvailabilityCard'
import { CapacityAlertPopUp } from '../../common/components/capacityAlertPopUp/CapacityAlertPopUp'
import { employmentTypes, ROLES } from '../../common/constants/appConstants'
import {
  getDropdownItemsFromQuarters,
  getPreviousQuartersSorted,
  getYearMonthDayLocaleByDate,
  hasRole,
} from '../../common/utils/utils'
import { getAuthConfig, getAuthEmploymentStatus, getAuthRoles, getAuthUserId } from '../../data/auth/authSelectors'
import {
  getProviderAppointmentUtilization,
  getProviderHistoricUtilization,
  getProviderTargetUtilization,
  getProviderUtilizationCharges,
  getProviderUtilizationQuarters,
  getRequestedCaseloadManagementQuarter,
} from '../../data/lyraTherapy/clientSelectors'
import {
  CalendarAvailabilitiesAlertPopUpValues,
  useCalendarAvailabilitiesAlertPopUp,
} from '../../hooks/useCalendarAvailabilitiesAlertPopUp'
import { getProviderCapacity } from '../../providers/data/providersDataActions'
import {
  getAppointmentUtilization,
  getHistoricUtilization,
  getTargetUtilization,
  getUtilizationCharges,
  getUtilizationQuarters,
  setCurrentQuarterCMD,
  setCurrentQuarterCMDDetailedWeeklyView,
} from '../clients/clientDetails/data/appointmentsAutoActions'

const Heading = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  paddingTop: theme.spacing['32px'],
  paddingBottom: theme.spacing['32px'],
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',
  zIndex: 1,
}))

const AvailabilityAndCapacityContainer = styled.View({
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  minHeight: '98px',
  padding: '24px',
  shadowColor: '#000',
  shadowOffset: {
    width: '0px',
    height: '2px',
  },
  shadowOpacity: '0.1',
  shadowRadius: '10px',
  width: '100%',
})

const HeadingTextContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['24px'],
}))

const Container = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  margin: `${theme.spacing['16px']} ${theme.spacing['32px']}`,
}))

const SnapshotSection = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  display: 'flex',
  flexDirection: theme.breakpoints.isMinWidthDesktop ? 'row' : 'column',
  justifyContent: 'space-between',
}))

const DataBoardContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['16px'],
  backgroundColor: colors.white,
  padding: `${theme.spacing['32px']} ${theme.spacing['32px']}`,
  borderRadius: '16px',
  border: `1px solid ${colors.ui_oatmeal3}`,
}))

const WeeklySnapshotContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  ...(theme.breakpoints.isMinWidthDesktop && { width: '50%', paddingRight: theme.spacing['12px'] }),
}))

const QuarterlySnapshotContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  ...(theme.breakpoints.isMinWidthDesktop && { width: '50%', paddingLeft: theme.spacing['12px'] }),
}))

const DropdownContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginRight: theme.spacing['16px'],
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
}))

const DropwdownLabel = styled.View({
  justifyContent: 'center',
  marginRight: '8px',
})

const FlexRowContainer = styled.View({
  flexDirection: 'row',
})

const CaseloadHeadingLearnmoreContainer = styled.View({
  flexDirection: 'row',
  gap: '8px',
})

export const ProviderPerformance: React.FC<ConnectedProps<typeof connector>> = ({
  getAppointmentUtilization,
  getHistoricUtilization,
  getProviderCapacity,
  getTargetUtilization,
  getUtilizationCharges,
  getUtilizationQuarters,
  setCurrentQuarterCMD,
  setCurrentQuarterCMDDetailedWeeklyView,
}) => {
  const appointmentUtilization: appointmentUtilization | undefined = useSelector(getProviderAppointmentUtilization)
  const employmentStatus = useSelector(getAuthEmploymentStatus)
  const historicUtilization: historicUtilization | undefined = useSelector(getProviderHistoricUtilization)
  const requestedQuarter: utilizationQuarters | undefined = useSelector(getRequestedCaseloadManagementQuarter)
  const targetUtilization: targetUtilization | undefined = useSelector(getProviderTargetUtilization)
  const config = useSelector(getAuthConfig)
  const showMinifiedUtilization = config?.showMinifiedUtilization ?? false
  const userId = useSelector(getAuthUserId)
  const userRoles = useSelector(getAuthRoles)
  const utilizationCharges: UtilizationCharges | undefined = useSelector(getProviderUtilizationCharges)
  const utilizationQuarters: utilizationQuarters | undefined = useSelector(getProviderUtilizationQuarters)
  const { formatMessage } = useIntl()
  const [currentQuarter, setCurrentQuarter] = useState<string | null>(null)
  const [selectedQuarter, setSelectedQuarter] = useState<string | null>(null)
  const [isLoadingWeeklySnapshot, setIsLoadingSnapshot] = useState(false)
  const {
    shouldShowPastQuartersInBCXDashboards,
    showCalendarAvailabilitiesWithCapacity,
    showDetailedWeeklyViewForCMD,
  } = useFlags()
  const quarterPaidTimeOff =
    historicUtilization &&
    historicUtilization.reduce(
      (totalPTO, week) =>
        week.providerPaidTimeOffHours ? totalPTO + parseInt(week.providerPaidTimeOffHours) : totalPTO,
      0,
    )
  const requestedQuarterName = requestedQuarter ? Object.keys(requestedQuarter)[0] : ''
  const todayDate = new Date()

  const requestedDate = requestedQuarterName.length && new Date(`${requestedQuarter?.[requestedQuarterName][1]}T00:00`) // need time or else UTC time is assumed
  const shouldRequestCurrentQuarter =
    !requestedDate ||
    isWithinInterval(todayDate, {
      start: new Date(`${requestedQuarter?.[requestedQuarterName][0]}T00:00`),
      end: new Date(`${requestedQuarter?.[requestedQuarterName][1]}T00:00`),
    })
  const targetDate = shouldRequestCurrentQuarter ? todayDate : requestedDate

  const newCaseloadDashboardVersion =
    employmentStatus &&
    hasRole(userRoles, [ROLES.LT_COACH, ROLES.LT_SS_COACH]) &&
    [employmentTypes.FULL_TIME, employmentTypes.PART_TIME].includes(employmentStatus)

  const [loadingUtilization, , fetchedUtilization] = useFetcher(
    [
      [getHistoricUtilization, { id: userId, date: getYearMonthDayLocaleByDate(targetDate) }, showMinifiedUtilization],
      [getAppointmentUtilization, { id: userId, date: getYearMonthDayLocaleByDate(targetDate) }],
      [
        getTargetUtilization,
        {
          id: userId,
          date: getYearMonthDayLocaleByDate(targetDate),
          includeFinalWeek: !shouldRequestCurrentQuarter,
        },
        !showMinifiedUtilization || newCaseloadDashboardVersion,
      ],
      [
        getUtilizationCharges,
        { id: userId, date: getYearMonthDayLocaleByDate(targetDate), sessionType: 'lyra_therapy_teens_parent_session' },
        hasRole(userRoles, [ROLES.LT_TEENS_THERAPIST, ROLES.LT_TEENS_THERAPIST_SUPERVISOR]),
      ],
      [getUtilizationQuarters, { date: getYearMonthDayLocaleByDate(todayDate) }],
      [getProviderCapacity, { id: userId }],
    ],
    [userId, newCaseloadDashboardVersion],
  )

  useEffect(() => {
    if (!shouldShowPastQuartersInBCXDashboards || !utilizationQuarters) {
      return
    }
    const sortedQuarters = getPreviousQuartersSorted({
      availableQuarters: utilizationQuarters,
      howManyQuartersToFetch: 2,
    })
    const quarterName = requestedQuarter ? Object.keys(requestedQuarter)[0] : sortedQuarters[0]
    setCurrentQuarter(sortedQuarters[0])
    setSelectedQuarter(quarterName)
    setCurrentQuarterCMDDetailedWeeklyView(sortedQuarters[0])
  }, [
    requestedQuarter,
    shouldShowPastQuartersInBCXDashboards,
    setCurrentQuarterCMDDetailedWeeklyView,
    utilizationQuarters,
  ])

  const {
    alertText,
    handleCloseAlertPopUpModal,
    handleLearnMore,
    handleOpenCalendar,
    hasProperRoleToSeeAlert,
    isAlertPopUpOpen,
  }: CalendarAvailabilitiesAlertPopUpValues = useCalendarAvailabilitiesAlertPopUp()

  const dropdownClickHandler = (quarter: string, isCurrent: boolean) => {
    const quarterEndDateBeforeLocale = new Date(`${utilizationQuarters?.[quarter][1]}T00:00`)
    setIsLoadingSnapshot(true)
    const allRequests = [
      getAppointmentUtilization({
        id: userId,
        date: getYearMonthDayLocaleByDate(isCurrent ? new Date() : quarterEndDateBeforeLocale),
      }),
      getTargetUtilization({
        id: userId,
        date: getYearMonthDayLocaleByDate(isCurrent ? new Date() : quarterEndDateBeforeLocale),
        includeFinalWeek: isCurrent ? false : true,
      }),

      setCurrentQuarterCMD({
        quarter: {
          [quarter]: utilizationQuarters?.[quarter],
        },
      }),
      setSelectedQuarter(quarter),
    ]
    if (userId && hasRole(userRoles, [ROLES.LT_TEENS_THERAPIST, ROLES.LT_TEENS_THERAPIST_SUPERVISOR])) {
      allRequests.push(
        getUtilizationCharges({
          id: userId,
          date: getYearMonthDayLocaleByDate(isCurrent ? new Date() : quarterEndDateBeforeLocale),
          sessionType: 'lyra_therapy_teens_parent_session',
        }),
      )
    }
    Promise.all(allRequests).then(() => {
      setIsLoadingSnapshot(false)
    })
  }

  const onLearnMoreClicked = useCallback(() => {
    track({
      event: mixpanelEvents.BUTTON_PRESS,
      action: mixpanelActions.LEARN_MORE,
    })
    window.open(
      'https://bct-lyrahealth.zendesk.com/hc/en-us/articles/360042313293-Caseload-Measurement-Based-Care-MBC-Compensation-Criteria',
      '_blank',
    )
  }, [])

  if (loadingUtilization || !fetchedUtilization) {
    return <LoadingIndicator size={45} />
  }
  if (!utilizationQuarters || !appointmentUtilization) {
    return null
  }
  let preselectedValue = ''
  if (requestedQuarter) {
    const requestedQuarterName = Object.keys(requestedQuarter)[0]
    const dropdownAppend = requestedQuarterName === currentQuarter ? ' Current' : ''
    preselectedValue = `${requestedQuarterName.replace('_', ' ')}${dropdownAppend}`
  }
  if (showDetailedWeeklyViewForCMD) {
    return (
      <>
        <AvailabilityAndCapacityContainer testID={tID('ProviderPerformance-availabilityContainer')}>
          <CaseloadHeadingLearnmoreContainer>
            <Subhead
              style={{ display: 'flex', alignItems: 'center' }}
              size={SubheadSize.LARGE}
              text={
                <FormattedMessage
                  defaultMessage='Caseload'
                  description='This is a heading that tells the Provider this is about the amount of case work they are taking on for this period of time'
                />
              }
            />

            <TextButton
              testID={tID('Caseload-learnMoreButton')}
              style={{ alignSelf: 'center' }}
              onPress={onLearnMoreClicked}
              text='Learn more'
              rightIcon={<ExternalLinkIcon size={24} strokeColor={colors.teal4} />}
            />
          </CaseloadHeadingLearnmoreContainer>
          <AvailabilityCard isMinified hideCalendarAvailability />
        </AvailabilityAndCapacityContainer>
        <DetailedWeeklyViewContainer />
      </>
    )
  } else if (showCalendarAvailabilitiesWithCapacity) {
    return (
      <>
        {hasProperRoleToSeeAlert && isAlertPopUpOpen && (
          <CapacityAlertPopUp
            alertText={alertText}
            handleOpenCalendar={handleOpenCalendar}
            handleLearnMore={handleLearnMore}
            onClose={handleCloseAlertPopUpModal}
          />
        )}
        <AvailabilityAndCapacityContainer testID={tID('ProviderPerformance-availabilityContainer')}>
          <Subhead
            style={{ display: 'flex', alignItems: 'center' }}
            size={SubheadSize.LARGE}
            text={
              shouldShowPastQuartersInBCXDashboards ? (
                <FormattedMessage
                  defaultMessage='Caseload'
                  description='This is a heading that tells the Provider this is about the amount of case work they are taking on for this period of time'
                />
              ) : (
                <FormattedMessage
                  defaultMessage='Caseload Management Dashboard'
                  description='This is a heading that tells the Provider this is about the amount of case work they are taking on for this period of time'
                />
              )
            }
          />
          <AvailabilityCard isMinified />
        </AvailabilityAndCapacityContainer>
        <Container>
          <Heading testID={tID('Caseload-heading')}>
            <HeadingTextContainer testID={tID('Caseload-title')}>
              <DashboardUpdateInfo
                resetDateTime={getStartOfNextQuarterByWeeks(utilizationQuarters)}
                quarterAndYear={selectedQuarter !== currentQuarter ? selectedQuarter : ''}
              />
            </HeadingTextContainer>
            <FlexRowContainer>
              {shouldShowPastQuartersInBCXDashboards && (
                <DropdownContainer>
                  <DropwdownLabel>
                    <BodyText
                      size={BodyTextSize.LARGE}
                      text={formatMessage({
                        defaultMessage: 'View quarter',
                        description: 'a label to tell the user to select a quarter of data to look at',
                      })}
                      color={colors.ui_oatmeal6}
                    />
                  </DropwdownLabel>
                  <DropdownButton
                    dropdownItems={getDropdownItemsFromQuarters(
                      getPreviousQuartersSorted({ availableQuarters: utilizationQuarters, howManyQuartersToFetch: 2 }),
                      dropdownClickHandler,
                    )}
                    dropdownWidth={185}
                    preselectedValue={preselectedValue}
                  />
                </DropdownContainer>
              )}
              <TextButton
                testID={tID('Caseload-learnMoreButton')}
                style={{ alignSelf: 'center' }}
                onPress={onLearnMoreClicked}
                text='Learn more'
                rightIcon={<ExternalLinkIcon size={24} strokeColor={colors.teal4} />}
              />
            </FlexRowContainer>
          </Heading>
          {currentQuarter === selectedQuarter ? (
            <DataBoardContainer>
              <CapacityHeader
                targetUtilization={targetUtilization}
                appointmentUtilization={appointmentUtilization}
                showMinified={showMinifiedUtilization}
              />
            </DataBoardContainer>
          ) : isLoadingWeeklySnapshot ? (
            <LoadingSkeleton width='100%' height={56} />
          ) : currentQuarter !== selectedQuarter ? (
            <InlineInfoBanner
              testId={'ProviderPerformance-inlineInfoBanner'}
              marginBottom='0px'
              text={`You are viewing ${preselectedValue} data. If manual adjustments were made after the quarter they will not be reflected here.`}
            />
          ) : (
            <></>
          )}
          <SnapshotSection>
            <WeeklySnapshotContainer testID={tID('ProviderPerformance-weeklySnapshotContainer')}>
              <DataBoardContainer>
                {isLoadingWeeklySnapshot ? (
                  <LoadingSkeleton width='100%' height={252} />
                ) : (
                  <WeeklySnapshot
                    appointmentUtilization={appointmentUtilization}
                    utilizationCharges={utilizationCharges}
                    isHistorical={currentQuarter !== selectedQuarter}
                  />
                )}
              </DataBoardContainer>
            </WeeklySnapshotContainer>
            <QuarterlySnapshotContainer>
              <DataBoardContainer>
                {isLoadingWeeklySnapshot ? (
                  <LoadingSkeleton width='100%' height={252} />
                ) : (
                  <QuarterlySnapshot
                    targetUtilization={
                      targetUtilization
                        ? quarterPaidTimeOff && quarterPaidTimeOff > 0
                          ? { ...targetUtilization, quarterPaidTimeOff }
                          : targetUtilization
                        : undefined
                    }
                    appointmentUtilization={appointmentUtilization}
                    utilizationCharges={utilizationCharges}
                    employmentStatus={employmentStatus}
                    showMinified={showMinifiedUtilization}
                    userRoles={userRoles}
                    hideWeeklySchedules={currentQuarter !== selectedQuarter}
                  />
                )}
              </DataBoardContainer>
            </QuarterlySnapshotContainer>
          </SnapshotSection>

          {!showMinifiedUtilization && (
            <DataBoardContainer>
              {isLoadingWeeklySnapshot ? (
                <LoadingSkeleton width='100%' height={252} />
              ) : (
                <QuarterlyProgressChart
                  targetUtilization={targetUtilization}
                  appointmentUtilization={appointmentUtilization}
                  utilizationCharges={utilizationCharges}
                />
              )}
            </DataBoardContainer>
          )}
        </Container>
      </>
    )
  }
  return (
    <Container>
      <Heading testID={tID('Caseload-heading')}>
        <HeadingTextContainer testID={tID('Caseload-title')}>
          <Subhead
            size={SubheadSize.LARGE}
            text={formatMessage({
              defaultMessage: 'Caseload',
              description: 'Header for Caseload Tab in Caseload',
            })}
            color={colors.ui_oatmeal6}
          />
          <DashboardUpdateInfo
            resetDateTime={getStartOfNextQuarterByWeeks(utilizationQuarters)}
            quarterAndYear={selectedQuarter !== currentQuarter ? selectedQuarter : ''}
          />
        </HeadingTextContainer>
        <FlexRowContainer>
          {shouldShowPastQuartersInBCXDashboards && (
            <DropdownContainer>
              <DropwdownLabel>
                <BodyText
                  size={BodyTextSize.LARGE}
                  text={formatMessage({
                    defaultMessage: 'View quarter',
                    description: 'a label to tell the user to select a quarter of data to look at',
                  })}
                  color={colors.ui_oatmeal6}
                />
              </DropwdownLabel>
              <DropdownButton
                dropdownItems={getDropdownItemsFromQuarters(
                  getPreviousQuartersSorted({ availableQuarters: utilizationQuarters, howManyQuartersToFetch: 2 }),
                  dropdownClickHandler,
                )}
                dropdownWidth={185}
                preselectedValue={preselectedValue}
              />
            </DropdownContainer>
          )}

          <TextButton
            testID={tID('Caseload-learnMoreButton')}
            style={{ alignSelf: 'center' }}
            onPress={onLearnMoreClicked}
            text='Learn more'
            rightIcon={<ExternalLinkIcon size={24} strokeColor={colors.teal4} />}
          />
        </FlexRowContainer>
      </Heading>
      {currentQuarter === selectedQuarter ? (
        <DataBoardContainer>
          <CapacityHeader
            targetUtilization={targetUtilization}
            appointmentUtilization={appointmentUtilization}
            showMinified={showMinifiedUtilization}
          />
        </DataBoardContainer>
      ) : isLoadingWeeklySnapshot ? (
        <LoadingSkeleton width='100%' height={56} />
      ) : (
        <InlineInfoBanner
          testId={'ProviderPerformance-inlineInfoBanner'}
          marginBottom='0px'
          text={`You are viewing ${preselectedValue} data. If manual adjustments were made after the quarter they will not be reflected here.`}
        />
      )}
      <SnapshotSection>
        <WeeklySnapshotContainer testID={tID('ProviderPerformance-weeklySnapshotContainer')}>
          <DataBoardContainer>
            {isLoadingWeeklySnapshot ? (
              <LoadingSkeleton width='100%' height={252} />
            ) : (
              <WeeklySnapshot
                appointmentUtilization={appointmentUtilization}
                utilizationCharges={utilizationCharges}
                isHistorical={currentQuarter !== selectedQuarter}
              />
            )}
          </DataBoardContainer>
        </WeeklySnapshotContainer>
        <QuarterlySnapshotContainer>
          <DataBoardContainer>
            {isLoadingWeeklySnapshot ? (
              <LoadingSkeleton width='100%' height={252} />
            ) : (
              <QuarterlySnapshot
                targetUtilization={
                  targetUtilization
                    ? quarterPaidTimeOff && quarterPaidTimeOff > 0
                      ? { ...targetUtilization, quarterPaidTimeOff }
                      : targetUtilization
                    : undefined
                }
                appointmentUtilization={appointmentUtilization}
                utilizationCharges={utilizationCharges}
                employmentStatus={employmentStatus}
                showMinified={showMinifiedUtilization}
                userRoles={userRoles}
                hideWeeklySchedules={currentQuarter !== selectedQuarter}
              />
            )}
          </DataBoardContainer>
        </QuarterlySnapshotContainer>
      </SnapshotSection>

      {!showMinifiedUtilization && (
        <DataBoardContainer>
          {isLoadingWeeklySnapshot ? (
            <LoadingSkeleton width='100%' height={252} />
          ) : (
            <QuarterlyProgressChart
              targetUtilization={targetUtilization}
              appointmentUtilization={appointmentUtilization}
              utilizationCharges={utilizationCharges}
            />
          )}
        </DataBoardContainer>
      )}
    </Container>
  )
}

const connector = connect(null, {
  getAppointmentUtilization,
  getHistoricUtilization,
  getProviderCapacity,
  getTargetUtilization,
  getUtilizationCharges,
  getUtilizationQuarters,
  setCurrentQuarterCMD,
  setCurrentQuarterCMDDetailedWeeklyView,
})
export default connector(ProviderPerformance)
