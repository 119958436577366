import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'

import queryString from 'query-string'
import { bindActionCreators } from 'redux'

import { LoadingIndicator } from '@lyrahealth-inc/ui-core'

import { LOGIN, OUTCOMES_CONSENT } from '../common/constants/routingConstants'
import {
  getAuthHydrated,
  getAuthIsLoggedIn,
  getAuthIsLTSupervisor,
  getAuthUserIsRegistered,
  getAuthUserOutcomesAgreed,
  getAuthUserOutcomesEnabled,
  getLastAuthorizedRoute,
} from '../data/auth/authSelectors'
import * as authActions from '../data/authActions'
import { RootState } from '../data/store'
import withRouter from '../routing/withRouter'

type OktaCallbackProps = {
  isLoggedIn?: boolean
  router?: any
  actions?: any
  isRegistered?: boolean
  lastAuthorizedRoute?: string
  showOutcomesConsent?: boolean
  hydrated?: boolean
  isLtSupervisor?: boolean
}
class OktaCallback extends Component<OktaCallbackProps> {
  componentDidMount() {
    this.props.actions.login(this.props.router.location.search, this.props.router.navigate)
  }

  render() {
    const { isLoggedIn, isRegistered, router, lastAuthorizedRoute, showOutcomesConsent, hydrated, isLtSupervisor } =
      this.props
    if (!hydrated) return false
    if (isLoggedIn) {
      if (isRegistered) {
        if (showOutcomesConsent && !isLtSupervisor) {
          return <Navigate to={OUTCOMES_CONSENT.route} state={{ withoutNav: true }} />
        }
        if (lastAuthorizedRoute && lastAuthorizedRoute !== LOGIN.route) {
          return <Navigate to={lastAuthorizedRoute} />
        } else if (router.location && queryString.parse(router.location.search).next) {
          // @ts-expect-error TS(2322): Type 'string | string[] | null' is not assignable ... Remove this comment to see the full error message
          window.location.href = queryString.parse(router.location.search).next
          return false
        }
      }
      // Redirect the user to their default page
      return <Navigate to='/' />
    } else {
      return (
        <div style={{ textAlign: 'center', paddingTop: '60px' }}>
          <LoadingIndicator size={45} />
        </div>
      )
    }
  }
}

const mapStateToProps = (state: RootState) => {
  const outcomesAgreed = getAuthUserOutcomesAgreed(state) ?? false
  const outcomesEnabled = getAuthUserOutcomesEnabled(state) ?? false

  return {
    isLoggedIn: getAuthIsLoggedIn(state),
    hydrated: getAuthHydrated(state),
    isRegistered: getAuthUserIsRegistered(state),
    lastAuthorizedRoute: getLastAuthorizedRoute(state),
    showOutcomesConsent: !!outcomesEnabled && !outcomesAgreed,
    isLtSupervisor: getAuthIsLTSupervisor(state),
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators({ ...authActions }, dispatch),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OktaCallback))
