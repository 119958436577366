import React, { FunctionComponent, ReactElement } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import {
  appointmentUtilization,
  getStartOfNextQuarterByWeeks,
  historicUtilization,
  targetUtilization,
  useFlags,
  utilizationQuarters,
} from '@lyrahealth-inc/shared-app-logic'

import { BodyText, DashboardUpdateInfo, ExternalLinkIcon, LoadingSkeleton } from '../../atoms'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { TextButton } from '../../atoms/textButton/TextButton'
import { InlineInfoBanner } from '../../molecules'
import { CaseloadManagementQuarterlySnapshot } from '../../molecules/caseloadManagementQuarterlySnapshot/CaseloadManagementQuarterlySnapshot'
import { CaseloadManagementWeeklySnapshot } from '../../molecules/caseloadManagementWeeklySnapshot/CaseloadManagementWeeklySnapshot'
import { BodyTextSize } from '../../styles'
import { Flex1View } from '../../templates/content/CommonViews'
import { ContentAreaScrollView } from '../../templates/content/ContentArea'
import { ThemeType, tID } from '../../utils'

export interface CaseloadManagementProps {
  availabilityCard: ReactElement
  viewHelpArticle: () => void
  appointmentUtilization: appointmentUtilization
  historicUtilization: historicUtilization
  targetUtilization: targetUtilization
  employmentStatus?: string
  handleDetailedWeeklyView?: () => void
  dropdownWithHandlers?: ReactElement
  isLoadingWeeklySnapshot?: boolean
  requestedQuarterName?: string
  shouldShowPastQuartersInBCXDashboards?: boolean
  utilizationQuarters?: utilizationQuarters
  selectedQuarter?: string | null
  currentQuarter?: string | null
}

const CaseloadManagementContainer = styled(Flex1View)(({ theme }) => ({
  padding: theme.breakpoints.isMinWidthLaptop ? '0 200px' : '0 56px',
}))

const ScrollContent = styled(ContentAreaScrollView)(({ theme }) => ({
  paddingTop: theme.spacing['24px'],
  paddingBottom: theme.spacing['8px'],
}))

const CaseloadHeading = styled.View({
  display: 'flex',
  flexDirection: 'row',
  paddingTop: '8px',
  paddingBottom: '24px',
  zIndex: 1,
  justifyContent: 'space-between',
})

const FeatureLink = styled(TextButton)(({ theme }) => ({
  marginTop: theme.spacing['4px'],
}))

const FeatureLinkContainer = styled.View(({ theme }) => ({
  display: 'flex',
  marginLeft: theme.spacing['16px'],
}))

const FeatureLinkNew = styled(TextButton)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: theme.spacing['4px'],
}))

const AvailabilityAndCapacityContainer = styled.View({
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  minHeight: '98px',
  padding: '24px',
  shadowColor: '#000',
  shadowOffset: {
    width: '0px',
    height: '2px',
  },
  shadowOpacity: '0.1',
  shadowRadius: '10px',
  width: '100%',
})

const SnapshotContainer = styled.View({
  width: '100%',
  minHeight: '98px',
  borderRadius: '16px',
  backgroundColor: '#fff',
  padding: '24px',
  marginBottom: '48px',
  shadowColor: '#000',
  shadowOffset: {
    width: '0px',
    height: '2px',
  },
  shadowOpacity: '0.1',
  shadowRadius: '10px',
})

const WeeklySnapshotContainer = styled.View({
  paddingBottom: '32px',
})

const FlexColumnContainer = styled.View({
  display: 'flex',
  flexDirection: 'column',
})

const FlexRowContainer = styled.View({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
})

const DropdownContainerNew = styled.View<{ theme: ThemeType }>({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
})

const DropdownContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginRight: theme.spacing['16px'],
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
}))

const DropwdownLabel = styled.View({
  display: 'flex',
  justifyContent: 'center',
  marginRight: '8px',
})

const FlexContainer = styled.View({
  display: 'flex',
})

/**
 * The page with all the user's current activities
 */
export const CaseloadManagement: FunctionComponent<CaseloadManagementProps> = ({
  availabilityCard,
  viewHelpArticle,
  appointmentUtilization,
  historicUtilization,
  targetUtilization,
  employmentStatus = 'FT',
  handleDetailedWeeklyView = noop,
  dropdownWithHandlers,
  isLoadingWeeklySnapshot = false,
  requestedQuarterName = '',
  shouldShowPastQuartersInBCXDashboards = false,
  utilizationQuarters,
  selectedQuarter = '',
  currentQuarter = '',
}) => {
  const { colors } = useTheme()
  const { formatMessage } = useIntl()
  const { showCalendarAvailabilitiesWithCapacity } = useFlags()
  const contentContainerStyle = {
    paddingBottom: 24,
    flexGrow: 1,
  }
  const formattedRequestedQuarterName = requestedQuarterName.replace('_', ' ')

  if (showCalendarAvailabilitiesWithCapacity) {
    return (
      <>
        <AvailabilityAndCapacityContainer testID={tID('CaseloadManagement-availabilityContainer')}>
          <Subhead
            style={{ display: 'flex', alignItems: 'center', marginRight: '24px' }}
            size={SubheadSize.LARGE}
            text={
              shouldShowPastQuartersInBCXDashboards ? (
                <FormattedMessage
                  defaultMessage='Caseload'
                  description='This is a heading that tells the Provider this is about the amount of case work they are taking on for this period of time'
                />
              ) : (
                <FormattedMessage
                  defaultMessage='Caseload Management Dashboard'
                  description='This is a heading that tells the Provider this is about the amount of case work they are taking on for this period of time'
                />
              )
            }
          />
          {availabilityCard}
        </AvailabilityAndCapacityContainer>
        <CaseloadManagementContainer testID={tID('CaseloadManagement-container')} defaultFlex>
          <ScrollContent contentContainerStyle={contentContainerStyle}>
            <CaseloadHeading testID={tID('CaseloadManagement-headerContainer')}>
              <FlexRowContainer>
                {shouldShowPastQuartersInBCXDashboards && utilizationQuarters && (
                  <DashboardUpdateInfo
                    resetDateTime={getStartOfNextQuarterByWeeks(utilizationQuarters)}
                    quarterAndYear={selectedQuarter !== currentQuarter ? selectedQuarter : ''}
                  />
                )}
                <FeatureLinkContainer>
                  <FeatureLinkNew
                    onPress={viewHelpArticle}
                    text={
                      shouldShowPastQuartersInBCXDashboards ? (
                        <FormattedMessage
                          defaultMessage='Learn more'
                          description='This is a link describing the user can click on this to learn more about the current dashboard webpage they are on'
                        />
                      ) : (
                        <FormattedMessage
                          defaultMessage='Learn more about this feature'
                          description='This is a link describing the user can click on this to learn more about the current dashboard webpage they are on'
                        />
                      )
                    }
                    rightIcon={<ExternalLinkIcon strokeColor={colors.iconActive} size={15} />}
                  />
                </FeatureLinkContainer>
              </FlexRowContainer>
              <FlexRowContainer>
                {shouldShowPastQuartersInBCXDashboards && (
                  <DropdownContainerNew>
                    <DropwdownLabel testID={tID('CaseloadManagement-dropdownLabel')}>
                      <BodyText
                        size={BodyTextSize.DEFAULT}
                        text={formatMessage({
                          defaultMessage: 'View quarter',
                          description: 'a label to tell the user to select a quarter of data to look at',
                        })}
                        color={colors.textPrimary}
                      />
                    </DropwdownLabel>
                    <FlexContainer testID={tID('CaseloadManagement-dropdownContainer')}>
                      {dropdownWithHandlers}
                    </FlexContainer>
                  </DropdownContainerNew>
                )}
              </FlexRowContainer>
            </CaseloadHeading>

            {requestedQuarterName?.length > 0 && (
              <InlineInfoBanner
                testId={'CaseloadManagement-inlineInfoBanner'}
                text={
                  <FormattedMessage
                    defaultMessage='You are viewing {formattedRequestedQuarterName} data. If manual adjustments were made after the quarter they will not be reflected here.'
                    description='This is a link describing the user can click on this to learn more about the current dashboard webpage they are on'
                    values={{
                      formattedRequestedQuarterName: formattedRequestedQuarterName,
                    }}
                  />
                }
              />
            )}

            <WeeklySnapshotContainer>
              {isLoadingWeeklySnapshot ? (
                <LoadingSkeleton width='100%' height={252} />
              ) : (
                <CaseloadManagementWeeklySnapshot
                  appointmentUtilization={appointmentUtilization}
                  historicUtilization={historicUtilization}
                  targetUtilization={targetUtilization}
                  employmentStatus={employmentStatus}
                  handleDisplayWeeklySnapshot={handleDetailedWeeklyView}
                  requestedQuarterName={requestedQuarterName}
                  showPastQuartersInLCCDashboard={shouldShowPastQuartersInBCXDashboards}
                />
              )}
            </WeeklySnapshotContainer>

            <WeeklySnapshotContainer>
              {isLoadingWeeklySnapshot ? (
                <LoadingSkeleton width='100%' height={252} />
              ) : (
                <CaseloadManagementQuarterlySnapshot
                  appointmentUtilization={appointmentUtilization}
                  historicUtilization={historicUtilization}
                  targetUtilization={targetUtilization}
                  requestedQuarterName={requestedQuarterName}
                  showPastQuartersInLCCDashboard={shouldShowPastQuartersInBCXDashboards}
                />
              )}
            </WeeklySnapshotContainer>
          </ScrollContent>
        </CaseloadManagementContainer>
      </>
    )
  }
  return (
    <CaseloadManagementContainer testID={tID('CaseloadManagement-container')} defaultFlex>
      <ScrollContent contentContainerStyle={contentContainerStyle}>
        <CaseloadHeading testID={tID('CaseloadManagement-headerContainer')}>
          <FlexColumnContainer>
            <FlexRowContainer>
              <Subhead
                style={{ display: 'flex', alignItems: 'center', marginRight: '24px' }}
                size={SubheadSize.LARGE}
                text={
                  shouldShowPastQuartersInBCXDashboards ? (
                    <FormattedMessage
                      defaultMessage='Caseload'
                      description='This is a heading that tells the Provider this is about the amount of case work they are taking on for this period of time'
                    />
                  ) : (
                    <FormattedMessage
                      defaultMessage='Caseload Management Dashboard'
                      description='This is a heading that tells the Provider this is about the amount of case work they are taking on for this period of time'
                    />
                  )
                }
              />
              {shouldShowPastQuartersInBCXDashboards && utilizationQuarters && (
                <DashboardUpdateInfo
                  resetDateTime={getStartOfNextQuarterByWeeks(utilizationQuarters)}
                  quarterAndYear={selectedQuarter !== currentQuarter ? selectedQuarter : ''}
                />
              )}
            </FlexRowContainer>
            <FeatureLink
              onPress={viewHelpArticle}
              text={
                shouldShowPastQuartersInBCXDashboards ? (
                  <FormattedMessage
                    defaultMessage='Learn more'
                    description='This is a link describing the user can click on this to learn more about the current dashboard webpage they are on'
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage='Learn more about this feature'
                    description='This is a link describing the user can click on this to learn more about the current dashboard webpage they are on'
                  />
                )
              }
              rightIcon={<ExternalLinkIcon strokeColor={colors.iconActive} size={15} />}
            />
          </FlexColumnContainer>
          <FlexRowContainer>
            {shouldShowPastQuartersInBCXDashboards && (
              <DropdownContainer>
                <DropwdownLabel testID={tID('CaseloadManagement-dropdownLabel')}>
                  <BodyText
                    size={BodyTextSize.DEFAULT}
                    text={formatMessage({
                      defaultMessage: 'View quarter',
                      description: 'a label to tell the user to select a quarter of data to look at',
                    })}
                    color={colors.textPrimary}
                  />
                </DropwdownLabel>
                <FlexContainer testID={tID('CaseloadManagement-dropdownContainer')}>
                  {dropdownWithHandlers}
                </FlexContainer>
              </DropdownContainer>
            )}
          </FlexRowContainer>
        </CaseloadHeading>
        {(!requestedQuarterName || requestedQuarterName?.length === 0) && (
          <SnapshotContainer testID={tID('CaseloadManagement-availabilityContainer')}>
            {availabilityCard}
          </SnapshotContainer>
        )}

        {requestedQuarterName?.length > 0 && (
          <InlineInfoBanner
            testId={'CaseloadManagement-inlineInfoBanner'}
            text={
              <FormattedMessage
                defaultMessage='You are viewing {formattedRequestedQuarterName} data. If manual adjustments were made after the quarter they will not be reflected here.'
                description='This is a link describing the user can click on this to learn more about the current dashboard webpage they are on'
                values={{
                  formattedRequestedQuarterName: formattedRequestedQuarterName,
                }}
              />
            }
          />
        )}

        <WeeklySnapshotContainer>
          {isLoadingWeeklySnapshot ? (
            <LoadingSkeleton width='100%' height={252} />
          ) : (
            <CaseloadManagementWeeklySnapshot
              appointmentUtilization={appointmentUtilization}
              historicUtilization={historicUtilization}
              targetUtilization={targetUtilization}
              employmentStatus={employmentStatus}
              handleDisplayWeeklySnapshot={handleDetailedWeeklyView}
              requestedQuarterName={requestedQuarterName}
              showPastQuartersInLCCDashboard={shouldShowPastQuartersInBCXDashboards}
            />
          )}
        </WeeklySnapshotContainer>

        <WeeklySnapshotContainer>
          {isLoadingWeeklySnapshot ? (
            <LoadingSkeleton width='100%' height={252} />
          ) : (
            <CaseloadManagementQuarterlySnapshot
              appointmentUtilization={appointmentUtilization}
              historicUtilization={historicUtilization}
              targetUtilization={targetUtilization}
              requestedQuarterName={requestedQuarterName}
              showPastQuartersInLCCDashboard={shouldShowPastQuartersInBCXDashboards}
            />
          )}
        </WeeklySnapshotContainer>
      </ScrollContent>
    </CaseloadManagementContainer>
  )
}
