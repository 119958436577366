import React, { Component } from 'react'
import CSSModules from 'react-css-modules'
import { connect } from 'react-redux'

import queryString from 'query-string'
import { bindActionCreators } from 'redux'
import { Field, reduxForm } from 'redux-form/immutable'

import { BootstrapContainer, Checkbox, PrimaryButton, ProfileListIcon, TextButton } from '@lyrahealth-inc/ui-core'

import styles from './outcomesConsent.module.scss'
import { trackClicks } from '../../../../../mixpanel/mixpanelTracking'
import { CLIENTS, OUTCOMES_CONSENT } from '../../../common/constants/routingConstants'
import * as alertActions from '../../../data/alertActions'
import { getLastAuthorizedRoute } from '../../../data/auth/authSelectors'
import { RootState } from '../../../data/store'
import withRouter from '../../../routing/withRouter'
import * as clientDashboardActions from '../../dashboard/data/clientsDashboardActions'

const validate = ($$values: any) => {
  const errors = {}
  if (!$$values.get('outcomes_agreed')) {
    ;(errors as any).outcomes_agreed = 'Required'
  }
  return errors
}

type OutcomesConsentProps = {
  actions?: any
  pristine?: boolean
  invalid?: boolean
  submitting?: boolean
  handleSubmit?: (...args: any[]) => any
  router?: any
  lastAuthorizedRoute?: string
}

class OutcomesConsent extends Component<OutcomesConsentProps> {
  componentDidMount() {
    trackClicks('OutcomesConsent')
  }

  _continue = () => {
    const { router, lastAuthorizedRoute } = this.props

    if (router.location.state && router.location.state.withoutNav) {
      // part of login, redirect to default homepage or lastAuthorizedRoute
      if (lastAuthorizedRoute && lastAuthorizedRoute !== OUTCOMES_CONSENT.route) {
        router.navigate(lastAuthorizedRoute)
      } else if (
        router.location &&
        queryString.parse(router.location.search).next &&
        queryString.parse(router.location.search).next !== OUTCOMES_CONSENT.route
      ) {
        // @ts-expect-error TS(2322): Type 'string | string[] | null' is not assignable ... Remove this comment to see the full error message
        window.location.href = queryString.parse(router.location.search).next
      } else {
        router.navigate('/')
      }
    } else {
      // rediret ("reload") clients dashboard
      router.navigate(CLIENTS.route)
    }
  }

  _submitForm = ($$values: any) => {
    const formData = new FormData()
    const vals = $$values.toJS()
    Object.keys(vals).forEach((key) => {
      formData.append(key, vals[key])
    })

    this.props.actions.hideAlerts()
    return this.props.actions.submitProviderOutcomesConsent(formData).then((_: any) => {
      this._continue()
    })
  }

  _notNow = () => {
    this._continue()
  }

  render() {
    const { handleSubmit, submitting, pristine, invalid, router } = this.props

    return (
      <BootstrapContainer col='col-md-10 col-md-offset-1' style={{ overflow: 'visible' }}>
        <div style={{ marginTop: '100px' }}>
          <div styleName='container'>
            <div styleName='welcome-container'>
              <ProfileListIcon />
              <h3>Welcome To Your Client Dashboard</h3>
              <p>
                Here you can view your Lyra clients and their progress in one place. To track their progress, Lyra
                automatically collects outcomes data from your clients.
              </p>
              <a
                id='learn-more'
                href='https://provider-support.lyrahealth.com/hc/en-us/articles/115012608808'
                target='_blank'
                rel='noreferrer'
              >
                Learn more
              </a>
            </div>
            <div styleName='form-container'>
              <p styleName='form-description'>
                To turn on your Client Dashboard, may we send emails to your Lyra clients on your behalf to collect
                outcomes data? The emails will look like you sent them to your clients. If you have questions about this
                process, please see the{' '}
                <a
                  target='_blank'
                  href='https://provider-support.lyrahealth.com/hc/en-us/articles/115012608808'
                  rel='noreferrer'
                >
                  FAQ
                </a>
                .
              </p>
              {/* @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message */}
              <form styleName='consent-form' onSubmit={handleSubmit(this._submitForm)}>
                <Field name='outcomes_agreed' component={Checkbox} type='checkbox'>
                  Yes, Lyra may reach out to my client(s) and collect outcomes data on my behalf.
                </Field>
                <PrimaryButton id='outcomes-agree' type='submit' isLoading={submitting} disabled={pristine || invalid}>
                  Turn on Client Dashboard
                </PrimaryButton>
                {router.location.state && router.location.state.withoutNav ? (
                  <TextButton
                    id='outcomes-not-now'
                    // @ts-expect-error TS(2322): Type '{ id: string; type: string; style: { marginT... Remove this comment to see the full error message
                    type='button'
                    style={{ marginTop: '10px' }}
                    text='Not now'
                    size='secondary'
                    onClick={this._notNow}
                  />
                ) : (
                  []
                )}
              </form>
            </div>
          </div>
        </div>
      </BootstrapContainer>
    )
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    lastAuthorizedRoute: getLastAuthorizedRoute(state),
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators({ ...clientDashboardActions, ...alertActions }, dispatch),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    reduxForm({
      form: 'OutcomesConsent',
      validate,
      // @ts-expect-error TS(2345): Argument of type 'typeof OutcomesConsent' is not a... Remove this comment to see the full error message
    })(CSSModules(OutcomesConsent, styles, { allowMultiple: true })),
  ),
)
