import React, { useCallback } from 'react'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { startOfYear, subYears } from 'date-fns'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import styled from 'styled-components'

import { LoadingIndicator, PlatformEngagementQuarterlyDetails, useFetcher } from '@lyrahealth-inc/ui-core-crossplatform'

import { getPlatformEngagementMetrics } from './data/platformEngagementActions'
import {
  getCurrentPlatformEngagementMetrics,
  getPreviousPlatformEngagementMetrics,
} from './data/platformEngagementSelectors'
import usePEQuarters from './hooks/usePEQuarters'
import useTransformPEMetrics from './hooks/useTransformPEMetrics'
import { actions as mixpanelActions, mixpanelEvents } from '../../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../../mixpanel/mixpanelTracking'
import { BC_PLATFORM_ENGAGEMENT } from '../../../common/constants/routingConstants'
import { getAuthUserId } from '../../../data/auth/authSelectors'

const Container = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

type PlatformEngagementQuarterlyDetailsContainerProps = ConnectedProps<typeof connector>

const PlatformEngagementQuarterlyDetailsContainer: React.FunctionComponent<
  PlatformEngagementQuarterlyDetailsContainerProps
> = ({ actions: { getPlatformEngagementMetrics } }) => {
  const userId = useSelector(getAuthUserId)
  const navigate = useNavigate()
  const onBackPressed = useCallback(() => {
    track({
      event: mixpanelEvents.BUTTON_PRESS,
      action: mixpanelActions.BACK,
    })
    navigate(BC_PLATFORM_ENGAGEMENT.route)
  }, [navigate])
  const onCurrentYearPressed = useCallback((isExpanded) => {
    track({
      event: mixpanelEvents.BUTTON_PRESS,
      action: mixpanelActions.CURRENT_YEAR,
      details: isExpanded ? 'Open' : 'Close',
    })
  }, [])

  const currentYearMetrics = useSelector(getCurrentPlatformEngagementMetrics)
  const previousYearMetrics = useSelector(getPreviousPlatformEngagementMetrics)

  const peQuartersCurrent = usePEQuarters()
  const peQuartersPrevious = usePEQuarters({ previousYear: true })
  const currentMetrics = useTransformPEMetrics(currentYearMetrics)
  const prevMetrics = useTransformPEMetrics(previousYearMetrics)

  const onPreviousYearPressed = useCallback((isExpanded) => {
    track({
      event: mixpanelEvents.BUTTON_PRESS,
      action: mixpanelActions.PREVIOUS_YEAR,
      details: isExpanded ? 'Open' : 'Close',
    })
  }, [])

  const [loadingMetrics, , fetchedMetrics] = useFetcher(
    [
      [getPlatformEngagementMetrics, { providerId: userId, data: peQuartersCurrent }],
      [getPlatformEngagementMetrics, { providerId: userId, data: peQuartersPrevious, isCurrent: false }],
    ],
    [userId],
  )
  return (
    <Container>
      {loadingMetrics && !fetchedMetrics ? (
        <LoadingIndicator topPadding={24} />
      ) : (
        <PlatformEngagementQuarterlyDetails
          currentYear={startOfYear(new Date()).getUTCFullYear()}
          previousYear={subYears(startOfYear(new Date()), 1).getUTCFullYear()}
          currentYearMetrics={currentMetrics}
          previousYearMetrics={prevMetrics}
          onBackPressed={onBackPressed}
          onCurrentYearPressed={onCurrentYearPressed}
          onPreviousYearPressed={onPreviousYearPressed}
        />
      )}
    </Container>
  )
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators({ getPlatformEngagementMetrics }, dispatch),
})
const connector = connect(null, mapDispatchToProps)
export default connector(PlatformEngagementQuarterlyDetailsContainer)
