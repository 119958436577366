import React from 'react'
import CSSModules from 'react-css-modules'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'

import axios from 'axios'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { capitalize, isEmpty } from 'lodash-es'
import { bindActionCreators } from 'redux'

import { addOrdinalSuffix, CustomerInfo, LDFlags } from '@lyrahealth-inc/shared-app-logic'
import { BootstrapContainer, CardFullWidth, NavButton } from '@lyrahealth-inc/ui-core'
import { tID } from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './clientDetails.module.scss'
import ClientOutcomes from './ClientOutcomes'
import * as clientDetailsAutoActions from './data/clientDetailsAutoActions'
import { getClientsClientDetailsData } from './data/clientDetailsSelectors'
import { trackClicks } from '../../../../mixpanel/mixpanelTracking'
import { ClientSessionLimitInfo } from '../../common/components/client/ClientSessionLimitInfo'
import LyraCodeProfileDisplay from '../../common/components/lyraCode/LyraCodeProfileDisplay'
import { healthPlanStates } from '../../common/constants/appConstants'
import { CLIENTS } from '../../common/constants/routingConstants'
import sessionLimitWarning from '../../common/content/sessionLimitWarning'
import sessionResetDate from '../../common/content/sessionResetDate'
import { getRequestPaymentCustomers } from '../../data/requestPayment/requestPaymentSelectors'
import { RootState } from '../../data/store'
import withRouter from '../../routing/withRouter'

type ClientDetailsProps = {
  clientDetails?: any
  customers?: CustomerInfo[]
  actions?: any
  router?: any
  flags?: LDFlags
}

export class ClientDetails extends React.Component<ClientDetailsProps> {
  canceler: any

  componentDidMount() {
    trackClicks('ClientDetails')
    const clientLyraId = this.props.clientDetails?.id
    if (clientLyraId) {
      this.canceler = axios.CancelToken.source()
      this.props.actions.getClientDetails({ clientLyraId, cancelToken: this.canceler.token })
    }
  }

  componentWillUnmount() {
    this.canceler && this.canceler.cancel()
    this.props.actions.clearClientSelected()
  }

  _backToClients = () => this.props.router.navigate(CLIENTS.route)

  _renderSessionTrackingPopover = (client: any) => {
    const name = capitalize(client.first_name)
    const pluralize = client.sessions_remaining === 1 ? '' : 's'
    let body = `Please remind ${name} that any sessions after their ${addOrdinalSuffix(
      client.sessions_limit,
    )} will not be covered by Lyra.`
    let sessionType = ''

    if (client.health_plan_status === 'health_plan_not_ready') {
      sessionType = 'free '
      body = `Please advise ${name} to check their Lyra Profile if they may be eligible for additional sessions using their medical plan.`
    } else if (client.health_plan_status === 'health_plan_ready') {
      sessionType = 'free '
      body =
        'After the free sessions, additional sessions will be submitted to the client’s medical plan on file with Lyra.'
    }

    let header = `${name} has ${client.sessions_remaining} ${sessionType}session${pluralize} remaining`
    if (client.health_plan_status === 'health_plan_in_use') {
      header = `${name} is using medical plan`
      body = 'Sessions will be submitted to the client’s medical plan on file with Lyra.'
    }
    return (
      <div>
        <h2 styleName='session-tracking-header'>{header}</h2>
        <p styleName='session-tracking-body'>{body}</p>
        <p styleName='session-tracking-contact'>
          If you have any questions please contact{' '}
          <a href='mailto:providers@lyraclinical.com'>providers@lyraclinical.com</a>.
        </p>
      </div>
    )
  }

  _renderClientInfo = () => {
    const client = this.props.clientDetails
    const clientCustomer = this.props.customers?.find((customer) => customer?.value === client.employer)
    let healthPlanInfo, specialityCareInfo
    const warningThreshold = 5
    const hasEAPSessions = client.eap_session_limit !== 0
    const showBHBcount = client.open_bhb_session_count > 0 && client.open_bhb_session_limit > 0
    switch (client.health_plan_status) {
      case healthPlanStates.READY:
        healthPlanInfo = (
          <>
            Your client <span styleName='bold'>can use</span> lyra with their health plan
            {hasEAPSessions && <> past the free EAP sessions</>}.
          </>
        )
        break
      case healthPlanStates.NOT_ELIGIBLE:
        healthPlanInfo = (
          <>
            Your client’s health plan <span styleName='bold'>does not cover Lyra</span>. You might not be paid for
            sessions{hasEAPSessions && <> past the free EAP limit</>}.
          </>
        )
        break
      case healthPlanStates.NOT_READY:
        healthPlanInfo = (
          <>
            If you and your client intend to continue{hasEAPSessions && <> past the free EAP sessions</>}, your client{' '}
            <span styleName='attention'>needs to enter their health plan information</span> to determine coverage.
          </>
        )
      case healthPlanStates.HEALTH_PLAN_PAYMENT_MISSING:
        healthPlanInfo = (
          <>
            Your client has entered their health plan information but is missing their payment card for copayments.{' '}
            <span styleName='attention'>
              Please have your client update their payment card for their health plan section to complete their profile.
            </span>{' '}
          </>
        )
    }

    if (client.customer_support_speciality_care) {
      specialityCareInfo = (
        <>
          {' '}
          If your client is high-risk, has an active substance abuse, a persistent mental health condition, has
          treatment resistant anxiety/depression, or is a minor, they may qualify for additional care.
        </>
      )
    }

    return (
      <div>
        <div styleName='title' className='row'>
          <h1 styleName='client-full-name'>{client.full_name}</h1>
          {/* No active or inactive concept in V1 <h3>{client.isActive ? 'Active Client' : 'Inactive Client'}</h3> */}
        </div>
        <div styleName='info' className='row'>
          <div className='col-xs-12 col-sm-6'>
            <h4>Employer</h4>
            <p>{client.displayed_employer ? client.displayed_employer : 'N/A'}</p>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <h4>Client Email</h4>
            <p>{client.product_email || (client.email ? client.email : 'N/A')}</p>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <h4>Sessions</h4>
            {hasEAPSessions && (
              <ClientSessionLimitInfo
                displayVisitsPerIssuePerYear={clientCustomer?.display_visits_per_issue_per_year}
                sessionUsed={client.sessions_used}
                sessionLimit={client.sessions_limit}
                usedSessionsByProgram={client.sessions_used_by_program}
                EAPSessionLimitByProgram={client.sessions_limit_by_program}
                warningThreshold={warningThreshold}
                testID={tID('ClientDetails-eap-session-count')}
              />
            )}
            {showBHBcount && (
              <p
                data-test-id='ClientDetails-bhb-session-count'
                // @ts-expect-error TS(2322): Type 'false | "alert"' is not assignable to type '... Remove this comment to see the full error message
                styleName={client.open_bhb_session_limit - client.open_bhb_session_count <= warningThreshold && 'alert'}
              >
                {client.open_bhb_session_count} of {client.open_bhb_session_limit} recommended health plan sessions
              </p>
            )}
            {client.speciality_care_session_count > 0 && (
              <p
                data-test-id='ClientDetails-sc-session-count'
                // @ts-expect-error TS(2322): Type 'false | "alert"' is not assignable to type '... Remove this comment to see the full error message
                styleName={client.sessions_remaining <= warningThreshold && 'alert'}
              >
                {client.speciality_care_session_count} high-risk sessions
              </p>
            )}
          </div>
          <div className='col-xs-12 col-sm-6'>
            <h4>Coverage Information</h4>
            <p data-test-id='ClientDetails-coverage-information'>
              {healthPlanInfo}
              {specialityCareInfo}
              {sessionLimitWarning({
                client,
                isProgramLevelSessionLimitEnabled: this.props.flags?.isProgramLevelSessionLimitEnabled,
              })}{' '}
              {sessionResetDate({ client })}
            </p>
          </div>
          {client.friendly_lyra_code && (
            <div data-test-id='ClientDetails-lyraCode' className='col-xs-12 col-sm-6'>
              <h4>Lyra code</h4>
              <LyraCodeProfileDisplay lyraCode={client.friendly_lyra_code} />
            </div>
          )}
        </div>
      </div>
    )
  }

  render() {
    const { clientDetails } = this.props
    if (!clientDetails || isEmpty(clientDetails)) {
      return <Navigate to={CLIENTS.route} />
    }

    return (
      <BootstrapContainer col='col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2' style={{ overflow: 'visible' }}>
        <div style={{ marginTop: '50px' }}>
          <NavButton text='Back to Clients' styleType='back' onClick={this._backToClients} />
          <CardFullWidth styleName='details-card' roundCorners>
            {this._renderClientInfo()}
            <ClientOutcomes
              patient_lyra_id={clientDetails.id}
              outcomes={clientDetails.outcomes}
              clientFullName={clientDetails.full_name}
              clientFirstName={clientDetails.first_name}
              consentStatus={clientDetails.email_consent}
              consentSubmitted={!clientDetails.subscribe_token}
              isRegistered={clientDetails.is_registered}
              title='Outcomes'
              learnMore
              reminder
            />
          </CardFullWidth>
        </div>
      </BootstrapContainer>
    )
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    clientDetails: getClientsClientDetailsData(state),
    customers: getRequestPaymentCustomers(state),
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators({ ...clientDetailsAutoActions }, dispatch),
  }
}

export default withLDConsumer()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(CSSModules(ClientDetails, styles, { allowMultiple: true }))),
)
