import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { View } from 'react-native'
import { useSelector } from 'react-redux'

import { addDays, format } from 'date-fns'
import { isEmpty } from 'lodash-es'
import styled from 'styled-components/native'

import {
  BodyText,
  CalendarOpenSpotsIllustration,
  CloseIcon,
  HeaderText,
  Modal,
  PressableOpacity,
  PrimaryButton,
  TertiaryButton,
  ThemeType,
  tID,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { ROLES } from '../../common/constants/appConstants'
import { hasRole, logToSumoLogic } from '../../common/utils/utils'
import { getAuthUser } from '../../data/auth/authSelectors'

export interface AvailabilityAndCapacityMismatchModalProps {
  isModalOpen: boolean
  onClose: () => void
  shortfall: number
  providerAvailabilitiesCount: number
}

const Container = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing['16px'],
}))

const CloseIconContainer = styled(PressableOpacity)({
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 1,
})

const Header = styled.View({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
})

const HeaderTextContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['8px'],
  marginTop: theme.spacing['16px'],
}))

const ButtonsContainer = styled.View<{ theme: ThemeType }>(
  ({
    theme: {
      breakpoints: { isMobileSized },
      spacing,
    },
  }) => ({
    alignItems: isMobileSized ? 'stretch' : 'center',
    flexDirection: isMobileSized ? 'column' : 'row-reverse',
    marginTop: spacing['32px'],
  }),
)

const CancelButtonContainer = styled.View(() => ({
  marginTop: '0px',
}))

export const AvailabilityAndCapacityMismatchModal: React.FC<AvailabilityAndCapacityMismatchModalProps> = ({
  isModalOpen,
  onClose,
  shortfall,
  providerAvailabilitiesCount,
}) => {
  const DATE_FORMAT = 'MM/dd'
  const today = format(new Date(), DATE_FORMAT)
  const twoWeeksFromToday = format(addDays(new Date(), 14), DATE_FORMAT)
  const authUser = useSelector(getAuthUser)
  const coachBodyText = `There isn\'t enough calendar availability between ${today} — ${twoWeeksFromToday} for clients to book with you. Coaches should have at least 1 open calendar spot for every new client you are opening for.`
  const coachLinkUrl =
    'https://coaching-lyrahealth.zendesk.com/hc/en-us/articles/16389043165843-Caseload-Best-Practices-for-FT-PT'
  const therapistBodyText = `There isn't enough calendar availability between ${today} — ${twoWeeksFromToday} for clients to book with you.\n\nYou need to have at least 1 open calendar slot for every new client, and we recommend opening a few more additional slots to help clients find a time.`
  const therapistLinkUrl = 'https://bct-lyrahealth.zendesk.com/hc/en-us/articles/1500004604002-Calendar-Best-Practices'
  const modalContentAndLink = {
    COACH: {
      bodyText: coachBodyText,
      linkUrl: coachLinkUrl,
    },
    LCT_PROVIDER: {
      bodyText: therapistBodyText,
      linkUrl: therapistLinkUrl,
    },
  }
  const link = hasRole(authUser?.roles, [ROLES.LT_COACH, ROLES.LT_SS_COACH])
    ? modalContentAndLink.COACH.linkUrl
    : hasRole(authUser?.roles, [ROLES.LT_THERAPIST, ROLES.LT_AUD_THERAPIST])
    ? modalContentAndLink.LCT_PROVIDER.linkUrl
    : ''
  const bodyText = hasRole(authUser?.roles, [ROLES.LT_COACH, ROLES.LT_SS_COACH])
    ? modalContentAndLink.COACH.bodyText
    : hasRole(authUser?.roles, [ROLES.LT_THERAPIST, ROLES.LT_AUD_THERAPIST])
    ? modalContentAndLink.LCT_PROVIDER.bodyText
    : ''
  const handlePrimaryButtonClick = useCallback(() => {
    track({ event: mixpanelEvents.AVAILABILITY_ALERT_OPEN_CALENDAR })
    window.open('https://calendar.google.com/')
  }, [])
  const handleTertiaryButtonClick = useCallback(() => {
    track({ event: mixpanelEvents.AVAILABILITY_ALERT_LEARN_MORE })
    !isEmpty(link) && window.open(link)
  }, [link])
  const handleCloseModal = useCallback(() => {
    track({ event: mixpanelEvents.AVAILABILITY_ALERT_CLOSE_MODAL })
    logToSumoLogic('providerCalendarAvailableSlots', authUser?.id, {
      action: 'Provider closes availability vs capacity mismatch modal',
      providerAvailabilitiesCount,
      providerId: authUser?.id,
    })
    onClose()
  }, [authUser?.id, onClose, providerAvailabilitiesCount])
  const modalContents = (
    <Container testID={tID('AvailabilityAndCapacityMismatchModal-container')}>
      <CloseIconContainer onPress={handleCloseModal} testID={tID('AvailabilityAndCapacityMismatchModal-close')}>
        <CloseIcon size={12} />
      </CloseIconContainer>
      <Header>
        <CalendarOpenSpotsIllustration />
        <HeaderTextContainer testID={tID('AvailabilityAndCapacityMismatchModal-header-text-container')}>
          <HeaderText text={`Open ${shortfall} calendar spots`} />
        </HeaderTextContainer>
      </Header>
      <BodyText testID={tID('AvailabilityAndCapacityMismatchModal-body-text')}>{bodyText}</BodyText>
      <ButtonsContainer>
        <PrimaryButton
          fullWidth={true}
          onPress={handlePrimaryButtonClick}
          testID={tID('AvailabilityAndCapacityMismatchModal-primary-button')}
          text={
            <FormattedMessage
              defaultMessage='Open calendar'
              description='This button opens a new tab and takes the user to their calendar page'
            />
          }
          disabled={false}
        />
        <CancelButtonContainer>
          <TertiaryButton
            fullWidth={true}
            onPress={handleTertiaryButtonClick}
            testID={tID('AvailabilityAndCapacityMismatchModal-tertiary-button')}
            text={
              <FormattedMessage
                defaultMessage='Learn more'
                description='This button is a call to action for the user to go to a different page to learn more about creating more calendar availabilities'
              />
            }
          />
        </CancelButtonContainer>
      </ButtonsContainer>
    </Container>
  )
  return (
    <View testID={tID('AvailabilityAndCapacityMismatchModal')}>
      <Modal
        onCloseEnd={onClose}
        onRequestClose={onClose}
        disableBottomSheet={true}
        visible={isModalOpen}
        modalContents={modalContents}
        width='544px'
        closeOnScrim
      />
    </View>
  )
}
