import React from 'react'
import CSSModules from 'react-css-modules'
import { connect } from 'react-redux'
import { Navigate, Route } from 'react-router-dom'

import { Map } from 'immutable'
import * as _ from 'lodash-es'
import { bindActionCreators } from 'redux'

import { BootstrapContainer, NavButton } from '@lyrahealth-inc/ui-core'

import styles from './paymentsPracticeDetails.module.scss'
import HealthPlanInfo from '../../../common/components/healthPlanInfo/HealthPlanInfoAdmins'
import TabFilters from '../../../common/components/tabFilters/TabFilters'
import { ROLES } from '../../../common/constants/appConstants'
import {
  PRACTICES,
  PRACTICES_DETAILS_EDIT_INFO,
  PRACTICES_DETAILS_HEALTHPLAN_PAYMENT_INFO,
  PRACTICES_DETAILS_INFO,
  PRACTICES_DETAILS_NEW_PAYMENT,
  PRACTICES_DETAILS_PAYMENT_HISTORY,
} from '../../../common/constants/routingConstants'
import { hasRole } from '../../../common/utils/utils'
import { getAuthRoles } from '../../../data/auth/authSelectors'
import { RootState } from '../../../data/store'
import { SentryRoutes } from '../../../index'
import PaymentHistoryDashboard from '../../../paymentsAdminHistoryDashboard/PaymentHistoryDashboard'
import RequestPayment from '../../../requestPayment/RequestPaymentAdmins'
import withRouter from '../../../routing/withRouter'
import * as practiceDetailsActions from '../data/practiceDetailsActions'
// pages
import { getPracticeDetailsData } from '../data/practiceDetailsSelectors'
import DisplayPracticeInfo from '../info/DisplayPracticeInfo'
import EditPracticeInfo from '../info/EditPracticeInfo'

type PaymentsPracticeDetailsProps = {
  router?: any
  actions?: any
  practiceDetails?: any // TODO: PropTypes.instanceOf(Map)
  roles?: any // TODO: PropTypes.instanceOf(List)
}

class PaymentsPracticeDetails extends React.Component<PaymentsPracticeDetailsProps> {
  componentDidMount() {
    if (!this.props.practiceDetails) {
      // we don't have the context for what practice we should fetch/display,
      // so punt them back to the practices list
      this.props.router.navigate(PRACTICES.route, { replace: true })
    }
  }

  componentWillUnmount() {
    this.props.actions.clearPracticeDetailsStore()
  }

  _backToPractices = () => {
    this.props.router.navigate(PRACTICES.route)
  }

  _handleTabClick = (tab: any) => {
    switch (tab.toLowerCase()) {
      case 'new payment':
        this.props.router.navigate(PRACTICES_DETAILS_NEW_PAYMENT.route, { replace: true })
        break
      case 'payment history':
        this.props.router.navigate(PRACTICES_DETAILS_PAYMENT_HISTORY.route, { replace: true })
        break
      case 'info':
        this.props.router.navigate(PRACTICES_DETAILS_INFO.route, { replace: true })
        break
    }
  }

  _renderTabs = () => {
    let tab
    const pathname = this.props.router.location.pathname
    if (_.includes(pathname, 'info')) {
      tab = 'Info'
    } else if (_.includes(pathname, 'new-payment')) {
      tab = 'New Payment'
    } else if (_.includes(pathname, 'payment-history')) {
      tab = 'Payment History'
    }

    const tabsToRender = ['INFO', 'PAYMENT HISTORY']
    if (!hasRole(this.props.roles, ROLES.VIEW_ONLY)) {
      tabsToRender.splice(1, 0, 'NEW PAYMENT')
    }

    // @ts-expect-error TS(2322): Type '(tab: any) => void' is not assignable to typ... Remove this comment to see the full error message
    return <TabFilters activeTab={tab} tabList={tabsToRender} handleClick={this._handleTabClick} />
  }

  render() {
    if (!this.props.practiceDetails) {
      return false
    }

    const { practiceDetails } = this.props

    return (
      <BootstrapContainer>
        <NavButton
          // @ts-expect-error TS(2322): Type '{ className: string; styleType: string; text... Remove this comment to see the full error message
          className={styles['back-button']}
          styleType='back'
          text='Back to Practices'
          onClick={this._backToPractices}
        />
        <div styleName='overview'>
          <h2>{practiceDetails.practice_name}</h2>
          <p>{practiceDetails.email}</p>
        </div>
        {this._renderTabs()}
        <SentryRoutes>
          <Route
            path={PRACTICES_DETAILS_EDIT_INFO.subroute}
            // @ts-expect-error TS(2322): Type '{ initialValues: any; }' is not assignable t... Remove this comment to see the full error message
            element={<EditPracticeInfo initialValues={Map(practiceDetails)} />}
          />
          <Route path={PRACTICES_DETAILS_INFO.subroute} element={<DisplayPracticeInfo data={practiceDetails} />} />
          {/* @ts-expect-error TS(2740): Type '{ isPractice: true; }' is missing the follow... Remove this comment to see the full error message */}
          <Route path={PRACTICES_DETAILS_NEW_PAYMENT.subroute} element={<RequestPayment isPractice />} />
          {/* @ts-expect-error TS(2322): Type '{ isPractice: true; }' is not assignable to ... Remove this comment to see the full error message */}
          <Route path={PRACTICES_DETAILS_PAYMENT_HISTORY.subroute} element={<PaymentHistoryDashboard isPractice />} />
          <Route path={PRACTICES_DETAILS_HEALTHPLAN_PAYMENT_INFO.subroute} element={<HealthPlanInfo />} />
          <Route path='*' element={<Navigate to={PRACTICES_DETAILS_INFO.route} />} />
        </SentryRoutes>
      </BootstrapContainer>
    )
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    practiceDetails: getPracticeDetailsData(state),
    roles: getAuthRoles(state),
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators({ ...practiceDetailsActions }, dispatch),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CSSModules(PaymentsPracticeDetails, styles)))
