import React, { Component } from 'react'
import { connect } from 'react-redux'

import { ContentLayout } from '@lyrahealth-inc/ui-core'

import { ROLES } from '../common/constants/appConstants'
import { hasRole } from '../common/utils/utils'
// dashboards
import { getAuthRoles } from '../data/auth/authSelectors'
import { RootState } from '../data/store'
import MyProviderInfo from '../myInfo/individualProvider/MyProviderInfo'
import MyPracticeInfo from '../myInfo/practicesAdmin/MyPracticeInfo'

type MyInfoRoutesProps = {
  roles?: any // TODO: PropTypes.instanceOf(List)
}

class MyInfoRoutes extends Component<MyInfoRoutesProps> {
  render() {
    const getContent = () => {
      if (hasRole(this.props.roles, ROLES.PRACTICES_ADMIN)) {
        return <MyPracticeInfo />
      } else if (hasRole(this.props.roles, [ROLES.INDIVIDUAL_PROVIDER, ROLES.PRACTICE_PROVIDER])) {
        return <MyProviderInfo {...this.props} />
      } else {
        return <h1>MYINFO_DASHBOARD, default</h1>
      }
    }
    return (
      <div>
        <ContentLayout>{getContent()}</ContentLayout>
      </div>
    )
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    roles: getAuthRoles(state),
  }
}

export default connect(mapStateToProps, null)(MyInfoRoutes)
