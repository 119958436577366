import React, { useState } from 'react'
import { connect, useSelector } from 'react-redux'

import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import styled, { useTheme } from 'styled-components/native'

import {
  BodyText,
  BodyTextSize,
  ContentContainer,
  IconBadge,
  Link,
  Modal,
  PrimaryButton,
  ThemeType,
  tID,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { SchedulingSubmission } from './SchedulingSubmission'
import { actions, mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { getAuthConfig } from '../../data/auth/authSelectors'
import { SchedulingCalendarStatuses } from '../constants'
import { enableCalendar } from '../data/schedulingActions'

const BadgeContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  width: '125px',
  height: '26px',
  marginBottom: theme.spacing['8px'],
  alignContent: 'flex-start',
}))

const StatusBadgeTextWrapper = styled.View<{ theme: ThemeType }>(() => ({
  flexDirection: 'column',
}))

const StatusButtonContainer = styled.View(() => ({
  alignContent: 'center',
  marginLeft: 'auto',
}))

const StatusContentWrapper = styled.View<{ theme: ThemeType }>(() => ({
  flexDirection: 'row',
  alignContent: 'space-between',
}))

const StatusSectionContainer = styled.View(({ theme }) => ({
  width: 'auto',
  padding: '24px',
  backgroundColor: theme.colors.backgroundPrimary,
  borderRadius: theme.spacing['8px'],
  marginTop: theme.spacing['40px'],
  marginBottom: theme.spacing['40px'],
  boxShadow: `0 2px 10px ${theme.colors.shadowLow}`,
}))

const SchedulingStatus: React.FC<SchedulingCalendarStatusProps> = ({
  providerId,
  providerCalendarState,
  schedulingCredentialsValid,
  buttonDisabled,
  actions: { enableCalendar },
}) => {
  const { colors } = useTheme()
  const config = useSelector(getAuthConfig)
  const [isSubmissionModalOpen, setSubmissionModalOpen] = useState(false)
  // Credentials.valid supersedes ProviderCalendarStatus, while this should only matter on staging due to data
  // inaccuracies, the behavior needs to be continued here to support previous functionality
  const startingCalendarState = schedulingCredentialsValid
    ? 'ProviderCalendarStatus.ONLINE_BOOKING_LIVE'
    : providerCalendarState
  const [statusBarCalendarState, setStatusBarCalendarState] = useState(startingCalendarState)
  const onStartSubmission = () => {
    track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.SCHEDULING_BEGIN_CALENDAR_SUBMISSION })
    setSubmissionModalOpen(true)
  }
  const submitCalendars = () => {
    track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.SCHEDULING_COMPLETE_CALENDAR_SUBMISSION })
    enableCalendar(providerId)
    setStatusBarCalendarState('ProviderCalendarStatus.ONLINE_BOOKING_PENDING')
    setSubmissionModalOpen(false)
  }
  const closeModal = () => {
    track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.SCHEDULING_CLOSE_SUBMISSION_MODAL })
    setSubmissionModalOpen(false)
  }

  const renderDescriptionLink = (calendarStatusInfo: { description: string; linkText: string }) => {
    const linkAddress = config?.internationalSmartSchedulingEnabled
      ? 'https://icas-provider-support.zendesk.com/hc/en-us/requests/new'
      : 'https://provider-support.lyrahealth.com/hc/en-us/requests/new?ticket_form_id=26162178922387'
    const mixpanelAction = config?.internationalSmartSchedulingEnabled
      ? actions.SCHEDULING_INTERNATIONAL_STATUS_CONTACT_US
      : actions.SCHEDULING_STATUS_CONTACT_US
    return (
      <>
        {calendarStatusInfo.description}
        <Link
          text={calendarStatusInfo.linkText}
          onPress={() => {
            track({ event: mixpanelEvents.BUTTON_PRESS, action: mixpanelAction })
            window.open(linkAddress, '_blank')
          }}
          size={BodyTextSize.SMALL}
        />
      </>
    )
  }

  const isCalendarLive = statusBarCalendarState === 'ProviderCalendarStatus.ONLINE_BOOKING_LIVE'

  const calendarSubmitted = [
    'ProviderCalendarStatus.ONLINE_BOOKING_PENDING',
    'ProviderCalendarStatus.ONLINE_BOOKING_LIVE',
  ].includes(statusBarCalendarState)

  const calendarConstantIdentifier = calendarSubmitted
    ? statusBarCalendarState
    : buttonDisabled
    ? 'noneSelected'
    : 'selected'

  const calendarStatusConstants = SchedulingCalendarStatuses[calendarConstantIdentifier]

  return (
    <ContentContainer>
      <Modal
        onCloseEnd={closeModal}
        onRequestClose={closeModal}
        disableBottomSheet={true}
        visible={isSubmissionModalOpen}
        modalContents={<SchedulingSubmission closeModal={closeModal} submitCalendars={submitCalendars} />}
        width='518px'
        closeOnScrim
        scrollable
        scrollableModalHeight='100%'
      />
      <StatusSectionContainer>
        <StatusContentWrapper>
          <StatusBadgeTextWrapper>
            <BadgeContainer>
              <IconBadge
                text={
                  <BodyText
                    color={isCalendarLive ? colors.textSuccess : colors.textWarning}
                    size={BodyTextSize.BADGE}
                    text={calendarStatusConstants.badgeText}
                  />
                }
                color={isCalendarLive ? colors.backgroundSuccess : colors.backgroundWarning}
              />
            </BadgeContainer>
            <BodyText
              color={colors.textSecondary}
              size={BodyTextSize.SMALL}
              text={
                calendarStatusConstants.linkText
                  ? renderDescriptionLink(calendarStatusConstants)
                  : calendarStatusConstants.description
              }
            />
          </StatusBadgeTextWrapper>
          {!calendarSubmitted && !schedulingCredentialsValid && (
            <StatusButtonContainer>
              <PrimaryButton
                text='Submit'
                onPress={onStartSubmission}
                disabled={buttonDisabled}
                testID={tID('StartSubmitCalendar-button')}
              />
            </StatusButtonContainer>
          )}
        </StatusContentWrapper>
      </StatusSectionContainer>
    </ContentContainer>
  )
}

type SchedulingCalendarStatusProps = {
  providerId: string
  providerCalendarState: string
  schedulingCredentialsValid: boolean
  buttonDisabled: boolean
  actions: {
    enableCalendar: (providerId: string) => void
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    actions: bindActionCreators(
      {
        enableCalendar,
      },
      dispatch,
    ),
  }
}

export default connect(null, mapDispatchToProps)(SchedulingStatus)
