import { createSelector } from 'reselect'

import { RootState } from '../../data/store'

export const getSchedulingStateData = (state: RootState) => state?.scheduling

export const getSchedulingLoaded = createSelector(
  getSchedulingStateData,
  (schedulingData) => schedulingData?.schedulingLoaded,
)

export const getSchedulingCredentials = createSelector(
  getSchedulingStateData,
  (schedulingData) => schedulingData?.credentials,
)

export const getSchedulingCredentialsValid = createSelector(
  getSchedulingCredentials,
  (schedulingCredentials) => schedulingCredentials?.valid,
)

export const getSchedulingAvailability = createSelector(
  getSchedulingStateData,
  (schedulingData) => schedulingData?.availability,
)

export const getSchedulingCalendars = createSelector(
  getSchedulingStateData,
  (schedulingData) => schedulingData?.calendars,
)

export const getSchedulingAuthUrl = createSelector(getSchedulingStateData, (schedulingData) => schedulingData?.auth_url)

export const getSchedulingError = createSelector(getSchedulingStateData, (schedulingData) => schedulingData?.error)

export const getSchedulingOAuthStatus = createSelector(
  getSchedulingStateData,
  (schedulingData) => schedulingData?.oauthStatus,
)
