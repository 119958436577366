import { createReducer } from '@reduxjs/toolkit'

import { ProviderAdminProviderInfo } from '@lyrahealth-inc/shared-app-logic'

import { SET_MY_INFO_DATA } from '../../common/constants/reduxConstants'

const initialState = { data: undefined }

export default createReducer<{ data?: ProviderAdminProviderInfo }>(initialState, (builder) => {
  builder.addCase(SET_MY_INFO_DATA, (state, action: any) => {
    state = action.data
    return state
  })
})
