import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { find } from 'lodash-es'

import { Capacity } from '@lyrahealth-inc/shared-app-logic'
import { Drawer, DropdownButton, RadioGroup } from '@lyrahealth-inc/ui-core'

import styles from './clientListOptions.module.scss'
import {
  BCC_ROLES,
  BCT_ROLES,
  episodeProgress,
  programConfig,
  programNameTitleMap,
  ROLES,
} from '../../../common/constants/appConstants'
import { hasRole } from '../../../common/utils/utils'
import { getAuthRoles } from '../../../data/auth/authSelectors'

const ClientListOptions: React.FC<ClientListOptionsProps> = ({
  sortOptionSelected,
  showActiveClients,
  setShowUnscheduledOnly,
  capacities = {},
  setEpisodeProgressFilter,
  setProgramFilter,
}) => {
  const [selectedSortBy, setSelectedSortBy] = useState<string>('next_appointment')
  const [selectedStatusOption, setSelectedStatusOption] = useState<string>('Active')
  const [selectedProgramOption, setSelectedProgramOption] = useState<string>('All')
  const [selectedSessionOption, setSelectedSessionOption] = useState<string>('All')
  const [selectedEpisodeProgressOption, setSelectedEpisodeProgressOption] = useState<string>('All')
  const userRoles = useSelector(getAuthRoles)
  const useLongerEpisodeCopy = hasRole(userRoles, [...BCC_ROLES, ...BCT_ROLES, ROLES.LT_AUD_THERAPIST])

  const sortOptions = [
    {
      text: 'Alphabetically',
      id: 'first_name',
      selectHandler: () => {
        sortOptionSelected('first_name')
        setSelectedSortBy('first_name')
      },
    },
    {
      text: 'Upcoming session',
      id: 'next_appointment',
      selectHandler: () => {
        sortOptionSelected('next_appointment')
        setSelectedSortBy('next_appointment')
      },
    },
    {
      text: 'Longest episode',
      id: 'session_number',
      selectHandler: () => {
        sortOptionSelected('session_number')
        setSelectedSortBy('session_number')
      },
    },
  ]
  const clientStatusOptions = [
    {
      text: 'Active',
      value: 'Active',
      id: '0',
    },
    {
      text: 'Inactive',
      value: 'Inactive',
      id: '1',
    },
  ]

  const clientProgramOptions = [
    {
      text: 'All',
      value: 'All',
      id: '0',
    },
  ]
  Object.keys(capacities).forEach((program) => {
    const id = parseInt(clientProgramOptions[clientProgramOptions.length - 1].id) + 1
    clientProgramOptions.push({
      text: programNameTitleMap[program],
      value: program,
      id: id.toString(),
    })
  })
  const nextSessionOptions = [
    {
      text: 'All',
      value: 'All',
      id: '0',
    },
    {
      text: 'Unscheduled',
      value: 'Unscheduled',
      id: '1',
    },
  ]

  const episodeProgressOptions = [
    {
      text: episodeProgress.ALL,
      value: episodeProgress.ALL,
      id: '0',
    },
    {
      text: episodeProgress.INTAKE_ONLY,
      value: episodeProgress.INTAKE_ONLY,
      id: '1',
    },
    {
      text: episodeProgress.FOLLOWUP_ONLY,
      value: episodeProgress.FOLLOWUP_ONLY,
      id: '2',
    },
    {
      text: useLongerEpisodeCopy ? episodeProgress.LONGER_EPISODES : episodeProgress.OVER_EPISODE_COUNT,
      value: useLongerEpisodeCopy ? episodeProgress.LONGER_EPISODES : episodeProgress.OVER_EPISODE_COUNT,
      id: '3',
    },
  ]

  return (
    <div className={styles['options-container']}>
      <div className={styles['section-title']}>Sort</div>
      <DropdownButton
        styling='inline'
        dropdownItems={sortOptions}
        menuItemsWrap={false}
        pullLeft
        className={styles.dropdown}
        id='ClientListOptions-sort-options'
      >
        <span>{find(sortOptions, ['id', selectedSortBy])?.text}</span>
      </DropdownButton>
      <div className={styles['section-title']}>Filter</div>
      <Drawer title='Status' selectedValue={selectedStatusOption}>
        <RadioGroup
          input={{
            name: 'client-status-group',
            onChange: (selectedOption: string) => {
              setSelectedStatusOption(selectedOption)
              showActiveClients(selectedOption === clientStatusOptions[0].value)
            },
            value: selectedStatusOption as any,
            onBlur: () => {},
            onFocus: () => {},
          }}
          buttons={clientStatusOptions}
          meta={{}}
          isStacked
        />
      </Drawer>
      <Drawer title='Next session' selectedValue={selectedSessionOption}>
        <RadioGroup
          input={{
            name: 'next-session-group',
            onChange: (selectedOption: string) => {
              setSelectedSessionOption(selectedOption)
              setShowUnscheduledOnly(selectedOption === nextSessionOptions[1].value)
            },
            value: selectedSessionOption as any,
            onBlur: () => {},
            onFocus: () => {},
          }}
          buttons={nextSessionOptions}
          meta={{}}
          isStacked
        />
      </Drawer>
      <Drawer title='Episode progress' selectedValue={selectedEpisodeProgressOption}>
        <RadioGroup
          input={{
            name: 'episode-progress-group',
            onChange: (selectedOption: string) => {
              setSelectedEpisodeProgressOption(selectedOption)
              setEpisodeProgressFilter(selectedOption)
            },
            value: selectedEpisodeProgressOption as any,
            onBlur: () => {},
            onFocus: () => {},
          }}
          buttons={
            Object.keys(capacities).some(
              (capacity) =>
                programConfig[capacity] &&
                'recommendedEpisodeLength' in programConfig[capacity] &&
                programConfig[capacity].recommendedEpisodeLength !== null,
            )
              ? episodeProgressOptions
              : episodeProgressOptions.slice(0, 3)
          }
          meta={{}}
          isStacked
        />
      </Drawer>
      {Object.keys(capacities).length > 1 && (
        <Drawer title='Program' selectedValue={programNameTitleMap[selectedProgramOption]}>
          <RadioGroup
            input={{
              name: 'program-group',
              onChange: (selectedOption: string) => {
                setSelectedProgramOption(selectedOption)
                setProgramFilter(selectedOption)
              },
              value: selectedProgramOption as any,
              onBlur: () => {},
              onFocus: () => {},
            }}
            buttons={clientProgramOptions}
            meta={{}}
            isStacked
          />
        </Drawer>
      )}
    </div>
  )
}

type ClientListOptionsProps = {
  sortOptionSelected: (option: string) => void
  showActiveClients: (option: boolean) => void
  setShowUnscheduledOnly: (option: boolean) => void
  capacities: Capacity
  setEpisodeProgressFilter: (episodeProgress: string) => void
  setProgramFilter: (program: string) => void
}

export default ClientListOptions
