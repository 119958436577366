import React from 'react'
import { useSelector } from 'react-redux'

import HealthPlanInfo from '../common/components/healthPlanInfo/HealthPlanInfo'
import HealthPlanInfoNew from '../common/components/healthPlanInfo/HealthPlanInfoAdmins'
import { ROLES } from '../common/constants/appConstants'
import { hasRole } from '../common/utils/utils'
import { getAuthRoles } from '../data/auth/authSelectors'

export const HealthPlanInfoRoutes: React.FC = () => {
  const roles: string[] = useSelector(getAuthRoles)
  if (hasRole(roles, [ROLES.PRACTICES_ADMIN])) {
    return <HealthPlanInfoNew />
  }
  return <HealthPlanInfo />
}

export default HealthPlanInfoRoutes
