import React, { FunctionComponent, useCallback, useRef } from 'react'
import { FormattedMessage } from 'react-intl'

import BottomSheetGorhom from '@gorhom/bottom-sheet'
import styled, { useTheme } from 'styled-components/native'

import { BodyText, LoadingIndicator, Subhead } from '../../atoms'
import { Modal } from '../../organisms'
import { SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'

export type ProviderCalendarLoadingModalProps = {
  isVisible?: boolean
}

const Container = styled.View(({ theme }) => ({
  flexDirection: 'column',
  gap: theme.spacing['16px'],
  alignItems: 'center',
}))

const HeaderText = styled(Subhead)(({ theme }) => ({
  marginTop: theme.spacing['8px'],
}))

const LoadingContainer = styled.View({
  width: '140px',
  height: '140px',
  alignItems: 'center',
  justifyContent: 'center',
})

export const ProviderCalendarLoadingModal: FunctionComponent<ProviderCalendarLoadingModalProps> = ({
  isVisible = false,
}) => {
  const theme = useTheme() as ThemeType
  const ref = useRef<BottomSheetGorhom>(null)
  const openBottomSheet = useCallback(() => {
    ref.current?.expand()
  }, [])
  return (
    <Modal
      visible={isVisible}
      bottomSheetRef={ref}
      snapPoints={[550]}
      width='600px'
      onLayout={openBottomSheet}
      modalContents={
        <Container testID={tID('ProviderCalendarLoadingModal')}>
          <LoadingContainer>
            <LoadingIndicator topPadding={0} size={64} />
          </LoadingContainer>
          <HeaderText
            textAlign='center'
            size={SubheadSize.MEDIUM}
            text={
              <FormattedMessage
                defaultMessage='Setting up your calendar'
                description='Subhead for modal where calendar template is saving'
              />
            }
          />
          <BodyText
            textAlign='center'
            color={theme.colors.textSecondary}
            text={
              <FormattedMessage
                defaultMessage="Don't refresh. We're syncing your template with Google Calendar. This may take a couple of minutes. Your screen will refresh automatically once done."
                description='Body text for modal where calendar template is saving'
              />
            }
          />
        </Container>
      }
      onRequestClose={() => {}}
      onCloseEnd={() => {}}
    />
  )
}
