import React, { useCallback, useEffect, useState } from 'react'
import CSSModules from 'react-css-modules'
import { View } from 'react-native'
import { connect, ConnectedProps, useSelector } from 'react-redux'

import { addDays, differenceInHours, format, parseISO } from 'date-fns'
import { isEmpty } from 'lodash-es'
import { bindActionCreators } from 'redux'
import styled, { useTheme } from 'styled-components/native'

import {
  Capacity,
  DAILY_END_TIME,
  DAILY_START_TIME,
  ProviderAvailability,
  ProviderUser,
  SLICE_DURATION_USER_MINUTES,
  useFlags,
} from '@lyrahealth-inc/shared-app-logic'
import {
  AlertIconStroke,
  BodyText,
  BodyTextSize,
  LoadingSkeleton,
  Modal,
  tID,
  toJS,
  useFetcher,
  useVisibilityChange,
} from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './availabilityCard.module.scss'
import CapacitiesFull from './CapacitiesFull'
import CapacitiesMinified from './CapacitiesMinified'
import CaseloadCapacities from './CaseloadCapacities'
import { mixpanelEvents } from '../../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../../mixpanel/mixpanelTracking'
import { getAuthConfig, getAuthProgramTaxonomies, getAuthUser } from '../../../data/auth/authSelectors'
import { getClientAppointmentsProviderAvailability } from '../../../data/lyraTherapy/clientSelectors'
import { getRequestPaymentSelectedProvider } from '../../../data/requestPayment/requestPaymentSelectors'
import { AvailabilityAndCapacityMismatchModal } from '../../../lyraTherapy/clients/AvailabilityAndCapacityMismatchModal'
import { getProviderAvailabilityV2 } from '../../../lyraTherapy/clients/clientDetails/data/appointmentsAutoActions'
import ProviderAvailabilityModal from '../../../lyraTherapy/clients/ProviderAvailabilityModal'
import { BCC_ROLES, ROLES } from '../../constants/appConstants'
import { getTotalCapacity, hasRole } from '../../utils/utils'

export type Config = {
  bcPlatform: boolean
  videoSessionsEnabled: boolean
  medicationEnabled: boolean
  zendeskWidget: boolean
  requestPayment: boolean
  helpCenterUrl: string
  eligibilityChecker: boolean
  showInPersonPrompt: boolean
}

export type ProviderAvailabilityResponse = {
  availability: ProviderAvailability[]
}

const CalendarOpenSlotsContainer = styled.View({
  display: 'flex',
})

const RowContainer = styled.View({
  display: 'flex',
  flexDirection: 'row',
})

const CalendarOpenSlotsLoadingContainer = styled.View(({ theme }) => ({
  marginRight: theme.spacing['16px'],
  width: '220px',
  height: '50px',
}))

const CalendarOpenSlots = styled.View(({ theme }) => ({
  alignItems: 'center',
  borderRightWidth: '1px',
  borderColor: theme.colors.borderDefault,
  display: 'flex',
  flexDirection: 'row',
  marginRight: theme.spacing['16px'],
  paddingRight: theme.spacing['16px'],
}))

const CalendarAvailabilitiesBlock = styled.View(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.colors.backgroundSection,
  borderRadius: '5px',
  display: 'flex',
  fontSize: '34px',
  fontWeight: 600,
  height: '44px',
  justifyContent: 'center',
  paddingLeft: theme.spacing['4px'],
  paddingRight: theme.spacing['4px'],
  minWidth: '44px',
  width: 'fit-content',
}))

const CalendarAvailabilitiesTextContainer = styled.View(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: theme.spacing['8px'],
  marginRight: theme.spacing['8px'],
}))

const CalendarAvailabilitiesTitle = styled(BodyText)(({ theme }) => ({
  color: theme.colors.textPrimary,
  marginLeft: '0px',
}))

const CalendarAvailabilitiesDateRange = styled.View({
  paddingLeft: '0px',
})

const LowCalendarAvailabilitiesAlertContainer = styled.View(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.colors.backgroundWarning,
  borderRadius: theme.spacing['4px'],
  color: theme.colors.textWarning,
  display: 'flex',
  flexDirection: 'row',
  height: '26px',
  padding: `${theme.spacing['4px']} ${theme.spacing['8px']}`,
}))

const LowAvailabilityTextContainer = styled.View(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 700,
  marginLeft: theme.spacing['4px'],
  textTransform: 'uppercase',
}))

export const AvailabilityCard: React.FC<AvailablityCardProps> = ({
  isMinified = false,
  caseloadDashboardCapacity = false,
  hideCalendarAvailability = false,
  actions: { getProviderAvailabilityV2 },
}) => {
  const { colors } = useTheme()
  const [windowVisible] = useVisibilityChange()
  const selectedProvider = useSelector(getRequestPaymentSelectedProvider)
  const user = useSelector(getAuthUser)
  const config = useSelector(getAuthConfig)
  const providerAvailability: ProviderAvailabilityResponse[] =
    useSelector(getClientAppointmentsProviderAvailability) ?? []
  const authUserProgramTaxonomies = useSelector(getAuthProgramTaxonomies)
  const providerAvailabilities = providerAvailability && providerAvailability[0]?.availability
  const provider = selectedProvider || user
  const [isCapacityModalOpen, setCapacityModalOpen] = useState(false)
  const [isMismatchModalOpen, setMismatchModalOpen] = useState(false)
  const [availabilityCapacityMismatchCount, setAvailabilityCapacityMismatchCount] = useState(0)
  const availabilityUpdatedAt = provider?.capacity_updated_at
  const isStaleAvailability =
    !availabilityUpdatedAt || differenceInHours(new Date(), parseISO(availabilityUpdatedAt)) > 24
  const currentCapacity: Capacity = provider?.capacity_value ?? {}
  const [shouldRequestAvailability, toggleRequestProviderAvailability] = useState(isStaleAvailability)
  const { shouldShowCapacityToAvailabilityRatioAlert, showCalendarAvailabilitiesWithCapacity } = useFlags()
  const shouldSeeNewAvailabilityCard = config?.bcPlatform ?? false
  const hasProperRolesToSeeCalendarAvailabilities = hasRole(provider?.roles, BCC_ROLES)
  const [isLoading, , hasFetched] = useFetcher(
    [
      [
        getProviderAvailabilityV2,
        {
          providerId: provider?.id,
          params: {
            slice_duration_minutes: SLICE_DURATION_USER_MINUTES,
            start_datetime: new Date().toISOString(),
            end_datetime: addDays(new Date(), 14).toISOString(),
            daily_start_time: DAILY_START_TIME,
            daily_end_time: DAILY_END_TIME,
            clientele: (authUserProgramTaxonomies?.length && authUserProgramTaxonomies?.[0]?.clientele) ?? '',
            treatment: (authUserProgramTaxonomies?.length && authUserProgramTaxonomies?.[0]?.treatment) ?? '',
            partner: (authUserProgramTaxonomies?.length && authUserProgramTaxonomies?.[0]?.partner) ?? '',
            offering: (authUserProgramTaxonomies?.length && authUserProgramTaxonomies?.[0]?.offering) ?? '',
            appointment_class: 'initial',
            lead_time_hours: 0,
          },
        },
        windowVisible && hasProperRolesToSeeCalendarAvailabilities && shouldSeeNewAvailabilityCard,
      ],
    ],
    [windowVisible],
  )

  useEffect(() => {
    toggleRequestProviderAvailability(isStaleAvailability)
  }, [isStaleAvailability])
  const isCapacityProvider =
    config?.bcPlatform ||
    hasRole(provider?.roles, [ROLES.INDIVIDUAL_PROVIDER, ROLES.PRACTICE_PROVIDER]) ||
    selectedProvider !== undefined
  useEffect(() => {
    if (isCapacityProvider) {
      track({ event: 'SHOW_PROVIDER_CAPACITY' })
    }
  }, [isCapacityProvider])
  const providerName = selectedProvider ? `${provider?.first_name} ${provider?.last_name}` : `you`
  const currentAvailabilityStatusText = (program: string) => {
    const capacity = currentCapacity[program]
    return isCapacityProvider ? (
      capacity ? (
        capacity === 1 ? (
          `Accepting 1 new Lyra client`
        ) : (
          `Accepting up to ${capacity} new Lyra clients`
        )
      ) : (
        `NOT accepting new Lyra clients`
      )
    ) : (
      <>
        New clients see {providerName} as <b>{provider?.available ? 'available' : 'not available'}</b> to book on Lyra
      </>
    )
  }

  const handleUpdateClick = () => {
    isCapacityProvider ? setCapacityModalOpen(true) : toggleRequestProviderAvailability(true)
    track({ event: 'BUTTON_PRESS', action: isCapacityProvider ? 'UPDATE_CAPACITY' : 'UPDATE_AVAILABILITY' })
  }

  const handleMismatchModalOpen = useCallback(() => {
    if (!isMismatchModalOpen) {
      setMismatchModalOpen(true)
      track({ event: mixpanelEvents.CAPACITY_AVAILABILITY_ALERT_MODAL_SHOWN })
    }
  }, [isMismatchModalOpen])
  if (isCapacityProvider && !isEmpty(currentCapacity)) {
    return (
      <View testID={tID('AvailabilityCard-container')}>
        <Modal
          onCloseEnd={() => setCapacityModalOpen(false)}
          onRequestClose={() => setCapacityModalOpen(false)}
          disableBottomSheet={true}
          visible={isCapacityModalOpen}
          modalContents={
            <ProviderAvailabilityModal
              currentCapacity={currentCapacity}
              providerId={provider?.id as string}
              selectedProvider={selectedProvider || {}}
              triggerMismatchModal={handleMismatchModalOpen}
              setMismatchModalCount={setAvailabilityCapacityMismatchCount}
              providerAvailabilities={providerAvailabilities}
              closeModal={() => setCapacityModalOpen(false)}
            />
          }
          width='518px'
          closeOnScrim
          scrollable
          scrollableModalHeight='100%'
        />
        {shouldShowCapacityToAvailabilityRatioAlert && (
          <AvailabilityAndCapacityMismatchModal
            onClose={() => setMismatchModalOpen(false)}
            isModalOpen={isMismatchModalOpen}
            shortfall={availabilityCapacityMismatchCount}
            providerAvailabilitiesCount={providerAvailabilities?.length ?? 0}
          />
        )}
        {showCalendarAvailabilitiesWithCapacity && shouldSeeNewAvailabilityCard ? (
          <RowContainer testID={tID('AvailabilityCard-rowContainer')}>
            {shouldSeeNewAvailabilityCard && !hideCalendarAvailability && hasProperRolesToSeeCalendarAvailabilities && (
              <CalendarOpenSlotsContainer>
                {isLoading || !hasFetched ? (
                  <CalendarOpenSlotsLoadingContainer>
                    <LoadingSkeleton width='100%' height={50} />
                  </CalendarOpenSlotsLoadingContainer>
                ) : (
                  <CalendarOpenSlots testID={tID('AvailabilityCard-calendar-open-slots-container')}>
                    <CalendarAvailabilitiesBlock>{providerAvailabilities?.length ?? 0}</CalendarAvailabilitiesBlock>
                    <CalendarAvailabilitiesTextContainer>
                      <CalendarAvailabilitiesTitle>Calendar Availability</CalendarAvailabilitiesTitle>
                      <CalendarAvailabilitiesDateRange>
                        <BodyText
                          color={colors.textSecondary}
                          text={`${format(new Date(), 'M/d')}-${format(addDays(new Date(), 14), 'M/d')}`}
                          size={BodyTextSize.SMALL}
                        />
                      </CalendarAvailabilitiesDateRange>
                    </CalendarAvailabilitiesTextContainer>
                    {!hasRole(provider?.roles, [ROLES.LT_PRESCRIBER]) &&
                      getTotalCapacity(currentCapacity) > providerAvailabilities?.length && (
                        <LowCalendarAvailabilitiesAlertContainer
                          testID={tID('AvailabilityCard-low-availability-alert')}
                        >
                          <AlertIconStroke />
                          <LowAvailabilityTextContainer>
                            <BodyText text='Low' color={colors.textWarning} size={BodyTextSize.CAPTION} />
                          </LowAvailabilityTextContainer>
                        </LowCalendarAvailabilitiesAlertContainer>
                      )}
                  </CalendarOpenSlots>
                )}
              </CalendarOpenSlotsContainer>
            )}
            {caseloadDashboardCapacity ? (
              <div className={styles['caseload-capacities-container']}>
                <CaseloadCapacities
                  provider={provider as ProviderUser}
                  currentAvailabilityStatusText={(programName: string) => currentAvailabilityStatusText(programName)}
                  handleUpdateClick={handleUpdateClick}
                  currentCapacity={currentCapacity}
                />
              </div>
            ) : (
              <>
                {isMinified ? (
                  <div className={styles['minified-container']}>
                    <CapacitiesMinified
                      provider={provider as ProviderUser}
                      currentAvailabilityStatusText={(programName: string) =>
                        currentAvailabilityStatusText(programName)
                      }
                      handleUpdateClick={handleUpdateClick}
                      currentCapacity={currentCapacity}
                    />
                  </div>
                ) : (
                  <CapacitiesFull
                    provider={provider as ProviderUser}
                    selectedProvider={selectedProvider as unknown as ProviderUser}
                    currentAvailabilityStatusText={currentAvailabilityStatusText}
                    handleUpdateClick={handleUpdateClick}
                    shouldRequestAvailability={shouldRequestAvailability}
                    isCapacityProvider={isCapacityProvider}
                  />
                )}
              </>
            )}
          </RowContainer>
        ) : caseloadDashboardCapacity ? (
          <div className={styles['caseload-capacities-container']}>
            <CaseloadCapacities
              provider={provider as ProviderUser}
              currentAvailabilityStatusText={(programName: string) => currentAvailabilityStatusText(programName)}
              handleUpdateClick={handleUpdateClick}
              currentCapacity={currentCapacity}
            />
          </div>
        ) : (
          <>
            {isMinified ? (
              <div className={styles['minified-container']}>
                <CapacitiesMinified
                  provider={provider as ProviderUser}
                  currentAvailabilityStatusText={(programName: string) => currentAvailabilityStatusText(programName)}
                  handleUpdateClick={handleUpdateClick}
                  currentCapacity={currentCapacity}
                />
              </div>
            ) : (
              <CapacitiesFull
                provider={provider as ProviderUser}
                selectedProvider={selectedProvider as unknown as ProviderUser}
                currentAvailabilityStatusText={currentAvailabilityStatusText}
                handleUpdateClick={handleUpdateClick}
                shouldRequestAvailability={shouldRequestAvailability}
                isCapacityProvider={isCapacityProvider}
              />
            )}
          </>
        )}
      </View>
    )
  } else {
    return null
  }
}

type AvailablityCardProps = ConnectedProps<typeof connector> & {
  isMinified: boolean
  caseloadDashboardCapacity?: boolean
  hideCalendarAvailability?: boolean
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      getProviderAvailabilityV2,
    },
    dispatch,
  ),
})

const connector = connect(null, mapDispatchToProps)
export default connector(toJS(CSSModules(AvailabilityCard, styles, { allowMultiple: true })))
