import React from 'react'
import CSSModules from 'react-css-modules'
import { Linking } from 'react-native'
import { connect, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { NavLink } from 'react-router-dom'

import { isEmpty } from 'lodash-es'
import * as _ from 'lodash-es'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'

import { ProviderUser, useFlags } from '@lyrahealth-inc/shared-app-logic'
import { TypeAheadField } from '@lyrahealth-inc/ui-core'
import {
  ApplicationHeader,
  BodyText,
  BodyTextSize,
  Menu,
  TabDropdownProps,
  ThemeType,
  toJS,
} from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './header.module.scss'
import { actions, mixpanelEvents } from '../../../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../../../mixpanel/mixpanelTracking'
import {
  getAuthConfig,
  getAuthEmploymentStatus,
  getAuthIsLoggedIn,
  getAuthLTProviders,
  getAuthSupervisor,
  getAuthUser,
} from '../../../../data/auth/authSelectors'
import { logout, supervisorSelectProvider } from '../../../../data/authActions'
import { RootState } from '../../../../data/store'
import { getLTVideoSessionOpen } from '../../../../lyraTherapy/data/ltVideoSelectors'
import { getProvider } from '../../../../providers/individualProvider/data/providerDetailsActions'
import { BCT_ROLES, employmentTypes, ROLES } from '../../../constants/appConstants'
import {
  BC_CASELOAD,
  BC_DASHBOARD,
  BC_MEASUREMENT_BASED_CARE,
  BC_PERFORMANCE,
  BC_PLATFORM_ENGAGEMENT,
  CALENDAR,
  CLIENTS,
  DOWNLOADS,
  ELIGIBILITY_CHECKER,
  LC_CALENDAR_CURRENT,
  LT_CLIENTS,
  MY_INFO,
  PAYMENT_HISTORY,
  PAYMENTS,
  PRACTICES,
  PROVIDERS,
  RECONCILER,
  REQUEST_PAYMENT,
  SCHEDULING,
  SETTINGS,
} from '../../../constants/routingConstants'
import { hasRole } from '../../../utils/utils'

const ApplicationHeaderContainer = styled.View(({ videoSessionOpen }: { videoSessionOpen?: boolean }) => ({
  ...(videoSessionOpen && { marginTop: '90px' }),
  zIndex: 1,
}))

const ProviderDropdownContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginLeft: spacing['32px'],
  alignItems: 'center',
}))

const DropdownInputContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  marginLeft: spacing['16px'],
  marginBottom: '-10px',
}))

export const HeaderContainer = ({ videoSessionOpen, supervisorSelectProvider, logout, getProvider }: any) => {
  const user = useSelector(getAuthUser)
  const config = useSelector(getAuthConfig)
  const supervisor = useSelector(getAuthSupervisor)
  const employmentStatus = useSelector(getAuthEmploymentStatus)
  const BCProviders = useSelector(getAuthLTProviders)
  const isLoggedIn = useSelector(getAuthIsLoggedIn)

  const roles = user?.roles
  const id = user?.id
  const firstName = user?.first_name
  const lastName = user?.last_name
  const practiceName = user?.practice_name
  const isRegistered = user?.is_registered
  const outcomesEnabled = user?.outcomes_enabled
  const outcomesAgreed = user?.outcomes_agreed
  const schedulingEnabled = user?.scheduling_enabled
  const navigate = useNavigate()
  const location = useLocation()
  const logoClickHandler = () => {
    return videoSessionOpen ? null : navigate('/')
  }
  // @ts-expect-error TS(7030): Not all code paths return a value.
  const selectProvider = async (selections: any) => {
    if (!selections || selections.length !== 1 || selections[0].id === id) return false
    let provider = BCProviders.find(({ id }: any) => id === selections[0].id)
    const providerData = await getProvider(provider?.id)
    provider = {
      ...provider,
      program_taxonomies: providerData?.data?.data?.program_taxonomies,
      employment_status: providerData?.data?.data?.employment_status,
    } as ProviderUser
    supervisorSelectProvider(provider)
    navigate(LT_CLIENTS.route)
  }

  const isBCCSupervisor =
    supervisor &&
    !isEmpty(supervisor.roles) &&
    hasRole(supervisor.roles, [ROLES.LT_COACH_SUPERVISOR, ROLES.LT_SS_COACH_SUPERVISOR])

  const newCaseloadDashboardVersion =
    hasRole(roles, [ROLES.LT_COACH, ROLES.LT_SS_COACH]) &&
    employmentStatus &&
    [employmentTypes.FULL_TIME, employmentTypes.PART_TIME].includes(employmentStatus)

  let loggedInButtons: any = []
  let tabs: TabDropdownProps[] = []
  const dropDownItems = []
  const menuItems = []
  const logoItems = []
  const hideLoggedInButtons = !!location.state && location.state.withoutNav
  const {
    isSmartSchedulingEnabled,
    isInternationalSmartSchedulingEnabled,
    shouldShowNewSmartSchedulingUI,
    showPlatformEngagementDashboard,
    isInProductCalendarEnabled,
    showCaseloadFromDropdown,
  } = useFlags()

  if (isRegistered && !hideLoggedInButtons) {
    if (hasRole(roles, [ROLES.INDIVIDUAL_PROVIDER, ROLES.PRACTICE_PROVIDER, ROLES.ICAS_PROVIDER])) {
      if (outcomesEnabled || hasRole(roles, ROLES.ICAS_PROVIDER)) {
        loggedInButtons.push(
          <li key={_.uniqueId('headerNavLink_')}>
            <NavLink className='updated-look-feel' to={CLIENTS.route} data-test-id='Header-DAClientslink'>
              Clients
              {!outcomesAgreed && (
                <svg className='nav-flag' height='6' width='6'>
                  <circle cx='3' cy='3' r='3' fill={styles.x_alert} />
                </svg>
              )}
            </NavLink>
          </li>,
        )
        tabs.push({
          activePathname: CLIENTS.route,
          currentPathname: location.pathname,
          testID: 'Header-DAClientslink',
          text: 'Clients',
          onPress: () => navigate(CLIENTS.route),
        })
      }
    }

    // Reconciler
    if (hasRole(roles, ROLES.RECONCILER)) {
      loggedInButtons.push(
        <li>
          <NavLink className='updated-look-feel' key={_.uniqueId('headerNavLink_')} to={RECONCILER.route}>
            ID Reconciler
          </NavLink>
        </li>,
      )
      tabs.push({
        activePathname: RECONCILER.route,
        currentPathname: location.pathname,
        testID: 'Header-idReconcilerLink',
        text: 'ID Reconciler',
        onPress: () => navigate(RECONCILER.route),
      })
    }

    if (config?.requestPayment) {
      loggedInButtons.push(
        <li key={_.uniqueId('headerNavLink_')}>
          <NavLink className='updated-look-feel' to={REQUEST_PAYMENT.route}>
            Request Payment
          </NavLink>
        </li>,
      )
      loggedInButtons.push(
        <li key={_.uniqueId('headerNavLink_')}>
          <NavLink className='updated-look-feel' to={PAYMENT_HISTORY.route}>
            Payment History
          </NavLink>
        </li>,
      )
      tabs.push({
        activePathname: REQUEST_PAYMENT.route,
        currentPathname: location.pathname,
        testID: 'Header-requestPaymentLink',
        text: 'Request Payment',
        onPress: () => navigate(REQUEST_PAYMENT.route),
      })
      tabs.push({
        activePathname: PAYMENT_HISTORY.route,
        currentPathname: location.pathname,
        testID: 'Header-paymentHistoryLink',
        text: 'Payment History',
        onPress: () => navigate(PAYMENT_HISTORY.route),
      })
    }

    // All DA-related roles
    if (config?.eligibilityChecker) {
      loggedInButtons.push(
        <li key={_.uniqueId('headerNavLink_')}>
          <NavLink className='updated-look-feel' to={ELIGIBILITY_CHECKER.route} data-test-id='Header-eligibilityLink'>
            Eligibility
          </NavLink>
        </li>,
      )
      tabs.push({
        activePathname: ELIGIBILITY_CHECKER.route,
        currentPathname: location.pathname,
        testID: 'Header-eligibilityLink',
        text: 'Eligibility',
        onPress: () => navigate(ELIGIBILITY_CHECKER.route),
      })
    }

    // Individual and Practice Providers; Excluding BC Providers but including ICAS Providers behind their own additional flag
    if (
      isSmartSchedulingEnabled &&
      (config?.smartSchedulingEnabled ||
        (config?.internationalSmartSchedulingEnabled && isInternationalSmartSchedulingEnabled))
    ) {
      loggedInButtons.push(
        <li key={_.uniqueId('headerNavLink_')}>
          <NavLink
            className='updated-look-feel'
            to={shouldShowNewSmartSchedulingUI ? SCHEDULING.route : CALENDAR.route}
            data-test-id='Header-calendarLink'
            onClick={() => track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.CALENDAR_NAV_BAR })}
          >
            Calendar
          </NavLink>
        </li>,
      )
      tabs.push({
        activePathname: shouldShowNewSmartSchedulingUI ? SCHEDULING.route : CALENDAR.route,
        currentPathname: location.pathname,
        testID: 'Header-calendarLink',
        text: 'Calendar',
        onPress: () => navigate(shouldShowNewSmartSchedulingUI ? SCHEDULING.route : CALENDAR.route),
      })
    }

    // Both Individual Provider and Practice Provider
    if (hasRole(roles, [ROLES.INDIVIDUAL_PROVIDER, ROLES.PRACTICE_PROVIDER])) {
      // We only push this to dropdown for DA providers if the phase 1 flag is false, additionally only push for ICAS
      // providers if their flag is also false.
      if (
        !isSmartSchedulingEnabled ||
        (!config?.smartSchedulingEnabled &&
          (!config?.internationalSmartSchedulingEnabled || !isInternationalSmartSchedulingEnabled))
      ) {
        if (schedulingEnabled) {
          dropDownItems.push(
            <li key={_.uniqueId('headerNavLink_')}>
              <NavLink
                className='updated-look-feel'
                to={CALENDAR.route}
                data-test-id='Dropdown-calendarLink'
                onClick={() => track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.CALENDAR_DROPDOWN })}
              >
                Calendar
              </NavLink>
            </li>,
          )
          if (isInProductCalendarEnabled) {
            menuItems.push({
              text: 'Settings',
              id: 'menuSettings',
              testId: 'Header-menuSettings',
              onPressItem: () => {
                track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.NAVIGATION_MENU_SETTINGS })
                navigate(SETTINGS.route)
              },
            })
          } else {
            menuItems.push({
              text: 'Calendar',
              id: 'menuCalendar',
              testId: 'Header-menuCalendar',
              onPressItem: () => {
                track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.CALENDAR_DROPDOWN })
                navigate(CALENDAR.route)
              },
            })
          }
        }
      }
    }

    // Practice Admin and Individual Provider
    if (hasRole(roles, [ROLES.PRACTICES_ADMIN, ROLES.INDIVIDUAL_PROVIDER])) {
      dropDownItems.push(
        <li key={_.uniqueId('headerNavLink_')}>
          <NavLink className='updated-look-feel' to={DOWNLOADS.route}>
            Downloads
          </NavLink>
        </li>,
      )
      menuItems.push({
        text: 'Downloads',
        id: 'menuDownloads',
        testId: 'Header-menuDownloads',
        onPressItem: () => {
          navigate(DOWNLOADS.route)
        },
      })
    }

    // Individual Provider, Practice Provider and Practice Admin
    if (hasRole(roles, [ROLES.INDIVIDUAL_PROVIDER, ROLES.PRACTICE_PROVIDER, ROLES.PRACTICES_ADMIN])) {
      dropDownItems.push(
        <li key={_.uniqueId('headerNavLink_')}>
          <NavLink className='updated-look-feel' to={MY_INFO.route}>
            My Info
          </NavLink>
        </li>,
      )
      dropDownItems.push(
        <li key={_.uniqueId('headerNavLink_')}>
          <a
            className='updated-look-feel'
            target='_blank'
            href={config?.helpCenterUrl || 'https://provider-support.lyrahealth.com/'}
            rel='noreferrer'
          >
            Help Center
          </a>
        </li>,
      )
      menuItems.push({
        text: 'My Info',
        id: 'menuMyInfo',
        testId: 'Header-menuMyInfo',
        onPressItem: () => {
          navigate(MY_INFO.route)
        },
      })
      menuItems.push({
        text: 'Help Center',
        id: 'menuHelpCenter',
        testId: 'Header-menuHelpCenter',
        onPressItem: () => {
          track({
            event: mixpanelEvents.BUTTON_PRESS,
            action: actions.HELP_CENTER_DROPDOWN,
            details: { helpCenterURL: config?.helpCenterUrl || 'https://provider-support.lyrahealth.com/' },
          })
          Linking.openURL(config?.helpCenterUrl || 'https://provider-support.lyrahealth.com/')
        },
      })
    }

    // Both Providers Admin and Payment Admin
    if (hasRole(roles, [ROLES.PROVIDERS_ADMIN, ROLES.PAYMENTS_ADMIN])) {
      loggedInButtons.push(
        <li>
          <NavLink className='updated-look-feel' key={_.uniqueId('headerNavLink_')} to={PROVIDERS.route}>
            Providers
          </NavLink>
        </li>,
      )
      loggedInButtons.push(
        <li>
          <NavLink className='updated-look-feel' key={_.uniqueId('headerNavLink_')} to={PRACTICES.route}>
            Practices
          </NavLink>
        </li>,
      )
      tabs.push({
        activePathname: PROVIDERS.route,
        currentPathname: location.pathname,
        testID: 'Header-providersLink',
        text: 'Providers',
        onPress: () => navigate(PROVIDERS.route),
      })
      tabs.push({
        activePathname: PRACTICES.route,
        currentPathname: location.pathname,
        testID: 'Header-practicesLink',
        text: 'Practices',
        onPress: () => navigate(PRACTICES.route),
      })
    }

    // Payments Admin only
    if (hasRole(roles, ROLES.PAYMENTS_ADMIN)) {
      loggedInButtons.push(
        <li>
          <NavLink className='updated-look-feel' key={_.uniqueId('headerNavLink_')} to={PAYMENTS.route}>
            Payments
          </NavLink>
        </li>,
      )
      tabs.push({
        activePathname: PAYMENTS.route,
        currentPathname: location.pathname,
        testID: 'Header-paymentsLink',
        text: 'Payments',
        onPress: () => navigate(PAYMENTS.route),
      })
    }

    // Lyra Therapy
    if (config?.bcPlatform) {
      const inVideoSessionForbiddenLinkProps = videoSessionOpen
        ? { onClick: (e: any) => e.preventDefault(), style: { cursor: 'not-allowed' } }
        : {}

      loggedInButtons = [
        <li key={_.uniqueId('headerNavLink_')}>
          <NavLink
            className='updated-look-feel'
            to={BC_DASHBOARD.route}
            {...inVideoSessionForbiddenLinkProps}
            data-test-id='Header-todoLink'
          >
            To-do
          </NavLink>
        </li>,
        <li key={_.uniqueId('headerNavLink_')}>
          <NavLink
            className='updated-look-feel'
            to={LT_CLIENTS.route}
            {...inVideoSessionForbiddenLinkProps}
            data-test-id='Header-clientsLink'
          >
            Clients
          </NavLink>
        </li>,
      ]
      tabs = [
        {
          activePathname: BC_DASHBOARD.route,
          currentPathname: location.pathname,
          disabled: videoSessionOpen,
          text: 'To-do',
          testID: 'Header-todoLink',
          onPress: () => navigate(BC_DASHBOARD.route),
        },
      ]

      if (isInProductCalendarEnabled) {
        tabs.push({
          activePathname: LC_CALENDAR_CURRENT.route,
          currentPathname: location.pathname,
          testID: 'Header-lcCalendarLink',
          disabled: videoSessionOpen,
          text: 'Calendar',
          onPress: () => navigate(LC_CALENDAR_CURRENT.route),
        })
      }

      tabs.push({
        activePathname: LT_CLIENTS.route,
        currentPathname: location.pathname,
        disabled: videoSessionOpen,
        text: 'Clients',
        testID: 'Header-clientsLink',
        onPress: () => navigate(LT_CLIENTS.route),
      })

      if (config.utilizationEnabled) {
        loggedInButtons.push(
          <li key={_.uniqueId('headerNavLink_')}>
            <NavLink
              className='updated-look-feel'
              to={BC_PERFORMANCE.route}
              {...inVideoSessionForbiddenLinkProps}
              data-test-id='Header-caseloadLink'
            >
              Caseload
            </NavLink>
          </li>,
        )
        isBCCSupervisor &&
          tabs.push({
            activePathname: newCaseloadDashboardVersion ? BC_CASELOAD.route : BC_PERFORMANCE.route,
            activePathnameExceptions: [BC_MEASUREMENT_BASED_CARE.route, BC_PLATFORM_ENGAGEMENT.route],
            currentPathname: location.pathname,
            disabled: videoSessionOpen,
            onPress: () => (newCaseloadDashboardVersion ? navigate(BC_CASELOAD.route) : navigate(BC_PERFORMANCE.route)),
            text: 'Caseload',
            testID: 'Header-caseloadLink',
          })
        !isBCCSupervisor &&
          tabs.push({
            activePathname: BC_PERFORMANCE.route,
            currentPathname: location.pathname,
            disabled: videoSessionOpen,
            onPress: () => navigate(BC_PERFORMANCE.route),
            text: 'Caseload',
            testID: 'Header-caseloadLink',
            dropdownWidth: 217,
            subTabs: [
              ...(hasRole(roles, BCT_ROLES) && showCaseloadFromDropdown
                ? [
                    {
                      activePathname: BC_PERFORMANCE.route,
                      activePathnameExceptions: [BC_MEASUREMENT_BASED_CARE.route, BC_PLATFORM_ENGAGEMENT.route],
                      currentPathname: location.pathname,
                      text: 'Caseload',
                      onPress: () => navigate(BC_PERFORMANCE.route),
                    },
                    {
                      activePathname: BC_MEASUREMENT_BASED_CARE.route,
                      currentPathname: location.pathname,
                      text: 'Measurement Based Care',
                      onPress: () => navigate(BC_MEASUREMENT_BASED_CARE.route),
                    },
                  ]
                : []),
              ...(showPlatformEngagementDashboard && hasRole(roles, BCT_ROLES)
                ? [
                    {
                      activePathname: BC_PLATFORM_ENGAGEMENT.route,
                      currentPathname: location.pathname,
                      text: 'Platform Engagement',
                      onPress: () => navigate(BC_PLATFORM_ENGAGEMENT.route),
                    },
                  ]
                : []),
            ],
          })
      }

      if (config.requestPayment) {
        loggedInButtons.push(
          <li>
            <NavLink
              className='updated-look-feel'
              key={_.uniqueId('headerNavLink_')}
              to={REQUEST_PAYMENT.route}
              {...inVideoSessionForbiddenLinkProps}
              data-test-id='Header-requestPaymentLink'
            >
              Request Payment
            </NavLink>
          </li>,
        )
        loggedInButtons.push(
          <li>
            <NavLink
              className='updated-look-feel'
              key={_.uniqueId('headerNavLink_')}
              to={PAYMENT_HISTORY.route}
              {...inVideoSessionForbiddenLinkProps}
              data-test-id='Header-paymentHistoryLink'
            >
              Payment History
            </NavLink>
          </li>,
        )
        tabs.push({
          activePathname: REQUEST_PAYMENT.route,
          currentPathname: location.pathname,
          disabled: videoSessionOpen,
          testID: 'Header-requestPaymentLink',
          text: 'Request Payment',
          onPress: () => navigate(REQUEST_PAYMENT.route),
        })
        tabs.push({
          activePathname: PAYMENT_HISTORY.route,
          currentPathname: location.pathname,
          disabled: videoSessionOpen,
          testID: 'Header-paymentHistoryLink',
          text: 'Payment History',
          onPress: () => navigate(PAYMENT_HISTORY.route),
        })
      }
    }

    // LT Supervisor
    if (!_.isEmpty(supervisor) && !_.isEmpty(BCProviders)) {
      const providerlistDropdown = BCProviders.reduce((res: any, provider: any) => {
        const label = `${provider.first_name} ${provider.last_name} - ${provider.id}`
        return [...res, { label, id: provider.id }]
      }, [])
      logoItems.push(
        <ProviderDropdownContainer>
          <BodyText size={BodyTextSize.LARGE} text='Provider:' />
          <DropdownInputContainer>
            {/* @ts-expect-error TS(2739): Type '{ placeholder: string; emptyLabel: string; m... Remove this comment to see the full error message */}
            <TypeAheadField
              placeholder='Select a provider...'
              emptyLabel='No provider found'
              multiSelect={false}
              options={providerlistDropdown}
              input={{ onChange: selectProvider, onBlur: selectProvider, name: 'supervisor-selector-for-provider' }}
            />
          </DropdownInputContainer>
        </ProviderDropdownContainer>,
      )
    }
  } // end is_registered

  if (isLoggedIn) {
    menuItems.push({
      text: 'Log out',
      id: 'menuLogOut',
      testId: 'Header-menuLogOut',
      onPressItem: () => {
        logout(true)
      },
    })
  }

  return (
    <ApplicationHeaderContainer videoSessionOpen={videoSessionOpen}>
      <ApplicationHeader
        currentPathname={location.pathname}
        handleLogoClick={logoClickHandler}
        tabs={tabs}
        title={practiceName ? `${practiceName}` : firstName || lastName ? `${firstName} ${lastName}` : undefined}
        logoItems={logoItems}
        menu={
          menuItems.length > 0 ? (
            <Menu menuItems={menuItems} testID={'Header-menu'} horizontalPosition='right' disableBottomSheetForWeb />
          ) : undefined
        }
      />
    </ApplicationHeaderContainer>
  )
}

HeaderContainer.propTypes = {
  videoSessionOpen: PropTypes.bool,
  supervisorSelectProvider: PropTypes.func,
  logout: PropTypes.func,
}

const mapStateToProps = (state: RootState) => ({
  videoSessionOpen: getLTVideoSessionOpen(state),
})

export default connect(mapStateToProps, { supervisorSelectProvider, logout, getProvider })(
  toJS(CSSModules(HeaderContainer, styles, { allowMultiple: true })),
)
