import React, { Component } from 'react'
import CSSModules from 'react-css-modules'
import { connect } from 'react-redux'

import { cloneDeep, get, isEmpty, isNil } from 'lodash-es'
import queryString from 'query-string'
import { bindActionCreators } from 'redux'

import { Assignment, AssignmentResponse, ClientObject, ConversationObject } from '@lyrahealth-inc/shared-app-logic'
import { BootstrapContainer, LoadingIndicator, Worksheet } from '@lyrahealth-inc/ui-core'

import styles from './assignmentDetails.module.scss'
import { track } from '../../../../../mixpanel/mixpanelTracking'
import { CLIENT_HOME, LT_CLIENTS } from '../../../common/constants/routingConstants'
import { getAuthIsLTSupervisor, getAuthUserId } from '../../../data/auth/authSelectors'
import {
  getClientAssignmentDetails,
  getClientCurrentSessionCount,
  getClientDetailsData,
} from '../../../data/lyraTherapy/clientSelectors'
import { RootState } from '../../../data/store'
import { getProviderFullName } from '../../../providers/data/providerSelectors'
import withRouter from '../../../routing/withRouter'
import { fetchMessage, sendMessage } from '../../messages/data/messagesAutoActions'
import { getSelectedConversation } from '../../messages/data/messagesSelectors'
import MiniBackNav from '../../miniBackNav/MiniBackNav'
import {
  clearSelectedAssignment,
  decreaseAssignmentUnreadCount,
  getAssignment,
  getAssignments,
  updateAssignmentResponse,
} from '../data/assignmentsAutoActions'

type AssignmentDetailsProps = {
  selectedAssignment?: Assignment
  clientDetails?: ClientObject
  router?: any
  actions?: any
  selectedConversation?: ConversationObject
  userId?: string
  currentSessionCount?: number
  isLtSupervisor: boolean
  providerFullname: string
}

class AssignmentDetails extends Component<AssignmentDetailsProps> {
  sessionCount: number

  constructor(props: AssignmentDetailsProps) {
    super(props)
    this.sessionCount = get(props.router, 'location.state.sessionCount')
  }

  componentDidMount() {
    const {
      selectedAssignment,
      router: {
        location: { search, state },
      },
      actions: { getAssignment },
    } = this.props
    const assignmentId = get(state, 'assignmentId') || get(queryString.parse(search), 'assignmentId')
    if (!selectedAssignment) {
      if (assignmentId) {
        return getAssignment({ id: assignmentId })
      } else {
        this._backToAssignments()
      }
    }
  }

  componentDidUpdate() {
    const {
      selectedAssignment,
      router: {
        location: { search, state },
      },
      actions: { getAssignment },
    } = this.props
    const assignmentId = get(state, 'assignmentId') || get(queryString.parse(search), 'assignmentId')
    if (!isNil(assignmentId) && selectedAssignment?.id !== assignmentId) {
      return getAssignment({ id: assignmentId })
    }
  }

  clearResponseId = () => {
    const { router } = this.props
    router.navigate(router.location.pathname, { replace: true, state: {} })
  }

  _backToAssignments = () => {
    const { router } = this.props
    router.navigate(CLIENT_HOME.route, { state: { sessionCount: this.sessionCount } })
  }

  _handleSubmitComment = async (inputValue: any, response: any) => {
    const {
      selectedConversation,
      selectedAssignment,
      userId,
      actions: { sendMessage, updateAssignmentResponse },
    } = this.props
    const data = {
      conversationId: selectedConversation?.conversation_id,
      message_body: inputValue,
      message_type: 'feedback',
      message_author_id: userId,
      message_author_type: 'provider',
      metadata: {
        activity_id: response.assignment_id,
        activity_response_id: response.id,
        title: selectedAssignment?.content.title,
        linkText: 'View Entry',
        submit_date: response.submit_date,
      },
    }
    const message = await sendMessage(data)
    await updateAssignmentResponse({ ...response, provider_feedback: message.message_id })
  }

  updateResponse = (response: any) => {
    const {
      selectedAssignment,
      actions: { updateAssignmentResponse, decreaseAssignmentUnreadCount },
    } = this.props
    updateAssignmentResponse(response).then(() =>
      decreaseAssignmentUnreadCount({
        assignmentId: selectedAssignment?.id,
      }),
    )
  }

  render() {
    const {
      router,
      selectedAssignment,
      clientDetails,
      currentSessionCount,
      selectedConversation,
      providerFullname,
      actions: { fetchMessage },
      isLtSupervisor,
    } = this.props
    if (!clientDetails) router.navigate(LT_CLIENTS.route)
    if (!selectedAssignment || isEmpty(selectedAssignment) || !currentSessionCount)
      return (
        <div styleName='loading-container'>
          <LoadingIndicator size={45} />
        </div>
      )

    // We want to make we have both a responseId and assignment data to be able to render the modal inside of Worksheet if needed
    if (get(router.location, 'state.responseId') && (selectedAssignment?.assignment_responses ?? []).length === 0) {
      return
    }
    return (
      <BootstrapContainer>
        <div styleName='container'>
          <MiniBackNav backFunc={this._backToAssignments} subText={`Activities for Session ${this.sessionCount}`} />
          <Worksheet
            readOnly
            updateResponse={this.updateResponse}
            responseId={get(router.location, 'state.responseId')}
            fetchComment={({ messageId }: any) =>
              fetchMessage({ conversationId: selectedConversation?.conversation_id, messageId })
            }
            content={cloneDeep({ ...(selectedAssignment as any), meta_data: selectedAssignment?.content_meta_data })}
            data={(selectedAssignment?.assignment_responses ?? []) as AssignmentResponse[] & never[]}
            clearResponseId={this.clearResponseId}
            sessionCount={currentSessionCount}
            itemActions={router.outletContext.activityActions.filter((action: any) =>
              action.shouldShow(selectedAssignment, this.sessionCount),
            )}
            submitComment={this._handleSubmitComment}
            receiverName={clientDetails?.first_name ?? ''}
            userRole='provider'
            supervisorMode={isLtSupervisor}
            providerFullName={providerFullname}
            track={track}
          />
        </div>
      </BootstrapContainer>
    )
  }

  componentWillUnmount() {
    const { actions } = this.props
    actions.clearSelectedAssignment()
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    selectedAssignment: getClientAssignmentDetails(state),
    clientDetails: getClientDetailsData(state),
    providerFullname: getProviderFullName(state),
    selectedConversation: getSelectedConversation(state),
    userId: getAuthUserId(state),
    currentSessionCount: getClientCurrentSessionCount(state),
    isLtSupervisor: getAuthIsLTSupervisor(state),
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators(
      {
        getAssignment,
        getAssignments,
        sendMessage,
        updateAssignmentResponse,
        clearSelectedAssignment,
        fetchMessage,
        decreaseAssignmentUnreadCount,
      },
      dispatch,
    ),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CSSModules(AssignmentDetails, styles)))
