import { createSelector } from 'reselect'

import { RootState } from '../store'

const getHealthPlanState = (state: RootState) => state?.healthPlan

export const getHealthPlanICD10s = createSelector(getHealthPlanState, (healthPlanState) => healthPlanState?.icd10s)

export const getHealthPlanCurrentCharge = createSelector(
  getHealthPlanState,
  (healthPlanState) => healthPlanState?.currentCharge,
)

export const getHealthPlanHPEligibility = createSelector(
  getHealthPlanState,
  (healthPlanState) => healthPlanState?.HPEligibility,
)
