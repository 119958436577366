import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../../../../data/store'

const getDownloadCSVState = (state: RootState) => state?.downloadCSV

export const getDownloadCSVModalState = createSelector(
  getDownloadCSVState,
  (downloadCSVState) => downloadCSVState?.showDownloadCSVModal,
)
