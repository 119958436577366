import React, { FunctionComponent, useEffect } from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'

import { noop } from 'lodash-es'
import styled from 'styled-components/native'

import { BodyText, Size } from '../../atoms/bodyText/BodyText'
import { FullWidthImage } from '../../atoms/fullWidthImage/FullWidthImage'
import { LinkIcon } from '../../atoms/icons/LinkIcon'
import { StyledMarkdown } from '../../atoms/styledMarkdown/StyledMarkdown'
import { AccessibilityRolesNative } from '../../constants'
import { ThemeType, tID } from '../../utils'

type InfoSheetLinks = {
  linkText: string
  linkUrl: string
}

export type InfoSheetBodyProps = {
  instructions?: string | null
  content?: string
  links?: InfoSheetLinks[]
  imageURL?: string
  onLinkClicked: (url: string) => void
  onView?: () => void
}

const ScrollContainer = styled.ScrollView`
  padding: 24px;
`

const ContentContainer = styled.View`
  margin-bottom: 32px;
`

const LinkContainer = styled.Pressable<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  padding: '16px',
  border: `1px solid ${theme.colors.borderDefault}`,
  borderRadius: '8px',
  marginBottom: '32px',
}))

const IconContainer = styled.View`
  margin-right: 16px;
`

export const InfoSheetBody: FunctionComponent<InfoSheetBodyProps> = ({
  instructions = '',
  content,
  links,
  imageURL,
  onLinkClicked,
  onView = noop,
}) => {
  useEffect(() => {
    onView()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SafeAreaView edges={['bottom']}>
      <ScrollContainer>
        {Boolean(imageURL) && <FullWidthImage url={imageURL ?? ''} />}
        <BodyText accessible={Boolean(instructions)} size={Size.DEFAULT} text={instructions ?? ''} />
        {Boolean(content) && (
          <ContentContainer>
            <StyledMarkdown content={content} customStyles={{ p3: { marginBottom: 10 } }} />
          </ContentContainer>
        )}
        {links?.map(({ linkText, linkUrl }) => (
          <LinkContainer
            key={linkUrl}
            onPress={() => onLinkClicked(linkUrl)}
            testID={tID('InfoSheetBody-link')}
            accessibilityRole={AccessibilityRolesNative.LINK}
          >
            <IconContainer>
              <LinkIcon size={35} />
            </IconContainer>
            <BodyText size={Size.SMALL} text={linkText} wrap />
          </LinkContainer>
        ))}
      </ScrollContainer>
    </SafeAreaView>
  )
}
