import { createSelector } from '@reduxjs/toolkit'

import { getClientDataCountry } from '../lyraTherapy/clientSelectors'
import { RootState } from '../store'

export const getRequestPaymentState = (state: RootState) => state?.requestPayment

export const getRequestPaymentCustomers = createSelector(
  getRequestPaymentState,
  (requestPaymentState) => requestPaymentState?.customers,
)
export const getRequestPaymentCustomersByCountry = createSelector(
  [getRequestPaymentState],
  (requestPaymentState) => requestPaymentState?.customersByCountry,
)

export const getRequestPaymentClientCustomers = createSelector(
  [getRequestPaymentCustomersByCountry, getClientDataCountry],
  (customersByCountry, clientCountry) => (clientCountry ? customersByCountry?.[clientCountry] : undefined),
)

export const getRequestPaymentSelectedProvider = createSelector(
  getRequestPaymentState,
  (requestPaymentState) => requestPaymentState?.selectedProvider,
)

export const getRequestPaymentRates = createSelector(
  getRequestPaymentState,
  (requestPaymentState) => requestPaymentState?.rates,
)

export const getRequestPaymentProvidersList = createSelector(
  getRequestPaymentState,
  (requestPaymentState) => requestPaymentState?.providersList,
)

export const getRequestPaymentCopy = createSelector(
  getRequestPaymentState,
  (requestPaymentState) => requestPaymentState?.copy,
)

export const getRequestPaymentPatient = createSelector(
  getRequestPaymentState,
  (requestPaymentState) => requestPaymentState?.patient,
)

export const getRequestPaymentData = createSelector(
  getRequestPaymentState,
  (requestPaymentState) => requestPaymentState?.data,
)

export const getRequestPaymentDataSessionTrackingStatus = createSelector(
  getRequestPaymentData,
  (requestPaymentData) => requestPaymentData?.session_tracking_status,
)

export const getRequestPaymentDataOutcomeReminderStatus = createSelector(
  getRequestPaymentData,
  (requestPaymentData) => requestPaymentData?.outcome_reminder_status,
)

export const getRequestPaymentResponse = createSelector(
  getRequestPaymentState,
  (requestPaymentState) => requestPaymentState?.response,
)

export const getRequestPaymentResponseData = createSelector(
  getRequestPaymentResponse,
  (requestPaymentResponse) => requestPaymentResponse?.data,
)

export const getRequestPaymentResponseDataSessionTrackingStatus = createSelector(
  getRequestPaymentResponseData,
  (requestPaymentResponseData) => requestPaymentResponseData?.session_tracking_status,
)

export const getRequestPaymentShowDuplicateModal = createSelector(
  getRequestPaymentState,
  (requestPaymentState) => requestPaymentState?.showDuplicateModal,
)

export const getRequestPaymentShowCancelledSessionsExceededModal = createSelector(
  getRequestPaymentState,
  (requestPaymentState) => requestPaymentState?.showCancelledSessionsExceededModal,
)

export const getRequestPaymentDataData = createSelector(
  getRequestPaymentData,
  (requestPaymentData) => requestPaymentData?.data,
)

export const getRequestPaymentLyraCodeValidationInfo = createSelector(
  getRequestPaymentState,
  (requestPaymentState) => requestPaymentState?.lyraCodeValidationInfo,
)

export const getRequestPaymentShowIneligibleClientModal = createSelector(
  getRequestPaymentState,
  (requestPaymentState) => requestPaymentState?.showIneligibleClientModal,
)

export const getRequestPaymentEligibilityStatus = createSelector(
  getRequestPaymentState,
  (requestPaymentState) => requestPaymentState?.eligibility?.status,
)
