import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { addDays, format } from 'date-fns'
import pluralize from 'pluralize'

import { mixpanelEvents } from '../../../mixpanel/mixpanelConstants'
import { track } from '../../../mixpanel/mixpanelTracking'
import { ProviderAvailabilityResponse } from '../common/components/availabilityCard/AvailabilityCard'
import { BCC_ROLES, ROLES } from '../common/constants/appConstants'
import { getTotalCapacity, hasRole } from '../common/utils/utils'
import { getAuthRoles, getAuthUserCapacityValue } from '../data/auth/authSelectors'
import { getClientAppointmentsProviderAvailability } from '../data/lyraTherapy/clientSelectors'

export type CalendarAvailabilitiesAlertPopUpValues = {
  alertText: string
  handleCloseAlertPopUpModal: () => void
  handleLearnMore: () => void
  handleOpenCalendar: () => void
  hasProperRoleToSeeAlert: boolean
  isAlertPopUpOpen: boolean
}

export const useCalendarAvailabilitiesAlertPopUp = () => {
  const [isAlertPopUpOpen, setAlertPopupOpen] = useState(false)
  const capacities = useSelector(getAuthUserCapacityValue)
  const providerAvailability: ProviderAvailabilityResponse[] =
    useSelector(getClientAppointmentsProviderAvailability) ?? []
  const providerAvailabilities = providerAvailability && providerAvailability[0]?.availability
  const calendarAvailabilities = providerAvailabilities?.length ?? 0
  const totalCapacity = getTotalCapacity(capacities)
  const capacityAvailabilityDifference = totalCapacity - calendarAvailabilities
  const dateRange = `${format(new Date(), 'M/d')}-${format(addDays(new Date(), 14), 'M/d')}`
  const userRoles: string[] = useSelector(getAuthRoles)

  const hasProperRoleToSeeAlert = hasRole(userRoles, [ROLES.LT_COACH, ROLES.LT_SS_COACH])

  const handleCloseAlertPopUpModal = () => {
    track({ event: mixpanelEvents.PERSISTENT_AVAILABILITY_ALERT_CLOSE_MODAL })
    setAlertPopupOpen(false)
  }

  const handleOpenCalendar = () => {
    track({ event: mixpanelEvents.PERSISTENT_AVAILABILITY_ALERT_OPEN_CALENDAR })
    window.open('https://calendar.google.com')
  }
  const handleLearnMore = () => {
    track({ event: mixpanelEvents.PERSISTENT_AVAILABILITY_ALERT_LEARN_MORE })
    window.open(
      hasRole(userRoles, BCC_ROLES)
        ? 'https://coaching-lyrahealth.zendesk.com/hc/en-us/articles/16389043165843-Caseload-Best-Practices-for-FT-PT'
        : 'https://bct-lyrahealth.zendesk.com/hc/en-us/articles/1500004604002-Calendar-Best-Practices',
    )
  }

  useEffect(() => {
    if (Array.isArray(providerAvailabilities) && calendarAvailabilities < totalCapacity) {
      setAlertPopupOpen(true)
      track({ event: mixpanelEvents.PERSISTENT_AVAILABILITY_ALERT_MODAL_SHOWN })
    } else {
      setAlertPopupOpen(false)
    }
  }, [calendarAvailabilities, providerAvailabilities, setAlertPopupOpen, totalCapacity])

  const alertText = `To help clients find a time that works for them, open ${capacityAvailabilityDifference} calendar ${pluralize(
    'spot',
    capacityAvailabilityDifference,
  )} between ${dateRange}`
  return {
    alertText,
    handleCloseAlertPopUpModal,
    handleLearnMore,
    handleOpenCalendar,
    hasProperRoleToSeeAlert,
    isAlertPopUpOpen,
  }
}
